import React from 'react'
import {FaBan,FaCheck,
    FaArrowAltCircleDown,
    FaRegCopy
} from 'react-icons/fa'

import {MdRefresh} from 'react-icons/md'
import TypeSelect from './TypeSelect';


import console from '../../util/consoleVerify'

const ModalCopyRecord=({
    languageDisplay,
    setShow,
    lb,

    basicData,
    genFakeValue,

    dataLabel,

    editInputState,
    setEditInputState,
    submitFunction,
    lastRecordId
})=>{

    return(
    <div className="Modal-background">
        <div className="Modal-box2">
           
            <div className="Modal-body"
                 style={{display:"block"}}
            >

                <div className='flex-center-center w-100'
                     style={{fontSize:"1.2rem"}}
                >
                    {languageDisplay
                    ?languageDisplay.saveAsNewRecord
                        ?languageDisplay.saveAsNewRecord
                        :"Save as New Record"
                    :"Save as New Record"
                    }
                </div>


                <div className="xc10 lc10 mc10 sc12 p-1 bd-blacks"
                    style={{display:"flex",alignItems:"baseline"}}    
                >    
                    <div className="xc6">
                        <p  className="label table-p"
                            //style={{color:dataLabel[b].lineColor}}
                            >{lb}</p>
                    </div>
                    <div className="xc6">
                        <input
                            style={{
                                backgroundColor:"rgba(230,230,230,1)"
                            }}
                            type="number"
                            value={editInputState.id}
                            disabled="disabled"
                            onChange={e=>{
                              
                            }}
                        />
                    </div>
                </div>



                <div className="xc10 lc10 mc10 sc12 p-1 bd-blacks"
                    style={{display:"flex",alignItems:"baseline"}}    
                >    
                    <div className="xc6"
                        style={{
                            display:"flex",
                            alignItems:"center",
                            justifyContent:"flex-start"
                        }}
                    >
                        <p  className="label table-p"

                            //style={{color:dataLabel[b].lineColor}}
                            >
                            {languageDisplay
                                ?languageDisplay.idOfNewRecord
                                    ?languageDisplay.idOfNewRecord
                                    :"Id of New Record"
                                :"Id of New Record"
                            }
                        </p>

                        <MdRefresh className='md-icon'
                            style={{marginLeft:"1rem"}}

                            onClick={e=>{
                                const tempNewId=parseFloat(lastRecordId)+1
                                setEditInputState({
                                    ...editInputState,
                                    newId:tempNewId
                                })
                            }}
                        />
                    </div>
                    <div className="xc6">
                        <input
                            style={{backgroundColor:"rgba(230,230,230,1)"}}
                            type="number"
                            value={editInputState.newId}
                            onChange={e=>{
                                //console.log(e.target.value)
                                setEditInputState(
                                    {...editInputState,
                                        newId:e.target.value
                                    }
                                )
                            }}
                        />
                    </div>
                </div>




                <div className="xc10 lc10 mc10 sc12 p-1 bd-blacks"
                    style={{display:"flex",alignItems:"baseline"}}    
                >    
                    <div className="xc6">
                        <p  className="label table-p"
                        >
                            {
                                dataLabel["type"].lb
                            } 
                                
                        </p>
                    </div>
                    <div className="xc6">
                        <TypeSelect
                            value={genFakeValue(editInputState["type"])}//{"1.one-1111"}//{filterState.type.value}
                            typeArray={basicData.typeArray}
                            onChange={(e)=>{

                                    if(e>0){
                                        console.log(e)
                                        setEditInputState({
                                            ...editInputState,
                                            ["type"]:e
                                        })
                                    }
                                  
                            }}
                        />
                    </div>
                </div>






            </div>
           
           
            <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                            //setShowError(true)
                            
                            submitFunction()
                            if(setShow){setShow(false)}
                            //setShow(true)
                        }}
                    >
                        <FaRegCopy/>
                    </button>
                </div>
                <div>
                    <button
                        onClick={e=>{
                            if(setShow){setShow(false)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>

        </div>
    </div>
    
    )
}


export default ModalCopyRecord
