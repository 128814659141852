import React from 'react'
import cosmeticUtil from '../../util/cosmeticUtil'
import Galleryone from '../../component/galleryone/Galleryone'
import dataTemplate from './dataTemplate'

import {MdCheckBox,MdCheckBoxOutlineBlank} from 'react-icons/md';
import console from '../../util/consoleVerify'

const {checkWindowLandscape}=cosmeticUtil
const {itemDateArray,itemStringArray,itemNumberArray,itemBooleanArray}=dataTemplate


function Album({
    pageData,dataLabel,
    editData,setEditData,
    dfCommon,

    keyArray
}){


    const genBgColor=(v)=>{
        let temp="#eee"//"#edf8ef"//"#bbc9a5"9f9e99
        if(editData&&v){
            if(editData._id==v._id){
                temp="#c0e6c6"//"#A0DAA9"//"#DFCFBE" 
            }
        }    
        return temp
    }

    const genLineColor=(i)=>{
        let temp="black"
        if(i%2===1){
            temp="brown"
        }
        return temp
    }
    
    //const [editData,setEditData]=React.useState(null)

    const genDate=(i,b)=>{
        let temp=""
        if(i[b]){
            if(new Date(i[b])){
                const date1=new Date(i[b]).toISOString()
                const date2=new Date(date1).toLocaleString('en-GB')

                temp=date2
            }
        }
        return temp
    }

    const genNumber=(i,b)=>{
        let temp=""
        if(i[b]){
            const temp2=parseFloat(i[b])
            if(temp2){
                temp=temp2
            }
        }
        return temp
    }

    const genBoolean=(i,b)=>{
        let temp=""

        if(i[b]){
            if(i[b]===true){
                return <MdCheckBox
                            style={{fontSize:"1.5rem"}}
                        />
            }
            else {
                return <MdCheckBoxOutlineBlank
                            style={{fontSize:"1.5rem"}}
                        />
            }
        }
        else {
            return null
        }
    }

    const genString=(i,b)=>{
        let temp=""
        if(i[b]===undefined||i[b]===null){

        }
        else {
            temp=i[b]
        }
        return temp
    }
    //===========================
    const genAlbumPhotoHeight=()=>{
        let tempAlbumPhotoHeight=50

        if(dfCommon){
            if(dfCommon.albumPhotoHeight>0){
                tempAlbumPhotoHeight=parseFloat(dfCommon.albumPhotoHeight)
            }
        }

        return tempAlbumPhotoHeight
    }


    const genAlbumHeight=()=>{
        let tempAlbumHeight=100

        if(dfCommon){
            if(dfCommon.albumHeight>0){
                tempAlbumHeight=parseFloat(dfCommon.albumHeight)
            }
        }

        return tempAlbumHeight
    }

    //===========================

    const genWidthOfCard=()=>{
        let tempText="albumxc8 albumlc6 albummc4 albumsc1"

        if(dfCommon){
            if(dfCommon.albumxc&&dfCommon.albumlc&&dfCommon.albummc&&dfCommon.albumsc){

                const albumxc=dfCommon.albumxc
                const albumlc=dfCommon.albumlc
                const albummc=dfCommon.albummc
                const albumsc=dfCommon.albumsc
                
                tempText=`albumxc${albumxc} albumlc${albumlc} albummc${albummc} albumsc${albumsc}`
            }
        }

        //console.log(tempText)
        
        return tempText
    }


    //let tempArray=Object.keys(dataLabel)
    let tempArray=keyArray

    tempArray.sort((a, b) => {
        return dataLabel[a].albumLineOrder - dataLabel[b].albumLineOrder;
    });



    const keyObjectArray=tempArray

    return (
    <div className="h-100 w-100 Modal-box"
         style={{padding:"1rem",borderRadius:"0",
                 background:dfCommon.albumBackgroundColor
                 ?dfCommon.albumBackgroundColor
                 :"transparent"
                }}
    >
        <div className='flex-center-center jc-start'
             style={{height:"100%"}}
        >
        {
            pageData.map((i,idxI)=>(
                <div key={idxI}
                     className={genWidthOfCard()}
                     style={{
                        height:`${genAlbumHeight()}%`,
                        padding:"1rem",
                    }}
                >
                    <div
                        style={{
                            height:"100%",
                            width:"100%",
                            boxShadow:"2px 5px 5px 2px #444",
                            border:"1px solid white",
                        }}
                    >
                        <div className="w-100 flex-center-center" 
                                style={{
                                    height:`${genAlbumPhotoHeight()}%`,
                                    width:"100%",margin:"auto",
                                    background:"white"
                                }}
                            >
                                <Galleryone imgarrs={i.photoUrl1} 
                                    width={"100%"}
                                
                                />
                        </div>


                        <div className='w-100'
                            style={{
                                height:`${100-genAlbumPhotoHeight()}%`,
                                backgroundColor:genBgColor(i),
                                //padding:"0.5rem",
                                overflowY:"auto"
                            }}

                            onClick={e=>{
                                setEditData(i)
                            }}
                        >


                            {
                                keyObjectArray.map((b,idxB)=>{

                                    const key0_5=b.substring(0,5)

                                    if(b==="id"&&dataLabel.id.showAlbumLine){
                                        return (
                                            <div key={idxB}
                                                 style={{
                                                    padding:"0 0.5rem",
                                                    backgroundColor:dataLabel[b].albumLineBackgroundColor,
                                                    color:`${dataLabel.id.albumLineColor}`
                                                 }}
                                            >
                                                <div>
                                                    {dataLabel[b].albumShowLb
                                                    ?`${dataLabel.id.lb}: ${i.id}`
                                                    :`${i.id}`
                                                    }
                                                </div>
                                                {
                                                    dataLabel.id.albumLineBreak
                                                    ?<br/>
                                                    :null
                                                }
                                            </div>
                                        )
                                    }

                                    else if(b==="type"&&dataLabel.type.showAlbumLine){
                                        return(
                                            <div key={idxB}
                                                style={{
                                                    backgroundColor:`${dataLabel.type.albumLineBackgroundColor}`
                                                }}    
                                               
                                            >
                                                <div
                                                     style={{
                                                        padding:"0 0.5rem",
                                                        color:`${dataLabel.type.albumLineColor}`
                                                     }}
                                                >
                                                    {dataLabel[b].albumShowLb
                                                    ?`${dataLabel[b].lb}: ${genString(i,b)}`
                                                    :`${genString(i,b)}`
                                                    }
                                                </div>
                                                {
                                                    dataLabel.type.albumLineBreak
                                                    ?<br/>
                                                    :null
                                                }
                                            </div>
                                        )
                                    }

                                    else if(
                                        (key0_5==="itemD"&&dataLabel[b].showAlbumLine)||
                                        (b==="createdAt"&&dataLabel[b].showAlbumLine)||
                                        (b==="updatedAt"&&dataLabel[b].showAlbumLine)
                                    ){
                                        return (
                                            <div key={idxB}
                                                style={{
                                                    lineHeight:"1.5rem",
                                                    color:dataLabel[b].albumLineColor,
                                                    padding:"0 0.5rem",
                                                    backgroundColor:dataLabel[b].albumLineBackgroundColor,
                                                }}
                                            >
                                                <div>
                                                    {dataLabel[b].albumShowLb
                                                    ?`${dataLabel[b].lb}: ${genDate(i,b)}`
                                                    :`${genDate(i,b)}`
                                                    }
                                                </div>

                                                {
                                                    dataLabel[b].albumLineBreak
                                                    ?<br/>
                                                    :null
                                                }
                                            </div>
                                        )
                                    }

                                    else if(key0_5==="itemS"&&dataLabel[b].showAlbumLine){
                                        return(
                                            <div key={idxB}
                                                style={{
                                                    lineHeight:"1.5rem",
                                                    color:dataLabel[b].albumLineColor,
                                                    padding:"0 0.5rem",
                                                    backgroundColor:dataLabel[b].albumLineBackgroundColor,
                                                }}
                                            >
                                                <div>
                                                    {dataLabel[b].albumShowLb
                                                    ?`${dataLabel[b].lb}: ${genString(i,b)}`
                                                    :`${genString(i,b)}`
                                                    }
                                                </div>
                                                {
                                                    dataLabel[b].albumLineBreak
                                                    ?<br/>
                                                    :null
                                                }
                                            </div>
                                        )
                                    }

                                    else if(key0_5==="itemN"&&dataLabel[b].showAlbumLine){
                                        return (
                                        <div key={idxB}
                                            style={{
                                                lineHeight:"1.5rem",
                                                color:dataLabel[b].albumLineColor,
                                                padding:"0 0.5rem",
                                                backgroundColor:dataLabel[b].albumLineBackgroundColor,
                                            }}
                                        >
                                            <div>
                                                {dataLabel[b].albumShowLb
                                                ?`${dataLabel[b].lb}: ${genNumber(i,b)}`
                                                :`${genNumber(i,b)}`
                                                }
                                            </div>
                                            {
                                                dataLabel[b].albumLineBreak
                                                ?<br/>
                                                :null
                                            }
                                        </div>
                                        )
                                    }

                                    else if(
                                        (key0_5==="itemB"&&dataLabel[b].showAlbumLine)||
                                        (b==="active"&&dataLabel[b].showAlbumLine)
                                    ){
                                        return(
                                            <div key={idxB}
                                                style={{
                                                    lineHeight:"1.5rem",
                                                    color:dataLabel[b].albumLineColor,
                                                    padding:"0 0.5rem",
                                                    backgroundColor:dataLabel[b].albumLineBackgroundColor,
                                                }}
                                            >
                                                <div style={{display:"flex"}}>
                                                    <div
                                                        style={{marginRight:"1rem"}}
                                                    >
                                                        {dataLabel[b].albumShowLb
                                                        ?`${dataLabel[b].lb}: `
                                                        :null
                                                        }
                                                    </div>
                                                    {
                                                        genBoolean(i,b)
                                                    }
                                                </div>
                                                {
                                                    dataLabel[b].albumLineBreak
                                                    ?<br/>
                                                    :null
                                                }
                                            </div>
                                        )
                                    }
                                    
                                })
                            }

                            
                            
                           
                        </div>
                    
                       
                    </div>

                </div>
            ))
        }
        </div>
        
    </div>
  
    )

}

export default Album