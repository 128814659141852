
//====================================
//type
//itemDate1,itemDate2,itemDate3,itemDate4,itemDate5,
//itemString1,itemString2,itemString3,itemString4,itemString5,
//itemString6,itemString7,itemString8,itemString9,itemString10,
//itemString11,itemString12,itemString13,itemString14,itemString15,
//itemString16,itemString17,itemString18,itemString19,itemString20,
//itemNumber1,itemNumber2,itemNumber3,itemNumber4,itemNumber5,
//itemNumber6,itemNumber7,itemNumber8,itemNumber9,itemNumber10,
//itemBoolean1,itemBoolean2,itemBoolean3,itemBoolean4,itemBoolean5

//=====================================
const timeZoneObj={

  "+1200":12,
  "+1100":11,
  "+1000":10,
  "+0900":9,

  "+0800":8,
  "+0700":7,
  "+0600":6,
  "+0500":5,
  
  "+0400":4,
  "+0300":3,
  "+0200":2,
  "+0100":1,
  
  "+0000":0,
  
  "-0100":-1,
  "-0200":-2,
  "-0300":-3,
  "-0400":-4,
  
  "-0500":-5,
  "-0600":-6,
  "-0700":-7,
  "-0800":-8,
  
  "-0900":-9,
  "-1000":-10,
  "-1100":-11,
  "-1200":-12,

}

//=====================================
const tempDate=new Date()

const tempA1=tempDate.toString().split("GMT")
const tempA2=tempA1[1].substring(0,5)
const tempA3=new Date(`${tempDate.toISOString().substring(0,10)}T00:00:00Z`)
const tempA5=new Date(tempA3.setHours(tempA3.getHours()-timeZoneObj[tempA2])).toISOString()


const toDate=tempA5


const userTableTemplate={
  
  id:{
    colOrder:1,lb:"id",type:"string",
    width:100,showCol:true,showColHead:true
  },
  username:{
    colOrder:2,lb:"username",type:"string",
    width:100,showCol:true,showColHead:true
  },
  password:{
    colOrder:3,lb:"password",type:"string",
    width:100,showCol:true,showColHead:true
  },
  active:{
    colOrder:4,lb:"active",type:"boolean",
    width:100,showCol:true,showColHead:true
  },
  userLevel:{
    colOrder:5,lb:"User Level",type:"string",
    width:100,showCol:true,showColHead:true
  },
  name:{
    colOrder:6,lb:"name",type:"string",
    width:100,showCol:true,showColHead:true
  },
  surname:{
    colOrder:7,lb:"surname",type:"string",
    width:100,showCol:true,showColHead:true
  }

}
//=====================================
const bankDetail={
  bankName:"K-Bank",
  accountNumber:"123-456-789",
  accountName:"Nikhom Phutthawithee"
}

//=====================================


//=====================================
const shop={
  shopName:"shopa",
  password:"shopa",
  active:true,
  code:"123456789",
  dateIn:"2023-01-01T12:40:20.964Z",
  
  ownerName:"ownera",
  ownerSurName:"surname",
  ownerEmail:"a@gmail.com",
  recoveryPassword:"abcd",

  expirationDate:"2023-01-10T12:40:20.964Z",
  tempExpirationDate:"2023-10-01T15:40:20.964Z",
  monthlyFee:100,
  remainingBalance:90,
  photoUrl1:[],

  timestamp:"2023-01-01T12:40:20.964Z",
  shopString1:null,
  shopString2:null,
  shopString3:null,
  shopString4:null,
  shopString5:null,

  shopNumber1:null,
  shopNumber2:null,
  shopNumber3:null,
  shopNumber4:null,
  shopNumber5:null,

  shopBoolean1:true,
  shopBoolean2:true,
  shopBoolean3:true,
  shopBoolean4:true,
  shopBoolean5:true
}

const user={
  _id:"001",
  id:1,
  username:"usera",
  password:"usera",
  active:true,
  code:"123456789",
  userLevel:"admin",

  shopId:"shopa",
  dateIn:"2023-01-01T12:40:20.964Z",
  name:"usera",
  surname:"surname",

  timestamp:"2023-01-01T12:40:20.964Z",

  userString1:null,
  userString2:null,
  userString3:null,
  userString4:null,
  userString5:null,

  userNumber1:null,
  userNumber2:null,
  userNumber3:null,
  userNumber4:null,
  userNumber5:null,

  userBoolean1:true,
  userBoolean2:true,
  userBoolean3:true,
  userBoolean4:true,
  userBoolean5:true
}

//================================
const blankAddInputStateForShop={
  shopName:"",
  password:"",
  active:true,
  code:"",
  dateIn:toDate,
  
  ownerName:"",
  ownerSurName:"",
  ownerEmail:"",
  recoveryPassword:"",

  expirationDate:toDate,
  tempExpirationDate:toDate,
  monthlyFee:0,
  remainingBalance:0,
  photoUrl1:[],

  timestamp:toDate,
}


const blankAddInputStateForUser={
  _id:"",
  id:2,
  username:"blank",
  password:"",
  active:true,
  code:"",
  userLevel:"staff",

  shopId:"blank",
  dateIn:toDate,
  name:"blank",
  surname:"blank",

  timestamp:toDate,
  
  photoUrl1:[], //5 

  userString1:null,
  userString2:null,
  userString3:null,
  userString4:null,
  userString5:null,

  userNumber1:null,
  userNumber2:null,
  userNumber3:null,
  userNumber4:null,
  userNumber5:null,

  userBoolean1:true,
  userBoolean2:true,
  userBoolean3:true,
  userBoolean4:true,
  userBoolean5:true
}

const blankAddInputStateForShopPayment={

  id:"100100", //1
  userId:1, //2
  shopId:"blank", //3
  shopName:"blank",
  timestamp:toDate,//4
  photoUrl1:[], //5 
  
  isCheck:true, //6
  hasProblem:false, //7

  transferDate:toDate, //8
  accountName:"Mee", //9
  accountNumber:"123456789", //10
  transferAmount:100, //11
  fromBank:"kbank", //12
  checkBy:"Nikhom", //13
  
  addMonth:0, //14
  newExpirationDate:toDate, //15

  dateIn:toDate, //16
  ownerName:"usera", //17
  ownerSurName:"surname", //18
  ownerEmail:"a@gmail.com", //19
  expirationDate:toDate, //20 
  tempExpirationDate:toDate, //21
  monthlyFee:100, //22
  remainingBalance:0, //23
  active:true,

 
}

const shopPayment={

  id:"100100", //1
  userId:1, //2
  shopId:"shopa", //3
  shopName:"shopa",
  timestamp:"2023-01-01T12:40:20.964Z",//4
  photoUrl1:[], //5 
  
  isCheck:true, //6
  hasProblem:false, //7

  transferDate:"2023-01-01T12:40:20.964Z", //8
  accountName:"Mee", //9
  accountNumber:"123456789", //10
  transferAmount:100, //11
  fromBank:"kbank", //12
  checkBy:"Nikhom", //13
  
  addMonth:{type:Number}, //14
  newExpirationDate:{type:Date}, //15

  dateIn:"2023-01-01T12:40:20.964Z", //16
  ownerName:"usera", //17
  ownerSurName:"surname", //18
  ownerEmail:"a@gmail.com", //19
  expirationDate:"2023-01-01T12:40:20.964Z", //20 
  tempExpirationDate:"2023-01-01T12:40:20.964Z", //21
  monthlyFee:100, //22
  remainingBalance:0, //23
  active:true,

  shopPaymentString1:null,
  shopPaymentString2:null,
  shopPaymentString3:null,
  shopPaymentString4:null,
  shopPaymentString5:null,

  shopPaymentNumber1:null,
  shopPaymentNumber2:null,
  shopPaymentNumber3:null,
  shopPaymentNumber4:null,
  shopPaymentNumber5:null,

  shopPaymentBoolean1:true,
  shopPaymentBoolean2:true,
  shopPaymentBoolean3:true,
  shopPaymentBoolean4:true,
  shopPaymentBoolean5:true
}

//====================================
const userArray=[
  user,
  {...user,_id:"002",id:2,username:"userB",password:"userB",userLevel:"supervisor"},
  {...user,_id:"003",id:3,username:"userC",password:"userC",userLevel:"staff",active:false},
  {...user,_id:"004",id:4,username:"userD",password:"userD",userLevel:"staff",active:false},
  {...user,_id:"005",id:5,username:"userE",password:"userE",userLevel:"staff"},
  {...user,_id:"006",id:6,username:"userF",password:"userF",userLevel:"staff"},
  {...user,_id:"007",id:7,username:"userG",password:"userG",userLevel:"staff"},
  {...user,_id:"008",id:8,username:"userH",password:"userH",userLevel:"staff"},
  {...user,_id:"009",id:9,username:"userI",password:"userI",userLevel:"staff"},
  {...user,_id:"010",id:10,username:"userJ",password:"userJ",userLevel:"staff"},
  {...user,_id:"011",id:11,username:"userK",password:"userK",userLevel:"staff"},
  {...user,_id:"012",id:12,username:"userL",password:"userL",userLevel:"staff"},

  {...user,_id:"013",id:13,username:"userM",password:"userM",userLevel:"staff"},
  {...user,_id:"014",id:14,username:"userN",password:"userN",userLevel:"staff"},
  {...user,_id:"015",id:15,username:"userO",password:"userO",userLevel:"staff"},
  {...user,_id:"016",id:16,username:"userP",password:"userP",userLevel:"staff"},
  {...user,_id:"017",id:17,username:"userQ",password:"userQ",userLevel:"staff"},
  {...user,_id:"018",id:18,username:"userR",password:"userR",userLevel:"staff"},
  {...user,_id:"019",id:19,username:"userS",password:"userS",userLevel:"staff"},
  {...user,_id:"020",id:20,username:"userT",password:"userT",userLevel:"staff"},
  {...user,_id:"021",id:21,username:"userU",password:"userU",userLevel:"staff"},
  {...user,_id:"022",id:22,username:"userV",password:"userV",userLevel:"staff"},

  {...user,_id:"023",id:23,username:"userW",password:"userW",userLevel:"staff"},
  {...user,_id:"024",id:24,username:"userX",password:"userX",userLevel:"staff"},
  {...user,_id:"025",id:25,username:"userY",password:"userY",userLevel:"staff"},
  {...user,_id:"026",id:26,username:"userZ",password:"userZ",userLevel:"staff"},


]

//=====================================
const dataObj = 
    {
        _id:"0001",
        id: 1, 
        timestamp:new Date('2023-03-11T00:00:00').toISOString(),

        shopId:"vrp",
        userId:"one",
        
        itemDate1:new Date('2023-04-02T00:00:00').toISOString(),
        itemDate2:new Date('2023-03-21T00:00:00').toISOString(),
        itemDate3:new Date('2023-03-22T00:00:00').toISOString(),
        itemDate4:new Date('2023-03-23T00:00:00').toISOString(),
        itemDate5:new Date('2023-03-24T00:00:00').toISOString(),
    
        itemString1:"a1",
        itemString2:"b2",
        itemString3:"ccc",
        itemString4:"ddd",
        itemString5:"eee",
        itemString6:"fff",
        itemString7:"ggg",
        itemString8:"hhh",
        itemString9:"iii",
        itemString10:"jjj",
        itemString11:"kkk",
        itemString12:"lll",
        itemString13:"mmm",
        itemString14:"nnn",
        itemString15:"ooo",
        itemString16:"kkk",
        itemString17:"lll",
        itemString18:"mmm",
        itemString19:"nnn",
        itemString20:"ooo",
    
        itemNumber1:1,
        itemNumber2:2,
        itemNumber3:3,
        itemNumber4:4,
        itemNumber5:5,
        itemNumber6:6,
        itemNumber7:7,
        itemNumber8:8,
        itemNumber9:9,
        itemNumber10:10,
    
        itemBoolean1:true,
        itemBoolean2:true,
        itemBoolean3:true,
        itemBoolean4:true,
        itemBoolean5:true,
    
        photoUrl1:[
            "https://picsum.photos/id/237/200/300",
            "https://picsum.photos/id/100/200/300"
        ],
    
        type:"type1",

        displayId:1,
        displayName:"pink",
    
    }
   

const mData = [
 
    {...dataObj,_id:"0001",id:1,
        itemDate1:'2023-04-01',
        photoUrl1:[ "https://www.youtube.com/embed/bvRupqffcWE",
               "https://picsum.photos/id/299/200/300", 
               "https://picsum.photos/id/27/200/300",
               "https://picsum.photos/id/7/200/300",
              ]
    },
 
    {...dataObj,_id:"0002",id:2,
        itemDate1:new Date('2023-04-02T12:00:00').toISOString(),
        photoUrl1:[ "https://www.youtube.com/embed/7lxgZ_domYI",
                   "https://picsum.photos/id/302/200/300" ]
    },
    {...dataObj,_id:"0003",id:3,
        itemDate1:new Date('2023-04-03T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/27/200/300",
                   "https://picsum.photos/id/10/200/300" ]
    },
    {...dataObj,_id:"0004",id:4,
        itemDate1:new Date('2023-04-04T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/27/200/300",
                   "https://picsum.photos/id/10/200/300" ]
    },
    {...dataObj,_id:"0005",id:5,
        itemDate1:new Date('2023-04-05T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/67/200/300",
                   "https://picsum.photos/id/22/200/300" ]
    },
    {...dataObj,_id:"0006",id:6,
        itemDate1:new Date('2023-04-06T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/73/200/300",
                   "https://picsum.photos/id/15/200/300" ]
    },
    {...dataObj,_id:"0007",id:7,
        itemDate1:new Date('2023-04-07T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/77/200/300",
                   "https://picsum.photos/id/33/200/300" ]
    },
    {...dataObj,_id:"0008",id:8,
        itemDate1:new Date('2023-04-08T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/47/200/300",
                   "https://picsum.photos/id/41/200/300" ]
    },
    {...dataObj,_id:"0009",id:9,
        itemDate1:new Date('2023-04-09T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/57/200/300",
                   "https://picsum.photos/id/310/200/300" ]
    },
    {...dataObj,_id:"0010",id:10,
        itemDate1:new Date('2023-04-10T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/237/200/300",
                   "https://picsum.photos/id/180/200/300" ]
    },
    {...dataObj,_id:"0011",id:11,
        itemDate1:new Date('2023-04-11T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/227/200/300",
                   "https://picsum.photos/id/140/200/300" ]
    },
    {...dataObj,_id:"0012",id:12,
        itemDate1:new Date('2023-04-12T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/252/200/300",
                   "https://picsum.photos/id/130/200/300" ]
    },
    {...dataObj,_id:"0013",id:13,
        itemDate1:new Date('2023-05-13T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/28/200/300",
               "https://picsum.photos/id/10/200/300" ]
    },
    {...dataObj,_id:"0014",id:14,
        itemDate1:new Date('2023-05-14T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/71/200/300",
                "https://picsum.photos/id/10/200/300" ]
    },
    {...dataObj,_id:"0015",id:15,
        itemDate1:new Date('2023-05-15T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/27/200/300",
                ]
    },
    {...dataObj,_id:"0016",id:16,
        itemDate1:new Date('2023-05-16T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/21/200/300",
                ]
    },
    {...dataObj,_id:"0017",id:17,
        itemDate1:new Date('2023-06-17T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/72/200/300",
               "https://picsum.photos/id/10/200/300" ]
    },
    {...dataObj,_id:"0018",id:18,
        itemDate1:new Date('2024-06-18T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/18/200/300",
                "https://picsum.photos/id/28/200/300" ]
    },
    {...dataObj,_id:"0019",id:19,
        itemDate1:new Date('2024-06-19T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/19/200/300",
                ]
    },
    {...dataObj,_id:"0020",id:20,
        itemDate1:new Date('2021-06-20T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/20/200/300",
                ]
    },
    {...dataObj,_id:"0021",id:21,
        itemDate1:new Date('2021-07-01T12:00:00').toISOString(),
        photoUrl1:[ "https://picsum.photos/id/21/200/300",
               "https://picsum.photos/id/14/200/300" ]
  },
  {...dataObj,_id:"0022",id:22,
      itemDate1:new Date('2021-07-02T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/22/200/300",
                "https://picsum.photos/id/12/200/300" ]
  },
  {...dataObj,_id:"0023",id:23,
      itemDate1:new Date('2021-07-23T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/23/200/300",
            "https://picsum.photos/id/13/200/300" ]
  },
  {...dataObj,_id:"0024",id:24,
      itemDate1:new Date('2021-07-24T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/24/200/300",
              "https://picsum.photos/id/14/200/300" ]
  },
  {...dataObj,_id:"0025",id:25,
      itemDate1:new Date('2022-07-25T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/25/200/300",
              ]
  },
  {...dataObj,_id:"0026",id:26,
      itemDate1:new Date('2022-08-16T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/26/200/300",
              ]
  },
  {...dataObj,_id:"0027",id:27,
      itemDate1:new Date('2021-08-17T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/27/200/300",
            "https://picsum.photos/id/37/200/300" ]
  },
  {...dataObj,_id:"0028",id:28,
      itemDate1:new Date('2021-08-18T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/28/200/300",
              "https://picsum.photos/id/10/38/300" ]
  },
  
  {...dataObj,_id:"0029",id:29,
      itemDate1:new Date('2022-08-19T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/29/200/300",
              ]
  },
  {...dataObj,_id:"0030",id:30,
      itemDate1:new Date('2025-08-20T12:00:00').toISOString(),
      photoUrl1:[ "https://picsum.photos/id/30/200/300",
              ]
  },
]


const preDefinedList={
    "type":["type1","type2","type3"],
    "itemString1":["a1","a2","a3"],
    "itemString2":["b1","b2","b3"],
    "itemString3":["c1","c2","c3"],
    "itemString4":["d1","d2","d3"],
    "itemString5":["e1","e2","e3"],

    "itemString6":["f1","f2","f3"],
    "itemString7":["g1","g2","g3"],
    "itemString8":["h1","h2","h3"],
    "itemString9":["i1","i2","i3"],
    "itemString10":["j1","j2","j3"],

    "itemString11":["k1","k2","k3"],
    "itemString12":["l1","l2","l3"],
    "itemString13":["m1","m2","m3"],
    "itemString14":["n1","n2","n3"],
    "itemString15":["o1","o2","o3"],

    "itemString16":["p1","p2","p3"],
    "itemString17":["q1","q2","q3"],
    "itemString18":["r1","r2","r3"],
    "itemString19":["s1","s2","s3"],
    "itemString20":["t1","t2","t3"],

}


const dataFormat = {
    type: {lb: 'ประเภท', type: "string",width: 90, showCol: true, showColHead: true,colOrder:1,
        lineColor:"green",formType:"select",showLine:true,lineBreak:false,lineOrder:1,
        albumLineColor:"green",showAlbumLine:true,albumLineOrder:1,albumLineBreak:false,
        filterLineColor:"blue",showFilterLine:true,filterLineOrder:1,filterLineBreak:false,
        useSelection:true,selectArray:preDefinedList["type"],
        xc:3,lc:3,mc:6,sc:12,

        useMm:true,heightP:80,widthP:50,
        paddingTopP:0,paddingBottomP:0,paddingLeftP:0,paddingRightP:0,
        marginTopP:0,marginBottomP:0,marginLeftP:0,marginRightP:0,
        xPerLine:1,gridColumnGap:2,gridRowGap:2,qty:1,
        showBorder:true,paddingOfEachlabel:5,

        labelLineJustifyContent:"right",labelShowLine:true,
        labelLineFontSize:14,labelLineMargin:10,
        labelShowLb:true,labelColor:"black",labelOrder:1,

        useMmforForm:true,
        heightPforForm:150,widthPforForm:250,
        paddingTopPforForm:0,paddingBottomPforForm:0,
        paddingLeftPforForm:0,paddingRightPforForm:0,
        marginTopPforForm:0,marginBottomPforForm:0,
        marginLeftPforForm:0,marginRightPforForm:0,
        
        //formUseMm:true,
        formTop:10,formLeft:0,formColor:"black",formShowLb:false,formShowLine:true,
        scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
        formFontSize:18
    },

    id: {lb: 'ไอดี', type: "number",width: 90, showCol: true, showColHead: true,colOrder:2,
         lineColor:"green",showLine:true,lineBreak:false,lineOrder:2,
         albumLineColor:"green",showAlbumLine:true,albumLineOrder:2,albumLineBreak:false,
         filterLineColor:"blue",showFilterLine:true,filterLineOrder:2,filterLineBreak:false,

        labelLineJustifyContent:"left",labelShowLine:true,
        labelLineFontSize:14,labelLineMargin:10,
        labelShowLb:true,labelColor:"black",labelOrder:2,

        formTop:10,formLeft:50,formColor:"red",formShowLb:true,formShowLine:true,
        scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
        formFontSize:14
        },
    //----------------------------------
    itemDate1: {lb: 'วันที่1', 
                type: "datetime",width: 130, showCol: true, showColHead: true,colOrder:3,
                
                lineColor:"brown",showLine:true,lineBreak:false,lineOrder:7,useCurrentDate:false,
                albumLineColor:"red",showAlbumLine:true,albumLineOrder:7,albumLineBreak:false,
                filterLineColor:"blue",showFilterLine:true,filterLineOrder:3,filterLineBreak:false,

                labelLineJustifyContent:"center",labelShowLine:true,
                labelLineFontSize:14,labelLineMargin:10,
                labelShowLb:true,labelColor:"red",labelOrder:3,

                formTop:20,formLeft:0,formColor:"black",formShowLb:true,formShowLine:true,
                scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                formFontSize:14
            },
    itemDate2: {lb: 'วันที่2', type: "date",width: 130, showCol: true, showColHead: true,colOrder:4,
                lineColor:"brown",showLine:true,lineBreak:false,lineOrder:6,useCurrentDate:false,
                albumLineColor:"red",showAlbumLine:true,albumLineOrder:6,albumLineBreak:false,
                filterLineColor:"blue",showFilterLine:false,filterLineOrder:4,filterLineBreak:false,

                labelLineJustifyContent:"center",labelShowLine:true,
                labelLineFontSize:14,labelLineMargin:10,
                labelShowLb:true,labelColor:"green",labelOrder:4,

                formTop:20,formLeft:50,formColor:"black",formShowLb:false,formShowLine:true,
                scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                formFontSize:14
            },
    itemDate3: {lb: 'วันที่3', type: "time",width: 130, showCol: true, showColHead: true,colOrder:5,
                lineColor:"brown",showLine:true,lineBreak:false,lineOrder:5,useCurrentDate:false,
                albumLineColor:"yellow",showAlbumLine:true,albumLineOrder:5,albumLineBreak:false,
                filterLineColor:"blue",showFilterLine:false,filterLineOrder:5,filterLineBreak:false,

                labelLineJustifyContent:"center",labelShowLine:true,
                labelLineFontSize:14,labelLineMargin:10,
                labelShowLb:true,labelColor:"orange",labelOrder:5,

                formTop:20,formLeft:100,formColor:"black",formShowLb:false,formShowLine:true,
                scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                formFontSize:14
            },
    itemDate4: {lb: 'วันที่4', type: "date",width: 130, showCol: true, showColHead: true,colOrder:6,
                lineColor:"brown",showLine:true,lineBreak:false,lineOrder:4,useCurrentDate:false,
                albumLineColor:"red",showAlbumLine:true,albumLineOrder:4,albumLineBreak:false,                
                labelLineJustifyContent:"center",labelShowLine:true,

                filterLineColor:"blue",showFilterLine:false,filterLineOrder:6,filterLineBreak:false,

                labelLineJustifyContent:"center",labelShowLine:true,
                labelLineFontSize:14,labelLineMargin:10,
                labelShowLb:true,labelColor:"blue",labelOrder:6,

                formTop:20,formLeft:150,formColor:"black",formShowLb:false,formShowLine:true,
                scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                formFontSize:14
            },            

    itemDate5: {lb: 'วันที่5', type: "date",width: 130, showCol: true, showColHead: true,colOrder:7,
                lineColor:"brown",showLine:true,lineBreak:false,lineOrder:3,useCurrentDate:false,
                albumLineColor:"gray",showAlbumLine:true,albumLineOrder:3,albumLineBreak:false,
                filterLineColor:"blue",showFilterLine:false,filterLineOrder:7,filterLineBreak:false,

                labelLineJustifyContent:"center",labelShowLine:true,
                labelLineFontSize:14,labelLineMargin:10,
                labelShowLb:true,labelColor:"purple",labelOrder:7,

                formTop:20,formLeft:200,formColor:"black",formShowLb:false,formShowLine:true,
                scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                formFontSize:14
            },

    //-------------------------------------
    itemString1: {lb: 'สตริง1', type: "string",width: 90, showCol: true, showColHead: true,colOrder:8,
                  lineColor:"yellow",formType:"select",showLine:true,lineBreak:false,lineOrder:8,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:8,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:false,filterLineOrder:8,filterLineBreak:false,
                  useSelection:true,selectArray:preDefinedList["itemString1"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"gray",labelOrder:8,

                  formTop:30,formLeft:0,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString2: {lb: 'สตริง2', type: "string",width: 90, showCol: true, showColHead: true,colOrder:9,
                  lineColor:"black",formType:"select",showLine:true,lineBreak:false,lineOrder:9,
                  albumLineColor:"pink",showAlbumLine:true,albumLineOrder:9,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:false,filterLineOrder:9,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString2"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"brown",labelOrder:9,

                  formTop:30,formLeft:50,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString3: {lb: 'สตริง3', type: "string",width: 90, showCol: true, showColHead: true,colOrder:10,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:10,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:10,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:false,filterLineOrder:10,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString3"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"pink",labelOrder:10,

                  formTop:30,formLeft:100,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString4: {lb: 'สตริง4', type: "string",width: 90, showCol: true, showColHead: true,colOrder:11,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:11,
                  albumLineColor:"silver",showAlbumLine:true,albumLineOrder:11,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:11,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString4"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:11,

                  formTop:30,formLeft:150,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString5: {lb: 'สตริง5', type: "string",width: 90, showCol: true, showColHead: true,colOrder:12,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:12,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:12,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:12,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString5"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:12,

                  formTop:30,formLeft:200,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },

    itemString6: {lb: 'สตริง6', type: "string",width: 90, showCol: true, showColHead: true,colOrder:13,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:13,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:13,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:13,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString6"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"violet",labelOrder:13,

                  formTop:40,formLeft:0,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString7: {lb: 'สตริง7', type: "string",width: 90, showCol: true, showColHead: true,colOrder:14,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:14,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:14,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:14,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString7"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:14,

                  formTop:40,formLeft:50,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString8: {lb: 'สตริง8', type: "string",width: 90, showCol: true, showColHead: true,colOrder:15,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:15,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:15,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:15,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString8"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:15,

                  formTop:40,formLeft:100,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString9: {lb: 'สตริง9', type: "string",width: 90, showCol: true, showColHead: true,colOrder:16,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:16,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:16,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:16,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString9"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:16,

                  formTop:40,formLeft:150,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString10: {lb: 'สตริง10', type: "string",width: 90, showCol: true, showColHead: true,colOrder:17,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:17,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:17,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:17,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString10"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:17,

                  formTop:40,formLeft:200,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },

    itemString11: {lb: 'สตริง11', type: "string",width: 90, showCol: true, showColHead: true,colOrder:18,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:18,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:18,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:18,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString11"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:18,

                  formTop:50,formLeft:1,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString12: {lb: 'สตริง12', type: "string",width: 90, showCol: true, showColHead: true,colOrder:19,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:19,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:19,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:19,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString12"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:19,

                  formTop:50,formLeft:30,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString13: {lb: 'สตริง13', type: "string",width: 90, showCol: true, showColHead: true,colOrder:20,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:20,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:20,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:20,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString13"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:20,

                  formTop:50,formLeft:50,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString14: {lb: 'สตริง14', type: "string",width: 90, showCol: true, showColHead: true,colOrder:21,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:21,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:21,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:21,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString14"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:21,

                  formTop:50,formLeft:70,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString15: {lb: 'สตริง15', type: "string",width: 90, showCol: true, showColHead: true,colOrder:22,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:22,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:22,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:22,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString15"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:22,

                  formTop:50,formLeft:90,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },

    itemString16: {lb: 'สตริง16', type: "string",width: 90, showCol: true, showColHead: true,colOrder:23,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:23,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:23,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:23,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString16"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:23,

                  formTop:60,formLeft:1,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString17: {lb: 'สตริง17', type: "string",width: 90, showCol: true, showColHead: true,colOrder:24,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:24,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:24,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:24,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString17"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:24,

                  formTop:60,formLeft:30,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString18: {lb: 'สตริง18', type: "string",width: 90, showCol: true, showColHead: true,colOrder:25,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:25,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:25,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:25,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString18"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:25,

                  formTop:60,formLeft:50,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString19: {lb: 'สตริง19', type: "string",width: 90, showCol: true, showColHead: true,colOrder:26,
                  lineColor:"black",formType:"text",showLine:true,lineBreak:false,lineOrder:26,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:26,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:26,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString19"],

                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:26,

                  formTop:60,formLeft:70,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemString20: {lb: 'สตริง20', type: "string",width: 90, showCol: true, showColHead: true,colOrder:27,
                  lineColor:"black",formType:"textarea",rows:"4",showLine:true,lineBreak:false,lineOrder:27,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:27,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:27,filterLineBreak:false,
                  useSelection:false,selectArray:preDefinedList["itemString20"],

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:27,

                  formTop:60,formLeft:90,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },

    itemNumber1: {lb: 'ตัวเลข1', type: "number",width: 90, showCol: true, showColHead: true,colOrder:28,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:28,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:28,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:28,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"left",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:5,color:"green",
                  labelShowLb:true,labelColor:"black",labelOrder:28,
                  
                  labelShowBarcode:true,labelLineHeight:60,
                  scaleB:1.5,marginTopB:12,heightB:2,fontSizeC:15,marginTopC:5,

                  formTop:72,formLeft:6,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5

                },
    itemNumber2: {lb: 'ตัวเลข2', type: "number",width: 90, showCol: true, showColHead: true,colOrder:29,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:29,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:29,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:29,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:29,
                  
                  labelShowBarcode:true,labelLineHeight:50,
                  scaleB:1.5,marginTopB:12,heightB:20,fontSizeC:15,marginTopC:5,

                  formTop:72,formLeft:50,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5
                },
    itemNumber3: {lb: 'ตัวเลข3', type: "number",width: 90, showCol: true, showColHead: true,colOrder:30,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:30,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:30,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:30,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:30,
                  
                  labelShowBarcode:true,labelLineHeight:50,
                  scaleB:1.5,marginTopB:12,heightB:20,fontSizeC:15,marginTopC:5,

                  formTop:70,formLeft:100,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5
                },
    itemNumber4: {lb: 'ตัวเลข4', type: "number",width: 90, showCol: true, showColHead: true,colOrder:31,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:31,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:31,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:31,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:31,
                  
                  labelShowBarcode:true,labelLineHeight:50,
                  scaleB:1.5,marginTopB:12,heightB:20,fontSizeC:15,marginTopC:5,

                  formTop:70,formLeft:150,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5


                },
    itemNumber5: {lb: 'ตัวเลข5', type: "number",width: 90, showCol: true, showColHead: true,colOrder:32,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:32,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:32,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:32,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:32,
                  
                  labelShowBarcode:true,labelLineHeight:50,
                  scaleB:1.5,marginTopB:12,heightB:20,fontSizeC:15,marginTopC:5,

                  formTop:70,formLeft:200,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5

                },

    itemNumber6: {lb: 'ตัวเลข6', type: "number",width: 90, showCol: true, showColHead: true,colOrder:33,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:33,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:33,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:33,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:33,
                  
                  labelShowBarcode:true,labelLineHeight:50,
                  scaleB:1.5,marginTopB:12,heightB:20,fontSizeC:15,marginTopC:5,

                  formTop:90,formLeft:6,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5
                },
    itemNumber7: {lb: 'ตัวเลข7', type: "number",width: 90, showCol: true, showColHead: true,colOrder:34,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:34,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:34,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:34,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:34,
                  
                  labelShowBarcode:true,labelLineHeight:50,
                  scaleB:1.5,marginTopB:12,heightB:20,fontSizeC:15,marginTopC:5,

                  formTop:90,formLeft:50,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5
                },
    itemNumber8: {lb: 'ตัวเลข8', type: "number",width: 90, showCol: true, showColHead: true,colOrder:35,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:35,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:35,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:35,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:35,
                  
                  labelShowBarcode:true,labelLineHeight:50,
                  scaleB:1.5,marginTopB:12,heightB:20,fontSizeC:15,marginTopC:5,

                  formTop:90,formLeft:100,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5
                },
    itemNumber9: {lb: 'ตัวเลข9', type: "number",width: 90, showCol: true, showColHead: true,colOrder:36,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:36,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:36,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:36,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:36,
                  
                  labelShowBarcode:true,labelLineHeight:50,
                  scaleB:1.5,marginTopB:12,heightB:15,fontSizeC:15,marginTopC:5,

                  formTop:90,formLeft:150,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5
                },
    itemNumber10: {lb: 'ตัวเลข10', type: "number",width: 90, showCol: true, showColHead: true,colOrder:37,
                  lineColor:"yellow",showLine:true,lineBreak:false,lineOrder:37,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:37,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:37,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:37,
                  
                  labelShowBarcode:true,labelLineHeight:60,
                  scaleB:1.5,marginTopB:12,heightB:15,fontSizeC:15,marginTopC:2,

                  formTop:90,formLeft:200,
                  formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:5,heightBforForm:20,
                  formFontSize:14,marginTopCforForm:5
                },

    itemBoolean1: {lb: 'บูลลิน1', type: "boolean",width: 60, showCol: true, showColHead: true,colOrder:38,
                  lineColor:"green",showLine:true,lineBreak:false,lineOrder:38,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:38,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:38,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:30,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:38,

                  formTop:120,formLeft:0,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemBoolean2: {lb: 'บูลลิน2', type: "boolean",width: 60, showCol: true, showColHead: true,colOrder:39,
                  lineColor:"green",showLine:true,lineBreak:false,lineOrder:39,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:39,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:39,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:39,

                  formTop:120,formLeft:50,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemBoolean3: {lb: 'บูลลิน3', type: "boolean",width: 60, showCol: true, showColHead: true,colOrder:40,
                  lineColor:"green",showLine:true,lineBreak:false,lineOrder:40,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:40,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:40,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:40,

                  formTop:120,formLeft:100,formColor:"black",formShowLb:true,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemBoolean4: {lb: 'บูลลิน4', type: "boolean",width: 60, showCol: true, showColHead: true,colOrder:41,
                  lineColor:"green",showLine:true,lineBreak:false,lineOrder:41,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:41,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:41,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"flex-end",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:41,

                  formTop:120,formLeft:150,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },
    itemBoolean5: {lb: 'บูลลิน5', type: "boolean",width: 60, showCol: true, showColHead: true,colOrder:42,
                  lineColor:"green",showLine:true,lineBreak:false,lineOrder:42,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:42,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:42,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"flex-start",labelShowLine:true,
                  labelLineFontSize:24,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:42,

                  formTop:120,formLeft:200,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,
                  formFontSize:14
                },

    photoUrl1:{lb: 'รูป', type: "arrayPhoto",width: 200, showCol: true, showColHead: true,colOrder:43,
                  lineColor:"black",showLine:true,lineBreak:false,lineOrder:43,
                  albumLineColor:"red",showAlbumLine:true,albumLineOrder:43,albumLineBreak:false,
                  filterLineColor:"blue",showFilterLine:true,filterLineOrder:43,filterLineBreak:false,

                  labelLineOrder:37,
                  labelLineJustifyContent:"center",labelShowLine:true,
                  labelLineFontSize:14,labelLineMargin:10,
                  labelShowLb:true,labelColor:"black",labelOrder:43,

                  formTop:1,formLeft:1,formColor:"black",formShowLb:false,formShowLine:true,
                  scaleBforForm:1.5,marginTopBforForm:12,heightBforForm:10,formFontSize:14
                },

}



const tempFilterState=
{
    id:{toCheck:false,min:1,max:10}, 
    type:{toCheck:false,value:""},
    active:{toCheck:false,value:true},
    createdAt:{toCheck:false,min:null,max:null},
    updatedAt:{toCheck:false,min:null,max:null},

    itemDate1:{toCheck:false,min:toDate,max:toDate}, 
    itemDate2:{toCheck:false,min:toDate,max:toDate}, 
    itemDate3:{toCheck:false,min:toDate,max:toDate}, 
    itemDate4:{toCheck:false,min:toDate,max:toDate}, 
    itemDate5:{toCheck:false,min:toDate,max:toDate}, 

    itemString1:{toCheck:false,value:""}, 
    itemString2:{toCheck:false,value:""}, 
    itemString3:{toCheck:false,value:""}, 
    itemString4:{toCheck:false,value:""}, 
    itemString5:{toCheck:false,value:""}, 
    itemString6:{toCheck:false,value:""}, 
    itemString7:{toCheck:false,value:""}, 
    itemString8:{toCheck:false,value:""}, 
    itemString9:{toCheck:false,value:""}, 
    itemString10:{toCheck:false,value:""}, 
    itemString11:{toCheck:false,value:""}, 
    itemString12:{toCheck:false,value:""}, 
    itemString13:{toCheck:false,value:""}, 
    itemString14:{toCheck:false,value:""}, 
    itemString15:{toCheck:false,value:""}, 
    itemString16:{toCheck:false,value:""}, 
    itemString17:{toCheck:false,value:""}, 
    itemString18:{toCheck:false,value:""}, 
    itemString19:{toCheck:false,value:""}, 
    itemString20:{toCheck:false,value:""}, 

    itemNumber1:{toCheck:false,min:"",max:""}, 
    itemNumber2:{toCheck:false,min:"",max:""},
    itemNumber3:{toCheck:false,min:"",max:""},
    itemNumber4:{toCheck:false,min:"",max:""},
    itemNumber5:{toCheck:false,min:"",max:""},
    itemNumber6:{toCheck:false,min:"",max:""},
    itemNumber7:{toCheck:false,min:"",max:""},
    itemNumber8:{toCheck:false,min:"",max:""},
    itemNumber9:{toCheck:false,min:"",max:""},
    itemNumber10:{toCheck:false,min:"",max:""},

    itemBoolean1:{toCheck:false,value:false}, 
    itemBoolean2:{toCheck:false,value:false}, 
    itemBoolean3:{toCheck:false,value:false}, 
    itemBoolean4:{toCheck:false,value:false}, 
    itemBoolean5:{toCheck:false,value:false}, 
}

const blankAddInputState= {
    type:1,
    id:"",
    active:true,
    updatedAt:null,
    createdAt:null,

    itemDate1:"",
    itemDate2:"",
    itemDate3:"",
    itemDate4:"",
    itemDate5:"",

    itemString1:"", 
    itemString2:"", 
    itemString3:"", 
    itemString4:"", 
    itemString5:"", 
    itemString6:"", 
    itemString7:"", 
    itemString8:"", 
    itemString9:"", 
    itemString10:"", 
    itemString11:"", 
    itemString12:"", 
    itemString13:"", 
    itemString14:"", 
    itemString15:"", 
    itemString16:"", 
    itemString17:"", 
    itemString18:"", 
    itemString19:"", 
    itemString20:"", 

    itemNumber1:"", 
    itemNumber2:"",
    itemNumber3:"",
    itemNumber4:"",
    itemNumber5:"",
    itemNumber6:"",
    itemNumber7:"",
    itemNumber8:"",
    itemNumber9:"",
    itemNumber10:"",

    itemBoolean1:false, 
    itemBoolean2:false, 
    itemBoolean3:false, 
    itemBoolean4:false, 
    itemBoolean5:false, 
    file1:null,
    photoUrl1:[]
}


const basicData={
    recordPerPage:4,
    maxPerPage:100
}

const dataTemplate={
    blankAddInputStateForShop,
    blankAddInputStateForUser,
    blankAddInputStateForShopPayment,


    tempFilterState,
    blankAddInputState,
    dataFormat,
    mData,
    basicData,
    shop,
    user,
    shopPayment,
    userArray,
    userTableTemplate,
    bankDetail

}

export default dataTemplate
