import React from 'react'
import Barcode from 'react-barcode'
import {MdClose,MdArrowUpward,MdArrowDownward,
    MdSettings,MdSave,MdRadioButtonChecked,MdRadioButtonUnchecked,
    MdCheckBox,MdCheckBoxOutlineBlank,MdEdit
} from 'react-icons/md';
import console from '../../util/consoleVerify'


function RenderBarcode({
    showScrollBar,
    editInputState,
    dataLabel,
    showBorderOfPage,
    dfCommon,
    setDfCommon,

    keyArray
}){

    const imgSrc="https://picsum.photos/id/108/100/100"

    const genArray=(x)=>{
        let tempArray=[]    
        for (let i=0;i<x;i++){
            tempArray=[...tempArray,i]
        }
        return tempArray
    }
    
    
    const genFr=(x)=>{
        let tempString=""
        for (let i=1;i<=x;i++){
            tempString=`${tempString} 1fr`
        }
        return tempString
    }

    //=====================


    //=============================================

    const renderBody=(idx,keyObjectArray)=>{

        const genDate=(i,b)=>{
            let temp=""
            if(i[b]){
                if(new Date(i[b])){
                    const date1=new Date(i[b]).toISOString()
                    const date2=new Date(date1).toLocaleString('en-GB')
    
                    temp=date2
                }
            }
            return temp
        }

        const genString=(i,b)=>{
            let temp=""
            if(i[b]===undefined||i[b]===null){
    
            }
            else {
                temp=i[b]
            }
            return temp
        }
    
        const genNumber=(i,b)=>{
            let temp=""
            if(i[b]){
                const temp2=parseFloat(i[b])
                if(temp2){
                    temp=temp2
                }
            }
            return temp
        }

        const genBoolean=(i,b)=>{
            let temp=""
    
            if(i[b]){
                if(i[b]===true){
                    return <MdCheckBox
                                style={{
                                    fontSize:"1.5rem",
                                    color:dataLabel[b].labelColor
                                }}
                            />
                }
                else {
                    return <MdCheckBoxOutlineBlank
                                style={{
                                    fontSize:"1.5rem",
                                    color:dataLabel[b].labelColor
                                }}
                            />
                }
            }
            else {
                return null
            }
        }

        const genPhoto=(i,b)=>{
            let temp=[]

            if(i[b]){
                if(i[b].length>0){
                    
                    let photoLength=i[b].length
                    let targetLength=photoLength
                    
                    let inputLength=Math.floor(parseInt(dataLabel[b].labelLineMargin))

                    if(inputLength<photoLength&&inputLength>0){
                        targetLength=inputLength
                    }


                    return i[b].map((ph,idxPh)=>{
                        if(idxPh<targetLength){
                            let checkYoutubeUrl=false
                            let checkPdf=false

                            let tempSplitArray=ph.split(".")

                            if(typeof ph == "string"){
                                if(ph.split("/embed/").length==2){
                                    checkYoutubeUrl=true
                                }

                                if(tempSplitArray.length===2){
                                    if( tempSplitArray[1]==="pdf"||
                                        tempSplitArray[1]==="PDF"
                                    ){
                                        checkPdf=true
                                    }
                                }
                            }


                            return checkYoutubeUrl
                                ?<embed 
                                    style={{
                                        width:"100%",
                                        height:"100%",
                                        margin:"0.2rem 0"
                                    }}            
                                    src={ph}
                                />
                                :checkPdf
                                    ?<embed 
                                        type="application/pdf" 
                                        src={ph} 
                                        style={{
                                            width:"100%",
                                            height:"100%"
                                        }}
                                    >
                                    </embed> 
                                    :<img 
                                        key={`ph-${idxPh}`}
                                        className="img" 
                                        src={ph} 
                                        style={{
                                        width:'100%',
                                        objectFit:"cover",
                                        margin:"0.2rem 0"
                                        }}
                                    
                                    />
                            

                        }
                      
                    })
                }
            }
          
        }

        return (
            <div  key={idx}
                  style={{
                       
                         border:dfCommon.showBorder?"1px solid black":"1px solid white",
                         padding:`${dfCommon.paddingOfEachLabel}px`,
                         overflow:"hidden",
                  }}
            >
                
                {
                    keyObjectArray.map((b,idxB)=>{
                        const key0_5=b.substring(0,5)

                        const {
                            labelLineJustifyContent,labelShowLine,
                            labelLineFontSize,labelLineMargin,labelColor,
                            labelShowLb,labelOrder,

                            labelShowBarcode,

                        }=dataLabel[b]

                        
                        if( b==="type"&&labelShowLine||
                            key0_5==="itemS"&&labelShowLine
                        ){

                            if(labelShowBarcode){
                                return  editInputState[b]===undefined||
                                        editInputState[b]===null||
                                        editInputState[b]===""
                                    ?null
                                    :<div  key={`${idx}-${idxB}`}
                                            style={{//transform:`scale(1)`,
                                                    display:"flex",
                                                    alignItems:"baseline",
                                                    justifyContent:`${labelLineJustifyContent}`,
                                                    flexWrap:"nowrap",
                                                    margin:`${labelLineMargin}px 0`,
                                                    width:"100%"
                                                }}
                                            >
                                                <div>

                                                        <div className='flex-center-center'
                                                            style={{
                                                                transform:`scale(${dfCommon.barcodeWidth})`,
                                                                paddingTop:`${dfCommon.barcodePaddingTop}px`,
                                                                paddingBottom:`${dfCommon.barcodePaddingBottom}px`,
                                                                paddingLeft:`${dfCommon.barcodePaddingLeft}px`,
                                                                paddingRight:`${dfCommon.barcodePaddingRight}px`
                                                            }}  
                                                                                                            
                                                        >
                                                            <Barcode
                                                                value={editInputState[b]}
                                                                height={dfCommon.barcodeHeight}//{labelHeightB}
                                                                width={1}
                                                                displayValue={false}
                                                                font={"monocode"}
                                                                fontSize={labelLineFontSize*2/3}
                                                                textAlign={"center"}
                                                                textPosition={"bottom"}
                                                                lineColor={labelColor}
                                                                margin={0}
                                                            />

                                                        </div>

                                                        {labelShowLb
                                                            ?<div
                                                                className='flex-center-center'
                                                                style={{
                                                                    fontSize:`${labelLineFontSize}px`,
                                                                    margin:`${labelLineMargin}px 0`,
                                                                    color:labelColor,
                                                                }}
                                                            >
                                                                {`${dataLabel[b].lb}: ${editInputState[b]}`}
                                                            </div>
                                                            :<div
                                                                className='flex-center-center'
                                                                style={{
                                                                    fontSize:`${labelLineFontSize}px`,
                                                                    margin:`${labelLineMargin}px 0`,
                                                                    color:labelColor,
                                                                }}
                                                            >
                                                                {editInputState[b]}
                                                            </div>
                                                        }

                                                </div>




                                            
                                    </div>
                                
                            }
                            else {
                                return (
                                    <div key={`${idx}-${idxB}`}
                                    style={{
                                            display:"flex",
                                            alignItems:"baseline",
                                            justifyContent:`${labelLineJustifyContent}`,
                                            flexWrap:"nowrap",
                                            fontSize:`${labelLineFontSize}px`,
                                            margin:`${labelLineMargin}px 0`,
                                            color:labelColor,
                                            
                                          }}
                                    >
                                        {labelShowLb
                                        ?`${dataLabel[b].lb}: ${genString(editInputState,b)}`
                                        :`${genString(editInputState,b)}`
                                        }
                                    </div>
                                  )
                            }
                       
                        }
                        else if(
                            (key0_5==="itemD"&&labelShowLine)||
                            (b==="createdAt"&&labelShowLine)||
                            (b==="updatedAt"&&labelShowLine)
                        )
                        {
                            return (
                                <div key={`${idx}-${idxB}`}
                                style={{
                                        display:"flex",
                                        alignItems:"baseline",
                                        justifyContent:`${labelLineJustifyContent}`,
                                        flexWrap:"nowrap",
                                        fontSize:`${labelLineFontSize}px`,
                                        margin:`${labelLineMargin}px 0`,
                                        color:labelColor,
                                       
                                      }}
                                >
                                    
                                    {labelShowLb
                                    ?`${dataLabel[b].lb}: ${genDate(editInputState,b)}`
                                    :`${genDate(editInputState,b)}`
                                    }  
                                    
                                </div>
                              )
                        }
                     
                        else if((key0_5==="itemN"&&labelShowLine)||
                                 (b==="id"&&labelShowLine)
                               ){
                            
                            if(labelShowBarcode){
                               
                                return editInputState[b]===undefined||
                                       editInputState[b]===null||
                                       editInputState[b]===""
                                    ?null
                                    :<div  key={`${idx}-${idxB}`}
                                        style={{//transform:`scale(1)`,
                                                display:"flex",
                                                alignItems:"baseline",
                                                justifyContent:`${labelLineJustifyContent}`,
                                                flexWrap:"nowrap",
                                                margin:`${labelLineMargin}px 0`,
                                                width:"100%"
                                            }}
                                            >
                                                <div
                                                >
                                                   
                                                    <div className='flex-center-center'
                                                        style={{
                                                            transform:`scale(${dfCommon.barcodeWidth})`,
                                                            paddingTop:`${dfCommon.barcodePaddingTop}px`,
                                                            paddingBottom:`${dfCommon.barcodePaddingBottom}px`,
                                                            paddingLeft:`${dfCommon.barcodePaddingLeft}px`,
                                                            paddingRight:`${dfCommon.barcodePaddingRight}px`

                                                        }}
                                                    >
                                                        <Barcode
                                                            value={editInputState[b]}
                                                            height={dfCommon.barcodeHeight}//{labelHeightB}
                                                            width={1}
                                                            displayValue={false}
                                                            font={"monocode"}
                                                            fontSize={labelLineFontSize*2/3}
                                                            textAlign={"center"}
                                                            textPosition={"bottom"}
                                                            lineColor={labelColor}
                                                            margin={0}
                                                        />
                                                    </div>

                                                    {/*
                                                    labelShowLb
                                                    ?<div className='flex-center-center'
                                                         style={{margin:"0"}}
                                                    >
                                                        {dataLabel[b].lb}
                                                    </div>
                                                    :null
                                                    */}
                                                    {labelShowLb
                                                        ?<div
                                                            className='flex-center-center'
                                                            style={{
                                                                fontSize:`${labelLineFontSize}px`,
                                                                margin:`${labelLineMargin}px 0`,
                                                                color:labelColor,
                                                            }}
                                                        >
                                                            {`${dataLabel[b].lb}: ${genNumber(editInputState,b)}`}
                                                        </div>
                                                        :<div
                                                            className='flex-center-center'
                                                            style={{
                                                                fontSize:`${labelLineFontSize}px`,
                                                                margin:`${labelLineMargin}px 0`,
                                                                color:labelColor,
                                                            }}
                                                        >
                                                            {genNumber(editInputState,b)}
                                                        </div>
                                                    }
                                                </div>
                                    </div>
                                
                            }
                            else{
                                return (
                                    <div key={`${idx}-${idxB}`}
                                    style={{
                                            display:"flex",
                                            alignItems:"baseline",
                                            justifyContent:`${labelLineJustifyContent}`,
                                            flexWrap:"nowrap",
                                            fontSize:`${labelLineFontSize}px`,
                                            margin:`${labelLineMargin}px 0`,
                                            color:labelColor,
                                       
                                          }}
                                    >
                                          {labelShowLb
                                          ?`${dataLabel[b].lb}: ${genNumber(editInputState,b)}`
                                          :`${genNumber(editInputState,b)}`
                                          }
                                    </div>
                                  )
                            }
                        }
                        else if(
                            (key0_5==="itemB"&&labelShowLine)||
                            (b==="active"&&labelShowLine)
                        )
                        {   
                            return (
                                <div key={`${idx}-${idxB}`}
                                style={{
                                        display:"flex",
                                        alignItems:"baseline",
                                        justifyContent:`${labelLineJustifyContent}`,
                                        flexWrap:"nowrap",
                                        fontSize:`${labelLineFontSize}px`,
                                        margin:`${labelLineMargin}px 0`,
                                     
                                      }}
                                >

                                    {labelShowLb
                                    ?<div className=''
                                    style={{display:"flex",
                                            alignItems:"center"
                                        }}
                                    >
                                        <div
                                            style={{
                                                    marginRight:"1rem",
                                                    color:labelColor,
                                                    fontSize:`${labelLineFontSize}px`
                                                }}
                                        >
                                            {
                                            `${dataLabel[b].lb}: `
                                            }
                                        </div>
                                        <div
                                            className=''
                                            style={{
                                                display:"flex",
                                                alignItems:"center"
                                            }}
                                        >
                                        {
                                            genBoolean(editInputState,b)
                                        }
                                        </div>
                                        
                                    </div>
                                    :<div className=''
                                            style={{
                                                display:"flex",
                                                alignItems:"center"
                                            }}
                                    >
                                        {
                                            genBoolean(editInputState,b)
                                        }
                                        
                                    </div>
                                    
                                    }
                                </div>
                              )
                        }
                      
                        else if(key0_5==="photo"&&labelShowLine){
                            return (
                                <div key={`${idx}-${idxB}`}
                                style={{
                                        width:"100%",
                                        display:"flex",
                                        alignItems:"baseline",
                                        justifyContent:`${labelLineJustifyContent}`,
                                        flexWrap:"nowrap",
                                        //fontSize:`${labelLineFontSize}px`,
                                        //margin:`${labelLineMargin}px 0`,
                                     
                                      }}
                                >
                                    <div className=''
                                        style={{width:
                                            labelLineFontSize>0&&labelLineFontSize<=100
                                            ?`${labelLineFontSize}%`
                                            :`100%`
                        
                                        }}
                                    >
                                        {genPhoto(editInputState,b)}    
                                    </div>
                                </div>
                            )
                        }


                    })
                }
            </div>
        )
    }


    //=============================================
    const renderAllBody=()=>{

        let tempArray=[]
        
        //tempArray=Object.keys(dataLabel)

        tempArray=keyArray
        
        tempArray.sort((a, b) => {
            return dataLabel[a].labelOrder - dataLabel[b].labelOrder;
        });

        const keyObjectArray=tempArray

        return(
            genArray(dfCommon.qty).map((i,idx)=>renderBody(idx,keyObjectArray))
        )
    }

    //==============================================
    //===============================================

    return (
      
    <div className="bgc-pureWhite"// label-border" 
        style={{
            height:dfCommon.useMm?`${dfCommon.heightP}mm`:`${dfCommon.heightP}%`,//mm
            width:dfCommon.useMm?`${dfCommon.widthP}mm`:`${dfCommon.widthP}%`,//mm
 
            paddingTop:`${dfCommon.paddingTopP}mm`,
            paddingBottom:`${dfCommon.paddingBottomP}mm`,
            paddingLeft:`${dfCommon.paddingLeftP}mm`,
            paddingRight:`${dfCommon.paddingRightP}mm`,

            marginTop:`${dfCommon.marginTopP}mm`,
            marginBottom:`${dfCommon.marginBottomP}mm`,
            marginLeft:`${dfCommon.marginLeftP}mm`,
            marginRight:`${dfCommon.marginRightP}mm`,
            
            overflow:showScrollBar?"scroll":"hidden",
            position:"relative",
            }}
        >
      
            <div 
                style={{
                    width:"100%",
                    display:"grid",
                    gridTemplateColumns:genFr(dfCommon.xPerLine),
                    gridRowGap:`${dfCommon.gridRowGap}mm`,
                    gridColumnGap:`${dfCommon.gridColumnGap}mm`,

                    //position:"absolute",
                    //zIndex:"10",
                    //top:"0mm",
                    //left:"0mm",

                   
                }}
            >
                {
                renderAllBody()
                }
            </div>
          
    </div>
    )
}


export default RenderBarcode

