import React from 'react';
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import TypeSelect from './TypeSelect';
import console from '../../util/consoleVerify'


function ModalTypeSelect({
    setShow,
    submitFunction,
    basicData
    }){


    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 

    const [showModalError,setShowModalError]=React.useState(false)
    //const {id,parentId,allDeleteId,routeAuth,detail}=editData

    const genFakeValue=()=>{

        let tempString=null
        //console.log(filterState.type)
        basicData.typeArray.map(g=>{
            if(g.typeId===basicData.defaultDataFormat){
            //if(g.typeId===2){
                tempString=`${g.typeId}.${g.typeName}`
            }
        })
    
        return tempString
    }
//===============================
//===============================
    return(

    <div className="Modal-background">
        <div className="Modal-box2"
        >
            <div className="Modal-header"
                 style={{display:"block"}}
            >
                {basicData&&
                    <TypeSelect
                        value={genFakeValue()}//{"1.one-1111"}//{filterState.type.value}
                        typeArray={basicData.typeArray}
                        onChange={(a)=>{

                            if(basicData.defaultDataFormat===a){

                            }
                            else {
                                submitFunction(a)
                            }   


                        
                            
                        }}
                    />
                    }
            </div>
          
            <div className="Modal-footer">
                
                <div>
                    <button
                        ref={refModalConfirm2}
                        onKeyDown={e=>{
                            //if(e.key=="ArrowLeft"){
                            //    refModalConfirm1.current.focus()
                            //}
                        }}
                        onClick={e=>{
                            if(setShow){setShow(false)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
            
        </div>
    </div>
    )
}

ModalTypeSelect.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}


export default ModalTypeSelect
