


import React from 'react'

import {MdVisibility,MdVisibilityOff,
    MdEdit,MdAddCircle,MdDelete,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineCheckBox,
    MdRefresh,
    MdCheckBox,
    MdCheckBoxOutlineBlank
} from 'react-icons/md';
import {FaBan,FaCheck} from 'react-icons/fa'
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'


function DeleteUserModal({
    editUser,
    submitCheck,
    submitCancel,
    languageDisplay
}){

    const tempArray=[
        {   key:"id",
            lb:"id",
            type:"number"
        },
        {   key:"username",
            lb:"username",
            type:"text"
        },
       
        {
            key:"userLevel",
            lb:"userLevel",
            type:"text"
        },
        {
            key:"name",
            lb:"name",
            type:"text"
        },
        {
            key:"surname",
            lb:"surname",
            type:"text"
        }

    ]
        
    const [inputState,setInputState]=React.useState(editUser)

    React.useEffect(()=>{
        console.log("inputState")
        console.log(inputState)
    },[inputState])

    return(
    <div className="Modal-background">

        <div className="Modal-box2"
             style={{width:"90%"}}
        >
            <div className="Modal-header">
                <h5>
                    {
                        languageDisplay
                        ?languageDisplay.deleteUser
                            ?languageDisplay.deleteUser
                            :"DELETE USER"
                        :"DELETE USER"
                    }
                </h5>
            </div>

            <div className='Modal-box'>
                {
                    tempArray.map((v,idxV)=>{
                        return (
                        <div key={`idxV-${idxV}`}
                            className='w-100'
                            style={{display:"flex",
                                   justifyContent:"flex-start",
                                   margin:"1rem 0"
                           }}
                        >
                           <div className='w-20'>
                               {
                                languageDisplay
                                ?languageDisplay[v.key]
                                    ?languageDisplay[v.key]
                                    :""
                                :""
                               }
                           </div>
                           
                           {v.key==="active"
                                ?<div className='w-80'
                                      style={{display:"flex",
                                              justifyContent:"center",
                                              alignItems:"center"
                                            }}
                                >
                                    {inputState.active
                                    ?<MdOutlineCheckBox className='md-icon'
                                          
                                    />
                                    :<MdOutlineCheckBoxOutlineBlank className='md-icon'
                                     
                                    />
                                    }
                                </div>
                                :<div className='w-80'>
                                    <div
                                        className='w-100'
                                       
                                       
                                    >
                                        {inputState[v.key]}
                                    </div>
                                    
                                </div>
                                }


                        </div>
                        )
                    })
                }
            </div>
           

            <div className="Modal-footer"
                style={{marginTop:"2rem"}}
            >
                   

                   <div>
                        <button                       
                            onClick={()=>{
                                submitCheck(editUser)
                            }}
                        >
                            <FaCheck/>
                        </button>
                    </div>

                    <div>
                        <button                       
                            onClick={()=>{
                                submitCancel(false)
                            }}
                        >
                            <FaBan/>
                        </button>
                    </div>
            </div>

        </div>
    </div>
    )

}

export default DeleteUserModal

