import React from 'react'
import Mnavbar from '../../component/mnavbar/Mnavbar'
import cosmeticUtil from '../../util/cosmeticUtil'
import renderWidthRangeBar from '../../component/table/renderWidthRangeBar'
import {FaHome,FaChevronCircleLeft,FaChevronCircleRight} from 'react-icons/fa'
import {MdSettings} from 'react-icons/md';

import RenderBarcode from './RenderBarcode'
import BarcodeSetting from './BarcodeSetting'
import {Link} from 'react-router-dom';

import {MainContext} from '../../context/MainContext'
import console from '../../util/consoleVerify'

const {checkWindowLandscape}=cosmeticUtil

function ItemBarcode(){
    const {
        pageData,setPageData,
        currentPage,setCurrentPage,
        totalPage,setTotalPage,
        editInputState,setEditInputState,
        dataLabel,setDataLabel,
        dataLabel2,setDataLabel2,
        widthLeft,setWidthLeft,
        showBarcodeSetting,setShowBarcodeSetting,

        submitDataFormat,

        dfCommon,setDfCommon,

        keyArray,keyArrayWithPhotoUrl,

        widthRight,setWidthRight,

        languageDisplay,
    
    }=React.useContext(MainContext)

    const refHome=React.createRef()
 
    //=========================
    return editInputState
    ?<div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}
         className=""
    >
        
        <div className='w-100 h-100 hide-on-print'
        >

                <div 
                        style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
                        <Mnavbar/>
                </div>

                <div style={{position:"relative"}}>
                        <MdSettings className="lg-icon"
                            style={{position:"absolute",right:"2rem",
                                    zIndex:"30"
                        }}
                            onClick={e=>{
                                setShowBarcodeSetting(!showBarcodeSetting)
                            }}
                        />

                </div>
            
                {
                editInputState&&
                <div style={{height:checkWindowLandscape()?"95%":"92%",
                             width:"100%",
                             display:"flex"
                            
                            }}>

                    <div className="bgc-lightGray" 
                        style={{height:"100%",
                               width:showBarcodeSetting?`${widthRight}%`:"100%",
                        }}>
                            {dfCommon
                            ?<RenderBarcode
                                showScrollBar={true}//{true}
                                editInputState={editInputState}
                                dataLabel={dataLabel2}
                                dfCommon={dfCommon}
                                setDfCommon={setDfCommon}
                                keyArray={keyArrayWithPhotoUrl}
                            />
                            :null
                            }
                    </div>


                    {showBarcodeSetting&&
                        <div className="bgc-shadeGray"
                            style={{height:"100%",
                                width:showBarcodeSetting?`${100-widthRight}%`:"100%",
                                backgroundColor:"yellow"

                            }}
                        >
                            {
                            dfCommon&&
                            <BarcodeSetting
                                dataLabel={dataLabel2}
                                setDataLabel={setDataLabel2}
                                submitDataFormat={submitDataFormat}
                                setShowBarcodeSetting={setShowBarcodeSetting}
                                dfCommon={dfCommon}
                                setDfCommon={setDfCommon}
                                keyArray={keyArrayWithPhotoUrl}
                                languageDisplay={languageDisplay}
                            />
                            }
                        </div>
                    }

                    {showBarcodeSetting&&
                    <div
                        style={{
                            position:"fixed",
                            bottom:"0",
                            width:"100vw"
                        }}
                    >
                        <input
                            type="range" min="1" max="100" 
                            value={widthRight}
                            onChange={e=>{
                                setWidthRight(e.target.value)
                            }}
                        />
                    </div>
                    }

                </div>
                }





        </div>

      
        {
        editInputState&&dfCommon
        ?<div className='hide-on-screen'
        >
                <RenderBarcode
                            showScrollBar={false}
                            editInputState={editInputState}
                            dataLabel={dataLabel2}
                            showBorderOfPage={true}
                            dfCommon={dfCommon}
                            setDfCommon={setDfCommon}
                            keyArray={keyArrayWithPhotoUrl}
                />
        </div>
        :null
        }


    </div>
    :
    <div className='home-bg-screen'
        style={{width:"100vw",height:"100vh"}}>
            <div className='flex-center-center'
                style={{height:"100%",width:"100%"}}
            >

                <button style={{
                            width:"10rem",
                            height:"10rem",
                            padding:"0"
                        }}
                    onClick={e=>{
                            refHome.current.click()
                            }}
                >
                    <FaHome
                        style={{fontSize:"4rem"}}
                    />
                    <div>
                        {
                            languageDisplay
                            ?languageDisplay.home
                                ?languageDisplay.home
                                :"Home"
                            :"Home"
                        }
                    </div>
                </button>

                
            </div>

        <div className="d-none">
 
                <Link ref={refHome} to="/cloud/home"/>  
        </div>     
    </div>
}

export default ItemBarcode