import React from 'react';
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import {MdUpload} from 'react-icons/md';
import console from '../../util/consoleVerify'


function ModalUpload({
    setShow,
    submitFunction,
    editData,
    languageDisplay

    }){


    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 

    const [showModalError,setShowModalError]=React.useState(false)
    //const {id,parentId,allDeleteId,routeAuth,detail}=editData
//===============================
//===============================
    return(

    <div className="Modal-background">
        <div className="Modal-box2"
        >
            <div className="Modal-header"
                 style={{display:"block"}}
            >
                <div className='flex-center-center'>
                    <FaDatabase style={{fontSize:"1.5rem",marginRight:"0.3rem"}}/>
                    <FaLongArrowAltRight style={{fontSize:"1.2rem"}}/>
                   
                    <FaLongArrowAltRight style={{fontSize:"1.2rem"}}/>
                    <MdUpload style={{fontSize:"2rem"}}/>
                </div>
                <div className='flex-center-center'>
                    {
                     languageDisplay
                     ?languageDisplay.upload
                        ?languageDisplay.upload
                        :"UPLOAD"
                     :"UPLOAD"   
                    }
                </div>
            </div>
          
            <div className="Modal-footer">
                <div>
                    <button
                        ref={refModalConfirm1}
                        onClick={e=>{
                            setShow(false)
                            submitFunction(editData)
                        }}
                        onKeyDown={e=>{
                            if(e.key=="ArrowRight"){
                                refModalConfirm2.current.focus()
                            }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        ref={refModalConfirm2}
                        onKeyDown={e=>{
                            if(e.key=="ArrowLeft"){
                                refModalConfirm1.current.focus()
                            }
                        }}
                        onClick={e=>{
                            if(setShow){setShow(false)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
            {/*
              showModalError
              ?renderModalError({show:showModalError,setShow:setShowModalError})
              :null
            */}
        </div>
    </div>
    )
}

ModalUpload.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}


export default ModalUpload
