import React from 'react';
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import {MdDelete} from 'react-icons/md';

import AddPageSetting from './AddPageSetting'
import console from '../../util/consoleVerify'


function ModalAddPageSetting({
    setShowAddPageSettingForShop,
    showAddPageSettingForShop,

    dfCommon,setDfCommon,
    keyArray,stringArray,
    stringArrayState,setStringArrayState,
    showModalStringArray,setShowModalStringArray,
    submitUpdateStringArray,

    dataLabel2,setDataLabel2,
    submitDataFormat
    }){


    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 

    const [showModalError,setShowModalError]=React.useState(false)
    //const {id,parentId,allDeleteId,routeAuth,detail}=editData
//===============================
//===============================
    return(

    <div style={{
        position:"fixed",top:"0",left:"0",
        height:"100vh",width:"100vw",
        zIndex:"200",
        background:"radial-gradient(circle, rgba(220,220,220,1) 0%, rgba(124,124,128,0.9) 100%)"
        }}
    >
       
        <AddPageSetting
            dataLabel={dataLabel2}
            setDataLabel={setDataLabel2}
            submitDataFormat={submitDataFormat}
            setShowAddSetting={setShowAddPageSettingForShop}

            dfCommon={dfCommon}
            setDfCommon={setDfCommon}
            keyArray={keyArray}
            stringArray={stringArray}

            stringArrayState={stringArrayState}
            setStringArrayState={setStringArrayState}
            showModalStringArray={showModalStringArray}
            setShowModalStringArray={setShowModalStringArray}
            submitUpdateStringArray={submitUpdateStringArray}
        />
              
        
    </div>
    )
}

ModalAddPageSetting.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}


export default ModalAddPageSetting
