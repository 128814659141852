import React from 'react'
import Ticon from '../ticon/Ticon'
import {Link} from 'react-router-dom';
import {MainContext} from '../../context/MainContext'
import LogOutToolForUser from '../../mpage/item/LogOutToolForUser';
import console from '../../util/consoleVerify'

import './Mnavbar.css'

function Mnavbar(){


    const {
        setCurrentLink,
        setPrevLink,
        formTemplate,
        basicDataSt,
        tokenSt,
        setTokenSt,

        languageDisplay,
    }=React.useContext(MainContext)

    //const haveShopToken=true
    //const haveUserToken=true
    //const user={username:"Peter",shopId:"a",branchId:"1"}
    const pageData=formTemplate
    //const [tokenSt,setTokenSt]=React.useState({haveShopToken,haveUserToken})


    const shouldRender= React.useRef(true)
    React.useEffect(()=>{
        return () => {
            //console.log('end.................................')
            shouldRender.current=false
        } 
    },[])

    const refHome=React.createRef()
    const refSearch=React.createRef()
    const refAdd=React.createRef()
    const refTable=React.createRef()
    const refAlbum=React.createRef()
    const refSetting=React.createRef()
    const refCalendar=React.createRef()
    const refLogIn=React.createRef()

    const getIconStyle=()=>{
        if(shouldRender.current){
            const toDate=new Date()
            
            let temp1=localStorage.getItem("expirationDate")
            const expirationDate=new Date(temp1)
            
            if(!temp1){
                return null
            }
    
            const temp3=new Date(expirationDate.toISOString())
            temp3.setDate(temp3.getDate()-3)
            const threeDayBeforeExpirationDate=new Date(temp3)
                   
            const a=toDate.getTime()-expirationDate.getTime()

            if(toDate>expirationDate){
                return {color:"red"}
            }
            else if(toDate>threeDayBeforeExpirationDate){
                return {color:"yellow"}
            }
            else{
                return {color:"#74b979"}
            }
        }
     }



    return (
        <div className="navFrame flex-center-center jc-space-between">

            <div id="1" className="h-100" 
                style={{
                        position:"absolute",top:"0",left:"0",
                        width:"100%",display:"flex",alignItems:"center",
                        justifyContent:"start",
                        overflowX:"auto",
                        overflowY:"hidden",
                       
                        }}>

                <div className="navLink" 
                    onClick={e=>{
                        //setMainContextFilterData()
                        refHome.current.click()
                    }}> 
                    
                        <div className="navIconBox"
                            style={{position:"relative"}}
                        >
                            <Ticon
                                iconName="FaHome"
                                className="navIcon"
                                iconStyle={getIconStyle()}
                            />
                        </div>                    
                </div>

                <div className="navLink" 
                     onClick={e=>{
                         //setMainContextFilterData()
                         setCurrentLink("search")
                         refSearch.current.click()
                     }}> 
                    <div className="navIconBox">
                        <Ticon
                            iconName="MdSearch"
                            className="navIcon"
                        />
                    </div>
                  
                </div>    

                <div className="navLink" 
                     onClick={e=>{
                         //setMainContextFilterData()
                         setCurrentLink("add")
                         refAdd.current.click()
                     }}> 
                    <div className="navIconBox">
                        <Ticon
                            iconName="MdAddCircle"
                            className="navIcon"
                        />
                    </div>
                  
                </div>

                <div className="navLink" 
                     onClick={e=>{
                        //setMainContextFilterData()
                        setCurrentLink("table")
                        setPrevLink("table")
                        refTable.current.click()
                }}> 
                    <div className="navIconBox">
                        <Ticon
                            iconName="FaTable"
                            className="navIcon"
                        />
                    </div>
                 
                </div>

                <div className="navLink" 
                     onClick={e=>{
                        //setMainContextFilterData()
                        setCurrentLink("album")
                        setPrevLink("album")
                        refAlbum.current.click()
                }}> 
                    <div className="navIconBox">
                        <Ticon
                            iconName="FaImages"
                            className="navIcon"
                        />
                    </div>
                 
                </div>    

                <div className="navLink" 
                     onClick={e=>{
                        //setMainContextFilterData()
                        setCurrentLink("calendar")
                        setPrevLink("calendar")
                        refCalendar.current.click()
                }}> 
                    <div className="navIconBox">
                        <Ticon
                            iconName="FaRegCalendarAlt"
                            className="navIcon"
                        />
                    </div>
                 
                </div>
               
                    

                <div className="navLink" 
                     onClick={e=>{
                        window.print()
                        //setMainContextFilterData()
                        //setCurrentLink("album")
                        //refAlbum.current.click()
                }}> 
                    <div className="navIconBox">
                        <Ticon
                            iconName="MdPrint"
                            className="navIcon"
                        />
                    </div>
                 
                </div>

                <div className="navLink" 
                     onClick={e=>{
                        refLogIn.current.click()
                        //setMainContextFilterData()
                        //setCurrentLink("album")
                        //refAlbum.current.click()
                }}> 
                    <div className="navIconBox">
                        <Ticon
                            iconName="MdVpnKey"
                            className="navIcon"
                        />
                    </div>
                 
                </div>

               
                
            </div>

            <LogOutToolForUser
               useShopLogOut={true}
               tokenSt={tokenSt}
               setReloadCheckToken={(val)=>{
                    if(val==="haveShopToken"){
                        setTokenSt({...tokenSt,haveShopToken:false})
                    }
                    else if(val==="haveUserToken"){
                        setTokenSt({...tokenSt,haveUserToken:false})
                    }
               }}
               user={basicDataSt.user}
               useHomeIcon={false}
               pageData={pageData}
               Language={"English"}   
            />


            <div className="d-none">
                <Link ref={refHome} to="/cloud/home"/>
                <Link ref={refSearch} to="/cloud/search"/>
                <Link ref={refAdd} to="/cloud/add"/>
                <Link ref={refTable} to="/cloud/table"/>
                <Link ref={refAlbum} to="/cloud/album"/>
                <Link ref={refSetting} to="/cloud/setting"/>
                <Link ref={refCalendar} to="/cloud/calendar"/>
                <Link ref={refLogIn} to="/cloud/login"/>

            </div>



        </div>
    )
}

export default Mnavbar
