import React from 'react';
import {FaBan,FaCheck,FaRobot} from 'react-icons/fa'

import console from '../../util/consoleVerify'

import { loadCaptchaEnginge, 
    LoadCanvasTemplate, 
    LoadCanvasTemplateNoReload, 
    validateCaptcha } from 'react-simple-captcha';


function ModalCapCha({
    setShow,
    inputState,setInputState,
    submitFunction,
    languageDisplay,
    displayError
  

}) {

const shouldRender= React.useRef(true)
const refCapChaSubmit=React.createRef()
const refCapChaCancel=React.createRef()

React.useEffect(()=>{
    
    setTimeout(()=>{
       if(shouldRender.current){
          loadCaptchaEnginge(4)
       }
    },50)
 
     return () => {
         shouldRender.current=false
     } 
 },[])

const [capCha,setCapCha]=React.useState("")

React.useEffect(()=>{
    //console.log(capCha)
},[capCha])

return (
 
<div className="Modal-background">
    <div className="Modal-box2">
            <div className="Modal-header">
                <FaRobot
                    style={{
                        fontSize:"2rem",
                        marginRight:"2rem",
                        color:"brown"
                    }}
                />
                <div
                    style={{fontSize:"1.2rem"}}
                >
                    {languageDisplay
                    ?languageDisplay.areYouRobot
                        ?languageDisplay.areYouRobot
                        :"Are you a bot ??"
                    :"Are you a bot ??"
                    }
                </div>
            </div>

            <div className="Modal-body">

                <div className='flex-center-center'
                        style={{marginBottom:"1rem",
                        alignItems:"flex-start"}}
                >
                    <div className='xc4 lc4 mc4 sc12'>
                        <LoadCanvasTemplate/>
                    </div>

                    <div className='xc8 lc8 mc8 sc12'>
                        <input
                            placeholder='Enter Text of The Left'
                            onKeyDown={e=>{
                                if(e.key=="Enter"){
                                    refCapChaSubmit.current.focus()                                
                                }
                            }}
                            onChange={e=>{
                                setCapCha(e.target.value)
                            }}

                        
                        />
                    </div>

                </div>

            </div>

            <div className="Modal-footer">
                <div>
                    <button
                        ref={refCapChaSubmit}
                        onClick={e=>{

                            if (validateCaptcha(capCha)==true) {
                                //console.log('CapCha=Pass')
                                
                                setShow(false)
                                submitFunction(inputState)
                            }
                            else {                 
                                const err={response:{data:{message:"areYouRobot"}}}

                                displayError("MCC114",err)
                                setShow(false)
                            }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                
                <div>
                    <button
                        ref={refCapChaCancel}
                        onClick={e=>{
                            setShow(false)
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>
    </div>
</div>

)
}

export default ModalCapCha;
