
import React from 'react'

import {Link} from 'react-router-dom';
import cosmeticUtil from '../../util/cosmeticUtil'

import {MdRefresh,MdSwapHoriz,MdSettings,
    MdSearch,MdEdit,MdAddCircle,MdDelete,MdPrint,
    MdChevronLeft,MdChevronRight,
    MdLastPage,MdFirstPage,
    MdRadioButtonChecked,MdClose,
} from 'react-icons/md';

import { FaBullseye, FaRegArrowAltCircleUp} from 'react-icons/fa';
import Ticon from '../../component/ticon/Ticon'
import console from '../../util/consoleVerify'


const {checkWindowLandscape}=cosmeticUtil


const goToFirst=(currentPage,totalPage,setCurrentPage)=>{
    setCurrentPage(1)
}
const goToPrev=(currentPage,totalPage,setCurrentPage)=>{
    if(currentPage>1){
        setCurrentPage(currentPage-1)
    }
}
const goToNext=(currentPage,totalPage,setCurrentPage)=>{
    if(currentPage<totalPage){
        setCurrentPage(currentPage+1)
    }
}
const goToLast=(currentPage,totalPage,setCurrentPage)=>{
    setCurrentPage(totalPage)
}



const RenderBadgeMaster = ({
    widthRight,setWidthRight,

    showTableSettingForShop,
    setShowTableSettingForShop,

    showFilterSettingForShop,
    setShowFilterSettingForShop,

    showAddPageSettingForShop,
    setShowAddPageSettingForShop,

    showModalAddPage,
    setShowModalAddPage,
    
    showModalEditPage,
    setShowModalEditPage,

    showModalConfirm,
    setShowModalConfirm,

    editInputState,
    setEditInputState,

    totalPageForShop,
    currentPageForShop,
    setCurrentPageForShop,
    countRecordForShop,

    setShowModalShopLogInWithRecoveryPassword

})=>{

    const badgeColor="#56C6A9"
    //const countRecord=2
    //const totalPage=10
    //const currentPage=3
    const setCurrentPage=()=>{}
    const submitSearch=()=>{}
    const setShowConfirm=()=>{}

    const refEdit=React.createRef()
    const refBarcode=React.createRef()
    
    const refRefresh=React.createRef()

    //const [tempCurrentPage,setTempCurrentPage]=React.useState(currentPage)

    //React.useEffect(()=>{
    //    if(tempCurrentPage>0&&tempCurrentPage<=totalPage){
    //        setCurrentPage(tempCurrentPage)
    //    }
    //},[tempCurrentPage])


    return (
    <div className="badge-frame-xc12"
        style={{background:badgeColor,
                width:"100%",
            }}
    > 
    

        <div className="flex-center-center flex-no-wrap xc12 jc-start"
              style={{justifyContent:"flex-start",
                      overflowX:"auto",
                      paddingRight:"2rem",
                      width:"50%"
              }}
        >

            <div>
                 <MdFirstPage
                    className="sm-icon"
                    onClick={e=>{
                        goToFirst(currentPageForShop,totalPageForShop,setCurrentPageForShop)
                  
                    }}
                 />
            </div>   
            


            <div>
                <MdChevronLeft
                    className="sm-icon"
                    style={{visible:"visible"
                           
                        }}
                    onClick={e=>{
                        goToPrev(currentPageForShop,totalPageForShop,setCurrentPageForShop)
             
                    }}
                />
            </div>



            
            <input 
                type="number"
                style={{width:"5rem"}}
                value={currentPageForShop
                    //pageNumber.toString()
            }
            onChange={e=>{
                if(e.target.value>totalPageForShop){
                    return 
                }
                
                if(e.target.value===0){
                    return
                }

                if(e.target.value==="0"){
                    return
                }
                
                if(e.target.value<=-1){
                    return
                }
                
                setCurrentPageForShop(e.target.value)
                
            }}
            />
         
            <div style={{
                paddingTop:"0rem",
                width:"5rem",
            }}>
                    <div
                        style={{fontSize:"0.9rem"}}
                    >
                        {
                        `${totalPageForShop}`
                        }
                    </div>
                    {<div style={{fontSize:"0.7rem"}}>
                        {countRecordForShop
                        ?`(${countRecordForShop})`
                        :`(-)`
                        }
                    </div>
               
                    }
            </div>
           

            <div>
                <MdChevronRight
                    className="sm-icon"
                    onClick={e=>{
                        goToNext(currentPageForShop,totalPageForShop,setCurrentPageForShop)
                       
                    }}
                />
            </div>
            
            <div>
                 <MdLastPage
                    className="sm-icon"
                    style={{marginRight:"0.5rem"}}
                    onClick={e=>{
                        goToLast(currentPageForShop,totalPageForShop,setCurrentPageForShop)
                        
                    }}
                 />
            </div>   
            
          
            <div className="iconbox"

                onClick={e=>{
                   submitSearch()
                }}
            >   
                <Ticon
                    iconName="MdRefresh"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>

            {
            <div className="iconbox"
                onClick={e=>{
                    setShowModalAddPage(true)
                    
                }}
            >
                <Ticon
                    iconName="MdAddCircle"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }


           {editInputState&&
           <div className="iconbox"
                onClick={e=>{
                    setShowModalEditPage(true)
                }}
            >   
                <Ticon
                    iconName="MdEdit"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
          
            {editInputState&&
            <div className="iconbox"
                onClick={e=>{
                    setShowModalConfirm(true)
     
                }}
            >
                <Ticon
                    iconName="MdDelete"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }

            
            <div className="iconbox"
                onClick={e=>{
                    setShowModalShopLogInWithRecoveryPassword(true)
                }}
            >
                <Ticon
                    iconName="FaKeybase"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>

            <div className="iconbox"
                onClick={e=>{
                    //setShowModalShopLogInWithRecoveryPassword(true)
                    
                    let tempCheck=true
                    const a1=localStorage.getItem("shopauthorization")
                    const a2=localStorage.getItem("userauthorization")
                    const a3=localStorage.getItem("username")
                    const a4=localStorage.getItem("expirationDate")

                    const tempA1=localStorage.getItem("tempshopauthorization")
                    const tempA2=localStorage.getItem("tempuserauthorization")
                    const tempA3=localStorage.getItem("tempusername")
                    const tempA4=localStorage.getItem("tempexpirationDate")

                    if(!a1){tempCheck=false}
                    if(!a2){tempCheck=false}
                    if(!a3){tempCheck=false}
                    if(!a4){tempCheck=false}

                    if(!tempA1){tempCheck=false}
                    if(!tempA2){tempCheck=false}
                    if(!tempA3){tempCheck=false}
                    if(!tempA4){tempCheck=false}

                    if(tempCheck){
                        localStorage.setItem("shopauthorization",tempA1)
                        localStorage.setItem("userauthorization",tempA2)
                        localStorage.setItem("username",tempA3)
                        localStorage.setItem("expirationDate",tempA4)

                        localStorage.setItem("tempshopauthorization",a1)
                        localStorage.setItem("tempuserauthorization",a2)
                        localStorage.setItem("tempusername",a3)
                        localStorage.setItem("tempexpirationDate",a4)

                        window.location.reload()
                    }

                }}
            >
                <Ticon
                    iconName="MdSwapHorizontalCircle"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>    


            <div className="iconbox"
                onClick={e=>{
                    setShowModalShopLogInWithRecoveryPassword(true)
     
                }}
            >
                <Ticon
                    iconName="MdDelete"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            
        </div>    
         
            
        <div className='w-50'
             style={{
                paddingRight:"2rem",
                display:"flex",
                alignItems:"center"
             }}
        >   
            
            <MdSettings className='md-icon'
                style={{color:"brown"}}
                onClick={e=>{
                    setShowAddPageSettingForShop(!showAddPageSettingForShop)
                }}
            />

            <MdSettings className='md-icon'
                onClick={e=>{
                    setShowFilterSettingForShop(!showFilterSettingForShop)
                }}
            />
            <div style={{width:"90%",
                       margin:"0 0.5rem"
                 }}>
                <input
                    type="range" min="10" max="100" 
                    value={widthRight}
                    onChange={e=>{
                        setWidthRight(e.target.value)
                    }}
                />

            </div>
            <MdSettings className='md-icon'
                 onClick={e=>{
                    setShowTableSettingForShop(!showTableSettingForShop)
                }}
            />
            {/*
            editInputState&&
            <div className="iconbox"
                onClick={e=>{
                    if(editInputState){
                        refForm.current.click()
                    }                   
                }}
            >
                <Ticon
                    iconName="AiOutlineFileImage"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            */}


            <div className="d-none">
                <Link ref={refEdit} to="/cloud/edit"/>
            </div>

              
        
        </div>
        
    </div>  
    )
}

export default RenderBadgeMaster

