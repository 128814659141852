

import React from 'react'
import cosmeticUtil from '../../util/cosmeticUtil'
import {MainContext} from '../../context/MainContext'

import MasterComponent from './MasterComponent'
import console from '../../util/consoleVerify'


const {checkWindowLandscape}=cosmeticUtil


function ItemShopPayment (){

    const {
 
        maxPerPage,

        dataLabelShopPayment,setDataLabelShopPayment,

        recordPerPageForShopPayment,setRecordPerPageForShopPayment,
        toSortUpForShopPayment,setToSortUpForShopPayment,
        toSortDownForShopPayment,setToSortDownForShopPayment,
        toAllTrueForShopPayment,setToAllTrueForShopPayment,
        filterStateForShopPayment,setFilterStateForShopPayment,
        lastRecordIdForShopPayment,setLastRecordIdForShopPayment,
        
        
        currentPageForShopPayment,setCurrentPageForShopPayment,
        totalPageForShopPayment,setTotalPageForShopPayment,
        countRecordForShopPayment,setCountRecordForShopPayment,
        
        
        keyArrayOfShopPayment,


        stringArray,
        setIsXls,
        setIsCsv,


        shopPaymentData,setShopPaymentData,
        editShopPayment,setEditShopPayment,
        
        prevLink,

        submitDataFormat,
        dfCommon,setDfCommon,

        showFilterSettingForShopPayment,setShowFilterSettingForShopPayment,
        showTableSettingForShopPayment,setShowTableSettingForShopPayment,
        showAddPageSettingForShopPayment,setShowAddPageSettingForShopPayment,

        showModalAddPageForShopPayment,setShowModalAddPageForShopPayment,
        showModalEditPageForShopPayment,setShowModalEditPageForShopPayment,
        showModalConfirmForShopPayment,setShowModalConfirmForShopPayment,
        
        keyArrayWithPhotoUrlOfShopPayment,
        widthRight,setWidthRight,
        basicDataSt,

        addInputStateForShopPayment,setAddInputStateForShopPayment,
        blankAddInputStateForShopPayment,
        editInputStateForShopPayment,setEditInputStateForShopPayment,
    

        submitLogInWithRecoveryPasswordByMaster,
        
        submitSearchForShopPayment,
        submitSearchForSearchButtonForShopPayment,
        submitAddForShopPayment,
        submitEditForShopPayment,
        submitDeleteForShopPayment,
        submitEditWithNewIdForShopPayment,
        submitCopyRecordForShopPayment,
        submitDataFormatForShopPayment,

        submitUpdateShopFromShopPayment,

        setPrevLink,
        setCurrentLink,

        dfCommonForShopPayment,
        setDfCommonForShopPayment,

        languageDisplay,
        

    }=React.useContext(MainContext)



    return <MasterComponent
            
            maxPerPage={maxPerPage}

            dataLabelShop={dataLabelShopPayment}
            setDataLabelShop={setDataLabelShopPayment}

            recordPerPageForShop={recordPerPageForShopPayment}
            setRecordPerPageForShop={setRecordPerPageForShopPayment}
            toSortUpForShop={toSortUpForShopPayment}
            setToSortUpForShop={setToSortUpForShopPayment}
            toSortDownForShop={toSortDownForShopPayment}
            setToSortDownForShop={setToSortDownForShopPayment}
            toAllTrueForShop={toAllTrueForShopPayment}
            setToAllTrueForShop={setToAllTrueForShopPayment}
            filterStateForShop={filterStateForShopPayment}
            setFilterStateForShop={setFilterStateForShopPayment}
            lastRecordIdForShop={lastRecordIdForShopPayment}
            setLastRecordIdForShop={setLastRecordIdForShopPayment}
            
            currentPageForShop={currentPageForShopPayment}
            setCurrentPageForShop={setCurrentPageForShopPayment}
            totalPageForShop={totalPageForShopPayment}
            setTotalPageForShop={setTotalPageForShopPayment}
            countRecordForShop={countRecordForShopPayment}
            setCountRecordForShop={setCountRecordForShopPayment}

            submitSearchForShop={submitSearchForShopPayment}
            submitSearchForSearchButtonForShop={submitSearchForSearchButtonForShopPayment}
            submitLogInWithRecoveryPasswordByMaster={submitLogInWithRecoveryPasswordByMaster}


            stringArray={stringArray}
            setIsXls={setIsXls}
            setIsCsv={setIsCsv}

            shopData={shopPaymentData}
            setShopData={setShopPaymentData}
            editShop={editShopPayment}
            setEditShop={setEditShopPayment}
            
            submitDataFormat={submitDataFormatForShopPayment}
            dfCommon={dfCommonForShopPayment}
            setDfCommon={setDfCommonForShopPayment}

            showFilterSettingForShop={showFilterSettingForShopPayment}
            setShowFilterSettingForShop={setShowFilterSettingForShopPayment}
            showTableSettingForShop={showTableSettingForShopPayment}
            setShowTableSettingForShop={setShowTableSettingForShopPayment}
            showAddPageSettingForShop={showAddPageSettingForShopPayment}
            setShowAddPageSettingForShop={setShowAddPageSettingForShopPayment}

            showModalAddPageForShop={showModalAddPageForShopPayment}
            setShowModalAddPageForShop={setShowModalAddPageForShopPayment}
            showModalEditPageForShop={showModalEditPageForShopPayment}
            setShowModalEditPageForShop={setShowModalEditPageForShopPayment}
            showModalConfirmForShop={showModalConfirmForShopPayment}
            setShowModalConfirmForShop={setShowModalConfirmForShopPayment}
            

            keyArrayFilterInput={keyArrayOfShopPayment}
            keyArrayFilterInputSetting={keyArrayOfShopPayment}
            
            keyArrayTable={keyArrayWithPhotoUrlOfShopPayment}
            keyArrayTableSetting={keyArrayOfShopPayment}

            keyArrayModalAdd={keyArrayOfShopPayment}
            keyArrayModalAddSetting={keyArrayOfShopPayment}

            keyArrayModalEdit={keyArrayOfShopPayment}

            //keyArrayWithPhotoUrl,
            widthRight={widthRight}
            setWidthRight={setWidthRight}
            basicDataSt={basicDataSt}

            blankAddInputState={blankAddInputStateForShopPayment}
            addInputState={addInputStateForShopPayment}
            setAddInputState={setAddInputStateForShopPayment}
            editInputState={editShopPayment}
            setEditInputState={setEditShopPayment}

            currentLink={""}
            prevLink={""}

            setPrevLink={setPrevLink}
            setCurrentLink={setCurrentLink}

            showPhoto={true}

            submitAddForShop={submitAddForShopPayment}
            submitDeleteForShop={submitDeleteForShopPayment}
            submitEditForShop={submitEditForShopPayment}

            submitEditWithNewIdForShop={submitEditWithNewIdForShopPayment}
            submitCopyRecordForShop={submitCopyRecordForShopPayment}


            matchEditDataField={"_id"}

            useUpdateShopAfterShopPayment={true}

            submitUpdateShopFromShopPayment={submitUpdateShopFromShopPayment}
    />

}

export default ItemShopPayment