import React from 'react'
import Mnavbar from '../../component/mnavbar/Mnavbar'
import {FaBan,FaCheck,FaFolder} from 'react-icons/fa';

import SettingPage from './SettingPage'
import fakeAxiosUtil from './fakeAxiosUtil'
import cosmeticUtil from '../../util/cosmeticUtil'
import {MainContext} from '../../context/MainContext'
import {FaHome,FaImages,FaTable,FaRegCalendarAlt} from 'react-icons/fa'
import {MdRefresh} from 'react-icons/md'
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'

import './Home.css'

//const {dataLabel,setDataLabel}=React.useContext(MainContext)

const {checkWindowLandscape}=cosmeticUtil

function ItemSetting(){

    const {
        basicDataSt,setBasicDataSt,
        dataLabel,setDataLabel,
        settingType,setSettingType,
        submitsearchdataformat,

        countDataFormat,setCountDataFormat,
        lastRecordIdDataFormat,setLastRecordIdDataFormat,
        dataDataFormat,setDataDataFormat, 
        newDataDataFormat,setNewDataDataFormat,
        deleteDataFormat,setDeleteDataFormat,
        editDataFormat,setEditDataFormat,
        defaultDataFormat,setDefaultDataFormat, 

        showAddDataFormatModal,setShowAddDataFormatModal,
        showDeleteDataFormatModal,setShowDeleteDataFormatModal,
        showEditDataFormatModal,setShowEditDataFormatModal,
        showUpdateDefaultDataFormatModal,setShowUpdateDefaultDataFormatModal,

        showDataFormatFromTemplate,setShowDataFormatFromTemplate,
        dataFormatFromTemplate,setDataFormatFromTemplate,

        selectDataFormatFromTemplate,setSelectDataFormatFromTemplate,

        submitNewDataDataFormat,
        submitDeleteDataFormat,
        submitEditDataFormat,
        submitUpdateDefaultDataFormat,

        submitSearchDataFormatFromTemplate,
        submitAddDataFormatFromTemplate,

        languageDisplay,

        
        

    }=React.useContext(MainContext)

    const refHome=React.createRef()

return dataDataFormat
    ?<div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <Mnavbar/>
        </div>
        <div 
            className=""
            style={{
                height:checkWindowLandscape()?"95%":"92%",width:"100%",
                //border:"1px solid red"
            }}
        >
            <SettingPage
                basicDataSt={basicDataSt}
                setBasicDataSt={setBasicDataSt}

                dataDataFormat={dataDataFormat}
                setDataDataFormat={setDataDataFormat}
                countDataFormat={countDataFormat}
                setCountDataFormat={setCountDataFormat}
                lastRecordIdDataFormat={lastRecordIdDataFormat}
                setLastRecordIdDataFormat={setLastRecordIdDataFormat}

                showAddDataFormatModal={showAddDataFormatModal}
                setShowAddDataFormatModal={setShowAddDataFormatModal}

                showDeleteDataFormatModal={showDeleteDataFormatModal}
                setShowDeleteDataFormatModal={setShowDeleteDataFormatModal}
                
                showEditDataFormatModal={showEditDataFormatModal}
                setShowEditDataFormatModal={setShowEditDataFormatModal}

                showUpdateDefaultDataFormatModal={showUpdateDefaultDataFormatModal}
                setShowUpdateDefaultDataFormatModal={setShowUpdateDefaultDataFormatModal}

                newDataDataFormat={newDataDataFormat}
                setNewDataDataFormat={setNewDataDataFormat}

                deleteDataFormat={deleteDataFormat}
                setDeleteDataFormat={setDeleteDataFormat}

                editDataFormat={editDataFormat}
                setEditDataFormat={setEditDataFormat}

                defaultDataFormat={defaultDataFormat}
                setDefaultDataFormat={setDefaultDataFormat}

                showDataFormatFromTemplate={showDataFormatFromTemplate}
                setShowDataFormatFromTemplate={setShowDataFormatFromTemplate}
                dataFormatFromTemplate={dataFormatFromTemplate}
                setDataFormatFromTemplate={setDataFormatFromTemplate}

                submitNewDataDataFormat={submitNewDataDataFormat}
                submitDeleteDataFormat={submitDeleteDataFormat}
                submitEditDataFormat={submitEditDataFormat}
                submitUpdateDefaultDataFormat={submitUpdateDefaultDataFormat}
                
                submitSearchDataFormatFromTemplate={submitSearchDataFormatFromTemplate}
                submitAddDataFormatFromTemplate={submitAddDataFormatFromTemplate}
                
                selectDataFormatFromTemplate={selectDataFormatFromTemplate}
                setSelectDataFormatFromTemplate={setSelectDataFormatFromTemplate}

                languageDisplay={languageDisplay}
            />
          
        </div>
    </div>

    :<div className='home-bg-screen'
    style={{width:"100vw",height:"100vh"}}>
            <div className='flex-center-center'
                style={{height:"100%",width:"100%"}}
            >

                    <button style={{
                                width:"10rem",
                                height:"10rem",
                                padding:"0",
                                backgroundColor:"gray"
                            }}
                        onClick={e=>{
                                submitsearchdataformat()
                        }}
                    >
                        <MdRefresh
                            style={{fontSize:"4rem"}}
                        />
                        <div>
                            {
                                languageDisplay
                                ?languageDisplay.refresh 
                                    ?languageDisplay.refresh
                                    :"Refresh"
                                :"Refresh"
                            }
                        </div>
                    </button>
            </div>

            <div className="d-none">

                    <Link ref={refHome} to="/cloud/home"/>  
            </div>     
    </div>



}

export default ItemSetting