

import React from 'react'
import cosmeticUtil from '../../util/cosmeticUtil'
import {MainContext} from '../../context/MainContext'
import MasterComponent from './MasterComponent'
import console from '../../util/consoleVerify'


const {checkWindowLandscape}=cosmeticUtil


function ItemUser (){

    const {
 
        maxPerPage,

        dataLabelUser,setDataLabelUser,

        recordPerPageForUser,setRecordPerPageForUser,
        toSortUpForUser,setToSortUpForUser,
        toSortDownForUser,setToSortDownForUser,
        toAllTrueForUser,setToAllTrueForUser,
        filterStateForUser,setFilterStateForUser,
        lastRecordIdForUser,setLastRecordIdForUser,
        
        currentPageForUser,setCurrentPageForUser,
        totalPageForUser,setTotalPageForUser,
        countRecordForUser,setCountRecordForUser,

        keyArrayOfUser,
        submitSearchForUser,
        submitSearchForSearchButtonForUser,


        stringArray,
        setIsXls,
        setIsCsv,


        userData,setUserData,
        editUser,setEditUser,
        
        prevLink,

        submitDataFormat,
        dfCommon,setDfCommon,

        showFilterSettingForUser,setShowFilterSettingForUser,
        showTableSettingForUser,setShowTableSettingForUser,
        showAddPageSettingForUser,setShowAddPageSettingForUser,

        showModalAddPageForUser,setShowModalAddPageForUser,
        showModalEditPageForUser,setShowModalEditPageForUser,
        showModalConfirmForUser,setShowModalConfirmForUser,
        
        //keyArrayWithPhotoUrl,
        widthRight,setWidthRight,
        basicDataSt,

        addInputStateForUser,setAddInputStateForUser,
        blankAddInputStateForUser,
        editInputStateForUser,setEditInputStateForUser,

        submitAddForUser,submitEditForUser,submitDeleteForUser,
        
        submitEditWithNewIdForUser,
        submitCopyRecordForUser,
        submitLogInWithRecoveryPasswordByMaster,

        setPrevLink,
        setCurrentLink,

        submitDataFormatForUser,
        dfCommonForUser,
        setDfCommonForUser,

        languageDisplay,
        

    }=React.useContext(MainContext)




    return <MasterComponent
            
            maxPerPage={maxPerPage}

            dataLabelShop={dataLabelUser}
            setDataLabelShop={setDataLabelUser}

            recordPerPageForShop={recordPerPageForUser}
            setRecordPerPageForShop={setRecordPerPageForUser}
            toSortUpForShop={toSortUpForUser}
            setToSortUpForShop={setToSortUpForUser}
            toSortDownForShop={toSortDownForUser}
            setToSortDownForShop={setToSortDownForUser}
            toAllTrueForShop={toAllTrueForUser}
            setToAllTrueForShop={setToAllTrueForUser}
            filterStateForShop={filterStateForUser}
            setFilterStateForShop={setFilterStateForUser}
            lastRecordIdForShop={lastRecordIdForUser}
            setLastRecordIdForShop={setLastRecordIdForUser}
            
            
            currentPageForShop={currentPageForUser}
            setCurrentPageForShop={setCurrentPageForUser}
            totalPageForShop={totalPageForUser}
            setTotalPageForShop={setTotalPageForUser}
            countRecordForShop={countRecordForUser}
            setCountRecordForShop={setCountRecordForUser}

            submitSearchForShop={submitSearchForUser}
            submitSearchForSearchButtonForShop={submitSearchForSearchButtonForUser}
            submitLogInWithRecoveryPasswordByMaster={submitLogInWithRecoveryPasswordByMaster}


            stringArray={stringArray}
            setIsXls={setIsXls}
            setIsCsv={setIsCsv}


            shopData={userData}
            setShopData={setUserData}
            editShop={editUser}
            setEditShop={setEditUser}
            

            submitDataFormat={submitDataFormatForUser}
            dfCommon={dfCommonForUser}
            setDfCommon={setDfCommonForUser}

            showFilterSettingForShop={showFilterSettingForUser}
            setShowFilterSettingForShop={setShowFilterSettingForUser}
            showTableSettingForShop={showTableSettingForUser}
            setShowTableSettingForShop={setShowTableSettingForUser}
            showAddPageSettingForShop={showAddPageSettingForUser}
            setShowAddPageSettingForShop={setShowAddPageSettingForUser}

            showModalAddPageForShop={showModalAddPageForUser}
            setShowModalAddPageForShop={setShowModalAddPageForUser}
            showModalEditPageForShop={showModalEditPageForUser}
            setShowModalEditPageForShop={setShowModalEditPageForUser}
            showModalConfirmForShop={showModalConfirmForUser}
            setShowModalConfirmForShop={setShowModalConfirmForUser}
            

            keyArrayFilterInput={keyArrayOfUser}
            keyArrayFilterInputSetting={keyArrayOfUser}
            
            keyArrayTable={keyArrayOfUser}
            keyArrayTableSetting={keyArrayOfUser}

            keyArrayModalAdd={keyArrayOfUser}
            keyArrayModalAddSetting={keyArrayOfUser}

            keyArrayModalEdit={keyArrayOfUser}


            //keyArrayWithPhotoUrl,
            widthRight={widthRight}
            setWidthRight={setWidthRight}
            basicDataSt={basicDataSt}

            blankAddInputState={blankAddInputStateForUser}
            addInputState={addInputStateForUser}
            setAddInputState={setAddInputStateForUser}
            editInputState={editUser}
            setEditInputState={setEditUser}


    
            currentLink={""}
            prevLink={""}

            showPhoto={false}

            submitAddForShop={submitAddForUser}
            submitDeleteForShop={submitDeleteForUser}
            submitEditForShop={submitEditForUser}

            submitEditWithNewIdForShop={submitEditWithNewIdForUser}
            submitCopyRecordForShop={submitCopyRecordForUser}

            setPrevLink={setPrevLink}
            setCurrentLink={setCurrentLink}

            matchEditDataField={"_id"}

            useUpdateShopAfterShopPayment={false}

            submitUpdateShopFromShopPayment={null}

    />

}

export default ItemUser

