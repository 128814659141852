import React from 'react';

import {FaWrench,FaMoneyBill,FaMoneyBillWave,FaRegMoneyBillAlt,
        FaBarcode,FaWarehouse,
        FaRegCalendarAlt,FaImages,FaTable,
        FaUserCog
       } from 'react-icons/fa';
import {MdVpnKey,MdTune,MdOutlineShoppingCart,
        MdClose,MdFastfood,MdPerson,MdSettingsApplications,
        MdInfoOutline,MdUpload
} from 'react-icons/md';

import {Link} from 'react-router-dom';

import MMCLOUDZONELOGO from './MMCLOUDZONELOGO'
import console from '../../util/consoleVerify'

import {MainContext} from '../../context/MainContext'

import './Home.css'

function Home() {


const refSetting=React.createRef()
const refTable=React.createRef()
const refAccount=React.createRef()
const refLogIn=React.createRef()
const refCalendar=React.createRef()
const refAlbum=React.createRef()
const refInfo=React.createRef()
const refUpload=React.createRef()
const refPayment=React.createRef()
const refLanguage=React.createRef()


const boxStyle={fontSize:"5rem"}
const bigDivStyle={gridRow:"1/3",gridColumn:"1/2"}
const mdDivStyle={gridRow:"1/2",gridColumn:"3/5"}

const {
    submitGetSupportLink,
    languageDisplay,
    languageSetting,setLanguageSetting,
    console
}=React.useContext(MainContext)

let temp1=""

const [LB,setLB]=React.useState({
    album:"..",
    table:"..",
    setting:"..",
    login:"..",
    info:"..",
    upload:"..",
    calendar:"..",
    account:"..",
    payment:"..",
    language:".."
})


React.useEffect(()=>{
    if(languageDisplay){
        const temp={
            album:languageDisplay.album,
            table:languageDisplay.table,
            setting:languageDisplay.setting,
            login:languageDisplay.login,
            info:languageDisplay.support,
            upload:languageDisplay.upload,
            calendar:languageDisplay.calendar,
            account:languageDisplay.account,
            payment:languageDisplay.payment,
            language:languageDisplay.language
        }
        setLB(temp)
    }
},[languageDisplay])

const boxArray=[
    {bgColor:"#FAE03C",//"rgb(255,102,0)",
    ref:refAlbum,
    LB:LB.album,
    color:"#444",
    icon:<FaImages style={{...boxStyle,color:"#444"}}/>,
    link:"album"
    },
    {bgColor:"#B76BA3",//"rgb(214,0,147)",
    ref:refTable,
    LB:LB.table,
    color:"white",
    icon:<FaTable style={{...boxStyle,color:"white"}}/>,
    link:"table"
    },
    {bgColor:"#004B8D",//"rgb(214,0,147)",
    ref:refCalendar,
    LB:LB.calendar,
    color:"white",
    icon:<FaRegCalendarAlt style={{...boxStyle,color:"white"}}/>,
    link:"calendar"
    },
    {bgColor:"rgb(192,0,0)", 
    ref:refSetting,
    LB:LB.setting,
    color:"white",
    icon:<MdSettingsApplications style={{...boxStyle,color:"white"}}/>,
    link:""
    },
    {bgColor:"rgb(117,81,57)", 
    ref:refAccount,
    LB:LB.account,
    color:"white",
    icon:<MdPerson style={{...boxStyle,color:"white"}}/>,
    link:""
    },
    {bgColor:"#ff9933", 
    ref:refLogIn,
    LB:LB.login,
    color:"#444",
    icon:<MdVpnKey style={{...boxStyle,color:"#444"}}/>,
    link:""
    },
    /*0
    {bgColor:"#E15D44", 
    ref:refUpload,
    LB:LB.upload,
    color:"#444",
    icon:<MdUpload style={{...boxStyle,color:"white"}}/>,
    link:""
    }
    */,
    {bgColor:"#00ffff",
    ref:refPayment,
    LB:LB.payment,
    color:"#444",
    icon:<FaMoneyBillWave style={{...boxStyle,color:"#444"}}/>,
    link:""
    },
    {bgColor:"#448d60", 
    ref:refInfo,
    LB:LB.info,
    color:"#444",
    icon:<MdInfoOutline style={{...boxStyle,color:"white"}}/>,
    link:""
    },
    {bgColor:"#333", 
    ref:refLanguage,
    LB:LB.language,
    color:"white",
    icon:<div style={{
        ...boxStyle,
        fontSize:"2.2rem",
        color:"white"
        
        }}>
            ไทย/Eng
         </div>,
    link:""
    },


]



const {
    setCurrentLink
}=React.useContext(MainContext)


const shouldRender= React.useRef(true)
React.useEffect(()=>{
    return () => {
        //console.log('end.......................................')
        shouldRender.current=false
    } 
},[])

const [target,setTarget]=React.useState(0)
const [target2,setTarget2]=React.useState(0)

React.useEffect(()=>{
    if(shouldRender.current){
        let randomInt=0
        let randomInt2=0
        while (randomInt===target&&shouldRender.current){
            //console.log('target........Home.....')
            randomInt=Math.floor(Math.random() * boxArray.length)
            randomInt2=Math.floor(Math.random() * boxArray.length)
        }
    
        setTimeout(()=>{
            if(shouldRender.current){
                //console.log('setTimeout........Home............')
                setTarget(randomInt)
                setTarget2(randomInt2)
            }
        },2000)
    }
},[target])

return (
    <div className="home-hero">
        <div className="home-bg-screen">
            <div className="triangle1"/>
            <div className="triangle2"/>
            <div className="triangle3"/>
            <div className="triangle4"/>
            <div className="triangle5"/>

            <div 
                    style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"flex-end",
                        width:"100vw",
                        height:"100vh",
                        position:"fixed",
                    }}

            >
                <div
                    style={{
                        padding:"auto",
                        transform:"scale(0.6)",   
                        opacity:"0.7"
                    }}
                   
                >
                    <MMCLOUDZONELOGO
                    />
                </div>
                
            </div>


         
            
            <div className="home-box">
                    <div 
                        className="home-grid"
                    >
                      
                        {
                            boxArray.map((i,idx)=>{
                            return false//idx==0
                                ?<div className={"home-tile2" }
                                    key={idx} 
                                    style={{//...bigDivStyle,
                                            backgroundColor:`${i.bgColor}`,
                                            display:"flex",
                                            justifyContent:"center",
                                            alignItems:"center",
                                            borderRadius:"5%",
                                            position:"relative",

                                            }}

                                    onClick={e=>{i.ref.current.click()}}
                                    >   
                                        {i.icon}
                                        <div 
                                            style={{position:"absolute",
                                                bottom:"0",
                                                right:"0.5rem",
                                                color:i.color,
                                                fontSize:"1.2rem",
                                                display:target==idx||target2==idx?"":"none"
                                            }}
                                        >
                                            {i.LB}
                                        </div>
                                    </div>
                                :<div className="home-tile" 
                                     key={idx} 
                                     style={{
                                            backgroundColor:`${i.bgColor}`,
                                            display:"flex",
                                             justifyContent:"center",
                                             alignItems:"center",
                                             borderRadius:"5%",
                                             position:"relative",

                                            }}
                                    onClick={e=>{
                                        setCurrentLink(i.link)
                                        i.ref.current.click()
                                    
                                    }}
                                >   
                                    {
                                    i.icon
                                    }
                                    <div 
                                        style={{position:"absolute",
                                            bottom:"0",
                                            right:"0.5rem",
                                            color:i.color,
                                            fontSize:"1.2rem",
                                            display:target==idx||target2==idx?"none":""
                                        }}
                                    >
                                        {i.LB}
                                    </div>
                                </div>
                            })
                        }
    
                  


                    </div>
                 
                  
            </div>
          
   
           

        </div>
       
        <div className="d-none">
            <Link ref={refAlbum} to="/cloud/album"/>
            <Link ref={refTable} to="/cloud/table"/>

            <Link ref={refCalendar} to="/cloud/calendar"/>
            <Link ref={refSetting} to="/cloud/setting"/>

            <Link ref={refAccount} to="/cloud/account"/>


            <Link ref={refLogIn} to="/cloud/login"/>
            <Link ref={refUpload} to="/cloud/upload"/>

            <Link ref={refPayment} to="/cloud/payment"/>

            <div ref={refInfo} onClick={async()=>{
                const result=await submitGetSupportLink()
                if(result){
                    if(result.data){
                        if(result.data.link){
                            temp1=result.data.link
                        }
                    }
                }
                window.location.href=temp1

            }}/>

            <div ref={refLanguage} onClick={()=>{

                //console.log("languageSetting......")

                if(languageSetting==="Thai"){
                    setLanguageSetting("English")
                }
                else {
                    setLanguageSetting("Thai")
                }

            }}/>


            

        </div>
        
    </div>
)
}

export default Home;