import React from 'react';

import {MdVisibility,MdVisibilityOff,
        MdEdit,MdAddCircle,MdDelete,
        MdOutlineCheckBoxOutlineBlank,
        MdOutlineCheckBox,
        MdRefresh,
        MdCheckBox,
        MdCheckBoxOutlineBlank,
        MdPassword

} from 'react-icons/md';
import {FaBan,FaCheck,
      FaPlusSquare,FaMinusSquare,
} from 'react-icons/fa'
import {Link} from 'react-router-dom';
import AddUserModal from './AddUserModal';
import EditUserModal from './EditUserModal';
import DeleteUserModal from './DeleteUserModal';
import ChangeUserPasswordModal from './ChangeUserPasswordModal'
import console from '../../util/consoleVerify'

function UserListModal({
    submitSearchUserList,
    submitAddUser,
    submitEditUser,
    submitDeleteUser,
    submitChangeUserPasswordByAdmin,

    userList,
    basicData,
    submitCheck,
    submitCancel,
    languageDisplay,

    checkChar
}){

    const refHome=React.createRef()

    const [editUser,setEditUser]=React.useState(null)
    const [showAddUser,setShowAddUser]=React.useState(false)
    const [showEditUser,setShowEditUser]=React.useState(false)
    const [showDeleteUser,setShowDeleteUser]=React.useState(false)
    const [showChangeUserPassword,setShowChangeUserPassword]=React.useState(false)

    const tempArray=[
        {
            key:"id",
            lb: languageDisplay
                ?languageDisplay.id
                    ?languageDisplay.id
                    :"ID"
                :"ID",
            className:"w-10"
        },
        {   key:"username",
            lb:languageDisplay
                ?languageDisplay.username
                    ?languageDisplay.username
                    :"USERNAME0"
                :"USERNAME",
            className:"w-20"
        },
        {
            key:"userLevel",
            lb: languageDisplay
                ?languageDisplay.userLevel
                    ?languageDisplay.userLevel
                    :"USER LEVEL"
                :"USER LEVEL",
            className:"w-20"
        },
        {
            key:"name",
            lb:languageDisplay
                ?languageDisplay.name
                    ?languageDisplay.name
                    :"NAME"
                :"NAME",
            className:"w-20"
        },
        {
            key:"surname",
            lb:languageDisplay
                ?languageDisplay.surname
                    ?languageDisplay.surname
                    :"SURNAME"
                :"SURNAME",
            className:"w-20"
        },
        {
            key:"active",
            lb:languageDisplay
                ?languageDisplay.active
                    ?languageDisplay.active
                    :"ACTIVE"
                :"ACTIVE",
            className:"w-10"
        },
        {
            key:"toDelete",
            lb:languageDisplay
                ?languageDisplay.toDelete
                    ?languageDisplay.toDelete
                    :"TO BE DELETED"
                :"TO BE DELETED",
            className:"w-10"
        }
    ]


    const genEditColor=(editUser,c)=>{
        let tempColor="black"
        if(editUser){
            if(editUser.id===c.id){
                tempColor="red"
            }
        }
        return tempColor
    }

    const genLineThrough=(c)=>{
        let temp=""
        if(c.toDelete){
            temp="line-through"
        }
        return temp
    }

    //===========================
    const renderGetUserList=()=>{
        return (
            <div className="Modal-background">
                <div className="Modal-box" 
                    style={{paddingBottom:"1rem",
                            width:"80%",
                            minWidth:"70%",
                            minHeight:"100px",
                            //height:"50%",
                            minHeight:"30%",
                            overflow:"auto"
                        }}
                >
    
                    <div className="flex-center-center">
                        <div className='w-100'
                        >
    
                            <div className='flex-center-center'>
                                <h5>{
                                    languageDisplay
                                    ?languageDisplay.userListDisplay
                                        ?languageDisplay.userListDisplay
                                        :"DISPLAY USER LIST"
                                    :"DISPLAY USER LIST"
                                    }
                                </h5>
                            </div>
    
    
                            <div className='w-100 flex-center-center jc-end'
                                style={{marginTop:"1rem"}}
                            >
                                    <div>
                                        <button
    
                                            onClick={e=>{
                                                submitSearchUserList()                                    
                                            }}
                                        >
                                            <FaCheck/>
                                        </button>
                                    </div>
    
                                    <div>
                                        <button
                                            
                                            onClick={e=>{
                                                submitCancel(false)
                                            }}
                                        >
                                            <FaBan/>
                                        </button>
                                    </div>
    
                            </div>
    
    
                        </div>
            
                    </div>
                </div>
            </div>  
            )
    }
    //===========================
    return (
        <div className="Modal-background">

                {userList
                ?<div className="Modal-box2"
                     style={{
                        width:"95%",
                        position:"relative"
                    }}
                >
                    <div className="Modal-header"
                    >
                        <h5>
                           {
                            languageDisplay
                            ?languageDisplay.userList
                               ?languageDisplay.userList
                               :"USER LIST"
                            :"USER LIST"
                           }
                        </h5>

                        <div 
                            style={{
                                position:"absolute",
                                left:"3rem"
                            
                            }}
                        >
                            <FaPlusSquare
                                className='md-icon'
                                style={{margin:"0 1rem"}}

                                onClick={e=>{
                                    setShowAddUser(true)
                                }}
                            />
                            {editUser&&
                            <FaMinusSquare
                                className='md-icon'
                                style={{
                                    margin:"0 1rem",
                                    visibility:editUser.id===1?"hidden":"visible"
                                }}
                                
                                onClick={e=>{
                                    setShowDeleteUser(true)
                                }}
                            />
                            }
                            {editUser&&
                            <MdEdit
                                  className='md-icon'
                                  style={{margin:"0 1rem"}}
                                  onClick={e=>{
                                    setShowEditUser(true)
                                  }}
                            />
                            }
                            {editUser&&
                            <MdPassword
                                className='md-icon'
                                style={{margin:"0 1rem"}}
                                onClick={e=>{
                                    setShowChangeUserPassword(true)
                                }}
                            />
                            }
                        </div>
                    </div>

                    <div 
                        style={{
                            width:"100%",
                            overflow:"auto"
                        }}
                    >
                    
                    <div className='w-100'
                         style={{
                            display:"flex"
                         }}
                    >
                    {
                      tempArray.map((e,idxE)=>{
                        return (
                            <div className={e.className}
                                 key={idxE}
                                 style={{
                                    fontSize:"1.2rem",
                                    backgroundColor:"black",
                                    color:"white",
                                    border:"0.1px solid white",
                                    display:"flex",
                                    justifyContent:"center",
                                    alignItems:"center"
                                 }}
                            >
                                {
                                  e.lb  
                                }
                            </div>
                        )
                      })  
                    }
                    </div>

                    {
                      userList.map((c,idxC)=>{
                        return (
                            <div className='w-100'
                                key={`userList-${idxC}`}
                                style={{
                                    display:"flex",
                                    backgroundColor:idxC%2===1?"#999":"#bbb",
                                }}
                                onClick={e=>{
                                    if(c.toDelete!==true){
                                        setEditUser(c)
                                    }
                                   
                                }}
                            >
                                {
                                tempArray.map((f,idxF)=>{
                                    return f.key==="active"||f.key==="toDelete"
                                        ?<div className={`${f.className}`}
                                              key={idxF}
                                             style={{
                                                    display:"flex",
                                                    alignItems:"center",
                                                    justifyContent:"center",
                                             }}  
                                        >
                                                {c[f.key]
                                                ?<MdCheckBox
                                                    style={{fontSize:"1.6rem",
                                                        color:genEditColor(editUser,c)
                                                    }}
                                                />
                                                : <MdCheckBoxOutlineBlank
                                                    style={{fontSize:"1.6rem",
                                                        color:genEditColor(editUser,c)
                                                    }}
                                                />
                                                }
                                        </div>
                                         
                                        :<div className={f.className}
                                            key={idxF}
                                            style={{
                                                fontSize:"1.2rem",
                                                textDecoration:genLineThrough(c),
                                                color:genEditColor(editUser,c)
                                            }}

                                        >
                                            { c[f.key] }
                                        </div>
                                    
                                })  
                                }
                            </div>
                        )
                      })   
                    }
                    </div>

                    <div className="Modal-footer"
                         style={{marginTop:"2rem"}}
                    >
                   
                        <div>
                            <button                       
                                onClick={()=>{
                                    //submitCancel(false)
                                    window.location.reload()
                                }}
                            >
                                <FaBan/>
                            </button>
                        </div>
                    </div>


                </div>     
                :renderGetUserList()
                
                }

            

            {showAddUser&&
            <AddUserModal
                submitCheck={submitAddUser}
                submitCancel={setShowAddUser}
                basicData={basicData}
                languageDisplay={languageDisplay}
                checkChar={checkChar}
            />
            }

            {showEditUser&&
            <EditUserModal
                editUser={editUser}
                submitCheck={submitEditUser}
                submitCancel={setShowEditUser}
                basicData={basicData}
                languageDisplay={languageDisplay}

            />
            }

            {showDeleteUser&&
            <DeleteUserModal
                editUser={editUser}
                submitCheck={submitDeleteUser}
                submitCancel={setShowDeleteUser}
                languageDisplay={languageDisplay}

            />
            }
            {showChangeUserPassword&&
            <ChangeUserPasswordModal
                editUser={editUser}
                submitCheck={submitChangeUserPasswordByAdmin}
                submitCancel={setShowChangeUserPassword}
                languageDisplay={languageDisplay}
                checkChar={checkChar}
            />
            }

            <div className="d-none">

                <Link ref={refHome} to="/cloud/home"/>  
            </div>     


        </div>
    )
    

}



export default UserListModal;


