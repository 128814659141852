import React from 'react'

import {FaBan,FaCheck,FaArrowAltCircleDown,} from 'react-icons/fa'
import {MdSaveAs,MdRefresh} from 'react-icons/md'

import console from '../../util/consoleVerify'


const ModalEditNewId=({
    setShow,
    lb,
    editInputState,
    setEditInputState,
    submitFunction,
    lastRecordId,
    languageDisplay
})=>{

    return(
    <div className="Modal-background">
        <div className="Modal-box2">
           
            <div className="Modal-body"
                 style={{display:"block"}}
            >

                <div className='flex-center-center w-100'
                     style={{fontSize:"1.2rem"}}
                >
                    {
                        languageDisplay
                        ?languageDisplay.editId 
                            ?languageDisplay.editId 
                            :"Edit new Id of this record"
                        :"Edit new Id of this record"
                    
                    }
                </div>


                <div className="xc10 lc10 mc10 sc12 p-1 bd-blacks"
                    style={{display:"flex",alignItems:"baseline"}}    
                >    
                    <div className="xc6">
                        <p  className="label table-p"
                            //style={{color:dataLabel[b].lineColor}}
                            >{lb}</p>
                    </div>
                    <div className="xc6">
                        <input
                            style={{
                                backgroundColor:"rgba(230,230,230,1)"
                            }}
                            type="number"
                            value={editInputState.id}
                            disabled="disabled"
                            onChange={e=>{
                                const tempNewId=parseFloat(lastRecordId)+1
                                setEditInputState({
                                    ...editInputState,
                                    newId:tempNewId
                                })
                            }}
                        />
                    </div>
                </div>



                <div className="xc10 lc10 mc10 sc12 p-1 bd-blacks"
                    style={{display:"flex",alignItems:"baseline"}}    
                >    
                    <div className="xc6"
                         style={{
                                display:"flex",
                                alignItems:"center",
                                justifyContent:"flex-start"
                            }}
                    >
                        <p  className="label table-p"
                            //style={{color:dataLabel[b].lineColor}}
                            >{
                                languageDisplay
                                ?languageDisplay.newId
                                    ?languageDisplay.newId
                                    :"New Id"
                                :"New Id"
                            }
                        </p>
                        <MdRefresh className='md-icon'
                                style={{marginLeft:"1rem"}}

                                onClick={e=>{
                                    const tempNewId=parseFloat(lastRecordId)+1
                                    setEditInputState({
                                        ...editInputState,
                                        newId:tempNewId
                                    })
    
                                }}
                             
                        />

                    </div>
                    <div className="xc6"
                    >
                        <input
                            style={{backgroundColor:"rgba(230,230,230,1)"}}
                            type="number"
                            value={editInputState.newId}
                            onChange={e=>{
                                //console.log(e.target.value)
                                setEditInputState(
                                    {...editInputState,
                                        newId:e.target.value
                                    }
                                )
                            }}
                        />
                    </div>
                </div>



            </div>
           
           
            <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                            //setShowError(true)
                            submitFunction()
                            if(setShow){setShow(false)}
                            //setShow(true)
                        }}
                    >
                        <MdSaveAs
                            style={{fontSize:"1.2rem"}}
                        />
                    </button>
                </div>
                <div>
                    <button
                        onClick={e=>{
                            if(setShow){setShow(false)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>

        </div>
    </div>
    
    )
}


export default ModalEditNewId

