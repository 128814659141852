import React, { isValidElement } from 'react'
import Mnavbar from '../../component/mnavbar/Mnavbar'

import cosmeticUtil from '../../util/cosmeticUtil'


import Payment from './Payment'

import console from '../../util/consoleVerify'

import appVerify from '../../util/appVerify';

import {MainContext} from '../../context/MainContext'

         
const {genMyTk,checkReqBody}=appVerify
const {checkWindowLandscape}=cosmeticUtil


function ItemPayment(){
    const {

            basicDataSt,
    
            userList,
            submitSearchUserList,
            submitAddUser,
            submitDeleteUser,
            submitEditUser,
            submitChangeUserPasswordByAdmin,
            submitChangeShopPassword,
            
            languageDisplay,
            myheader,
            languageSetting,
    
            showError,setShowError,
            messageError,setMessageError,
    

        
    }=React.useContext(MainContext)
 


    //=========================
    return(

    <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <Mnavbar/>
        </div>
    
        <div
            className='bgc-shadeGray' 
            style={{
                height:checkWindowLandscape()?"95%":"92%",width:"100%"
            }}
        >
          {
          
          basicDataSt&&  
          <Payment
                basicData={basicDataSt.basicData}
                userList={userList}
                submitSearchUserList={submitSearchUserList}
                submitAddUser={submitAddUser}

                submitEditUser={submitEditUser}
                submitDeleteUser={submitDeleteUser}
                submitChangeUserPasswordByAdmin={submitChangeUserPasswordByAdmin}
                user={basicDataSt.user}
                languageDisplay={languageDisplay}
                submitChangeShopPassword={submitChangeShopPassword}
                languageSetting={languageSetting}
                myheader={myheader}

                showError={showError}
                setShowError={setShowError}
                messageError={messageError}
                setMessageError={setMessageError}
            
          />
          
          }
        </div>
        
    </div>

    )
}

export default ItemPayment