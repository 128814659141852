
import React from 'react'
import {MdDelete,MdSkipPrevious,MdSkipNext,MdFastForward,MdFastRewind,
        MdRefresh,MdZoomIn,MdClose,MdOutlineAttachment
} from 'react-icons/md';
import {FaYoutube,FaImages} from 'react-icons/fa';
import photoUtil from        '../../component/galleryone_add/photoUtil'
import console from '../../util/consoleVerify'


import './Galleryone_add.css';

const {fileListItem,changeArrayFile,handleInputFile,
       reloadImage,resetFile,deleteFileUrl,deletePhotoUrl,
       moveFirst,movePrevious,moveNext,moveLast,
}=photoUtil

export default function Gallery20_add({
            //imgarrs,totalImages,
            fileUrl,arrayFile,setArrayFile,keyName,
            setShowImage,
            inputState,setInputState,
            useYoutubeLink,
            imageLimit

        }) {

    //console.log('Gallaryone_add')
    //console.log(imageLimit)
    const [showModalImage,setShowModalImage]=React.useState(false)
    let [imageSrc,setImageSrc]=React.useState(null)
    let [isYoutube,setIsYoutube]=React.useState(false)

    let [modalImageWidth,setModalImageWidth]=React.useState(30);

    let temp=[]
    inputState[keyName].map(i=>{
        if(i!=""){ temp=[...temp,i] }
    })        
    //imarrs = photoUrl+fileUrl
    const imgarrs=[...temp,...fileUrl]
    const totalImages=temp.length+fileUrl.length
           
    //const [imgFrameHeight,setImgFrameHeight]=React.useState('')

    const [imgTrackWidth,setImgTrackWidth]=React.useState('')

    const refFrame=React.useRef();
    
    const refButtonAddPicture=React.useRef()
    

    const [youtubeURL,setYoutubeURL]=React.useState("")

    React.useEffect(()=>{

        if(imgarrs){if(imgarrs.length>0){
            //to get imgFrameHeight 
            if(refFrame){
                //refFrame.current.click()
            }
            //to set imgTrackWidth
            let temp=imgarrs.length*100
            setImgTrackWidth(`${temp}%`)
        }} 
        
    },[])
    
    
    
 
/*
    React.useEffect(()=>{
        if(imgFrameHeight){
            //console.log(imgFrameHeight);
        }
    },[imgFrameHeight])
*/
    const renderImg=(arrs)=>{

        const arrsLength=arrs.length

        let isAllPhotoUrl=true
        for(let i=0;i<arrsLength;i++){
            if(arrs[i].blob){
                isAllPhotoUrl=false
                break
            }
        }


        if(arrs){
            return arrs.map((i,index)=>{

            
                let checkYoutubeUrl=false
                let checkPdf=false

                if(typeof i == "string"){
                    if(i.split("/embed/").length==2){
                         checkYoutubeUrl=true
                    }
                    const tempSplitArrayForPdf=i.split(".")

                    if(tempSplitArrayForPdf.length===2){
                            if(tempSplitArrayForPdf[1]==="pdf"||
                               tempSplitArrayForPdf[1]==="PDF"
                              ){
                                checkPdf=true
                                checkYoutubeUrl=false
                            }
                        }

                }

                else {
                    if(i.name){
                        const tempSplitArrayForPdf2=i.name.split(".")
                        if(tempSplitArrayForPdf2.length===2){
                            if(tempSplitArrayForPdf2[1]==="pdf"||
                               tempSplitArrayForPdf2[1]==="PDF"
                              ){
                                checkPdf=true
                                checkYoutubeUrl=false
                            }
                        }
                    }
                }

                /*
                else if(tempSplitArrayForPdf.length===2){
                    if(tempSplitArrayForPdf[1]==="pdf"){
                        checkPdf=true
                        checkYoutubeUrl=false
                    }
                }
                */


                //if(i.split("/embed/").length==2){
                //    checkYoutubeUrl=true
                //}

                let imgSrc
                let imgName
                let isFile
                //in case of fileUrl
                if(i.blob){
                    isFile=true
                    imgSrc=i.blob
                    imgName=i.name 
                }    
                //in case of PhotoUrl
                else {
                    isFile=false
                    imgSrc=i
                    imgName=i
                }


                return    (
                    <div 
                         key={index} 
                         style={{height:'100%',width:'100%',
                                display:'grid',placeItems: 'center',
                                position:"relative"}}
                        onClick={e=>{
                            //reloadImage({setShowImage})
                        }}
                    >
                        {
                        checkYoutubeUrl
                        ?<div style={{width:"100%",height:"70vh",position:"relative"}} >
                            {
                            /*
                            <div className='' 
                                style={{position:"absolute",
                                        width:"100%",
                                        height:"100%",
                                        top:"0",
                                        left:"0",
                                        zIndex:"10"
                                        }}
                            
                            >
                            </div>
                            */
                            }
                            <embed style={{width:"100%",height:"100%"}}
                                    src={i}
                            />
                        </div>
                
                        :checkPdf
                                ?<div className='w-100 h-100'
                                      style={{minHeight:"70vh"}}
                                >
                                    <embed 
                                        type="application/pdf" 
                                        src={imgSrc} 
                                        style={{width:"100%",height:"100%"}}>
                                    </embed> 
                                </div>
                                :<img className="img" src={imgSrc} style={{width:"100%",height:"auto"}} />
                        }
                        {((isAllPhotoUrl)&&(index>0))
                        ?<MdSkipPrevious
                                style={{
                                    position:"absolute",border:isFile?"3px solid red":"3px solid green",fontSize:"2rem",
                                    backgroundColor:"white",
                                    bottom:"0.5rem",left:"0.5rem",
                                    zIndex:"10",borderRadius:"50%"
                                }}
                                onClick={e=>moveFirst({idx:index,inputState,setInputState,keyName,reloadImage,setShowImage})}
                        />
                        :null
                        }

                        {((isAllPhotoUrl)&&(index>1))
                        ?<MdFastRewind
                                style={{
                                    position:"absolute",border:isFile?"3px solid red":"3px solid green",fontSize:"2rem",
                                    backgroundColor:"white",
                                    bottom:"4rem",left:"0.5rem",
                                    zIndex:"10",borderRadius:"50%"
                                }}
                                onClick={e=>movePrevious({idx:index,inputState,setInputState,keyName,reloadImage,setShowImage})}
                        />
                        :null
                        }

                        {((isAllPhotoUrl)&&(index<arrsLength-2))
                        ?<MdFastForward
                                style={{
                                    position:"absolute",border:isFile?"3px solid red":"3px solid green",fontSize:"2rem",
                                    backgroundColor:"white",
                                    bottom:"4rem",right:"0.5rem",
                                    zIndex:"10",borderRadius:"50%"
                                }}
                                onClick={e=>moveNext({idx:index,inputState,setInputState,keyName,reloadImage,setShowImage})}
                        />
                        :null
                        }

                        {((isAllPhotoUrl)&&(index<arrsLength-1))
                        ?<MdSkipNext
                                style={{
                                    position:"absolute",border:isFile?"3px solid red":"3px solid green",fontSize:"2rem",
                                    backgroundColor:"white",
                                    bottom:"0.5rem",right:"0.5rem",
                                    zIndex:"10",borderRadius:"50%"
                                }}
                                onClick={e=>moveLast({idx:index,inputState,setInputState,keyName,reloadImage,setShowImage})}
                        />
                        :null
                        }
                        
                        <MdDelete  
                            style={{
                                position:"absolute",
                                border:isFile?"3px solid red":"3px solid green",
                                fontSize:"2rem",
                                backgroundColor:"white",
                                top:"0.5rem",right:"0.5rem",
                                zIndex:"10",borderRadius:"50%"
                            }}
                            onClick={e=>{
                                if(isFile){
                                    deleteFileUrl({name:imgName,arrayFile,setArrayFile,reloadImage,setShowImage})
                                    //console.log(imgName)                          
                                }else{
                                    //console.log(imgName)
                                    deletePhotoUrl({name:imgName,inputState,setInputState,keyName,reloadImage,setShowImage})
                                }
                            }}             
                        />

                        
                        <MdZoomIn
                            style={{
                                position:"absolute",
                                border:"3px solid black",
                                fontSize:"2rem",
                                backgroundColor:"white",
                                top:"0.4rem",left:"0.4rem",
                                zIndex:"10",borderRadius:"50%"
                            }}
                            onClick={e=>{
                                
                                setIsYoutube(checkYoutubeUrl)
                                setImageSrc(imgSrc)
                                setTimeout(()=>{
                                    setShowModalImage(true)
                                },50)
                            }}             
                        />    

                    </div>   
                    )
                }
            ) 
        }
    } 

    //accept="image/jpeg,image/png,image/jpg,image/*"
    return (
    <div style={{height:"100%",width:"100%",overflow:"hidden",paddingBottom:"2rem"}}>        
                
        <input
            type="file"
            multiple="multiple" accept="image/jpeg,image/png,image/jpg,image/*,application/pdf"
            style={{display:"none"}}
            ref={refButtonAddPicture}

            onChange={e=>{

                //console.log("e.target.files....")
                //console.log(e.target.files)
                //setArrayFile(e.target.files)
                
                handleInputFile({
                    files:e.target.files,
                    arrayFile,
                    setArrayFile
                })
                
            }}    
        />
            
        <div 
            style={{
                display:"flex",
                alignItems:"baseline",
                justifyContent:"flex-start",
                flexWrap: "wrap",
                width:"100%",
                height:"10%",
                margin:"0.5rem 0",
            }}
        >
            
            <button 
                style={{
                    backgroundColor:
                    totalImages<imageLimit
                    ?""
                    :"gray"
                }}

                onClick={e=>{
                    //console.log('inputState')
                    //console.log(inputState)
                    if(totalImages<imageLimit){
                        refButtonAddPicture.current.click()
                    }

                }} 
            >
                <MdOutlineAttachment/>
                {/*
                <FaImages/>       
                */
                }
            </button>  

            <div>
                {`( pdf, jpg, jpeg, png )  =  ${totalImages}`}
            </div>

            <MdRefresh className='md-icon'
                style={{marginLeft:"2rem"}}
                onClick={e=>{
                    reloadImage({setShowImage})
                }}
            />

        </div>
        {useYoutubeLink
        ?<div style={{margin:"0.1rem 0", 
                      height:"10%",margin:"0.5rem 0",
                      display:"flex"
                      }}>

                        
            <div style={{}}>
                <button
                    style={{backgroundColor:
                            totalImages<imageLimit
                            ?""
                            :"gray"
                        }}

                    onClick={e=>{
                        //console.log('youtubeURL')
                        //console.log(youtubeURL)
                        /*
                        https://www.youtube.com/embed/ttkwrUJhPKk&_&https://www.youtube.com/embed/9wi3qP2mCM4
                        */
                        if(totalImages<imageLimit){
                            let temp = youtubeURL.split('watch?v=')

                            if(youtubeURL.includes('shorts/')){
                                temp = youtubeURL.split('shorts/')
                            }
                            
                            if(temp.length==2){
                                const temp2=`${temp[0]}embed/${temp[1]}`
                                const temp3=inputState[keyName]
                                const tempInputState={...inputState,[keyName]:[...temp3,temp2]}
                                setInputState({...tempInputState})
                                reloadImage({setShowImage})
                            }
                        }
                    }}
                >
                    <FaYoutube/>
                
                </button>
            </div>
            <div style={{width:"80%"}}>
                <input value={youtubeURL}
                    placeholder={"youtube link/ยูทูบลิงค์"}
                    onChange={e=>{
                            setYoutubeURL(e.target.value)
                    }}
                />
            </div>
        </div>
        :null
        }


        {//style={{height:imgFrameHeight,overflow:"auto"}}
        (imgarrs)
        ?   <div style={{width:"100%",height:"100%",display:"flex",
                        justifyContent:"center"}}>
                <div className="img-frame" 
                    id="img-id"
                    ref={refFrame}
                    style={{height:'60%',width:"60%",overflow:"scroll"}}
                    onClick={e=>{
                        //const currentWidth=document.getElementById('img-id').getBoundingClientRect().width
                        //setImgFrameHeight(currentWidth*0.57)
                        //setImgFrameHeight($(e.currentTarget).width()*0.57)
                    }}    
                >       
                    <div className="img-track" 
                        style={{display:'flex',width:imgTrackWidth,height:"80%",marginBottom:"2rem"}}>
                        {renderImg(imgarrs)}
                    </div>  
                </div>
            </div>
        :null  
        }



    </div>
    )
}

Gallery20_add.defaultProps={
    useYoutubeLink:true,
    imageLimit:4
}

