import React from 'react'

import {FaBan,FaCheck,FaFolder} from 'react-icons/fa';

import {MdAddCircle,MdAddCircleOutline} from 'react-icons/md';
import Calendar from './Calendar'
import {Link} from 'react-router-dom';
import photoUtil from '../../component/galleryone_add/photoUtil'
import Gallery20_add from '../../component/galleryone_add/Gallery20_add'
import TypeSelect from './TypeSelect';
import console from '../../util/consoleVerify'


function AddPage({
    dataLabel,
    addInputState,
    setAddInputState,
    currentLink,prevLink,
    blankAddInputState,
    stringArray,
    lastRecordId,
    submitAdd,
    keyArray,
    basicData,
    setCancel,
    showPhoto,
    languageDisplay,
    dfCommon,setDfCommon,

    submitUpdateDefaultDataFormatWithFilterState

}){

    console.log("AddPage....")
    
    //console.log(dfCommon)
    //console.log('AddPAge....dataLabel')
    //console.log(dataLabel)

    //console.log('AddPage....stringArray')
    //console.log(stringArray)

    const refTable=React.createRef()
    const refAlbum=React.createRef()
    const refCalendar=React.createRef()
    const refHome=React.createRef()
    const refCheck=React.createRef()

    const {changeArrayFile}=photoUtil


    const [showImage1,setShowImage1]=React.useState(true)
    const [arrayFile1,setArrayFile1]=React.useState([])
    const [fileUrl1,setFileUrl1]=React.useState([])


    React.useEffect(()=>{
        //console.log('arrayFile1')
        //console.log(arrayFile1)
    },[arrayFile1])

    React.useEffect(()=>{
        //console.log('fileUrl1')
        //console.log(fileUrl1)
    },[fileUrl1])

    const keyName=["photoUrl1"]

    React.useEffect(()=>{
          //console.log('useEffect ArrayFile1') 
          if(keyName){
              if(keyName[0]=="photoUrl1"){
                  changeArrayFile({ 
                      arrayFile:arrayFile1,
                      fileUrl:fileUrl1,
                      setFileUrl:setFileUrl1,
                      inputState:addInputState,
                      setInputState:setAddInputState,
                      keyName:keyName[0],
                    
                      setShowImage:setShowImage1})
              }
          }
        },[arrayFile1])


    let imageLimit=20
    //let imageLimit=process.env.REACT_APP_IMAGE_LIMIT
    const shopArray=process.env.REACT_APP_VIP_SHOP.split("_@vip@_")
    
    //console.log('AddPage ....dataLabel')
    //console.log(dataLabel)
    
    let refInput=[]

    let keyObjectArray=[]
    

    if(dataLabel){
        
        //let tempArray=Object.keys(dataLabel)
        let tempArray=keyArray

        tempArray.sort((a, b) => {
            return dataLabel[a].lineOrder - dataLabel[b].lineOrder;
        });

        const tempArray2=tempArray

        let tempArray3=[]

        let tempArrayRef=[]
    
        tempArray2.map(a=>{
            if(dataLabel[a].showLine){
                tempArray3=[...tempArray3,a]
                
                tempArrayRef=[...tempArrayRef,React.createRef()]
            }
        })

        keyObjectArray=tempArray3

        refInput=tempArrayRef
    }

    //=========================

    const renderFooter=()=>{
        return(
        <div className='' 
            style={{display:"flex",
                    
                     position:"absolute",
                     bottom:"2.5rem",
                     right:"2rem",
                     zIndex:"100"
                    }}
        >
                 
                <div style={{marginLeft:"8rem"}}>
                    <button
                        ref={refCheck}
                       
                        onClick={e=>{
                          
                           
                                if(prevLink==="table"){
                                    refTable.current.click()
                                    submitAdd()
                                }
                                else if(prevLink==="album"){
                                    refAlbum.current.click()
                                    submitAdd()
                                }
                                else if(prevLink==="calendar"){
                                    refCalendar.current.click()
                                    submitAdd()
                                }
                                else {
                                    //refHome.current.click()ddd
                                    submitAdd()
                                }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                       
                <div>
                    <button
                        //ref={refCancelForm}
                        onKeyDown={e=>{
                            //if(e.key=="ArrowLeft"){
                            //    refCancelForm.current.focus()
                            //}
                        }}
                        onClick={e=>{
                            if(setCancel){
                                setCancel(false)
                            }
                            else {
                                if(currentLink==="table"){
                                    refTable.current.click()
                                    setAddInputState(blankAddInputState)

                                }
                                else if(currentLink==="album"){
                                    refAlbum.current.click()
                                    setAddInputState(blankAddInputState)

                                }
                                else if(currentLink==="calendar"){
                                    refCalendar.current.click()
                                    setAddInputState(blankAddInputState)

                                }
                                else {
                                    refHome.current.click()
                                }
                            }

                            
                        
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
    
    
        </div>
        )
    }

//======================================
const genFakeValue=(value)=>{

    let tempString=null
    //console.log(filterState.type)
    basicData.typeArray.map(g=>{
        if(g.typeId===value){
        //if(g.typeId===2){
            tempString=`${g.typeId}.${g.typeName}`
        }
    })

    return tempString
}

//======================================
const renderNumber=(b,idxB)=>{
    return(
    <React.Fragment
            key={`renderNumber-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex",
                   backgroundColor:dataLabel[b].lineBackgroundColor
                
                }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}</p>
            </div>
            <div className="xc8">
                <input
                    ref={refInput[idxB]}
                    onKeyDown={e=>{
                        if(e.key==="Enter"||e.key==="ArrowRight"){
                            if(idxB==refInput.length-1){
                                refCheck.current.focus()
                            }
                            else {
                                refInput[idxB+1].current.focus()
                            }
                        }
                    }}

                    type="number"
                    value={addInputState[b]}
                    onChange={e=>{
                        setAddInputState({
                            ...addInputState,
                            [b]:e.target.value
                        })
                    }}
                />
            </div>
        </div>
        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }
    
    </React.Fragment>
    )
}
//======================================
const renderSelect=(b,idxB)=>{
    return (
    <React.Fragment
        key={`renderSelect-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
        style={{display:"flex",
                backgroundColor:dataLabel[b].lineBackgroundColor
        }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}</p>
            </div>
            <div className="xc8">
                <select
                        ref={refInput[idxB]}

                        onKeyDown={e=>{
                            if(e.key==="Enter"||e.key==="ArrowRight"){
                                if(idxB==refInput.length-1){
                                    refCheck.current.focus()
                                }
                                else {
                                    refInput[idxB+1].current.focus()
                                }
                            }
                        }}

                        type="select"
                        value={addInputState[b]}
                        onChange={e=>{
                            setAddInputState({
                                ...addInputState,
                                [b]:e.target.value
                            })
                        }}
                >
                    <option>...</option>
                    { stringArray&&
                      stringArray[b].map((s,idxS)=>(
                        <option
                            key={idxS}
                            //selected={addInputState[b]==s?"selected":""}
                            value={s}

                        >{s}</option>
                      ))   
                    }
                </select>
            </div>
        </div>

        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }

    </React.Fragment>
    )
}
//======================================
const renderString=(b,idxB)=>{
    return(
    <React.Fragment
    key={`renderString-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex",
                    backgroundColor:dataLabel[b].lineBackgroundColor
            }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}</p>
            </div>
            <div className="xc8">                
                <input
                    ref={refInput[idxB]}

                    onKeyDown={e=>{
                        if(e.key==="Enter"||e.key==="ArrowRight"){
                            if(idxB==refInput.length-1){
                                refCheck.current.focus()
                            }
                            else {
                                refInput[idxB+1].current.focus()
                            }
                        }
                    }}

                    type="text"
                    value={addInputState[b]}
                    onChange={e=>{
                        setAddInputState({
                            ...addInputState,
                            [b]:e.target.value
                        })
                    }}
                />
                
            </div>
        </div>

        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }
    </React.Fragment>
    )
}
//======================================
const renderTypeSelect=(b,idxB)=>{
    return(
    <React.Fragment
    key={`renderString-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex",
                    backgroundColor:dataLabel[b].lineBackgroundColor
            }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}
                </p>
            </div>
            <div className="xc8"
                 style={{display:"flex"}}
            >
                
                <input 
                  style={{width:"5px",backgroundColor:"gray"}}
                  ref={refInput[idxB]}
                  onKeyDown={e=>{
                      if(e.key==="Enter"||e.key==="ArrowRight"){
                          if(idxB==refInput.length-1){
                              refCheck.current.focus()
                          }
                          else {
                              refInput[idxB+1].current.focus()
                          }
                      }
                  }}
                />
                <TypeSelect
                    value={genFakeValue(addInputState[b])}//{"1.one-1111"}//{filterState.type.value}
                    typeArray={basicData.typeArray}
                    onChange={(a)=>{

                        submitUpdateDefaultDataFormatWithFilterState(a)

                            {/*
                            setAddInputState({
                                ...addInputState,
                                [b]:a
                            })
                            */}
                       
                    }}
                />




            </div>
        </div>

        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }
    </React.Fragment>
    )
}

//=======================================
const renderTextArea=(b,idxB)=>{
    return(
    <React.Fragment
    key={`renderTextArea-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex",
                 backgroundColor:dataLabel[b].lineBackgroundColor
            }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}</p>
            </div>
            <div className="xc8">
                <textarea
                    ref={refInput[idxB]}

                    onKeyDown={e=>{

                        if(e.key==="ArrowRight"){
                            if(idxB==refInput.length-1){
                                refCheck.current.focus()
                            }
                            else {
                                refInput[idxB+1].current.focus()
                            }
                        }
                    }}
                    rows={"5"}
                    value={addInputState[b]}
                    onChange={e=>{
                        setAddInputState({
                            ...addInputState,
                            [b]:e.target.value
                        })
                    }}
                />
            </div>
        </div>
        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }
    </React.Fragment>
    )
}
//=====================================
const renderDate=(b,idxB)=>{
    return(
        <React.Fragment
        key={`renderDate-${idxB}`}
        >
            <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex",
                        backgroundColor:dataLabel[b].lineBackgroundColor
                }}    
            >    
                <div className="xc4">
                    <p  className="label table-p"
                        style={{color:dataLabel[b].lineColor}}
                        >{dataLabel[b].lb}</p>
                </div>
                <div className="xc8"
                     style={{display:"flex"}}    
                >
                
                    <input 
                        style={{width:"5px",backgroundColor:"gray"}}
                        ref={refInput[idxB]}
                        onKeyDown={e=>{
                            if(e.key==="Enter"||e.key==="ArrowRight"){
                                if(idxB==refInput.length-1){
                                    refCheck.current.focus()
                                }
                                else {
                                    refInput[idxB+1].current.focus()
                                }
                            }
                        }}
                    />
                    <Calendar
                      
                        ref={refInput[idxB]}
                        nextRef={refInput[idxB+1]}
                        style={{height:"1.6rem",fontSize:"0.8rem"}}
                        showDateOption={"datetime"}
                        useTimeSetting={true}
                        onMyClick={
                            
                            (e)=>{

                                if(b==="createdAt"||b==="updatedAt"){
                                }
                                else {
                                    setAddInputState({
                                        ...addInputState,
                                        [b]:e
                                    })
                                }
                            }
                            
                        }
                        value={addInputState[b]}
                    />
                </div>
            </div>
            {
            dataLabel[b].lineBreak
            ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                    style={{display:"flex"}}    
            />    
            :null
            }
    </React.Fragment>
    )
}
//=====================================
//======================================
const renderBoolean=(b,idxB)=>{
    return(
        <React.Fragment
            key={`renderBoolean-${idxB}`}
        >
            <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex",
                    backgroundColor:dataLabel[b].lineBackgroundColor
                   }}    
            >    
                <div className="xc4">
                    <p  className="label table-p"
                        style={{color:dataLabel[b].lineColor}}
                        >{dataLabel[b].lb}</p>
                </div>
                <div className="xc8">
                    <input
                        ref={refInput[idxB]}
                        onKeyDown={e=>{
                            if(e.key==="Enter"||e.key==="ArrowRight"){
                                if(idxB==refInput.length-1){
                                    refCheck.current.focus()
                                }
                                else {
                                    refInput[idxB+1].current.focus()
                                }
                            }
                        }}
                        type="checkbox"
                        checked={addInputState[b]}
                        onChange={e=>{
                            setAddInputState({
                                ...addInputState,
                                [b]:e.target.checked
                            })
                        }}
                    />
                </div>
            </div>

            {
            dataLabel[b].lineBreak
            ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex"}}    
            />    
            :null
            }
        </React.Fragment>
    )
}

//======================================
const renderBody=()=>{
   
    return (
        <div className="flex-center-start jc-start">
            {
                keyObjectArray.map((b,idxB)=>{
                
                    const key0_5=b.substring(0,5)

                    if(b==="id"&&dataLabel[b].showLine){
                        return renderNumber(b,idxB)
                    }
                    else if(b=="type"&&dataLabel[b].showLine){
                        return renderTypeSelect(b,idxB)
                    }

                    else if(
                            (key0_5==="itemS"||
                             b==="shopId"||
                             b==="username"||
                             b==="userPassword"||
                             b==="code"||
                             b==="userLevel"||
                             b==="name"||
                             b==="surname"||
                             b==="shopTarget"||
                             b==="accountName"||
                             b==="accountNumber"||
                             b==="fromBank"||
                             b==="checkBy"||
                             b==="ownerName"||
                             b==="ownerSurname"||
                             b==="ownerEmail"||
                             b==="recoveryPassword"||
                             b==="shopPassword"||
                             b==="shopName"
                    
                        )&&dataLabel[b].showLine){

                        if(dataLabel[b].stringInputType==="textarea"){
                            return renderTextArea(b,idxB)           
                        }
                        else if(dataLabel[b].stringInputType==="selection"){
                            return renderSelect(b,idxB)
                        }
                        else{
                            return renderString(b,idxB)
                        }
                     
                    }
                    else if(
                        (key0_5==="itemN"||
                        b==="userId"||
                        b==="transferAmount"||
                        b==="addMonth"||
                        b==="newRemainingBalance"||
                        b==="monthlyFee"||
                        b==="thisMonthDiscount"||
                        b==="remainingBalance"||
                        b==="overdueBalance"
                        
                        )&&dataLabel[b].showLine){
                        return renderNumber(b,idxB)
                    }
                    else if(
                        (key0_5==="itemD"||
                        b==="createdAt"||
                        b==="updatedAt"||
                        b==="timestamp"||
                        b==="dateIn"||
                        b==="transferDate"||
                        b==="newExpirationDate"||
                        b==="expirationDate"||
                        b==="tempExpirationDate"
                        )&&dataLabel[b].showLine)
                        {
                        return renderDate(b,idxB)
                    }
                    else if(
                        (key0_5==="itemB"||
                         b==="active"||
                         b==="isCheck"||
                         b==="hasProblem"||
                         b==="toDelete"||
                         b==="newSignUp"||
                         b==="forgetShopPassword"
                        )&&dataLabel[b].showLine)
                        
                    {
                        return renderBoolean(b,idxB)
                    }
                })
            }
        </div>
    )
}


//==========================
//==========================

    return (

    <div className="h-100 w-100 Modal-box"
         style={{
            padding:"0",
            margin:"0",
            borderRadius:"0",
            background:dfCommon.formPageBackgroundColor
            ?dfCommon.formPageBackgroundColor
            :"transparent"
         }}
    >
        <div 
             className="Modal-box bgc-trans" 
             style={{
                paddingBottom:"7rem",
                width:"100%",
                background:"transparent"
             }}>
            <div className="Modal-header bgc-trans">
                <div className='flex-center-center'>
                    <MdAddCircleOutline
                         style={{
                            fontSize:"2.5rem",
                            marginRight:"1rem"
                        }}
                    />
                    <h3>
                    {   languageDisplay
                        ?languageDisplay.add
                            ?languageDisplay.add
                            :"Add"
                        :"Add"
                    }
                    </h3>
                </div>
                {
                  renderFooter()  
                }
            </div>

            <div className="Modal-body bgc-trans" 
            >
                <div className="ModalInsideBody bgc-trans"
              
                >
                {
                  renderBody()  
                }
                </div>
            </div>


            {
            /*
            <div className='w-100'>
                <div>aaaa</div>
             
                <embed
                    src={"http://localhost/p40upload/scl.pdf"}
                    type="application/pdf" 
                    width="100%"
                    height="200px"
                >
                </embed>

            </div>


            
            <div className='w-100'>
                <input

                    type="file"
                    multiple="multiple" accept="image/jpeg,image/png,image/jpg,image/*,application/pdf"
         
                    onChange={e=>{
                        console.log(e.target.files)
                    }}
                />
            </div>

           
            <div className='w-100'>
                <div>aaaa</div>
             
                <embed
                    src={"http://localhost/p40upload/scl.pdf"}
                    type="application/pdf" 
                    width="100%"
                    height="500px"
                >
                </embed>

            </div>
            */
            }
               
            
            <div className="xc6 lc8 mc10 sc12 bgc-darkGray bd-lightGray h-100"
            >
                {
                showImage1&&showPhoto
                ?<Gallery20_add 
                    fileUrl={fileUrl1}
                    arrayFile={arrayFile1}
                    setArrayFile={setArrayFile1}
                    keyName={keyName[0]}

                    setShowImage={setShowImage1}
                    inputState={addInputState}
                    setInputState={setAddInputState}

                    imageLimit={imageLimit}
                />
                :null
            }   
            </div>    


           

            <div className="d-none">
                <Link ref={refTable} to="/cloud/table"/>
                <Link ref={refAlbum} to="/cloud/album"/>    
                <Link ref={refCalendar} to="/cloud/calendar"/>     
                <Link ref={refHome} to="/cloud/home"/>       
            </div>
        </div>
    </div>
    )

}

AddPage.defaultProps={
    showPhoto:true
}

export default AddPage