import React from 'react';
import './Calendar.css';
import console from '../../util/consoleVerify'

import {FaRegCalendarAlt} from 'react-icons/fa';
import {BsClock} from 'react-icons/bs';

import {MdClose,MdDelete} from 'react-icons/md';

//==================
function Calendar(props) {

const {onMyClick,value,style,styleIcon,
       showDateOption,timeOption,

       days,thaiMonths,useTimeSetting,

       useTimeZeroForNoValue
    }=props
//value id isostring

//console.log('value')
//console.log(value)

/*
const thaiMonths=[
    "มค","กพ","มีค","เมย","พค","มิย",
    "กค","สค","กย","ตค","พย","ธค"
]

const thaiMonths=[
    "01","02","03","04","05","06",
    "07","08","09","10","11","12"
]
*/
/*
const days=[
    "Su","Mo","Tu","Wd","Th","Fr","Sa"
]
*/

/*
const datePattern=/^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/gi

const valDate= (pttn,str)=>{
    return new RegExp(pttn).test(str)
}
*/
const temp59=[
               0,1,2,3,4,5,6,7,8,9,
               10,11,12,13,14,15,16,17,18,19,
               20,21,22,23,24,25,26,27,28,29,
               30,31,32,33,34,35,36,37,38,39,
               40,41,42,43,44,45,46,47,48,49,
               50,51,52,53,54,55,56,57,58,59
             ]
const temp24=[
                0,1,2,3,4,5,6,
                7,8,9,10,11,12,
                13,14,15,16,17,18,
                19,20,21,22,23,24
            ]
/*
const convertNewDateToString=(newDate)=>{
        const temp =newDate.toString()

        let tempMonth=temp.substring(4,7)
        if(tempMonth=="Jan"){tempMonth="01"}
        else if(tempMonth=="Feb"){tempMonth="02"}
        else if(tempMonth=="Mar"){tempMonth="03"}
        else if(tempMonth=="Apr"){tempMonth="04"}
        else if(tempMonth=="May"){tempMonth="05"}
        else if(tempMonth=="Jun"){tempMonth="06"}
        else if(tempMonth=="Jul"){tempMonth="07"}
        else if(tempMonth=="Aug"){tempMonth="08"}
        else if(tempMonth=="Sep"){tempMonth="09"}
        else if(tempMonth=="Oct"){tempMonth="10"}
        else if(tempMonth=="Nov"){tempMonth="11"}
        else if(tempMonth=="Dec"){tempMonth="12"}

        const tempDate=temp.substring(8,10)
        const tempYear=temp.substring(11,15)

        const tempNewDate=`${tempYear}-${tempMonth}-${tempDate}`

        return tempNewDate
}
*/

/*
const convertISOtoLocale=(date)=>{
    const date1=new Date(date).toISOString()
    const date2=new Date(date1).toLocaleString('fr-ca')
    const date3=new Date(date1).toLocaleString('en-GB')
    const date4=date2.substring(0,10)+'T'+date3.substring(12,date3.length)
    return date4
}
*/

/*
const convertLocaleToISO=(date)=>{
    const date5=new Date(date).toISOString()
    return date5
}

const genCurrentDate=()=>{
    const date1=new Date().toISOString()
    //2021-10-29T05:43:33.516Z
    const date4=convertISOtoLocale(date1)
    
    return new Date(date4)
}
*/


//console.log(new Date(value))
//console.log(new Date(value).toISOString())

const timeZoneObj={

    "+1200":12,
    "+1100":11,
    "+1000":10,
    "+0900":9,
  
    "+0800":8,
    "+0700":7,
    "+0600":6,
    "+0500":5,
    
    "+0400":4,
    "+0300":3,
    "+0200":2,
    "+0100":1,
    
    "+0000":0,
    
    "-0100":-1,
    "-0200":-2,
    "-0300":-3,
    "-0400":-4,
    
    "-0500":-5,
    "-0600":-6,
    "-0700":-7,
    "-0800":-8,
    
    "-0900":-9,
    "-1000":-10,
    "-1100":-11,
    "-1200":-12,
  
  }
  





const genDate=(value)=>{
    //value is date in ISOstring which is UK time
    if(value){
        let tempDate=new Date(value)

        if(timeOption){
            const {hour,minute,second}=timeOption
            tempDate=new Date(tempDate.setHours(hour,minute,second))
        }

        return tempDate//.toLocaleString('en-GB')

    }
    else{
        if(useTimeZeroForNoValue){
            const tempDate=new Date()

            const tempA1=tempDate.toString().split("GMT")
            const tempA2=tempA1[1].substring(0,5)
            const tempA3=new Date(`${tempDate.toISOString().substring(0,10)}T00:00:00Z`)
            const tempA5=new Date(tempA3.setHours(tempA3.getHours()-timeZoneObj[tempA2]))

            return tempA5
        }
        else {
            return new Date()
        }
    }
    
    /*
    if(value){
        const shortDate = value.substring(0,10)
        if(valDate(datePattern,shortDate)){
            const temp=convertISOtoLocale(value)
            return new Date(temp)
        }
        else{
            return genCurrentDate()
        }
    }
    else{
        return genCurrentDate()
    }
    */
}

let [date,setDate]=React.useState(genDate(value))

React.useEffect(()=>{
    //console.log('date.....mmmmmmmm...')
    //console.log(date)

    //console.log('hours')
    //console.log(new Date(date).getHours())

    //console.log('minutes')
    //console.log(new Date(date).getMinutes())

},[date])

const [showCalendar,setShowCalendar]=React.useState(false)

const getSelectedDateThisMonth=(i,date)=>{

    let temp=date.setDate(i)
    temp=new Date(temp)

    setDate(temp)

    onMyClick(temp.toISOString())

    //const dateUK="2021-12-31T18:50:41.750Z"
    //Sat Jan 01 2022 01:50:41 GMT+0700 (Indochina Time)
}

const genThaiFullDate=(value)=>{
    //console.log('genThaiFullDate............')
    //console.log(value)
    
    if(value){
        //const shortDate = value.substring(0,10)
        //if(valDate(datePattern,shortDate)){
        let tempDate=new Date(value)
        
        //console.log('timeOption')
        //console.log(timeOption)

        if(timeOption){
            const {hour,minute,second}=timeOption
            tempDate=new Date(tempDate.setHours(hour,minute,second))
        }

        const date2=tempDate.toLocaleString('en-GB')

        if(showDateOption=="date"){
            return date2.substring(0,10)
        }
        else if(showDateOption=="datetime"){
            return date2
        }
        else if(showDateOption=="time"){
            return date2.substring(12,21)
        }
        else{
            return '--'
        }
        //}
        //else{
        //}
    }

    else {
        return '--'
    }
    /*
        const thaiDays=[
            "อา","จ","อ","พ","พฤ","ศ","ส"
        ]
       
        const thaiMonth=thaiMonths[date.getMonth()]
        const thaiDay=thaiDays[date.getDay()]
        const thaiYear=date.getFullYear()+543
        const thaiDate=date.getDate()
        const temp=`${thaiDay} ${thaiDate} ${thaiMonth} ${thaiYear}`
        return temp
        */
}

const genThaiMonth=(date)=>{

    const thaiMonth=thaiMonths[date.getMonth()]
    const thaiYear=date.getFullYear()//+543

    const temp=`${thaiMonth}-${thaiYear}`
    return temp
}

const genPrevMonthDay=(date)=>{
    const lastFullDayPrevMonth=new Date(date.getFullYear(),date.getMonth(),0)
    const lastDayPrevMonth=lastFullDayPrevMonth.getDay()
    const lastDatePrevMonth=lastFullDayPrevMonth.getDate()

    //console.log('lastFullDayPrevMonth........9999.........')
    //console.log(lastFullDayPrevMonth)


    let prevMonthDay =[]
    for (let i=lastDatePrevMonth-lastDayPrevMonth;i<=lastDatePrevMonth;i++){
        prevMonthDay=[...prevMonthDay,i]
    }
    return prevMonthDay
}

const genThisMonthDay=(date)=>{
    const lastDate=new Date(date.getFullYear(),date.getMonth()+1,0).getDate()
    let thisMonthDay=[]
    for (let j=1;j<=lastDate;j++){
        thisMonthDay=[...thisMonthDay,j]
    }
    return thisMonthDay
}

const genNextMonthDay=(date)=>{
    const lastFullDayThisMonth=new Date(date.getFullYear(),date.getMonth()+1,0)
    const lastDayThisMonth=lastFullDayThisMonth.getDay()

    //console.log('lastFullDayThisMonth........88888........')
    //console.log(lastFullDayThisMonth)

    let nextMonthDay=[]
    for (let k=1;k<=6-lastDayThisMonth;k++){
        nextMonthDay=[...nextMonthDay,k]
    }
    return nextMonthDay
}

//==================================
return (
    !showCalendar
    ?<div style={{width:"100%",height:"100%",backgroundColor:"white",
                  display:"flex",justifyContent:"flex-start",alignItems:"center",
                  borderRadius:"5px 5px",
                  ...style
    }}>
        <FaRegCalendarAlt className="sm-icon"
            style={styleIcon}
            onClick={e=>setShowCalendar(true)}
        />
        <div style={{fontSize:"0.8rem"}}>
        {genThaiFullDate(value)}
        </div>
    </div>
    :<div className="container" style={{width:"100vw",height:"100vh"}}>

        <div className="calendar">
            <div className="month">
                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setFullYear(date.getFullYear()-1)
                        temp=new Date(temp)
                        setDate(temp)
                      }}
                 >
                    <i>&lt;&lt;</i>
                </div>


                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setMonth(date.getMonth()-1)
                        temp=new Date(temp)
                        setDate(temp)
                      }}
                 >
                    <i>&lt;</i>
                </div>


                <div className="date">
                    <div>{genThaiMonth(date)}</div>
                </div>
               
                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setMonth(date.getMonth()+1)
                        temp=new Date(temp)
                        setDate(temp)
                      }}
                >
                    <i>&gt;</i>
                </div>

                <div className="arrow"
                      onClick={e=>{
                        let temp=date.setFullYear(date.getFullYear()+1)
                        temp=new Date(temp)
                        setDate(temp)
                      }}
                >
                    <i>&gt;&gt;</i>
                </div>

                <MdDelete className='md-icon'
                    style={{color:"brown",marginRight:"0.5rem"}}

                    onClick={e=>{
                        //setShowCalendar(!showCalendar)
                        onMyClick(null)
                        setShowCalendar(!showCalendar)
                    }}
                />

                <MdClose className='md-icon'
                    style={{color:"brown"}}

                    onClick={e=>{
                        setShowCalendar(!showCalendar)
                    }}
                />

            </div>



            {
            
            <div className="weekdays">
                {
                    days.map((i,index)=>{
                        return(
                            <div key={index}>{i}</div>
                        )        
                    })
                }
            </div>
            
            }

            {
            
            <div className="days">
                
                {
                genPrevMonthDay(date).map((i,index)=>(
                    <div key={index} className="prev-date">
                        {i}
                    </div>
                ))
                }

                {
                genThisMonthDay(date).map((i,index)=>(
                    <div style={{cursor:"pointer"}}
                         key={index} 
                         className={i==date.getDate()?"today":null}
                         onClick={e=>{
                            getSelectedDateThisMonth(i,date)
                            setShowCalendar(false)
                        }}
                    >
                        {i}
                    </div>
                ))
                }
                
                {
                genNextMonthDay(date).map((i,index)=>(
                    <div key={index} className="next-date">
                        {i}
                    </div>
                ))
                }

            </div>   
            
            }


            {
            useTimeSetting
            ?<div className="bd-black" 
            style={{width:"100%",display:"flex",alignItem:"center",
                    justifyContent:"center",
                    background:"#999",marginTop:"0.2rem",
                    boxShadow:"0 0.5rem 0.3rem rgba(0,0,0,0.4)",
                    }}>
          

                    <div style={{width:"5%",display:"flex",alignItems:"center"}}>
                        <BsClock style={{fontSize:"1.2rem"}}/>
                    </div>

                    <div style={{width:"40%"}}>

                        <div style={{display:"flex",margin:"0.5rem 0"}}>
                            
                            
                            <div style={{paddingLeft:"0.5rem",width:"40%"}}>
                                <select
                                    onChange={e=>{
                                        
                                        let tempDate=date
                                        let a=new Date(tempDate.setHours(e.target.value))
                                        a=new Date(a.setSeconds(0))
                                        setDate(a)
                                    }}
                                >
                                    <option>..hr(ชม)..</option>
                                    {                            
                                    temp24.map((u,idxU)=>{
                                            return(
                                                <option key={idxU} value={u}
                                                    selected={new Date(date).getHours()==u?"selected":""}
                                                >{u}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                            
                            
                            <div style={{paddingLeft:"0.5rem",width:"40%"}}>
                                <select
                                    onChange={e=>{
                                        //e.target.value
                                        let tempDate=date
                                        let a=new Date(tempDate.setMinutes(e.target.value))
                                        a=new Date(a.setSeconds(0))
                                        setDate(a)
                                    }}
                                >
                                    <option>..min(นาที)..</option>
                                    {
                                        temp59.map((u,idxU)=>{
                                            return(
                                                <option key={idxU} value={u}
                                                    selected={new Date(date).getMinutes()==u?"selected":""}
                                                >{u}</option>
                                            )
                                        })
                                    }
                                </select>


                            </div>
                        </div>
                    </div>

                    
            </div>
            :null

            }


          
        </div>
       
    </div>
  );
}


Calendar.defaultProps={
    onMyClick:()=>{},
    showDateOption:"date",
    timeOption:null,
    value:null,
    //value:new Date(new Date().setHours(0,0,0)).toISOString(),
    //defalutHour:0,
    //defaultMinute:0,
    //defaultSecond:0,

    days:["Su/อา ","Mo/จ","Tu/อ","Wd/พ","Th/พฤ","Fr/ศ","Sa/ส"],
    thaiMonths:[
        "01","02","03","04","05","06",
        "07","08","09","10","11","12"
    ],
    useTimeSetting:false,
    useTimeZeroForNoValue:false
}

export default Calendar;
