import React from 'react'
import colorList from './colorList'
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight,} from 'react-icons/fa';
import {MdDelete,MdColorLens} from 'react-icons/md';
import console from '../../util/consoleVerify'

function ColorPick({
    setShow,
    settingColorFunction
}){

    const [selectColorName,setSelectColorName]=React.useState(null)
    const [selectColor,setSelectColor]=React.useState(null)



    const renderBody=()=>{
        return colorList.map((c,idxC)=>{
            return (
            <div className='flex-center-center jc-start w-100'
                 style={{margin:"0.5rem"}}
                 key={idxC}
            >
                <div   
                    style={{background:c.colorHex,width:"5rem",height:"2rem",
                           border:"2px solid white",
                           marginRight:"1rem"
                    }}
                >
                </div>
                <div style={{marginRight:"1rem",width:"2rem"}}>
                    <input
                        style={{fontSize:"1.2rm"}}
                        type="checkbox"
                        checked={selectColorName===c.colorName?true:false}
                        onChange={e=>{
                            setSelectColor(c)
                            setSelectColorName(c.colorName)
                        }}
                    />
                </div>
                <div style={{marginRight:"1rem"}}>
                    {c.colorName}
                </div>
                <div style={{marginRight:"1rem"}}>
                    {c.colorHex}
                </div>
                <div style={{marginRight:"1rem"}}>
                    {c.colorDec}
                </div>
                
            </div>
            )
        })
    }


    return (
    <div className="Modal-background">
        <div className="Modal-box4"
        >
            <div className="Modal-header">
                <div className='flex-center-center w-100'>
                    <MdColorLens style={{fontSize:"3.5rem",color:"yellow"}}/>
                    <div style={{fontSize:"2.5rem",color:"red",marginLeft:"0.6rem"}}>
                        C
                    </div>
                    <div style={{fontSize:"2.5rem",color:"green"}}>
                        o
                    </div>
                    <div style={{fontSize:"2.5rem",color:"blue"}}>
                        l
                    </div>
                    <div style={{fontSize:"2.5rem",color:"green"}}>
                        o
                    </div>
                    <div style={{fontSize:"2.5rem",color:"pink"}}>
                        r
                    </div>

                    <div style={{fontSize:"2.5rem",color:"red",marginLeft:"0.6rem"}}>
                        P
                    </div>
                    <div style={{fontSize:"2.5rem",color:"green"}}>
                        a
                    </div>
                    <div style={{fontSize:"2.5rem",color:"blue"}}>
                        l
                    </div>
                    <div style={{fontSize:"2.5rem",color:"green"}}>
                        e
                    </div>
                    <div style={{fontSize:"2.5rem",color:"yellow"}}>
                        t
                    </div>
                    <div style={{fontSize:"2.5rem",color:"orange"}}>
                        t
                    </div>
                    <div style={{fontSize:"2.5rem",color:"brown"}}>
                        e
                    </div>
                </div>
            </div>

            <div className='w-100'
                 style={{height:"80%",overflowY:"scroll",
                         padding:"1rem",overflowX:"hidden"}}
            >
                {renderBody()}
            </div>
          
            <div className="Modal-footer">
                <div>
                    <button
                        //ref={refModalConfirm1}
                        onClick={e=>{
                            if(selectColorName){
                                settingColorFunction(selectColorName)
                            }
                            setShow(false)
                            //setShowError(true)
                            //submitFunction(editData)
                        }}
                        onKeyDown={e=>{
                            if(e.key=="ArrowRight"){
                                //refModalConfirm2.current.focus()
                            }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        //ref={refModalConfirm2}
                        onKeyDown={e=>{
                            if(e.key=="ArrowLeft"){
                                //refModalConfirm1.current.focus()
                            }
                        }}
                        onClick={e=>{
                            setShow(false)
                            //if(setShow){setShow(false)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
            {/*
              showModalError
              ?renderModalError({show:showModalError,setShow:setShowModalError})
              :null
            */}
        </div>
    </div>




    )

}

export default ColorPick