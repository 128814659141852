const colorPlate = [
   

  
    {colorName:"transparent",	
    colorHex:"",
    colorDec:""
    },

    {colorName:"aliceblue",	
    colorHex:"#f0f8ff",
    colorDec:"rgb(240,248,255)"
    },

    {colorName:"antiquewhite",	
    colorHex:"#faebd7",
    colorDec:"rgb(250,235,215)"
    },

    {colorName:"aquamarine",	
    colorHex:"#7fffd4",
    colorDec:"rgb(127,255,212)"
    },

    {colorName:"azure",	
    colorHex:"#f0ffff",
    colorDec:"rgb(240,255,255)"
    },
    
    {colorName:"beige",	
    colorHex:"#f5f5dc",
    colorDec:"rgb(245,245,220)"
    },

    {colorName:"bisque",	
    colorHex:"#ffe4c4",
    colorDec:"rgb(255,228,196)"
    },

    {colorName:"black",	
    colorHex:"#000000",
    colorDec:"rgb(0,0,0)"
    },
    

    {colorName:"blanchedalmond",	
    colorHex:"#ffebcd",
    colorDec:"rgb(255,235,205)"
    },

    {colorName:"blue",	
    colorHex:"#0000ff",
    colorDec:"rgb(0,0,255)"
    },
    
    {colorName:"blueviolet",	
    colorHex:"#8a2be2",
    colorDec:"rgb(138,43,226)"
    },

    {colorName:"brown",	
    colorHex:"#a52a2a",
    colorDec:"rgb(165,42,42)"
    },

    {colorName:"burlywood",	
    colorHex:"#deb887",
    colorDec:"rgb(222,184,135)"
    },

    {colorName:"cadetblue",	
    colorHex:"#5f9ea0",
    colorDec:"rgb(95,158,160)"
    },

    {colorName:"chartreuse",	
    colorHex:"#7fff00",
    colorDec:"rgb(127,255,0)"
    },
    
    {colorName:"chocolate",	
    colorHex:"#d2691e",
    colorDec:"rgb(210,105,30)"
    },

    {colorName:"coral",	
    colorHex:"#ff7f50",
    colorDec:"rgb(255,127,80)"
    },

    {colorName:"cornflowerblue",	
    colorHex:"#6495ed",
    colorDec:"rgb(100,149,237)"
    },

    {colorName:"cornsilk",	
    colorHex:"#fff8dc",
    colorDec:"rgb(255,248,220)"
    },

    {colorName:"crimson",	
    colorHex:"#dc143c",
    colorDec:"rgb(220,20,60)"
    },
    
    {colorName:"cyan",	
    colorHex:"#00ffff",
    colorDec:"rgb(0,255,255)"
    },

    {colorName:"darkblue",	
    colorHex:"#00008b",
    colorDec:"rgb(0,0,139)"
    },

    {colorName:"darkcyan",	
    colorHex:"#008b8b",
    colorDec:"rgb(0,139,139)"
    },

    {colorName:"darkgoldenrod",	
    colorHex:"#b8860b",
    colorDec:"rgb(184,134,11)"
    },

    {colorName:"darkgray",	
    colorHex:"#a9a9a9",
    colorDec:"rgb(169,169,169)"
    },
    
    {colorName:"darkgreen",	
    colorHex:"#006400",
    colorDec:"rgb(0,100,0)"
    },

    {colorName:"darkkhaki",	
    colorHex:"#bdb76b",
    colorDec:"rgb(189,183,107)"
    },

    {colorName:"darkmagenta",	
    colorHex:"#8b008b",
    colorDec:"rgb(139,0,139)"
    },

    {colorName:"darkolivegreen",	
    colorHex:"#556b2f",
    colorDec:"rgb(85,107,47)"
    },
    
    {colorName:"darkorange",	
    colorHex:"#ff8c00",
    colorDec:"rgb(255,140,0)"
    },

    {colorName:"darkorchid",	
    colorHex:"#9932cc",
    colorDec:"rgb(153,50,204)"
    },

    {colorName:"darkred",	
    colorHex:"#8b0000",
    colorDec:"rgb(139,0,0)"
    },

    {colorName:"darksalmon",	
    colorHex:"#e9967a",
    colorDec:"rgb(233,150,122)"
    },

    {colorName:"darkseagreen",	
    colorHex:"#8fbc8f",
    colorDec:"rgb(143,188,143)"
    },
    
    {colorName:"darkslateblue",	
    colorHex:"#483d8b",
    colorDec:"rgb(72,61,139)"
    },
    
    {colorName:"darkslategray",	
    colorHex:"#2f4f4f",
    colorDec:"rgb(47,79,79)"
    },

    {colorName:"darkturquoise",	
    colorHex:"#00ced1",
    colorDec:"rgb(0,206,209)"
    },

    {colorName:"darkviolet",	
    colorHex:"#9400d3",
    colorDec:"rgb(148,0,211)"
    },
    
    {colorName:"deeppink",	
    colorHex:"#ff1493",
    colorDec:"rgb(255,20,147)"
    },

    {colorName:"deepskyblue",	
    colorHex:"#00bfff",
    colorDec:"rgb(0,191,255)"
    },

    {colorName:"dimgray",	
    colorHex:"#696969",
    colorDec:"rgb(105,105,105)"
    },

    {colorName:"dodgerblue",	
    colorHex:"#1e90ff",
    colorDec:"rgb(30,144,255)"
    },
    
    {colorName:"firebrick",	
    colorHex:"#b22222",
    colorDec:"rgb(178,34,34)"
    },
    
    {colorName:"floralwhite",	
    colorHex:"#fffaf0",
    colorDec:"rgb(255,250,240)"
    },

    {colorName:"forestgreen",	
    colorHex:"#228b22",
    colorDec:"rgb(34,139,34)"
    },

    {colorName:"gainsboro",	
    colorHex:"#dcdcdc",
    colorDec:"rgb(220,220,220)"
    },
    
    {colorName:"ghostwhite",	
    colorHex:"#f8f8ff",
    colorDec:"rgb(248,248,255)"
    },

    {colorName:"gold",	
    colorHex:"#ffd700",
    colorDec:"rgb(255,215,0)"
    },

    {colorName:"goldenrod",	
    colorHex:"#daa520",
    colorDec:"rgb(218,165,32)"
    },

    {colorName:"gray",	
    colorHex:"#808080",
    colorDec:"rgb(128,128,128)"
    },

    {colorName:"green",	
    colorHex:"#008000",
    colorDec:"rgb(0,128,0)"
    },
    
    {colorName:"greenyellow",	
    colorHex:"#adff2f",
    colorDec:"rgb(173,255,47)"
    },

    {colorName:"honeydew",	
    colorHex:"#f0fff0",
    colorDec:"rgb(240,255,240)"
    },

    {colorName:"hotpink",	
    colorHex:"#ff69b4",
    colorDec:"rgb(255,105,180)"
    },

    {colorName:"indianred",	
    colorHex:"#cd5c5c",
    colorDec:"rgb(205,92,92)"
    },
    
    {colorName:"indigo",	
    colorHex:"#4b0082",
    colorDec:"rgb(75,0,130)"
    },

    {colorName:"ivory",	
    colorHex:"#fffff0",
    colorDec:"rgb(255,255,240)"
    },

    {colorName:"khaki",	
    colorHex:"#f0e68c",
    colorDec:"rgb(240,230,140)"
    },

    {colorName:"lavender",	
    colorHex:"#e6e6fa",
    colorDec:"rgb(230,230,250)"
    },

    {colorName:"lavenderblush",	
    colorHex:"#fff0f5",
    colorDec:"rgb(255,240,245)"
    },
    
    {colorName:"lawngreen",	
    colorHex:"#7cfc00",
    colorDec:"rgb(124,252,0)"
    },

    {colorName:"lemonchiffon",	
    colorHex:"#fffacd",
    colorDec:"rgb(255,250,205)"
    },

    {colorName:"lightblue",	
    colorHex:"#add8e6",
    colorDec:"rgb(173,216,230)"
    },

    {colorName:"lightcoral",	
    colorHex:"#f08080",
    colorDec:"rgb(240,128,128)"
    },

    {colorName:"lightcyan",	
    colorHex:"#e0ffff",
    colorDec:"rgb(224,255,255)"
    },
    
    {colorName:"lightgoldenrodyellow",	
    colorHex:"#fafad2",
    colorDec:"rgb(250,250,210)"
    },

    {colorName:"lightgray",	
    colorHex:"#d3d3d3",
    colorDec:"rgb(211,211,211)"
    },

    {colorName:"lightgreen",	
    colorHex:"#90ee90",
    colorDec:"rgb(144,238,144)"
    },

    {colorName:"lightpink",	
    colorHex:"#ffb6c1",
    colorDec:"rgb(255,182,193)"
    },

    {colorName:"lightsalmon",	
    colorHex:"#ffa07a",
    colorDec:"rgb(255,160,122)"
    },

    {colorName:"lightseagreen",	
    colorHex:"#20b2aa",
    colorDec:"rgb(32,178,170)"
    },

    {colorName:"lightskyblue",	
    colorHex:"#87cefa",
    colorDec:"rgb(135,206,250)"
    },

    {colorName:"lightslategray",	
    colorHex:"#778899",
    colorDec:"rgb(119,136,153)"
    },

    {colorName:"lightsteelblue",	
    colorHex:"#b0c4de",
    colorDec:"rgb(176,196,222)"
    },

    {colorName:"lightyellow",	
    colorHex:"#ffffe0",
    colorDec:"rgb(255,255,224)"
    },

    {colorName:"lime",	
    colorHex:"#00ff00",
    colorDec:"rgb(0,255,0)"
    },

    {colorName:"limegreen",	
    colorHex:"#32cd32",
    colorDec:"rgb(50,205,50)"
    },

    {colorName:"linen",	
    colorHex:"#faf0e6",
    colorDec:"rgb(250,240,230)"
    },
    
    {colorName:"magenta",	
    colorHex:"#ff00ff",
    colorDec:"rgb(255,0,255)"
    },

    {colorName:"maroon",	
    colorHex:"#800000",
    colorDec:"rgb(128,0,0)"
    },

    {colorName:"mediumaquamarine",	
    colorHex:"#66cdaa",
    colorDec:"rgb(102,205,170)"
    },

    {colorName:"mediumblue",	
    colorHex:"#0000cd",
    colorDec:"rgb(0,0,205)"
    },

    {colorName:"mediumorchid",	
    colorHex:"#ba55d3",
    colorDec:"rgb(186,85,211)"
    },
    
    {colorName:"mediumpurple",	
    colorHex:"#9370db",
    colorDec:"rgb(147,112,219)"
    },

    {colorName:"mediumseagreen",	
    colorHex:"#3cb371",
    colorDec:"rgb(60,179,113)"
    },

    {colorName:"mediumslateblue",	
    colorHex:"#7b68ee",
    colorDec:"rgb(123,104,238)"
    },

    {colorName:"mediumspringgreen",	
    colorHex:"#00fa9a",
    colorDec:"rgb(0,250,154)"
    },

    {colorName:"mediumturquoise",	
    colorHex:"#48d1cc",
    colorDec:"rgb(72,209,204)"
    },

    {colorName:"mediumvioletred",	
    colorHex:"#c71585",
    colorDec:"rgb(199,21,133)"
    },

    {colorName:"midnightblue",	
    colorHex:"#191970",
    colorDec:"rgb(25,25,112)"
    },

    {colorName:"mintcream",	
    colorHex:"#f5fffa",
    colorDec:"rgb(245,255,250)"
    },

    {colorName:"mistyrose",	
    colorHex:"#ffe4e1",
    colorDec:"rgb(255,228,225)"
    },

    {colorName:"moccasin",	
    colorHex:"#ffe4b5",
    colorDec:"rgb(255,228,181)"
    },
    
    {colorName:"navajowhite",	
    colorHex:"#ffdead",
    colorDec:"rgb(255,222,173)"
    },

    {colorName:"navy",	
    colorHex:"#000080",
    colorDec:"rgb(0,0,128)"
    },

    {colorName:"oldlace",	
    colorHex:"#fdf5e6",
    colorDec:"rgb(253,245,230)"
    },

    {colorName:"olive",	
    colorHex:"#808000",
    colorDec:"rgb(128,128,0)"
    },

    {colorName:"olivedrab",	
    colorHex:"#6b8e23",
    colorDec:"rgb(107,142,35)"
    },
    
    {colorName:"orange",	
    colorHex:"#ffa500",
    colorDec:"rgb(255,165,0)"
    },

    {colorName:"orangered",	
    colorHex:"#ff4500",
    colorDec:"rgb(255,69,0)"
    },

    {colorName:"orchid",	
    colorHex:"#da70d6",
    colorDec:"rgb(218,112,214)"
    },

    {colorName:"palegoldenrod",	
    colorHex:"#eee8aa",
    colorDec:"rgb(238,232,170)"
    },

    {colorName:"palegreen",	
    colorHex:"#98fb98",
    colorDec:"rgb(152,251,152)"
    },
    
    {colorName:"paleturquoise",	
    colorHex:"#afeeee",
    colorDec:"rgb(175,238,238)"
    },

    {colorName:"palevioletred",	
    colorHex:"#db7093",
    colorDec:"rgb(219,112,147)"
    },

    {colorName:"papayawhip",	
    colorHex:"#ffefd5",
    colorDec:"rgb(255,239,213)"
    },

    {colorName:"peachpuff",	
    colorHex:"#ffdab9",
    colorDec:"rgb(255,218,185)"
    },

    {colorName:"peru",	
    colorHex:"#cd853f",
    colorDec:"rgb(205,133,63)"
    },
    
    {colorName:"pink",	
    colorHex:"#ffc0cb",
    colorDec:"rgb(255,192,203)"
    },

    {colorName:"plum",	
    colorHex:"#dda0dd",
    colorDec:"rgb(221,160,221)"
    },

    {colorName:"powderblue",	
    colorHex:"#b0e0e6",
    colorDec:"rgb(176,224,230)"
    },

    {colorName:"purple",	
    colorHex:"#800080",
    colorDec:"rgb(128,0,128)"
    },

    {colorName:"red",	
    colorHex:"#ff0000",
    colorDec:"rgb(255,0,0)"
    },
    
    {colorName:"rosybrown",	
    colorHex:"#bc8f8f",
    colorDec:"rgb(188,143,143)"
    },

    {colorName:"royalblue",	
    colorHex:"#4169e1",
    colorDec:"rgb(65,105,225)"
    },

    {colorName:"saddlebrown",	
    colorHex:"#8b4513",
    colorDec:"rgb(139,69,19)"
    },

    {colorName:"salmon",	
    colorHex:"#fa8072",
    colorDec:"rgb(250,128,114)"
    },

    {colorName:"sandybrown",	
    colorHex:"#f4a460",
    colorDec:"rgb(244,164,96)"
    },

    {colorName:"seagreen",	
    colorHex:"#2e8b57",
    colorDec:"rgb(46,139,87)"
    },

    {colorName:"seashell",	
    colorHex:"#fff5ee",
    colorDec:"rgb(255,245,238)"
    },

    {colorName:"sienna",	
    colorHex:"#a0522d",
    colorDec:"rgb(160,82,45)"
    },

    {colorName:"silver",	
    colorHex:"#c0c0c0",
    colorDec:"rgb(192,192,192)"
    },

    {colorName:"skyblue",	
    colorHex:"#87ceeb",
    colorDec:"rgb(135,206,235)"
    },
    
    {colorName:"slateblue",	
    colorHex:"#6a5acd",
    colorDec:"rgb(106,90,205)"
    },

    {colorName:"slategray",	
    colorHex:"#708090",
    colorDec:"rgb(112,128,144)"
    },

    {colorName:"snow",	
    colorHex:"#fffafa",
    colorDec:"rgb(255,250,250)"
    },

    {colorName:"springgreen",	
    colorHex:"#00ff7f",
    colorDec:"rgb(0,255,127)"
    },
    
    {colorName:"steelblue",	
    colorHex:"#4682b4",
    colorDec:"rgb(70,130,180)"
    },

    {colorName:"tan",	
    colorHex:"#d2b48c",
    colorDec:"rgb(210,180,140)"
    },

    {colorName:"teal",	
    colorHex:"#008080",
    colorDec:"rgb(0,128,128)"
    },

    {colorName:"thistle",	
    colorHex:"#d8bfd8",
    colorDec:"rgb(216,191,216)"
    },

    {colorName:"tomato",	
    colorHex:"#ff6347",
    colorDec:"rgb(255,99,71)"
    },
    
    {colorName:"turquoise",	
    colorHex:"#40e0d0",
    colorDec:"rgb(64,224,208)"
    },

    {colorName:"violet",	
    colorHex:"#ee82ee",
    colorDec:"rgb(238,130,238)"
    },

    {colorName:"wheat",	
    colorHex:"#f5deb3",
    colorDec:"rgb(245,222,179)"
    },

    {colorName:"white",	
    colorHex:"#ffffff",
    colorDec:"rgb(255,255,255)"
    },


    {colorName:"whitesmoke",	
    colorHex:"#f5f5f5",
    colorDec:"rgb(245,245,245)"
    },
    
    {colorName:"yellow",	
    colorHex:"#ffff00",
    colorDec:"rgb(255,255,0)"
    },

    {colorName:"yellowgreen",	
    colorHex:"#9acd32",
    colorDec:"rgb(154,205,50)"
    }
]

export default colorPlate