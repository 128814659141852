import React from 'react';
import {MdErrorOutline,MdRefresh} from 'react-icons/md'
import {FaBan} from 'react-icons/fa'
import console from '../../util/consoleVerify'

const ModalLineSignUp=({
    signUpImage,
    languageDisplay,
    setShow,
    getSignUpImage
})=>{


return signUpImage
    ?<div className="Modal-background">
        <div className="Modal-box2">
            <div className="Modal-header">
                <div
                     style={{fontSize:"2rem"}}
                >
                    {
                      languageDisplay
                      ?languageDisplay.activateNewAccount
                        ?languageDisplay.activateNewAccount
                        :"ACCTIVATE NEW ACCOUNT"
                      :"ACCTIVATE NEW ACCOUNT"
                    }
                </div>
            </div>
            <div className="Modal-header">
                <h5 style={{color:"black"}}>
                    {
                      languageDisplay
                      ?languageDisplay.addContactToActivateSignUp
                        ?languageDisplay.addContactToActivateSignUp
                        :"PLEASE ADD FRIEND, SUBMIT NEW ACCOUNT NAME TO ACCTIVATE THE ACCOUNT"
                      :"PLEASE ADD FRIEND, SUBMIT NEW ACCOUNT NAME TO ACCTIVATE THE ACCOUNT"
                    }
                </h5>
            </div>

            <div className="Modal-body">
                <div className ="flex-center-center QRcode"
                  
                >

                    <img 
                        src={signUpImage} 
                        style={{
                                width:'100%',
                                objectFit:"cover"
                            }}
                    />
                
                </div>
            </div>

            <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                            setShow()
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>

        </div>
        

    </div>
    :<div className='Modal-background'
            style={{width:"100vw",height:"100vh"}}
    >
    
            <div className='flex-center-center'
                style={{height:"100%",width:"100%"}}
            >

                    <button style={{
                                width:"10rem",
                                height:"10rem",
                                padding:"0",
                                backgroundColor:"gray"
                            }}
                        onClick={e=>{
                            getSignUpImage()
                        }}
                    >
                        <MdRefresh
                            style={{fontSize:"4rem"}}
                        />
                        <div>
                            {
                                languageDisplay
                                ?languageDisplay.refresh 
                                    ?languageDisplay.refresh
                                    :"Refresh"
                                :"Refresh"
                            }
                        </div>
                    </button>
            </div>
    </div>
}



export default ModalLineSignUp
               