import React from 'react';
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import Calendar from './Calendar'

import console from '../../util/consoleVerify'


function ModalUpdateShopFromShopPayment({
    setShow,
    submitFunction,
    editData,
    languageDisplay

    }){

    const refModalConfirm1 = React.createRef()
    const refModalCancel = React.createRef()

    const refExpirationDate = React.createRef()    
    const refTempExpirationDate = React.createRef()
    const refThisMonthDiscount = React.createRef()
    const refRemainingBalance = React.createRef()


    const [updateShopState,setUpdateShopState]=React.useState({
        shopId:editData.shopId,
        expirationDate:editData.newExpirationDate,
        tempExpirationDate:editData.newExpirationDate,
        thisMonthDiscount:editData.thisMonthDiscount,
        remainingBalance:editData.newRemainingBalance
    })


    const renderBody=()=>{


        const tempArray=[
            "expirationDate",
            "tempExpirationDate",
            "thisMonthDiscount",
            "remainingBalance"
        ]
    
        const refArray=[
            refExpirationDate,
            refTempExpirationDate,
            refThisMonthDiscount,
            refRemainingBalance,
            refModalConfirm1
        ]

        const tempArray2=[
            "newExpirationDate",
            "newExpirationDate",
            "thisMonthDiscount",
            "newRemainingBalance"
        ]

        return  tempArray.map((i,idxI)=>{
                return(
                <div key={idxI}
                    className="flex-center-center jc-start w-100"
                    style={{marginBottom:"1rem"}}
                >
    
                    <div 
                        style={{
                            display:"flex",
                            justifyContent:"space-between",
                            width:"60%"
                        }}
                    >
                        <div 
                            style={{
                                width:"33%"
                            }}
                        >
                            {`${tempArray2[idxI]}:`}
                        </div>

                        <div 
                            style={{
                                width:"33%"
                            }}
                        >
                            {`${editData[tempArray2[idxI]]}`}
                        </div>

                        <div 
                            style={{
                                width:"33%",
                                color:"red"
                            }}
                        >
                            {i}
                        </div>
                    </div>
                    
                    <div className="xc4">
                        {
                        i==="thisMonthDiscount"||i==="remainingBalance"
                        ?<input
                            type="number"
                            ref={refArray[idxI]}
                            value={updateShopState[i]}

                            onKeyDown={e=>{
                                if(e.key==="Enter"){
                                    if(i==="thisMonthDiscount"){
                                        refRemainingBalance.current.focus()
                                    }
                                    else if(i==="remainingBalance"){
                                        refModalConfirm1.current.focus()
                                    }
                                }
                            }}

                            onChange={e=>{
                                setUpdateShopState({
                                    ...updateShopState,
                                    [i]:e.target.value
                                })
                            }}

                        />
                        :<Calendar 
                            
                            ref={refArray[idxI]}
                            nextRef={refArray[idxI+1]}
                            style={{height:"1.6rem",fontSize:"0.8rem"}}
                            showDateOption={"datetime"}
                            useTimeSetting={true}
                            onMyClick={
                                (e)=>{
                                    setUpdateShopState({
                                        ...updateShopState,
                                        [i]:e
                                    })
                                 
                                }
                            }
                            value={updateShopState[i]}
                        />
                        }
                    </div>

                </div>
                )
        })
        
      
    }

//===============================

//===============================
    return(

    <div className="Modal-background">
        <div className="Modal-box2"
        >
            <div className="Modal-header"
                 style={{display:"block"}}
            >
                <div className='flex-center-center'>
                    <FaDatabase style={{fontSize:"1.5rem",marginRight:"0.3rem"}}/>
                    
                    <h5>
                        {` ===> shopId =${updateShopState.shopId}`}
                    </h5>
                   
                </div>
                <div className='flex-center-center'>
                    {
                     languageDisplay
                     ?languageDisplay.shoppaymentToShop
                        ?languageDisplay.shoppaymentToShop
                        :"SHOPPAYMENT TO SHOP"
                     :"SHOPPAYMENT TO SHOP"   
                    }
                </div>
            </div>
            
            {
                renderBody()
            }
         
            <div className="Modal-footer">
                <div>
                    <button
                        ref={refModalConfirm1}
                        onClick={e=>{
                            setShow(false)
                            submitFunction(updateShopState)
                        }}
                        onKeyDown={e=>{
                            if(e.key=="ArrowRight"){
                                refModalCancel.current.focus()
                            }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        ref={refModalCancel}
                        onKeyDown={e=>{
                            if(e.key=="ArrowLeft"){
                                refModalConfirm1.current.focus()
                            }
                        }}
                        onClick={e=>{
                            if(setShow){setShow(false)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
         
        </div>
    </div>
    )
}

ModalUpdateShopFromShopPayment.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}


export default ModalUpdateShopFromShopPayment
