
import React from 'react'
import { 
    MdSettings,MdCheckBox,MdCheckBoxOutlineBlank,
    MdEdit
       
} from 'react-icons/md';

import {FaArrowAltCircleDown,FaArrowAltCircleUp,FaCircle,
    FaList,FaBars,FaAngleDown,
    FaCheck,FaBan,FaImages
} from 'react-icons/fa';
import console from '../../util/consoleVerify'

import ColorPick from './ColorPick';
    
function TaskCalendarSetting({
    dataLabel,setDataLabel,
    dateArrayOfItem,
    taskCalendarSetting,
    setShowTaskCalendarSetting,
    submitDataFormat,
    keyArray,
    keyArrayWithPhotoUrl,
    dfCommon,setDfCommon,
    languageDisplay
}){
//====================
console.log('TaskCalendarSetting')

let tempArray=[]
if(dataLabel){
    //tempArray=Object.keys(dataLabel)
    tempArray=keyArray
    tempArray.sort((a, b) => {
        return dataLabel[a].calendarOrder - dataLabel[b].calendarOrder;
    });
}

const keyObjectArray=tempArray

const [showColorPlate,setShowColorPlate]=React.useState(false)
const [showColorPlateForBackground,setShowColorPlateForBackground]=React.useState(false)
const [editFilterLine,setEditFilterLine]=React.useState(null)

const [editKey,setEditKey]=React.useState(null)
const [editKeyInputState,setEditKeyInputState]=React.useState(null)

const [showColorPlateForAll,setShowColorPlateForAll]=React.useState(false)
const [showColorPlateForBackgroundForAll,setShowColorPlateForBackgroundForAll]=React.useState(false)
const [showColorPlateForBackgroundForPage,setShowColorPlateForBackgroundForPage]=React.useState(false)

//==================                
const renderFooter=()=>{
    return(
    <div className='' 
        style={{display:"flex",
                
                position:"fixed",
                bottom:"2.5rem",
                right:"2rem",
                zIndex:"100"
                }}
    >
            
            <div style={{marginLeft:"8rem"}}>
                <button
                
                    onClick={e=>{
                        
                      //submitDataFormat()
                        submitDataFormat()
                    }}
                >
                    <FaCheck/>
                </button>
            </div>
                
            <div>
                <button
                    
                    onClick={e=>{
                        setShowTaskCalendarSetting(false)
                    }}
                >
                    <FaBan/>
                </button>
            </div>


    </div>
    )
}

//==========================
    return (
        <div 
            style={{
                width:"100%",height:"100%",
                position:"relative",
                overflowY:"auto",
                paddingBottom:"8rem"
            }}
            >
                    <div className="flex-center-top"
                        style={{marginBottom:"1rem"}}
                    >
                        
                    
                        <h3>
                            {languageDisplay
                            ?languageDisplay.calendarSetting
                                ?languageDisplay.calendarSetting
                                :"Task Calendar Setting"
                            :"Task Calendar Setting"
                            }
                        </h3>
                    </div>

                    {
                        renderFooter()
                    }     

                    <div 
                         style={{
                            display:"flex",
                            width:"100%"
                        }}
                    >
                        <div style={{
                            width:"50%",
                            }}>
                            <input
                                type="checkbox"
                                checked={dfCommon.calendarShowPhoto}
                                onChange={e=>{
                                    const temp={...dfCommon,
                                            calendarShowPhoto:!dfCommon.calendarShowPhoto
                                        }
                                    setDfCommon(temp)
                                }}
                            />
                        </div>
                        <div style={{
                            width:"50%",                           
                            }}>
                            <FaImages
                                style={{fontSize:"2rem"}}
                            />
                        </div>
                       
                    </div>   

                    {dateArrayOfItem&&
                    
                    <div    className='flex-center-center jc-start w-100'
                            style={{
                                    
                                }}
                    >   
                        {dateArrayOfItem.map((a,idxA)=>{
                            return(
                            <div key={`label-${idxA}`}
                                className='flex-center-center'
                                    style={{
                                        padding:"0 0.5rem",
                                        display:"flex",
                                        alignItems:"center",
                                        height:"100%",
                                        width:"50%",
                                    }}
                            >
                                    <div className='w-15'>
                                        <input
                                            type="checkbox"
                                            checked={
                                                dataLabel[a].calendarToCheck
                                                //dfCommon[a]
                                            }
            
                                            onChange={e=>{

                                                if(e.target.checked){
                                                    let tempDataLabel=dataLabel

                                                    dateArrayOfItem.map(b=>{
                                                        if(b===a){
                                                            const tempObj={...dataLabel[b],
                                                                            calendarToCheck:true
                                                                        }
                                                            
                                                            tempDataLabel={...tempDataLabel,
                                                                        [b]:tempObj
                                                                        }
                                                        }
                                                        else {
                                                            const tempObj={...dataLabel[b],
                                                                calendarToCheck:false
                                                            }
                                                            
                                                            tempDataLabel={...tempDataLabel,
                                                            [b]:tempObj
                                                            }
                                                        }
                                                    })

                                                    setDataLabel(tempDataLabel)
                                                }
                                            
                                                
                                            }}
            
                                        />
                                    </div>
                                    <div className='w-85'>
                                        {dataLabel[a].lb}
                                    </div>
                                
                                </div>
                            )
                        })
                    

                        }

                    </div>
                    }

                    <div className='w-100'
                        style={{visibility:"hidden"}}
                    >
                        newLine
                    </div>



                    <div className='w-100'
                        style={{display:"flex"}}
                    >
                        
                        <div className='w-70'
                            style={{display:"flex",justifyContent:"flex-start"}}
                        >

                            <MdCheckBox className='sm-icon'
                                        style={{
                                            color:"brown",
                                            fontSize:"2.5rem"
                                        }}
                                        onClick={e=>{
                                            let tempDataLabel={...dataLabel}
                                            
                                            keyObjectArray.map((g,idxG)=>{
                                                
                                                const tempObj={...tempDataLabel[g],
                                                                calendarShowLine:true
                                                            }
                                                tempDataLabel={...tempDataLabel,[g]:tempObj}
                                            })

                                            setDataLabel(tempDataLabel)
                                        }}           
                            />

                            <MdCheckBoxOutlineBlank 
                                className='sm-icon'
                                style={{
                                    color:"brown",
                                    fontSize:"2.5rem"
                                }}
                                onClick={e=>{
                                    let tempDataLabel={...dataLabel}
                                            
                                            keyObjectArray.map((g,idxG)=>{
                                                
                                                const tempObj={...tempDataLabel[g],
                                                                calendarShowLine:false
                                                            }
                                                tempDataLabel={...tempDataLabel,[g]:tempObj}
                                            })

                                            setDataLabel(tempDataLabel)
                                }}    
                            />

                            <div
                                style={{
                                    width:"2rem",
                                    height:"2rem",
                                    borderRadius:"0.25rem",
                                    backgroundColor:
                                        dfCommon.taskCalendarBackgroundColor
                                        ?dfCommon.taskCalendarBackgroundColor
                                        :"transparent",
                                    border:"1px solid white",
                                    margin:"auto 0",
                                    marginLeft:"0.8rem"
                                }}

                                onClick={e=>{
                                    setShowColorPlateForBackgroundForPage(true)
                                }}
                            >   
                            </div>

                            <div
                                style={{margin:"auto 0",marginLeft:"0.5rem"}}
                            >
                                {
                                     dfCommon.taskCalendarBackgroundColor
                                     ?dfCommon.taskCalendarBackgroundColor
                                     :"transparent"
                                }
                            </div>

                        </div>

                        <div className='w-30'
                            style={{display:"flex",
                                    justifyContent:"flex-start",
                                    alignItems:"center"
                                    }}
                        >

                                <div
                                    style={{
                                        width:"2rem",
                                        height:"1.2rem",
                                        borderRadius:"0.25rem",
                                        backgroundColor:"transparent",
                                        border:"1px solid white"
                                    }}

                                    onClick={e=>{
                                        setShowColorPlateForAll(true)
                                    }}
                                >   
                                </div>

                                <div
                                    style={{
                                        width:"2rem",
                                        height:"1.2rem",
                                        borderRadius:"0.25rem",
                                        backgroundColor:"transparent",
                                        border:"1px solid white",
                                        marginLeft:"1rem"
                                    }}

                                    onClick={e=>{
                                        setShowColorPlateForBackgroundForAll(true)
                                    }}
                                >   
                                </div>

                        </div>

                    </div>


                    <div className="flex-center-center jc-start"
                    >
                    {   
                        dataLabel&&
                        keyObjectArray.map((b,idxB)=>{

                            const key0_5=b.substring(0,5)

                            let key_short=` (${b.substring(0,2)})`
                            if(key0_5==="itemS"||key0_5==="itemN"||key0_5==="itemB"||key0_5==="itemD"){
                                key_short=` (${b.substring(4,5)}${b.slice(-1)})`
                            }

                            const { lb,
                                    calendarShowLine,
                                    calendarOrder,
                                    calendarBackgroundColor,
                                    calendarColor,                                    
                                    calendarShowLb
                                }=dataLabel[b]


                            return (
                                <div  className='flex-center-center jc-start w-100'
                                        key={`ab-${idxB}`}
                                        style={{
                                                height:"2.3rem",
                                        }}
                                >
                                        <div style={{width:"10%"}}
                                        >
                                            {
                                                calendarShowLine
                                                ?<MdCheckBox className='sm-icon'
                                                    style={{color:"brown"}}
                                                    onClick={e=>{
                                                        const tempObj={...dataLabel[b],
                                                                        calendarShowLine:false
                                                                    }
                                                    
                                                        setDataLabel({...dataLabel,
                                                                        [b]:tempObj
                                                                    })
                                                    }}

                                                />
                                                :<MdCheckBoxOutlineBlank className='sm-icon'
                                                    style={{color:"brown"}}
                                                    onClick={e=>{
                                                        const tempObj={...dataLabel[b],
                                                                        calendarShowLine:true
                                                                    }
                                                    
                                                        setDataLabel({...dataLabel,
                                                                        [b]:tempObj
                                                                    })
                                                    }}

                                                />

                                            }

                                        </div>


                                            
                                        <div style={{width:"30%"}}
                                        >
                                            {editKey===b
                                            ?<input
                                                value={editKeyInputState}
                                                onChange={e=>{
                                                    setEditKeyInputState(e.target.value)
                                                }}
                                            />
                                            :<div className='w-100'
                                                style={{
                                                    display:"flex",
                                                    alignItems:"center"
                                                }}
                                                onClick={e=>{

                                                    if(editKey){
                                                        const tempObj={
                                                            [editKey]:{...dataLabel[editKey],lb:editKeyInputState}
                                                        }
                                                        setDataLabel({...dataLabel,...tempObj})
                                                    }
                                                    
                                                    setEditKey(b)
                                                    setEditKeyInputState(dataLabel[b].lb)
                                                }}
                                            >
                                                <MdEdit className='sm-icon'/>
                                                <div className='w-90'>
                                                    {lb+key_short}
                                                </div>
                                            </div>
                                            }
                                            
                                        </div>


                                        <div style={{width:"10%"}}
                                        >{
                                            calendarShowLb
                                            ?<MdCheckBox className='sm-icon'
                                                onClick={e=>{
                                                    const tempObj={...dataLabel[b],
                                                                calendarShowLb:false
                                                                }
                                                    
                                                    setDataLabel({...dataLabel,
                                                                    [b]:tempObj
                                                                })
                                                }}
                                            />
                                            :<MdCheckBoxOutlineBlank className='sm-icon'
                                                    onClick={e=>{
                                                        const tempObj={...dataLabel[b],
                                                                    calendarShowLb:true
                                                                    }
                                                        
                                                        setDataLabel({...dataLabel,
                                                                        [b]:tempObj
                                                                    })
                                                    }}
                                            />
                                        }</div>
                                            



                                        <div className='flex-center-center'
                                            style={{width:"20%"}}
                                        >
                                            {
                                                //<div>{dataLabel[b].colOrder}</div>
                                            }
                                            <FaArrowAltCircleDown className='sm-icon'
                                                style={{visibility:idxB===(keyObjectArray.length-1)?"hidden":"visible"}}
                                                onClick={e=>{
                                                    let tempObj={}

                                                    keyObjectArray.map((g,idxG)=>{
                                                        //console.log(dataLabel[g].filterLineOrder)
                                                        if(idxG===idxB){
                                                                tempObj={...tempObj,
                                                                    [g]:{...dataLabel[g],calendarOrder:(idxG+1)+1}
                                                                }
                                                        }
                                                        else if(idxG===idxB+1){
                                                                tempObj={...tempObj,
                                                                    [g]:{...dataLabel[g],calendarOrder:(idxG+1)-1}
                                                                }
                                                        }
                                                        else {
                                                                tempObj={...tempObj,
                                                                    [g]:{...dataLabel[g],calendarOrder:(idxG+1)}
                                                                }
                                                        }
                                                        
                                                    })

                                                    let tempObj2=dataLabel
                                                    Object.keys(tempObj).map(a=>{
                                                        tempObj2={...tempObj2,[a]:tempObj[a]}
                                                    })
            
                                                    setDataLabel(tempObj2)

                                                }}
                                            />
                                            <FaArrowAltCircleUp className='sm-icon'
                                                style={{visibility:idxB===0?"hidden":"visible"}}
                                                onClick={e=>{
                                                    let tempObj={}

                                                    keyObjectArray.map((g,idxG)=>{
                                                        //console.log(dataLabel[g].filterLineOrder)
                                                        if(idxG===idxB-1){
                                                                tempObj={...tempObj,
                                                                    [g]:{...dataLabel[g],calendarOrder:(idxG+1)+1}
                                                                }
                                                        }
                                                        else if(idxG===idxB){
                                                                tempObj={...tempObj,
                                                                    [g]:{...dataLabel[g],calendarOrder:(idxG+1)-1}
                                                                }
                                                        }
                                                        else {
                                                                tempObj={...tempObj,
                                                                    [g]:{...dataLabel[g],calendarOrder:(idxG+1)}
                                                                }
                                                        }
                                                    })

                                                    let tempObj2=dataLabel
                                                    Object.keys(tempObj).map(a=>{
                                                        tempObj2={...tempObj2,[a]:tempObj[a]}
                                                    })
            
                                                    setDataLabel(tempObj2)

                                                }}
                                            />
                                            
                                        </div>


                                        <div className='flex-center-center jc-start' 
                                            style={{width:"30%"}}
                                        >
                            
                                            <div
                                                style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                                    backgroundColor:dataLabel[b].calendarColor,
                                                    border:"1px solid white"
                                                }}

                                                onClick={e=>{
                                                    setShowColorPlate(true)
                                                    setEditFilterLine({[b]:dataLabel[b]})
                                                }}
                                            >   
                                            </div>

                                            <div
                                                style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                                    backgroundColor:dataLabel[b].calendarBackgroundColor,
                                                    border:"1px solid white",
                                                    marginLeft:"1rem"
                                                }}

                                                onClick={e=>{
                                                    setShowColorPlateForBackground(true)
                                                    setEditFilterLine({[b]:dataLabel[b]})
                                                }}
                                            >   
                                            </div>
                                        </div>


                                      
                                </div>

                            )    
                        })
                    }
                    </div>


                {showColorPlate&&
                        <ColorPick
                            setShow={setShowColorPlate}
                            settingColorFunction={(x)=>{
                                const tempKey=Object.keys(editFilterLine)[0]
                                const tempObj={...editFilterLine[tempKey],
                                               calendarColor:x}
                                setDataLabel({...dataLabel,[tempKey]:tempObj})
                                setEditFilterLine(null)
                            }}
                        />
                }

                {showColorPlateForBackground&&
                        <ColorPick
                            setShow={setShowColorPlateForBackground}
                            settingColorFunction={(x)=>{
                                const tempKey=Object.keys(editFilterLine)[0]
                                const tempObj={...editFilterLine[tempKey],
                                                calendarBackgroundColor:x}
                                setDataLabel({...dataLabel,[tempKey]:tempObj})
                                setEditFilterLine(null)
                            }}
                        />
                }
                    

                    {showColorPlateForAll&&
                        <ColorPick
                            setShow={setShowColorPlateForAll}
                            settingColorFunction={(x)=>{

                                const tempKey=Object.keys(dataLabel)
                                
                                let tempObj2={}

                                tempKey.map(a=>{
                                    tempObj2={...tempObj2,
                                        [a]:{...dataLabel[a],calendarColor:x}
                                    }
                                })

                                setDataLabel(tempObj2)
                                setEditFilterLine(null)
                    
                            }}
                        />
                }

                {showColorPlateForBackgroundForAll&&
                        <ColorPick
                            setShow={setShowColorPlateForBackgroundForAll}
                            settingColorFunction={(x)=>{
            
                                const tempKey=Object.keys(dataLabel)
                                
                                let tempObj2={}

                                tempKey.map(a=>{
                                    tempObj2={...tempObj2,
                                        [a]:{...dataLabel[a],calendarBackgroundColor:x}
                                    }
                                })

                                setDataLabel(tempObj2)
                                setEditFilterLine(null)
                
                            }}
                        />
                }
    
                {showColorPlateForBackgroundForPage&&
                    <ColorPick
                        setShow={setShowColorPlateForBackgroundForPage}
                        settingColorFunction={(x)=>{
                            console.log(x)
                            setDfCommon({...dfCommon,taskCalendarBackgroundColor:x})
                        }}
                    />
                }

        </div>
    
    )

}

export default TaskCalendarSetting