
import React from 'react';
import {Route,Switch,Redirect} from 'react-router-dom';


import ItemLogin from './mpage/item/ItemLogin'
import Home from './mpage/item/Home'

import ItemAlbum from './mpage/item/ItemAlbum'
import ItemSearch from './mpage/item/ItemSearch'
import ItemTable from './mpage/item/ItemTable'
import ItemAdd from './mpage/item/ItemAdd'
import ItemEdit from './mpage/item/ItemEdit'
import ItemBarcode from './mpage/item/ItemBarcode'
import ItemUpload from './mpage/item/ItemUpload'
import ItemCalendar from './mpage/item/ItemCalendar'
import ItemSetting from './mpage/item/ItemSetting'
import ItemAccount from './mpage/item/ItemAccount'
import ItemPayment from './mpage/item/ItemPayment'



import ItemUser from './mpage/item/ItemUser'
import ItemShop from './mpage/item/ItemShop'
import ItemShopPayment from './mpage/item/ItemShopPayment'
import ItemLogo from './mpage/item/ItemLogo'



import {MainContext} from './context/MainContext'

import ModalSuccess from './mpage/item/ModalSuccess'
import ModalError from './mpage/item/ModalError'

import './App2.css'

function App(){

const {tokenSt,basicDataSt,
      showError,setShowError,
      messageError,setMessageError,

      showSuccess,setShowSuccess,
      messageSuccess,setMessageSuccess,
      languageDisplay,

}=React.useContext(MainContext)


return basicDataSt.basicData
      ?<div>
            <Route exact path="/" render={()=>(<Redirect to="/cloud/home"/>)}/>
            <Route exact path="/cloud" render={()=>(<Redirect to="/cloud/home"/>)}/>

            <Route exact path="/cloud/home" component={Home}/>
            <Route exact path="/cloud/album" component={ItemAlbum}/>
   
            
            <Route exact path="/cloud/login" component={ItemLogin}/>
            <Route exact path="/cloud/search" component={ItemSearch}/>
            <Route exact path="/cloud/table" component={ItemTable}/>
            <Route exact path="/cloud/add" component={ItemAdd}/>
            <Route exact path="/cloud/edit" component={ItemEdit}/>
           
            <Route exact path="/cloud/barcode" component={ItemBarcode}/>
            <Route exact path="/cloud/calendar" component={ItemCalendar}/>
            <Route exact path="/cloud/setting" component={ItemSetting}/>
            <Route exact path="/cloud/account" component={ItemAccount}/>
            <Route exact path="/cloud/payment" component={ItemPayment}/>
            <Route exact path="/cloud/logo" component={ItemLogo}/>



            <Route exact path="/cloud/upload_mm23pos" component={ItemUpload}/>
            <Route exact path="/cloud/shop_mm23pos" component={ItemShop}/>
            <Route exact path="/cloud/shoppayment_mm23pos" component={ItemShopPayment}/>
            <Route exact path="/cloud/user_mm23pos" component={ItemUser}/>


            {showSuccess&&
             <ModalSuccess
                  showSuccess={showSuccess}
                  setShowSuccess={setShowSuccess}
                  messageSuccess={messageSuccess}
                  setMessageSuccess={setMessageSuccess}
            />  
                  
            }

            {showError&&
             <ModalError
                  showError={showError}
                  setShowError={setShowError}
                  messageError={messageError}
                  setMessageError={setMessageError}
                  languageDisplay={languageDisplay}
             />  
            }


      </div>
      :<>
            <ItemLogin/>
            {showError&&
             <ModalError
                  showError={showError}
                  setShowError={setShowError}
                  messageError={messageError}
                  setMessageError={setMessageError}
                  languageDisplay={languageDisplay}
             />  
            }
      </>

}

export default App;





/*


import React from 'react';
import {MdVisibility,MdVisibilityOff} from 'react-icons/md';


function App(){

const refUserName=React.createRef()
const refUserPassword=React.createRef()
const refCheck=React.createRef()

const vrfChar=[
      "A","B","C","D","E","F","G","H","I","J",
      "K","L","M","N","O","P","Q","R","S","T",
      "U","V","W","X","Y","Z",
      "a","b","c","d","e","f","g","h","i","j",
      "k","l","m","n","o","p","q","r","s","t",
      "u","v","w","x","y","z",
      "1","2","3","4","5","6","7","8","9","0",
      "@","_","-","+","#","&"
  ]
  
  const checkChar=(testChar)=>{
      let tempCheck=false
      
      vrfChar.map(a=>{
          if(!tempCheck){
              if(testChar===a){
                  tempCheck=true
              }
          }
      })
  
      return tempCheck
  
  }

const [inputState,setInputState]=React.useState({
      userName:"Peter",
      userPassword:"",
      starUserPassword:""
})

const [hidePassword,setHidePassword]=React.useState(false)

      return (
            <div className='w-100'>
      
                  <div className='w-100'>
                        <input
                              ref={refUserName}
                              type="text"   
                              value={inputState.userName}

                              onKeyDown={e=>{
                                    if(e.key==="Enter"){
                                          if(refUserPassword){
                                                refUserPassword.current.focus()
                                          }
                                    }
                              }}
                        
                        />
                  </div>

                  <div 
                        style={{
                              width:"100%",
                              position:"relative"
                        }}
                  >
                        <input
                              ref={refUserPassword}
                              style={{width:"100%"}}
                              type="text"
                              value={hidePassword
                                    ?inputState.userPassword
                                    :inputState.starUserPassword
                                    }


                              onKeyDown={e=>{

                                    const tempCheck=checkChar(e.key)
                                    if(tempCheck){
                                          const temp=inputState.userPassword+e.key
                                          const tempStar=inputState.starUserPassword+"*"

                                          setInputState({
                                                ...inputState,
                                                userPassword:temp,
                                                starUserPassword:tempStar
                                          })

                                    }
                                    else if(e.key==="Backspace"){
                                          const temp=inputState.userPassword.slice(0,-1)
                                          setInputState({
                                                ...inputState,
                                                userPassword:temp
                                          })
                                    }
                  
                                    else if(e.key==="Enter"){
                                          if(refCheck){
                                                refCheck.current.focus()
                                          }
                                    }
                              }}
                        />

                        {hidePassword
                        ?<MdVisibilityOff 
                              className="sm-icon" 
                              style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                              onClick={e=>{
                                    setHidePassword(!hidePassword)
                              }}
                        />
                        : <MdVisibility
                              className="sm-icon" 
                              style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                              onClick={e=>{
                              setHidePassword(!hidePassword)
                              }}
                        />
                        }
                  </div>

                  <button
                        ref={refCheck}
                  >
                        Check
                  </button>


            </div>

      )


}

export default App












*/