import React from 'react'

import axios from '../util/axiosVerify';
import console from '../util/consoleVerify';

import fakeAxiosUtil from '../mpage/item/fakeAxiosUtil'
import dataTemplate from '../mpage/item/dataTemplate'
import FormTemplate from './FormTemplate'
import uploadTableTemplate from './uploadTableTemplate'
import LangList from './LangList'
import appVerify from '../util/appVerify';

const {genMyTk,checkReqBody}=appVerify


const {getLimit,getDataLength,getLimitRow,
      findToltalPage,
      genCsv,genXlsx,genArrayCommandAndSortForFilterAxios
}=fakeAxiosUtil

const {dataFormat,basicData,
    blankShop,
    tempFilterState,
    blankAddInputState,
    blankAddInputStateForShop,
    blankAddInputStateForUser,
    blankAddInputStateForShopPayment,
    mData,
    shop,
    user,
    shopPayment,
    userArray,
    userTableTemplate,
    bankDetail
}=dataTemplate

export const MainContext=React.createContext();

const findHaveToken=(tokenName)=>{
    if(localStorage.getItem(tokenName)){
        return true
    }
    else {
        return false
    }
}


const MainContextProvider=(props)=>{
    //console.log('MainContext....')




const vrfChar=[
    "A","B","C","D","E","F","G","H","I","J",
    "K","L","M","N","O","P","Q","R","S","T",
    "U","V","W","X","Y","Z",
    "a","b","c","d","e","f","g","h","i","j",
    "k","l","m","n","o","p","q","r","s","t",
    "u","v","w","x","y","z",
    "1","2","3","4","5","6","7","8","9","0",
    "@","_","-","+","#","&"
]

const checkChar=(testChar)=>{
    let tempCheck=false
    
    vrfChar.map(a=>{
        if(!tempCheck){
            if(testChar===a){
                tempCheck=true
            }
        }
    })

    return tempCheck

}






const formTemplate =FormTemplate

const [tokenSt,setTokenSt] = React.useState({
    reloadCheckToken:true,
    haveShopToken:false,
    haveUserToken:false,
    userName:null
})

const [basicDataSt,setBasicDataSt]=React.useState({
    reloadBasicData:true,
    basicData:null,
    shop:null,
    user:null
})

const maxPerPage=basicData.maxPerPage

const genLanguageDisplay=(langList,languageSetting)=>{
    const tempKeyArray=Object.keys(langList)
    let tempObj=null
    if(languageSetting){

        tempKeyArray.map(a=>{
            tempObj={...tempObj,[a]:langList[a][languageSetting]}
        })
    }
    return tempObj
}

const [languageSetting,setLanguageSetting]=React.useState("Thai")

const [languageDisplay,setLanguageDisplay]=React.useState(
    genLanguageDisplay(LangList,languageSetting)
)

React.useEffect(()=>{
    const temp=genLanguageDisplay(LangList,languageSetting)
    setLanguageDisplay(temp)

},[languageSetting])



const [recordPerPage,setRecordPerPage]=React.useState(4)
const [toSortUp,setToSortUp]=React.useState("id")
const [toSortDown,setToSortDown]=React.useState(null)
const [toAllTrue,setToAllTrue]=React.useState(true)

const [recordPerPageForShop,setRecordPerPageForShop]=React.useState(4)
const [toSortUpForShop,setToSortUpForShop]=React.useState("shopName")
const [toSortDownForShop,setToSortDownForShop]=React.useState(null)
const [toAllTrueForShop,setToAllTrueForShop]=React.useState(true)
const [lastRecordIdForShop,setLastRecordIdForShop]=React.useState(0)

const [currentPageForShop,setCurrentPageForShop]=React.useState(1)
const [totalPageForShop,setTotalPageForShop]=React.useState(0)
const [countRecordForShop,setCountRecordForShop]=React.useState(null)


const [recordPerPageForUser,setRecordPerPageForUser]=React.useState(4)
const [toSortUpForUser,setToSortUpForUser]=React.useState("createdAt")
const [toSortDownForUser,setToSortDownForUser]=React.useState(null)
const [toAllTrueForUser,setToAllTrueForUser]=React.useState(true)
const [lastRecordIdForUser,setLastRecordIdForUser]=React.useState(0)

const [currentPageForUser,setCurrentPageForUser]=React.useState(1)
const [totalPageForUser,setTotalPageForUser]=React.useState(0)
const [countRecordForUser,setCountRecordForUser]=React.useState(null)


const [recordPerPageForShopPayment,setRecordPerPageForShopPayment]=React.useState(4)
const [toSortUpForShopPayment,setToSortUpForShopPayment]=React.useState("createAt")
const [toSortDownForShopPayment,setToSortDownForShopPayment]=React.useState(null)
const [toAllTrueForShopPayment,setToAllTrueForShopPayment]=React.useState(true)
const [lastRecordIdForShopPayment,setLastRecordIdForShopPayment]=React.useState(0)

const [currentPageForShopPayment,setCurrentPageForShopPayment]=React.useState(1)
const [totalPageForShopPayment,setTotalPageForShopPayment]=React.useState(0)
const [countRecordForShopPayment,setCountRecordForShopPayment]=React.useState(null)



const [pageData,setPageData]=React.useState([])
const [shopData,setShopData]=React.useState([])
const [shopPaymentData,setShopPaymentData]=React.useState([])
const [userData,setUserData]=React.useState([])

const [calendarData,setCalendarData]=React.useState(
    []
)

const [currentPage,setCurrentPage]=React.useState(1)
const [totalPage,setTotalPage]=React.useState(1)
const [countRecord,setCountRecord]=React.useState(null)

const [editData,setEditData]=React.useState(null)
const [stringArray,setStringArray]=React.useState(null)
const [lastRecordId,setLastRecordId]=React.useState(0)


const [dfCommon,setDfCommon]=React.useState(null)
const [dfCommonForShop,setDfCommonForShop]=React.useState(null)
const [dfCommonForUser,setDfCommonForUser]=React.useState(null)
const [dfCommonForShopPayment,setDfCommonForShopPayment]=React.useState(null)

const [showFilterSetting,setShowFilterSetting]=React.useState(false)
const [showTableSetting,setShowTableSetting]=React.useState(false)
const [showAlbumSetting,setShowAlbumSetting]=React.useState(false)
const [showAddSetting,setShowAddSetting]=React.useState(false)
const [showBarcodeSetting,setShowBarcodeSetting]=React.useState(false)
const [showTaskCalendarSetting,setShowTaskCalendarSetting]=React.useState(false)
const [showAddPageSettingForShop,setShowAddPageSettingForShop]=React.useState(false)

const [showShopPayment,setShowShopPayment]=React.useState(false)

const [showAddDataFormatModal,setShowAddDataFormatModal]=React.useState(false)
const [showDeleteDataFormatModal,setShowDeleteDataFormatModal]=React.useState(false)
const [showEditDataFormatModal,setShowEditDataFormatModal]=React.useState(false)
const [showUpdateDefaultDataFormatModal,setShowUpdateDefaultDataFormatModal]=React.useState(false)

const [showDataFormatFromTemplate,setShowDataFormatFromTemplate]=React.useState(false)
const [dataFormatFromTemplate,setDataFormatFromTemplate]=React.useState(null)
const [selectDataFormatFromTemplate,setSelectDataFormatFromTemplate]=React.useState(null)

const [widthRight,setWidthRight]=React.useState(50)

const [stringArrayState,setStringArrayState]=React.useState(null)
const [showModalStringArray,setShowModalStringArray]=React.useState(false)

const [countDataFormat,setCountDataFormat]=React.useState(0)
const [lastRecordIdDataFormat,setLastRecordIdDataFormat]=React.useState(0)
const [dataDataFormat,setDataDataFormat]=React.useState(null)
const [newDataDataFormat,setNewDataDataFormat]=React.useState(null)
const [deleteDataFormat,setDeleteDataFormat]=React.useState(null)
const [editDataFormat,setEditDataFormat]=React.useState(null)
const [defaultDataFormat,setDefaultDataFormat]=React.useState(null)
const [defaultDataFormatName,setDefaultDataFormatName]=React.useState("")

const [allDataFormat,setAllDataFormat]=React.useState(null)
const [allStringArray,setAllStringArray]=React.useState(null)

const [totalPage2,setTotalPage2]=React.useState(1)

const [userList,setUserList]=React.useState(null)

let [modalImageWidth,setModalImageWidth]=React.useState(30);

const myheader={headers: 
    {'Content-Type': 'application/json',
    'Shopauthorization':localStorage.getItem('shopauthorization'),
    'Userauthorization':localStorage.getItem('userauthorization')
    }
}

const setReloadCheckToken=(value)=>{
    setTokenSt({...tokenSt,reloadCheckToken:true})
}

const [filterState,setFilterState]=React.useState(tempFilterState)
const [filterStateForShop,setFilterStateForShop]=React.useState(null)
const [filterStateForShopPayment,setFilterStateForShopPayment]=React.useState(null)
const [filterStateForUser,setFilterStateForUser]=React.useState(null)

//==============================




//==============================
const booleanArrayOfShop=[
    "active","newSignUp","forgetShopPassword"
]
const stringArrayOfShop=[
    "shopName","shopPassword","code","ownerName","ownerSurname",
    "ownerEmail","recoveryPassword",
]
const numberArrayOfShop=[
    "monthlyFee","remainingBalance","thisMonthDiscount"
]
const dateArrayOfShop=[
    "dateIn","expirationDate","tempExpirationDate",
    "timestamp","createdAt","updatedAt"
]

const keyArrayOfShop=[
    ...booleanArrayOfShop,
    ...stringArrayOfShop,
    ...numberArrayOfShop,
    ...dateArrayOfShop
]
//================================
const booleanArrayOfShopPayment=[
    "active","isCheck","hasProblem"
]
const stringArrayOfShopPayment=[
    "shopId","accountName","accountNumber","fromBank",
    "checkBy","ownerName","ownerSurname","ownerEmail",
    "shopTarget"
]
const numberArrayOfShopPayment=[
    "id","userId","transferAmount","addMonth",
    "remainingBalance","newRemainingBalance","monthlyFee",
    "overdueBalance","thisMonthDiscount"
]
const dateArrayOfShopPayment=[
    "dateIn","transferDate","expirationDate","tempExpirationDate",
    "newExpirationDate","timestamp","createdAt","updatedAt"
]
const  photoUrlArrayOfShopPayment=[
    "photoUrl1"
]
const keyArrayOfShopPayment=[
    ...booleanArrayOfShopPayment,
    ...stringArrayOfShopPayment,
    ...numberArrayOfShopPayment,
    ...dateArrayOfShopPayment
]

const keyArrayWithPhotoUrlOfShopPayment=[
    ...booleanArrayOfShopPayment,
    ...stringArrayOfShopPayment,
    ...numberArrayOfShopPayment,
    ...dateArrayOfShopPayment,
    ...photoUrlArrayOfShopPayment
    ]

//==============================
const booleanArrayOfUser=[
    "active","toDelete"
]
const stringArrayOfUser=[
    "shopId","username","userPassword","code",
    "userLevel","name","surname","shopTarget"
]
const numberArrayOfUser=[
    "id"
]
const dateArrayOfUser=[
    "dateIn","timestamp","createdAt","updatedAt"
]

const keyArrayOfUser=[
    ...booleanArrayOfUser,
    ...stringArrayOfUser,
    ...numberArrayOfUser,
    ...dateArrayOfUser
]


//==============================
const booleanArrayOfItem=[
    "active",
    "itemBoolean1","itemBoolean2","itemBoolean3","itemBoolean4","itemBoolean5"
]
const stringArrayOfItem=[
    "itemString1","itemString2","itemString3","itemString4","itemString5",
    "itemString6","itemString7","itemString8","itemString9","itemString10",
    "itemString11","itemString12","itemString13","itemString14","itemString15",
    "itemString16","itemString17","itemString18","itemString19","itemString20",
]
const numberArrayOfItem=[
    "id","type",
    "itemNumber1","itemNumber2","itemNumber3","itemNumber4","itemNumber5",
    "itemNumber6","itemNumber7","itemNumber8","itemNumber9","itemNumber10"
]
const dateArrayOfItem=[
    "createdAt","updatedAt",
    "itemDate1","itemDate2","itemDate3","itemDate4","itemDate5"
]
const  photoUrlArrayOfItem=[
    "photoUrl1"
]
//==============================
//-----------------------------
//-----------------------------
const keyArray=[
                ...booleanArrayOfItem,
                ...stringArrayOfItem,
                ...numberArrayOfItem,
                ...dateArrayOfItem
               ]

const keyArrayWithPhotoUrl=[
                ...booleanArrayOfItem,
                ...stringArrayOfItem,
                ...numberArrayOfItem,
                ...dateArrayOfItem,
                ...photoUrlArrayOfItem
                ]

const keyArrayWithPhotoUrlForUpload=[
    "shopId","userId",
    ...booleanArrayOfItem,
    ...stringArrayOfItem,
    ...numberArrayOfItem,
    ...dateArrayOfItem,
    ...photoUrlArrayOfItem
    ]

//---------------------------------------
//---------------------------------------
//---------------------------------------


const genPrevMonthDay=(date)=>{
    const lastFullDayPrevMonth=new Date(date.getFullYear(),date.getMonth(),0)
    const lastDayPrevMonth=lastFullDayPrevMonth.getDay()
    const lastDatePrevMonth=lastFullDayPrevMonth.getDate()

    let prevMonthDay =[]
    for (let i=lastDatePrevMonth-lastDayPrevMonth;i<=lastDatePrevMonth;i++){
        prevMonthDay=[...prevMonthDay,i]
    }
    return prevMonthDay
}

const genThisMonthDay=(date)=>{
    const lastDate=new Date(date.getFullYear(),date.getMonth()+1,0).getDate()
    let thisMonthDay=[]
    for (let j=1;j<=lastDate;j++){
        thisMonthDay=[...thisMonthDay,j]
    }
    return thisMonthDay
}

const genNextMonthDay=(date)=>{
    const lastFullDayThisMonth=new Date(date.getFullYear(),date.getMonth()+1,0)
    const lastDayThisMonth=lastFullDayThisMonth.getDay()

    let nextMonthDay=[]
    for (let k=1;k<=6-lastDayThisMonth;k++){
        nextMonthDay=[...nextMonthDay,k]
    }
    return nextMonthDay
}


//---------------------------------------
//---------------------------------------
//---------------------------------------

const genFilterState=({dataFormat,stringArray,numberArray,booleanArray,dateArray})=>{
    //console.log('genFilterState.............')

    const {dfString,dfNumber,dfDate,dfBoolean,dfCommon}=dataFormat

    let tempObj={}
    stringArray.map((a,idxA)=>{
        const {toCheck,value,sortUp,sortDown}=dfString[idxA]
        tempObj={...tempObj,
            [a]:{toCheck:toCheck,value:value,
            }
        }
    })

    numberArray.map((a,idxA)=>{
        const {toCheck,min,max,value,sortUp,sortDown}=dfNumber[idxA]
        tempObj={...tempObj,
            [a]:{toCheck:toCheck,min:min,max:max,value:value
            }
        }
    })

    booleanArray.map((a,idxA)=>{
        const {toCheck,value,sortUp,sortDown}=dfBoolean[idxA]
        tempObj={...tempObj,
            [a]:{toCheck:toCheck,value:value,
            }
        }
    })

    dateArray.map((a,idxA)=>{
        const {toCheck,min,max,sortUp,sortDown}=dfDate[idxA]
        tempObj={...tempObj,
            [a]:{toCheck:toCheck,min:min,max:max,
            }
        }
    })
    //console.log(tempObj)
    return tempObj
}

//---------------------------------------
const genDataLabel=({dataFormat,stringArray,numberArray,booleanArray,dateArray,photoUrlArray})=>{
    
    const {dfString,dfNumber,dfDate,dfBoolean,dfCommon,dfPhotoUrl}=dataFormat

    let tempObj={}

    if(stringArray){
        stringArray.map((a,idxA)=>{
            tempObj={...tempObj,[a]:dfString[idxA]}
        })
    }

    if(numberArray){
        numberArray.map((a,idxA)=>{
            tempObj={...tempObj,[a]:dfNumber[idxA]}
        })
    }

    if(booleanArray){
        booleanArray.map((a,idxA)=>{
            tempObj={...tempObj,[a]:dfBoolean[idxA]}
        })    
    }
   
    if(dateArray){
        dateArray.map((a,idxA)=>{
            tempObj={...tempObj,[a]:dfDate[idxA]}
        })
    }
    
    if(photoUrlArray){
        photoUrlArray.map((a,idxA)=>{
            tempObj={...tempObj,[a]:dfPhotoUrl[idxA]}
        })
    }
    

    return tempObj
}






React.useEffect(()=>{
    console.log('useEffect reloadTokenSt.....')
    console.log(tokenSt)

    let tempSt={...tokenSt}


    if(tokenSt.reloadCheckToken){

        if(localStorage.getItem('shopauthorization')){
            tempSt={...tempSt,haveShopToken:true}
        }
        else{
            tempSt={...tempSt,haveShopToken:false}
            localStorage.removeItem('userauthorization')
            localStorage.removeItem('username')
        }

        if(localStorage.getItem('userauthorization')&&
        localStorage.getItem('username')){
            
            tempSt={...tempSt,
                    haveUserToken:true,
                    userName:localStorage.getItem('username')
                }
            }
        else{
            
            localStorage.removeItem('userauthorization')
            localStorage.removeItem('username')

            tempSt={...tempSt,
                haveUserToken:false,
                userName:null
            }
        }

        tempSt={...tempSt,
            reloadCheckToken:false
        }

        setTokenSt(tempSt)
    }

},[tokenSt])



React.useEffect(()=>{
    console.log('useEffect BasicDataSt........')
    //console.log(basicDataSt)


 
    if(basicDataSt.reloadBasicData&&
        localStorage.getItem('shopauthorization')&&
        localStorage.getItem('userauthorization')
       ){
         
         let tempRecordPerPage=recordPerPage
         let tempBlankAddInputState=blankAddInputState

         axios.post('/p40basicdata/getdataforcontext',{},genMyTk(myheader))
         .then(result=>{

             console.log('React.useEffect.......getdataforcontext...........')
             console.log(result.data)
             localStorage.setItem("expirationDate",result.data.expirationDate)

             const {basicData,dataFormat,dataFormatMaster,
                    stringArray,shop,user}=result.data
        
             let tempDefaultDataFormatName=""
             
             if(basicData){
                if(basicData.typeArray&&basicData.defaultDataFormat){
                    basicData.typeArray.map(q=>{
                        if(q.typeId===basicData.defaultDataFormat){
                            tempDefaultDataFormatName=q.typeName
                        }
                    })
                }
             }

             console.log(3)

             if(basicData){
                if(basicData.defaultDataFormat){
                   setDefaultDataFormat(basicData.defaultDataFormat)
                }
             }

             setDeleteDataFormat()
             setDefaultDataFormatName(tempDefaultDataFormatName)

             const tempBasicDataSt={...basicDataSt,
                 reloadBasicData:false,
                 basicData:basicData,
                 shop:shop,
                 user:user,
             }

             console.log(3)

             if(stringArray){
                 setStringArray(stringArray[0])
             }

             tempBlankAddInputState={...tempBlankAddInputState,
                type:basicData.defaultDataFormat
             }

             console.log(4)

             dataFormatMaster.map(a=>{
                if(a.dfName==="shopDataFormat"){
                    console.log("shopDataFormat")
                    setDataLabelShop(genDataLabel({
                        dataFormat:a,
                        stringArray:stringArrayOfShop,
                        dateArray:dateArrayOfShop,
                        numberArray:numberArrayOfShop,
                        booleanArray:booleanArrayOfShop
                    }))
                    
                    setToSortDownForShop(a.dfCommon.toSortDown)
                    setToSortUpForShop(a.dfCommon.toSortUp)
                    setToAllTrueForShop(a.dfCommon.toAllTrue)
                    setRecordPerPageForShop(a.dfCommon.recordPerPage) 
                    
                    let tempFilterStateForShop=genFilterState({
                        dataFormat:a,
                        numberArray:numberArrayOfShop,
                        stringArray:stringArrayOfShop,
                        dateArray:dateArrayOfShop,
                        booleanArray:booleanArrayOfShop    
                    })
                    
                    setFilterStateForShop(tempFilterStateForShop)
                    setDfCommonForShop(a.dfCommon)
                    
                }


                else if(a.dfName==="userDataFormat"){
                    //setDataLabelShopPayment(genDataLabel(a))
                    setDataLabelUser(genDataLabel({
                        dataFormat:a,
                        stringArray:stringArrayOfUser,
                        dateArray:dateArrayOfUser,
                        numberArray:numberArrayOfUser,
                        booleanArray:booleanArrayOfUser
                    }))
                    
                    setToSortDownForUser(a.dfCommon.toSortDown)
                    setToSortUpForUser(a.dfCommon.toSortUp)
                    setToAllTrueForUser(a.dfCommon.toAllTrue)
                    setRecordPerPageForUser(a.dfCommon.recordPerPage) 
                    
                    let tempFilterStateForUser=genFilterState({
                        dataFormat:a,
                        numberArray:numberArrayOfUser,
                        stringArray:stringArrayOfUser,
                        dateArray:dateArrayOfUser,
                        booleanArray:booleanArrayOfUser    
                    })
                    
                    setFilterStateForUser(tempFilterStateForUser)
                    setDfCommonForUser(a.dfCommon)

                }


                else if(a.dfName==="shopPaymentDataFormat"){
                    //setDataLabelUser(genDataLabel(a))
                    setDataLabelShopPayment(genDataLabel({
                        dataFormat:a,
                        stringArray:stringArrayOfShopPayment,
                        dateArray:dateArrayOfShopPayment,
                        numberArray:numberArrayOfShopPayment,
                        booleanArray:booleanArrayOfShopPayment,
                        photoUrlArray:photoUrlArrayOfShopPayment
                    }))
                    
                    setToSortDownForShopPayment(a.dfCommon.toSortDown)
                    setToSortUpForShopPayment(a.dfCommon.toSortUp)
                    setToAllTrueForShopPayment(a.dfCommon.toAllTrue)
                    setRecordPerPageForShopPayment(a.dfCommon.recordPerPage) 
                    
                    let tempFilterStateForShopPayment=genFilterState({
                        dataFormat:a,
                        numberArray:numberArrayOfShopPayment,
                        stringArray:stringArrayOfShopPayment,
                        dateArray:dateArrayOfShopPayment,
                        booleanArray:booleanArrayOfShopPayment    
                    })
                    
                    setFilterStateForShopPayment(tempFilterStateForShopPayment)
                    setDfCommonForShopPayment(a.dfCommon)

                }

             })

             console.log(5)
             //console.log('basicData.....')
             //console.log(basicData)

             //console.log("dataFormat[0].....")
             //console.log(dataFormat[0])

             const tempDataLabel=genDataLabel({
                dataFormat:dataFormat[0],
                stringArray:stringArrayOfItem,
                numberArray:numberArrayOfItem,
                booleanArray:booleanArrayOfItem,
                dateArray:dateArrayOfItem,
                photoUrlArray:photoUrlArrayOfItem
            })

            console.log(6)
            //console.log("tempDataLabel...")
            //console.log(tempDataLabel)
             
            let tempFilterState={...genFilterState({
                                                dataFormat:dataFormat[0],
                                                stringArray:stringArrayOfItem,
                                                booleanArray:booleanArrayOfItem,
                                                numberArray:numberArrayOfItem,
                                                dateArray:dateArrayOfItem
                                     }),
                                     type:
                                     {toCheck:true,value:basicData.defaultDataFormat}
                                 }

             const tempDataFormat=dataFormat[0]
             
             console.log(6.5)

             const tempToSortDown=tempDataFormat.dfCommon.toSortDown//basicData.toSortDown
             const tempToSortUp=tempDataFormat.dfCommon.toSortUp//basicData.toSortUp
             const tempToAllTrue=tempDataFormat.dfCommon.toAllTrue//basicData.toAllTrue
             tempRecordPerPage=tempDataFormat.dfCommon.recordPerPage//basicData.recordPerPage

             setDataLabel2(tempDataLabel)
             setFilterState({...tempFilterState,
                                type:{toCheck:true,
                                      value:basicData.defaultDataFormat}
                            })

             setToSortDown(tempToSortDown)
             setToSortUp(tempToSortUp)
             setToAllTrue(tempToAllTrue)
             setRecordPerPage(tempRecordPerPage) 

             setDfCommon(tempDataFormat.dfCommon)
             
             setBasicDataSt(tempBasicDataSt)

             console.log(7)
   
             //====================================
             const {arrayCommand,sort}=genArrayCommandAndSortForFilterAxios({
                 stringArray:stringArrayOfItem,
                 booleanArray:booleanArrayOfItem,
                 numberArray:numberArrayOfItem,
                 dateArray:dateArrayOfItem,
                 toSortUp:tempToSortUp,toSortDown:tempToSortDown,
                 filterState:tempFilterState
             })
             console.log(8)
             //console.log(arrayCommand)
             
             let qry
             if(tempToAllTrue){
                 qry={$and:arrayCommand}
             }
             else {
                 qry={$or:arrayCommand}
             }

    
             if(arrayCommand.length>0){
                 
                axios.post('/p40item/getlimit',
                {   sort:sort,
                    pageNumber:currentPage,
                    limitRow:tempRecordPerPage,
                    ...qry
                },
                genMyTk(myheader))

                .then(result=>{
                    
                    console.log('/getlimit....get item after dataforcontext')
                    console.log(result)
        
                    console.log(10)
                    if(result.data){
                         if(result.data.data){
                             setPageData(result.data.data)
                         }
                         const tempLastRecordId=result.data.lastRecordId
                         setLastRecordId(tempLastRecordId)
        
                         tempBlankAddInputState={...tempBlankAddInputState,
                            id:tempLastRecordId+1,
                         }
        
                         const tempDataLength=result.data.count
        
                         setCountRecord(tempDataLength)
        
                         const tempTotalPage=findToltalPage(
                                         tempDataLength,
                                         tempRecordPerPage
                                         )
                         setTotalPage(tempTotalPage)
                    }
                    
                    setAddInputState(tempBlankAddInputState)
                })

                .catch(err=>{
                    displayError("MC804",err)
                })
              
             }

         })

        
         .catch((err)=>{
             console.log('err at getdataforcontext...........')
             console.log(err.response)

             if(err.response){
                if(err.response.data){
                    if(err.response.data.message){
                        if(err.response.data.message.includes("unauthorizedShop")){

                            localStorage.removeItem('shopauthorization')
                            let tempSt={...tokenSt}
                            tempSt={...tempSt,
                                haveShopToken:false,
                            }
                            setTokenSt(tempSt)
                        }
                        else if(err.response.data.message.includes("unauthorizedUser")){
                                                    
                            localStorage.removeItem('userauthorization')
                            localStorage.removeItem('username')
                            let tempSt={...tokenSt}
                            tempSt={...tempSt,
                                haveUserToken:false,
                                userName:null
                            }
                            setTokenSt(tempSt)
                        }
                        else if(err.response.data.message.includes("expired")){
                            localStorage.removeItem('userauthorization')
                            localStorage.removeItem('username')
                            let tempSt={...tokenSt}
                            tempSt={...tempSt,
                                haveUserToken:false,
                                userName:null
                            }
                            setTokenSt(tempSt)
                        }
                    }
                }
             }

             const tempSt={...basicDataSt,
                 reloadBasicData:false
             }
             setBasicDataSt(tempSt)


         })



     }


},[basicDataSt])


   //=================================================

   const [thisPage,setThisPage]=React.useState(null)

   //=================================================
   const [showFilterSettingForShop,setShowFilterSettingForShop]=React.useState(false)
   const [showTableSettingForShop,setShowTableSettingForShop]=React.useState(false)
   const [showFormSettingForShop,setShowFormSettingForShop]=React.useState(false)

   const [showModalAddPageForShop,setShowModalAddPageForShop]=React.useState(false)
   const [showModalEditPageForShop,setShowModalEditPageForShop]=React.useState(false)
   const [showModalConfirmForShop,setShowModalConfirmForShop]=React.useState(false)

   //================================================
   const [showFilterSettingForUser,setShowFilterSettingForUser]=React.useState(false)
   const [showTableSettingForUser,setShowTableSettingForUser]=React.useState(false)
   const [showFormSettingForUser,setShowFormSettingForUser]=React.useState(false)

   const [showModalAddPageForUser,setShowModalAddPageForUser]=React.useState(false)
   const [showModalEditPageForUser,setShowModalEditPageForUser]=React.useState(false)
   const [showModalConfirmForUser,setShowModalConfirmForUser]=React.useState(false)

   const [showAddPageSettingForUser,setShowAddPageSettingForUser]=React.useState(false)


   //================================================
   const [showFilterSettingForShopPayment,setShowFilterSettingForShopPayment]=React.useState(false)
   const [showTableSettingForShopPayment,setShowTableSettingForShopPayment]=React.useState(false)
   const [showFormSettingForShopPayment,setShowFormSettingForShopPayment]=React.useState(false)

   const [showModalAddPageForShopPayment,setShowModalAddPageForShopPayment]=React.useState(false)
   const [showModalEditPageForShopPayment,setShowModalEditPageForShopPayment]=React.useState(false)
   const [showModalConfirmForShopPayment,setShowModalConfirmForShopPayment]=React.useState(false)

   const [showAddPageSettingForShopPayment,setShowAddPageSettingForShopPayment]=React.useState(false)

   //=================================================

   const [currentLink,setCurrentLink]=React.useState("table")
   const [prevLink,setPrevLink]=React.useState("table")
  

   const [addInputState,setAddInputState]=React.useState(blankAddInputState)
   const [editInputState,setEditInputState]=React.useState(null)


   React.useEffect(()=>{
        console.log('addInputState....')
        console.log(addInputState)
   },[addInputState])

   React.useEffect(()=>{
        //console.log('editInputState....')
        //console.log(editInputState)
   },[editInputState])


   const [editShop,setEditShop]=React.useState(null)
   React.useEffect(()=>{
       //console.log('editShop')
       //console.log(editShop)
   },[editShop])

   const [editShopPayment,setEditShopPayment]=React.useState(null)
   React.useEffect(()=>{
       //console.log('editShopPayment')
       //console.log(editShopPayment)
   },[editShopPayment])

   const [editUser,setEditUser]=React.useState(null)
   React.useEffect(()=>{
       //console.log('editUser')
       //console.log(editUser)
   },[editUser])


   const [addInputStateForShop,setAddInputStateForShop]=React.useState(blankAddInputStateForShop)
   //const [editInputStateForShop,setEditInputStateForShop]=React.useState(null)
   React.useEffect(()=>{
       //console.log('addInputStateForShop')
       //console.log(addInputStateForShop)
   },[addInputStateForShop])


   const [addInputStateForUser,setAddInputStateForUser]=React.useState(blankAddInputStateForUser)
   //const [editInputStateForUser,setEditInputStateForUser]=React.useState(null)
   React.useEffect(()=>{
       //console.log('addInputStateForUser')
       //console.log(addInputStateForUser)
   },[addInputStateForUser])

   const [addInputStateForShopPayment,setAddInputStateForShopPayment]=React.useState(blankAddInputStateForShopPayment)
   //const [editInputStateForShopPayment,setEditInputStateForShopPayment]=React.useState(null)
   React.useEffect(()=>{
       //console.log('addInputStateForShopPayment')
       //console.log(addInputStateForShopPayment)
   },[addInputStateForShopPayment])

   const [uploadPageData,setUploadPageData]=React.useState(null)
   const [uploadCurrentPage,setUploadCurrentPage]=React.useState(0)
   const [uploadCurrentPageData,setUploadCurrentPageData]=React.useState(null)
   const [uploadTotalPage,setUploadTotalPage]=React.useState(1)
   const [uploadEditData,setUploadEditData]=React.useState(null)
   const [uploadEditInputState,setUploadEditInputState]=React.useState(null)
   const [tempUploadTableTemplate,setTempUploadTableTemplate]=React.useState(uploadTableTemplate)

   const [showError,setShowError]=React.useState(false)
   const [messageError,setMessageError]=React.useState(null)

   const [showSuccess,setShowSuccess]=React.useState(false)
   const [messageSuccess,setMessageSuccess]=React.useState(null)



   const displayError=(line,err)=>{

        //console.log('error')
        //console.log(err.response.data.message)

        setShowError(true) 
        let tempErr="error"
        if(err.response){
            if(err.response.data){
                if(err.response.data.message){
                    tempErr=err.response.data.message
                }
            }
        }
        setMessageError(`${line}: ${languageDisplay[tempErr]}`)
    }



   React.useEffect(()=>{

       if(uploadPageData&&uploadCurrentPage>0){
           setUploadCurrentPageData(uploadPageData[uploadCurrentPage-1])
       }

   },[uploadCurrentPage])

   //const [dataLabel,setDataLabel]=React.useState(dataFormat)
   const [dataLabel2,setDataLabel2]=React.useState(null)
   const [dataLabelShop,setDataLabelShop]=React.useState(null)
   const [dataLabelShopPayment,setDataLabelShopPayment]=React.useState(null)
   const [dataLabelUser,setDataLabelUser]=React.useState(null)

   const [settingType,setSettingType]=React.useState("predefined_list")

   const [isCsv,setIsCsv]=React.useState(false)
   const [isXls,setIsXls]=React.useState(false)

   const [widthLeft,setWidthLeft]=React.useState(50)
  
   const [firstLoadCalendar,setFirstLoadCalendar]=React.useState(true)
   const [firstLoadDate,setFirstLoadDate]=React.useState(true)
   const [date,setDate]=React.useState(new Date())
   const [thisWeekDay,setThisWeekDay]=React.useState([])
   const [minMaxDate,setMinMaxDate]=React.useState({
       minDate:null,
       maxDate:null
   })

//===========================================
//===========================================

    const genThisWeekDay=(date)=>{

        let tempDayOfWeek=[]
        
        const currentDay=date.getDay()

        if(currentDay===0){
            //Sun --> *,Mon,Tue,Wed,Thu,Fri,Sat
            tempDayOfWeek=[0,1,2,3,4,5,6]
        }
        else if(currentDay===1){
            //Mon --> Sun,*,Tue,Wed,Thu,Fri,Sat
            tempDayOfWeek=[-1,0,1,2,3,4,5]
        }
        else if(currentDay===2){
            //Tue --> Sun,Mon,*,Wed,Thu,Fri,Sat
            tempDayOfWeek=[-2,-1,0,1,2,3,4]
        }
        else if(currentDay===3){
            //Wed  --> Sun,Mon,Tue,*,Thu,Fri,Sat
            tempDayOfWeek=[-3,-2,-1,0,1,2,3]
        }
        else if(currentDay===4){
            //Thu  --> Sun,Mon,Tue,Wed,*,Fri,Sat
            tempDayOfWeek=[-4,-3,-2,-1,0,1,2]
        }
        else if(currentDay===5){
            //Fri  --> Sun,Mon,Tue,Wed,Thu,*,Sat
            tempDayOfWeek=[-5,-4,-3,-2,-1,0,1]
        }
        else if(currentDay===6){
            //Sat   --> Sun,Mon,Tue,Wed,Thu,Fri,*
            tempDayOfWeek=[-6,-5,-4,-3,-2,-1,0]
        }
        
        const tempFullYear=date.getFullYear()
        const tempMonth=date.getMonth()
        const tempDate=date.getDate()
        const tempHour=date.getHours()
        const tempMinute=date.getMinutes()
        const tempSecond=date.getSeconds()

        let tempArray=[]

        tempDayOfWeek.map((a,idxA)=>{
            const tempDate2=new Date(
                tempFullYear,tempMonth,tempDate+a,tempHour,tempMinute,tempSecond
            )
            tempArray=[...tempArray,tempDate2]
            //tempArray[idxA].setDate(tempArray[idxA].getDate()+a)
        })

        const tempFullYearOfMinDate=tempArray[0].getFullYear()
        const tempMonthOfMinDate=tempArray[0].getMonth()
        const tempDateOfMinDate=tempArray[0].getDate()

        const minDate=new Date(
            tempFullYearOfMinDate,tempMonthOfMinDate,tempDateOfMinDate,
            0,0,0
        )

        const tempFullYearOfMaxDate=tempArray[6].getFullYear()
        const tempMonthOfMaxDate=tempArray[6].getMonth()
        const tempDateOfMaxDate=tempArray[6].getDate()

        const maxDate=new Date(
            tempFullYearOfMaxDate,tempMonthOfMaxDate,tempDateOfMaxDate,
            24,0,0
        )

        return {
            minDate:minDate,
            maxDate:maxDate,
            thisWeekDay:tempArray
        }
    }

    //====================================
    //====================================
    const userLevel=["admin","supervisor","staff"]
    //===================================
    //===================================

     const genFilterStateForCalendar=(minDate,maxDate)=>{
        let tempObj={}
    
        let tempSort={id:-1}
    
        dateArrayOfItem.map(a=>{
            tempObj={...tempObj,[a]:{toCheck:false}}
        })
    
        let tempItemDate=null
        dateArrayOfItem.map(a=>{
            if(dataLabel2[a].calendarToCheck){
                tempItemDate=a
                tempSort={[a]:1}
    
            }
        })
        
        if(tempItemDate){
            tempObj={...tempObj,
                [tempItemDate]:{
                     toCheck:true,
                     min:minDate,
                     max:maxDate
                    }
                }
        }
    
        const tempFilterState={...filterState,...tempObj}
        return {tempFilterState,tempItemDate,tempSort}
    }


    const submitSearch=async()=>{
        console.log('submitSearch')
        try{
            const temp=await getlimitFunc(false)
        }
        catch(err){

            displayError("1130",err)

        }
    }

    const getlimitFunc2=(filterState2,tempSort)=>{

        console.log('getlimitFunc2')
        
        console.log(tempSort)

        console.log("filterState2")
        console.log(filterState2)
    
        return new Promise(async (resolve,reject)=>{
            try {
                    const {arrayCommand,sort}=genArrayCommandAndSortForFilterAxios({
                        stringArray:stringArrayOfItem,
                        booleanArray:booleanArrayOfItem,
                        numberArray:numberArrayOfItem,
                        dateArray:dateArrayOfItem,
                        toSortUp:toSortUp,
                        toSortDown:toSortDown,
                        filterState:filterState2
                    })
                    
                    let qry
                    if(toAllTrue){
                        qry={$and:arrayCommand}
                    }
                    else {
                        qry={$or:arrayCommand}
                    }
    
                    const result=await axios.post('/p40item/getlimit',
                    {   sort:tempSort,
                        pageNumber:1,
                        limitRow:100,
                        ...qry
                    },
                    genMyTk(myheader))
                    //console.log(result.data)
                    if(result.data){
                        console.log('result.data......')
                        console.log(result.data)
                        //setPageData2(result.data.data)
                    }
                    
                    resolve(result.data.data)
            }
            catch (err){
                    reject(err)

           }
    
        })
    }


    const genCalendarDateAndData=async({currentDate,addYear,addMonth,addDate})=>{
         
        try {
            console.log("genCalendarDateAndData....cccc....")

            const tempFullYear=currentDate.getFullYear()
            const tempMonth=currentDate.getMonth()
            const tempDate=currentDate.getDate()
            const tempHour=currentDate.getHours()
            const tempMinute=currentDate.getMinutes()
            const tempSecond=currentDate.getSeconds()
    
            const temp2=new Date(
                tempFullYear+addYear,tempMonth+addMonth,tempDate+addDate,tempHour,tempMinute,tempSecond
            )
    
            const {minDate,maxDate,thisWeekDay}=genThisWeekDay(temp2)
            


            const {tempFilterState,tempItemDate,tempSort}=genFilterStateForCalendar(
                minDate,maxDate
            )
            
            const tempData=await getlimitFunc2(tempFilterState,tempSort)
            
            let tempObj2={
                0:[],1:[],2:[],3:[],4:[],5:[],6:[]
            }
    
            tempData.map(b=>{
                const aDay=new Date(b[tempItemDate]).getDay()
                
                const tempArray=[...tempObj2[aDay],b]
        
                tempObj2={...tempObj2,[aDay]:tempArray}
            })
        
            setDate(temp2)
            setMinMaxDate({minDate:minDate,maxDate:maxDate})
            setThisWeekDay(thisWeekDay)
            setCalendarData(tempObj2)
            
        }
        catch (err){

            displayError("1231",err)
          
        }
    }

    //===================================
    React.useEffect(()=>{

        console.log('React.useEffect of date')
            if(firstLoadDate){

                const tempDate=new Date()
                const {minDate,maxDate,thisWeekDay}=genThisWeekDay(date)
                
                setMinMaxDate({minDate:minDate,maxDate:maxDate})
                setThisWeekDay(thisWeekDay)
                setFirstLoadDate(false)
            }
      
    },[date])
    

    React.useEffect(()=>{
        if( localStorage.getItem('shopauthorization')&&
            localStorage.getItem('userauthorization')&&
            totalPage>1
        )
        {
           submitSearch()
        }
        //setPageData(getLimit(currentPage,recordPerPage))
    },[currentPage])

    React.useEffect(()=>{
        if(isCsv){
            genCsv(pageData,dataLabel2,setIsCsv)
        }
    },[isCsv])

    React.useEffect(()=>{
        if(isXls){
            genXlsx(pageData,dataLabel2,setIsXls)
        }
    },[isXls])

    //===================================
    //===================================



//========================================
//========================================
//========================================
//========================================
const genFD=(inputState)=>{
    //console.log('genFD')

    const tempArray=Object.keys(inputState)
    const fd=new FormData()
    
    tempArray.map((b,idxB)=>{
        //console.log(b)
        if(b==="file1"){
            for(let i=0;i<inputState[b].length;i++){
                const fileName=`${inputState[b][i].name}`
                //console.log()
                fd.append(`imageUrl`,inputState[b][i],fileName);  
            }
        }
        else if(b==="photoUrl1"){
            inputState[b].map((c,idxC)=>{
                //console.log(`${b}[${idxC}].......${inputState[b][idxC]}...`)
                fd.append(`${b}[${idxC}]`,inputState[b][idxC])
            })
        }
        else {
            fd.append(`${b}`,inputState[b])
        }
    })
    //console.log('end genFD')
    return fd

}
 
//=========================================
const addFileNameInPhotoUrl=(formInputState)=>{
    let temp1=[]
    if(formInputState.file1){
        //console.log(1)
      for (let i=0;i<formInputState.file1.length;i++){
        temp1=[...temp1,formInputState.file1[i].name]
      }
    }
    if(formInputState.photoUrl1){
      //console.log(2)  
      formInputState.photoUrl1=[...formInputState.photoUrl1,...temp1]
    }
    //console.log(3)
    return formInputState
  }
//========================================
const getlimitFunc=(isFilterInput)=>{

    return new Promise(async (resolve,reject)=>{
        try {
                //console.log('getlimitFunc.....')
                //console.log(filterState)

                const {arrayCommand,sort}=genArrayCommandAndSortForFilterAxios({
                    stringArray:stringArrayOfItem,
                    booleanArray:booleanArrayOfItem,
                    numberArray:numberArrayOfItem,
                    dateArray:dateArrayOfItem,
                    toSortUp:toSortUp,
                    toSortDown:toSortDown,
                    filterState:filterState
                })
                console.log(1)
                //console.log("arrayCommand")
                //console.log(arrayCommand)
                
                let qry
                if(toAllTrue){
                    qry={$and:arrayCommand}
                }
                else {
                    qry={$or:arrayCommand}
                }

                let result2
                if(filterState.type.value!==basicDataSt.basicData.defaultDataFormat){
                    
                    result2=await axios.post('/p40dataformat/getcustom',
                        {id:filterState.type.value},
                        myheader
                    )
        
                    const tempBasicData={...basicDataSt.basicData,
                            defaultDataFormat:filterState.type.value
                        }

                    setBasicDataSt({...basicDataSt,basicData:tempBasicData})

                    const tempDataLabel=genDataLabel({
                        dataFormat:result2.data.data[0],
                        stringArray:stringArrayOfItem,
                        numberArray:numberArrayOfItem,
                        booleanArray:booleanArrayOfItem,
                        dateArray:dateArrayOfItem,
                        photoUrlArray:photoUrlArrayOfItem
                    })


                    setDataLabel2(tempDataLabel)
                   
                    let tempTypeName=""
                    basicDataSt.basicData.typeArray.map(ta=>{
                        if(ta.typeId===filterState.type.value){
                            tempTypeName=ta.typeName
                        }
                    })
                    
                    setDefaultDataFormatName(tempTypeName)
                    setDefaultDataFormat(filterState.type.value)

                }
                
                const result=await axios.post('/p40item/getlimit',
                {   sort:sort,
                    pageNumber:isFilterInput?1:currentPage,
                    limitRow:recordPerPage,
                    ...qry
                },
                genMyTk(myheader))

                //console.log('result.data........')
                //console.log(result.data.data)

                if(result.data){
                    if(result.data.data){
                        setPageData(result.data.data)
                    }
                    let tempLastRecordId=0
                    
                    if(result.data.lastRecordId){
                        tempLastRecordId=result.data.lastRecordId
                    }
                    setLastRecordId(tempLastRecordId)
                    
                    let tempType=blankAddInputState.type

                    if(addInputState){
                        if(addInputState.type){
                            tempType=addInputState.type
                        }
                    }
                    
                    setAddInputState({...blankAddInputState,
                        id:tempLastRecordId+1,
                        type:tempType
                    
                    })
                    
                    
                    const tempDataLength=result.data.count
                    setCountRecord(tempDataLength)
                    const tempTotalPage=findToltalPage(
                                    tempDataLength,
                                    recordPerPage
                                    )
                    setTotalPage(tempTotalPage)
                }
                
                resolve("Happy.....")
        }
        catch (err){
                reject(err)
        }

    })
}
//========================================
//========================================
const getlimitFuncForShopPayment=(isFilterInput)=>{

    return new Promise(async (resolve,reject)=>{
        try {
                console.log('getlimitFuncForShopPayment.....')

                //console.log(1)
                const {arrayCommand,sort}=genArrayCommandAndSortForFilterAxios({
                    stringArray:stringArrayOfShopPayment,
                    booleanArray:booleanArrayOfShopPayment,
                    numberArray:numberArrayOfShopPayment,
                    dateArray:dateArrayOfShopPayment,
                    toSortUp:toSortUpForShopPayment,
                    toSortDown:toSortDownForShopPayment,
                    filterState:filterStateForShopPayment
                })

                //console.log(2)
                //console.log(arrayCommand)
                
                let qry
                if(toAllTrue){
                    qry={$and:arrayCommand}
                }
                else {
                    qry={$or:arrayCommand}
                }
                
                const result=await axios.post('/p40shoppayment/getlimitbyadmin',
                {   sort:sort,
                    pageNumber:isFilterInput?1:currentPageForShopPayment,
                    limitRow:recordPerPageForShopPayment,
                    ...qry
                },
                genMyTk(myheader))
                
                //console.log('result.data........')
                //console.log(result.data)

                if(result.data){
                    if(result.data.data){
                        setShopPaymentData(result.data.data)
                    }
                    const tempLastRecordId=result.data.lastRecordId
                    setLastRecordIdForShopPayment("")
                    //setAddInputState({...blankAddInputState,id:tempLastRecordId+1})
                    const tempDataLength=result.data.count
                    setCountRecordForShopPayment(tempDataLength)
                    
                    const tempTotalPage=findToltalPage(
                                    tempDataLength,
                                    recordPerPageForShopPayment
                                    )
                    setTotalPageForShopPayment(tempTotalPage)
                    
                }
                
                resolve("Happy.....")
        }
        catch (err){
                reject(err)
        }

    })
}

//========================================
//========================================

const getlimitFuncForUser=(isFilterInput)=>{

    return new Promise(async (resolve,reject)=>{
        try {
                console.log('getlimitFuncForUser.....')

                //console.log(1)
                const {arrayCommand,sort}=genArrayCommandAndSortForFilterAxios({
                    stringArray:stringArrayOfUser,
                    booleanArray:booleanArrayOfUser,
                    numberArray:numberArrayOfUser,
                    dateArray:dateArrayOfUser,
                    toSortUp:toSortUpForUser,
                    toSortDown:toSortDownForUser,
                    filterState:filterStateForUser
                })

                //console.log(2)
                //console.log(arrayCommand)
                let qry
                if(toAllTrue){
                    qry={$and:arrayCommand}
                }
                else {
                    qry={$or:arrayCommand}
                }


                
                //console.log(3)
                const result=await axios.post('/p40user/getlimitbyadmin',
                {   sort:sort,
                    pageNumber:isFilterInput?1:currentPageForUser,
                    limitRow:recordPerPageForUser,
                    ...qry
                },
                genMyTk(myheader))
                //console.log(result.data)

                console.log('result.data........')
                console.log(result.data)

                if(result.data){
                    if(result.data.data){
                        setUserData(result.data.data)
                    }
                    const tempLastRecordId=result.data.lastRecordId
                    setLastRecordIdForUser("")
                    //setAddInputState({...blankAddInputState,id:tempLastRecordId+1})
                    
                    const tempDataLength=result.data.count
                    setCountRecordForUser(tempDataLength)
                    
                    const tempTotalPage=findToltalPage(
                                    tempDataLength,
                                    recordPerPageForUser
                                    )
                    setTotalPageForUser(tempTotalPage)
                    
                }
                
                resolve("Happy.....")
        }
        catch (err){
                reject(err)
        }

    })
}

//=========================================
const getlimitFuncForShop=(isFilterInput)=>{

    return new Promise(async (resolve,reject)=>{
        try {
                //console.log('getlimitFuncForShop.....')
                //console.log(filterStateForShop)

                //console.log(1)
                const {arrayCommand,sort}=genArrayCommandAndSortForFilterAxios({
                    stringArray:stringArrayOfShop,
                    booleanArray:booleanArrayOfShop,
                    numberArray:numberArrayOfShop,
                    dateArray:dateArrayOfShop,
                    toSortUp:toSortUpForShop,
                    toSortDown:toSortDownForShop,
                    filterState:filterStateForShop
                })

                //console.log(2)
                //console.log(arrayCommand)
                let qry
                if(toAllTrue){
                    qry={$and:arrayCommand}
                }
                else {
                    qry={$or:arrayCommand}
                }


                
                //console.log(3)
                const result=await axios.post('/p40shop/getlimitbyadmin',
                {   sort:sort,
                    pageNumber:isFilterInput?1:currentPageForShop,
                    limitRow:recordPerPageForShop,
                    ...qry
                },
                genMyTk(myheader))
                

                console.log('result.data........')
                console.log(result.data)

                
                if(result.data){
                    if(result.data.data){
                        setShopData(result.data.data)
                    }
                    const tempLastRecordId=result.data.lastRecordId
                    setLastRecordIdForShop("")
                    //setAddInputState({...blankAddInputState,id:tempLastRecordId+1})
                    
                    const tempDataLength=result.data.count
                    setCountRecordForShop(tempDataLength)
                    
                    const tempTotalPage=findToltalPage(
                                    tempDataLength,
                                    recordPerPageForShop
                                    )
                    setTotalPageForShop(tempTotalPage)
                    
                }
                
                resolve("Happy.....")
        }
        catch (err){
                reject(err)
        }

    })
}
//========================================



//========================================
const submitNewDataDataFormat=async()=>{
    console.log('submitNewDataDataFormat')

    try {

        const {_id,...remaining}=stringArray
        
        let tempArray=[]

        dataDataFormat.map(a=>{
            tempArray=[...tempArray,
                {
                 typeId:a.id,
                 typeName:a.dfName   
                }
            ]
        })

        tempArray=[...tempArray,
            { typeId:newDataDataFormat.id,
              typeName:newDataDataFormat.dfName
            }
        ]

        const result=await axios.post('/p40dataformat/addcustom',
        newDataDataFormat,
        genMyTk(myheader))

        const result2=await axios.post('/p40stringarray/addcustom',
        {...remaining,id:newDataDataFormat.id},
        genMyTk(myheader))

        const result3=await axios.post('/p40basicdata/updatecustom',
            { id:basicDataSt.basicData.id,
              typeArray:tempArray
            },
        genMyTk(myheader))
        
        window.location.reload()
        //setShowAddDataFormatModal(false)
        //setDataDataFormat(null)

    }
    catch (err){

        displayError("1711",err)
      
    }

}
//========================================
const submitEditDataFormat=async()=>{
    console.log('submitEditDataFormat')

    try {
        const {id,dfName}=editDataFormat
        
        const result=await axios.post('/p40dataformat/updatecustom',
        {id,dfName},
        genMyTk(myheader))

        let tempArray=[]

        dataDataFormat.map(a=>{
            if(a.id===editDataFormat.id){
                tempArray=[...tempArray,
                    {
                        typeId:editDataFormat.id,
                        typeName:editDataFormat.dfName   
                    }
                ]
            }
            else {
                tempArray=[...tempArray,
                    {
                     typeId:a.id,
                     typeName:a.dfName   
                    }
                ]
            }
        })

        const result3=await axios.post('/p40basicdata/updatecustom',
        { id:basicDataSt.basicData.id,
          typeArray:tempArray
        },
        genMyTk(myheader))

        window.location.reload()
        //setShowEditDataFormatModal(false)
        //setDataDataFormat(null)
    }
    catch (err){

        displayError("1760",err)
     
    }

}
//========================================
const submitDeleteDataFormat=async()=>{
    console.log("submitDeleteDataFormat")

    try {
        const result=await axios.post('/p40dataformat/deletecustom',
        {id:deleteDataFormat.id},
        genMyTk(myheader))

        const result2=await axios.post('/p40stringarray/deletecustom',
        {id:deleteDataFormat.id},
        genMyTk(myheader))

        let tempArray=[]

        dataDataFormat.map(a=>{
            if(a.id===deleteDataFormat.id){
                
            }
            else {
                tempArray=[...tempArray,
                    {
                     typeId:a.id,
                     typeName:a.dfName   
                    }
                ]
            }
        })

        const result3=await axios.post('/p40basicdata/updatecustom',
        { id:basicDataSt.basicData.id,
          typeArray:tempArray
        },
        genMyTk(myheader))
        
        window.location.reload()
        //setDataDataFormat(null)
        //setShowDeleteDataFormatModal(false)
    }
    catch (err){

        displayError("1806",err)

    }
}
//==========================================
//==========================================
//==========================================
//========================================
const submitUpdateStringArray=async()=>{
    console.log('submitUpdateStringArray')

    try {

        const result=await axios.post('/p40stringarray/updatecustom',
        {    
            id:stringArrayState.id,
            [`${stringArrayState.key}`]:stringArrayState.value,
           
        },
        genMyTk(myheader))
        //setStringArrayState

        const temp={...stringArray,[stringArrayState.key]:stringArrayState.value}
        setStringArray(temp)

        setShowModalStringArray(false)
    }
    catch (err){

        displayError("1835",err)
   
    }
}
//========================================
const submitAddForShop=async()=>{
    console.log('submitAddForShop...')

    try {
  

        const result=await axios.post('/p40shop/addshopbyadmin',
        addInputStateForShop,
        myheader
        )
        

        setAddInputStateForShop(blankAddInputStateForShop)
        //console.log(result.data)
        setShowModalAddPageForShop(false)
        await getlimitFuncForShop(true)
    }
    catch (err){

        displayError("1859",err)
       
    }
}


const submitUpdateShopFromShopPayment=async(inputState)=>{
    console.log('submitUpdateShopFromShopPayment...')
    try {
        console.log(inputState)
        
        const {_id,__v,shopId,...remaining}=inputState

        const result=await axios.post('/p40shop/updateshopbyadmin',
        {...remaining,shopName:shopId},
        myheader
        )
        
        
    }
    catch (err){
        displayError("1918",err)
    }
}





const submitEditForShop=async()=>{
    console.log('submitUpdateForShop...')
    try {
        const {_id,__v,...remaining}=editShop

        const result=await axios.post('/p40shop/updateshopbyadmin',
        {...remaining},
        myheader
        )

        setEditShop(null)
        setShowModalEditPageForShop(false)
        await getlimitFuncForShop(true)
        //console.log(result.data)
    }
    catch (err){
        displayError("1881",err)
    }
}


const submitDeleteForShop=async()=>{
    console.log('submitDeleteForShop')
    try {
        const {shopName}=editShop
        const result=await axios.post('/p40shop/deleteshopbyadmin',
        {shopName},
        myheader
        )
        setEditShop(null)
        setShowModalConfirmForShop(false)
        await getlimitFuncForShop(true)
    }
    catch (err){

        displayError("1899",err)
  
    }
}
//=======================================
const submitAddForUser=async()=>{
    console.log('submitAddForUser...')
    try {

        const result=await axios.post('/p40user/adduserbyadmin',
        addInputStateForUser,
        myheader
        )
        //console.log(result.data)
        setAddInputStateForUser(blankAddInputStateForUser)
        setShowModalAddPageForUser(false)
        await getlimitFuncForUser(true)

    }
    catch (err){

        displayError("1920",err)
    
    }
}
const submitEditForUser=async()=>{
    console.log('submitEditForUser...')
    try {
        const {_id,__v,...remaining}=editUser

        const result=await axios.post('/p40user/updateuserbyadmin',
        {...remaining},
        myheader
        )
        setEditUser(null)
        setShowModalEditPageForUser(false)
        await getlimitFuncForUser(true)
        //console.log(result.data)

    }
    catch (err){

        displayError("1941",err)

    }
}
const submitDeleteForUser=async()=>{
    console.log('submitDeleteForUser')
    try {
        const {id,shopTarget}=editUser
        const result=await axios.post('/p40user/deleteuserbyadmin',
        {id,shopTarget},
        myheader
        )
        setEditUser(null)
        setShowModalConfirmForUser(false)
        await getlimitFuncForUser(true)
        
    }
    catch (err){

        displayError("1960",err)
     
    }
}
//======================================
const submitAddForShopPayment=async()=>{
    console.log('submitAddForShopPayment...')
    try {

        const {id,file1,shopTarget,
            photoUrl1,...remaining}=addInputStateForShopPayment
        
        let checkFile1=false
        if(file1){
            if(file1.length>0){
                checkFile1=true
            }
        }
        
        const result=await axios.post('/p40shoppayment/addshoppaymentbyadmin',
        {...remaining,id:id,shopTarget:shopTarget,photoUrl1:photoUrl1},
        myheader
        )

        if(checkFile1){

            const tempFormInputState2=addFileNameInPhotoUrl({id,file1,photoUrl1,shopTarget})

            const fd=genFD(tempFormInputState2)

            const result2=await axios.post('/p40shoppayment/updatephotobyadmin',
                fd,
                {headers:{
                            ...myheader.headers,
                            shopTarget:shopTarget
                        }
                }
            )
            //console.log("9.......................")
        }

        setAddInputStateForShopPayment(blankAddInputStateForShopPayment)
        setShowModalAddPageForShopPayment(false)
        await getlimitFuncForShopPayment(true)
    }
    catch (err){

        displayError("2007",err)
     
    }
}

//===========================================
const submitEditForShopPayment=async()=>{
    console.log('submitEditForShopPayment...')
    try {
        const {_id,__v,
                id,file1,shopTarget,photoUrl1,
                ...remaining}=editShopPayment

        let checkFile1=false
        if(file1){
            if(file1.length>0){
                checkFile1=true
            }
        }
        
        const result=await axios.post('/p40shoppayment/updateshoppaymentbyadmin',
        {...remaining,id:id,shopTarget:shopTarget,photoUrl1:photoUrl1},
        myheader
        )

        if(checkFile1){

            const tempFormInputState2=addFileNameInPhotoUrl({id,file1,photoUrl1,shopTarget})

            const fd=genFD(tempFormInputState2)

            const result2=await axios.post('/p40shoppayment/updatephotobyadmin',
                fd,
                {headers:{
                            ...myheader.headers,
                            shopTarget:shopTarget
                        }
                }
            )
        }

        //
        setShowModalEditPageForShopPayment(false)
        setEditShopPayment(null)
        await getlimitFuncForShopPayment(true)
    }
    catch (err){

        displayError("2055",err)
   
    }
}

//============================
const submitDeleteForShopPayment=async()=>{
    console.log('submitDeleteForShopPayment')
    try {
        const {id,shopTarget}=editShopPayment

        const result=await axios.post('/p40shoppayment/deleteshoppaymentbyadmin',
        {id,shopTarget},
        myheader
        )
        setEditShopPayment(null)
        setShowModalConfirmForShopPayment(false)
        await getlimitFuncForShopPayment(true)
        
    }
    catch (err){

        displayError("2077",err)
       
    }
}
//========================================
//========================================
const submitEditWithNewIdForShop=async()=>{
    try {

    }
    catch (err){

        displayError("2089",err)
       
    }
}

const submitCopyRecordForShop=async()=>{
    try {
        
    }
    catch (err){

        displayError("2100",err)
      
    }
}

const submitEditWithNewIdForUser=async()=>{
    try {
        
    }
    catch (err){

        displayError("2111",err)
      
    }
}

const submitCopyRecordForUser=async()=>{
    try {
        
    }
    catch (err){

        displayError("2122",err)
       
    }
}

const submitEditWithNewIdForShopPayment=async()=>{
    try {
        
    }
    catch (err){

        displayError("2133",err)
        
    }
}

const submitCopyRecordForShopPayment=async()=>{
    try {
        
    }
    catch (err){

        displayError("2144",err)

    }
}

//========================================
//========================================
//========================================
const submitAdd=async()=>{
        console.log('submitAdd.....currentLink')
        //console.log(currentLink)
        try{ 
                const {id,file1,photoUrl1,...remaining}=addInputState

                if(!addInputState.type){
                    throw { message:"Invalid Type" }
                }

                let checkFile1=false
                if(file1){
                    if(file1.length>0){
                        checkFile1=true
                    }
                }
                
                const result =await axios.post('/p40item/addcustom',
                {...remaining,id:id,photoUrl1:photoUrl1},
                genMyTk(myheader))

                if(checkFile1){
                    const tempFormInputState2=addFileNameInPhotoUrl({id,file1,photoUrl1})
            
                    const fd=genFD(tempFormInputState2)

                    const result2=await axios.post('/p40item/updatephoto',fd,myheader)
                }
                
                const temp=await getlimitFunc()
                await submitSearchInCalendar()

        }
        catch(err){

            displayError("2187",err)
           
        }        
}
//=====================================
const submitCopyRecord=async()=>{
        console.log('submitCopyRecord')
        try{

            const {_id,id,newId,file1,photoUrl1,...remaining}=editInputState
        
            if(newId>0){

                let checkFile1=false
                if(file1){
                    if(file1.length>0){
                        checkFile1=true
                    }
                }
                
                const result =await axios.post('/p40item/addcustom',{...remaining,id:newId},genMyTk(myheader))

                if(checkFile1){
                    const tempFormInputState2=addFileNameInPhotoUrl({id:newId,file1,photoUrl1})
                
                    const fd=genFD(tempFormInputState2)

                    const result2=await axios.post('/p40item/updatephoto',fd,genMyTk(myheader))
                }
                
                const temp=await getlimitFunc()
                await submitSearchInCalendar()

            }

        }
        catch(err){

            displayError("2225",err)
           
        }        
}


//=====================================
const submitEditWithNewId=async()=>{
        console.log('submitEditNewId')

        try {
                const {id,newId,file1,photoUrl1}=editInputState
        
                let checkFile1=false
                if(file1){
                    if(file1.length>0){
                        checkFile1=true
                    }
                }
                
                const result =await axios.post('/p40item/updatecustom',editInputState,genMyTk(myheader))

                if(checkFile1){
                    const tempFormInputState2=addFileNameInPhotoUrl({id:newId,file1,photoUrl1})

                    const fd=genFD(tempFormInputState2)

                    const result2=await axios.post('/p40item/updatephoto',fd,genMyTk(myheader))
                }

                const temp=await getlimitFunc()
                await submitSearchInCalendar()

        }
        catch (err) {

            displayError("2261",err)
          
        }
}
//=====================================
const submitEdit=async()=>{ 
        try {
            const {id,file1,photoUrl1}=editInputState
            console.log('submitEdit.....')

            let checkFile1=false
            if(file1){
                if(file1.length>0){
                    checkFile1=true
                }
            }
            
            const result =await axios.post('/p40item/updatecustom',editInputState,genMyTk(myheader))

            if(checkFile1){
                const tempFormInputState2=addFileNameInPhotoUrl({id,file1,photoUrl1})
                const fd=genFD(tempFormInputState2)

                const result2=await axios.post('/p40item/updatephoto',fd,genMyTk(myheader))
            }
            
            const temp=await getlimitFunc()
        
            await submitSearchInCalendar()

        }
        catch (err) {

            displayError("2294",err)
          
        }
}
//=====================================
const submitDeleteById=async()=>{
        console.log('submitDeleteById.....')
        try{
            const result=await axios.post('/p40item/deletecustom',{id:editInputState.id},genMyTk(myheader))
            const temp=await getlimitFunc()

            await submitSearchInCalendar()

        }
        catch (err){

            displayError("2310",err)
  
        }
}
//=====================================

//====================================
const submitSearchForSearchButton=async()=>{
    console.log('submitSearchForSearchButton...9999999999')
    try{
        const temp=await getlimitFunc(true)
        setCurrentPage(1)
    }
    catch(err){

        displayError("2325",err)
    
    }
}


//=======================================
const submitUploadData=async(data)=>{
    console.log('submitUploadData')
    
    try{
        const result=await axios.post('/p40item/uploaddata',{dataArray:data},genMyTk(myheader))
        console.log(result.data)
    }
    catch (err){

        displayError("2341",err)
      
    }
}


//=======================================
const submitSearchUserList=async()=>{
    console.log('submitSearchUserlist')
    try{
        const result=await axios.post('/p40user/getcustom',
        {id:{$gte:1}},
        genMyTk(myheader))

        setUserList(result.data.data)
        
        console.log(result.data.data)
    }
    catch (err){

        displayError("2361",err)
       
    }
}
//=======================================

const submitDataFormat=async()=>{
        //console.log('submitDataFormat....')
        //console.log(dataLabel2)

        try{

            const tempDfCommon={
                            ...dfCommon,
                            toSortDown:toSortDown,
                            toSortUp:toSortUp,
                            toAllTrue:toAllTrue,
                            recordPerPage:recordPerPage,

                        }
            //console.log(1)
            let tempDfBoolean=[]
            booleanArrayOfItem.map(a=>{
                const {_id,...tempDataLabel2}=dataLabel2[a]
                
                const {toCheck,value}=filterState[a]
                const tempObjFilterState={
                    [a]:{
                        toCheck:toCheck,
                        booleanValue:value,
                        } 
                }

                tempDfBoolean=[...tempDfBoolean,{...tempDataLabel2,...filterState[a]}]

            })


            let tempDfString=[]
            stringArrayOfItem.map(a=>{
                const {_id,...tempDataLabel2}=dataLabel2[a]
                
                const {toCheck,value}=filterState[a]
                const tempObjFilterState={
                    [a]:{
                        toCheck:toCheck,
                        stringValue:value,
                        } 
                }

                tempDfString=[...tempDfString,{...tempDataLabel2,...filterState[a]}]
            
            })

            //console.log(2)
            let tempDfNumber=[]
            numberArrayOfItem.map(a=>{
                const {_id,...tempDataLabel2}=dataLabel2[a]

                const {toCheck,min,max}=filterState[a]
                const tempObjFilterState={
                    [a]:{
                        toCheck:toCheck,
                        minNumber:min,
                        maxNumber:max
                        } 
                }
                tempDfNumber=[...tempDfNumber,{...tempDataLabel2,...filterState[a]}]
            })

            let tempDfDate=[]
            dateArrayOfItem.map(a=>{
                const {_id,...tempDataLabel2}=dataLabel2[a]
               
                const {toCheck,min,max}=filterState[a]
                const tempObjFilterState={
                    [a]:{
                        toCheck:toCheck,
                        minDate:min,
                        maxDate:max
                        } 
                }


                tempDfDate=[...tempDfDate,{...tempDataLabel2,...filterState[a]}]
            })

            //console.log(3)
            let tempDfPhotoUrl=[]
            photoUrlArrayOfItem.map(a=>{
                    const {_id,...tempDataLabel2}=dataLabel2[a]
                    tempDfPhotoUrl=[...tempDfPhotoUrl,{...tempDataLabel2}]
            })

            //console.log(4)

            //console.log("basicDataSt")
            //console.log(basicDataSt)

            //console.log("tempDfCommon")
            //console.log(tempDfCommon)

            const tempDataFormat={
                id:basicDataSt.basicData.defaultDataFormat,
                dfCommon:tempDfCommon,
                dfBoolean:tempDfBoolean,
                dfString:tempDfString,
                dfNumber:tempDfNumber,
                dfDate:tempDfDate,
                dfPhotoUrl:tempDfPhotoUrl
            }

            console.log(5)
            console.log("tempDataFormat.............")
            console.log(tempDataFormat)
            
            const result =await axios.post('/p40dataformat/updatecustom',tempDataFormat,genMyTk(myheader))

            //console.log('result.data')
            //console.log(result.data)

            //console.log('currentLink')
            //console.log(currentLink)
            
            setShowAlbumSetting(false)
            setShowTableSetting(false)
            setShowTaskCalendarSetting(false)
            setShowAddSetting(false)
            setShowFilterSetting(false)

            /*
            if(currentLink==="album"){
                setShowAlbumSetting(false)
            }
            else if(currentLink==="table"){
                setShowTableSetting(false)
            }
            else if(currentLink==="calendar"){
                setShowTaskCalendarSetting(false)
            }
            else if(currentLink==="add"){
                setShowAddSetting(false)
            }
            else if(currentLink==="search"){
                setShowFilterSetting(false)
            }
            */
        }
        catch(err){

            displayError("2511",err)
          
        }
}
//=======================================
const submitDataFormatForShop=async()=>{
    console.log('submitDataFormatForShop....')
    try {

        const tempDfCommon={
            ...dfCommonForShop,
            toSortDown:toSortDownForShop,
            toSortUp:toSortUpForShop,
            toAllTrue:toAllTrueForShop,
            recordPerPage:recordPerPageForShop,
        }

        let tempDfBoolean=[]
        booleanArrayOfShop.map(a=>{
            const {_id,...tempDataLabelShop}=dataLabelShop[a]
            tempDfBoolean=[...tempDfBoolean,{...tempDataLabelShop,...filterStateForShop[a]}]
        })

        let tempDfString=[]
        stringArrayOfShop.map(a=>{
            const {_id,...tempDataLabelShop}=dataLabelShop[a]
            tempDfString=[...tempDfString,{...tempDataLabelShop,...filterStateForShop[a]}]
        
        })

        let tempDfNumber=[]
            numberArrayOfShop.map(a=>{
                const {_id,...tempDataLabelShop}=dataLabelShop[a]
                tempDfNumber=[...tempDfNumber,{...tempDataLabelShop,...filterStateForShop[a]}]
        })

        let tempDfDate=[]
            dateArrayOfShop.map(a=>{
                const {_id,...tempDataLabelShop}=dataLabelShop[a]
                tempDfDate=[...tempDfDate,{...tempDataLabelShop,...filterStateForShop[a]}]
            })

       
        const tempDataFormat={
            id:2, //shop
            dfCommon:tempDfCommon,
            dfBoolean:tempDfBoolean,
            dfString:tempDfString,
            dfNumber:tempDfNumber,
            dfDate:tempDfDate,
        }


        //console.log('tempDataFormat')
        //console.log(tempDataFormat)
        const result =await axios.post('/p40dataformat/updatecustom',tempDataFormat,genMyTk(myheader))

        setShowTableSettingForShop(false)
        setShowFilterSettingForShop(false)
        setShowAddPageSettingForShop(false)
    }
    catch (err){

        displayError("2574",err)
    
    }

}

const submitDataFormatForUser=async()=>{
    console.log('submitDataFormatForUser...')
    try {

        const tempDfCommon={
            ...dfCommonForUser,
            toSortDown:toSortDownForUser,
            toSortUp:toSortUpForUser,
            toAllTrue:toAllTrueForUser,
            recordPerPage:recordPerPageForUser,
        }

        let tempDfBoolean=[]
        booleanArrayOfUser.map(a=>{
            const {_id,...tempDataLabelUser}=dataLabelUser[a]
            tempDfBoolean=[...tempDfBoolean,{...tempDataLabelUser,...filterStateForUser[a]}]
        })

        let tempDfString=[]
        stringArrayOfUser.map(a=>{
            const {_id,...tempDataLabelUser}=dataLabelUser[a]
            tempDfString=[...tempDfString,{...tempDataLabelUser,...filterStateForUser[a]}]
        
        })

        let tempDfNumber=[]
            numberArrayOfUser.map(a=>{
                const {_id,...tempDataLabelUser}=dataLabelUser[a]
                tempDfNumber=[...tempDfNumber,{...tempDataLabelUser,...filterStateForUser[a]}]
        })

        let tempDfDate=[]
            dateArrayOfUser.map(a=>{
                const {_id,...tempDataLabelUser}=dataLabelUser[a]
                tempDfDate=[...tempDfDate,{...tempDataLabelUser,...filterStateForUser[a]}]
            })

       
        const tempDataFormat={
            id:4, //user
            dfCommon:tempDfCommon,
            dfBoolean:tempDfBoolean,
            dfString:tempDfString,
            dfNumber:tempDfNumber,
            dfDate:tempDfDate,
        }

        //console.log('tempDataFormat')
        //console.log(tempDataFormat)
        const result =await axios.post('/p40dataformat/updatecustom',tempDataFormat,genMyTk(myheader))

        setShowTableSettingForUser(false)
        setShowFilterSettingForUser(false)
        setShowAddPageSettingForUser(false)

    }
    catch (err){

        displayError("2638",err)
      
    }

}
//=======================================
//=======================================
const submitSearchInCalendar=async()=>{
    console.log("submitSearchInCalendar..............")

    //===================================
    try{
        const {tempFilterState,tempItemDate,tempSort}=genFilterStateForCalendar(
                                                minMaxDate.minDate,minMaxDate.maxDate
                                            )

        const pageData=await getlimitFunc2(tempFilterState,tempSort)

        let tempObj2={
            0:[],
            1:[],
            2:[],
            3:[],
            4:[],
            5:[],
            6:[]
        }

        pageData.map(b=>{
            const aDay=new Date(b[tempItemDate]).getDay()
            
            const tempArray=[...tempObj2[aDay],b]
    
            tempObj2={...tempObj2,[aDay]:tempArray}
        })
    
        setCalendarData(tempObj2)

    }
    catch(err){

        displayError("2679",err)
        
    }
}

//=========================================
//========================================
const submitDataFormatForShopPayment=async()=>{
    console.log('sumitDataFormatForShopPayment...')
    try {
        const tempDfCommon={
            ...dfCommonForShopPayment,
            toSortDown:toSortDownForShopPayment,
            toSortUp:toSortUpForShopPayment,
            toAllTrue:toAllTrueForShopPayment,
            recordPerPage:recordPerPageForShopPayment,
        }

        let tempDfBoolean=[]
        booleanArrayOfShopPayment.map(a=>{
            const {_id,...tempDataLabelShopPayment}=dataLabelShopPayment[a]
            tempDfBoolean=[...tempDfBoolean,{...tempDataLabelShopPayment,...filterStateForShopPayment[a]}]
        })

        let tempDfString=[]
        stringArrayOfShopPayment.map(a=>{
            const {_id,...tempDataLabelShopPayment}=dataLabelShopPayment[a]
            tempDfString=[...tempDfString,{...tempDataLabelShopPayment,...filterStateForShopPayment[a]}]
        
        })

        let tempDfNumber=[]
            numberArrayOfShopPayment.map(a=>{
                const {_id,...tempDataLabelShopPayment}=dataLabelShopPayment[a]
                tempDfNumber=[...tempDfNumber,{...tempDataLabelShopPayment,...filterStateForShopPayment[a]}]
        })

        let tempDfDate=[]
            dateArrayOfShopPayment.map(a=>{
                const {_id,...tempDataLabelShopPayment}=dataLabelShopPayment[a]
                tempDfDate=[...tempDfDate,{...tempDataLabelShopPayment,...filterStateForShopPayment[a]}]
            })

       
        const tempDataFormat={
            id:3, //shoppayment
            dfCommon:tempDfCommon,
            dfBoolean:tempDfBoolean,
            dfString:tempDfString,
            dfNumber:tempDfNumber,
            dfDate:tempDfDate,
        }
        
        //console.log('tempDataFormat')
        //console.log(tempDataFormat)
        const result =await axios.post('/p40dataformat/updatecustom',tempDataFormat,genMyTk(myheader))

        setShowTableSettingForShopPayment(false)
        setShowFilterSettingForShopPayment(false)
        setShowAddPageSettingForShopPayment(false)


    }
    catch (err){

        displayError("2744",err)
       
    }

}


//=======================================

//=======================================
//=======================================

const submitSearchForSearchButtonForShopPayment=async()=>{
    console.log('submitSearchForSearchButtonForShopPayment')

    try{
        const temp=await getlimitFuncForShopPayment(true)
        setCurrentPageForShopPayment(1)
    }
    catch(err){

        displayError("2765",err)
       
    }
}
//=======================================
const submitSearchForSearchButtonForUser=async()=>{
    console.log('submitSearchForSearchButtonForUser')

    try{
        const temp=await getlimitFuncForUser(true)
        setCurrentPageForUser(1)
    }
    catch(err){

        displayError("2779",err)
      
    }
}
//=======================================
const submitSearchForSearchButtonForShop=async()=>{
    console.log('submitSearchForSearchButtonForShop')

    try{
        const temp=await getlimitFuncForShop(true)
        setCurrentPageForShop(1)
    }
    catch(err){

        displayError("2793",err)
       
    }
}
//======================================
//======================================

const submitSearchForShop=async()=>{
    console.log('submitSearchForShop')
    try{
        const temp=await getlimitFuncForShop(false)
    }
    catch (err){

        displayError("2807",err)
       
    }
}
const submitSearchForUser=async()=>{
    console.log('submitSeachForUser')
    try{
        const temp=await getlimitFuncForUser(false)

    }
    catch (err){

        displayError("2819",err)
       
    }
}
const submitSearchForShopPayment=async()=>{
    console.log(submitSearchForShopPayment)
    try{
        const temp=await getlimitFuncForShopPayment(false)

    }
    catch (err){

        displayError("2831",err)
        
    }
}

React.useEffect(()=>{
    if( 
        localStorage.getItem('shopauthorization')&&
        localStorage.getItem('userauthorization')&&
        totalPageForShop>0
    )
    {
        submitSearchForShop()
    }
},[currentPageForShop])

React.useEffect(()=>{
    if( 
        localStorage.getItem('shopauthorization')&&
        localStorage.getItem('userauthorization')&&
        totalPageForUser>0
    )
    {
        submitSearchForUser()
    }
},[currentPageForUser])

React.useEffect(()=>{
    if( 
        localStorage.getItem('shopauthorization')&&
        localStorage.getItem('userauthorization')&&
        totalPageForShopPayment>0
    )
    {
        submitSearchForShopPayment()
    }
},[currentPageForShopPayment])
//=============================================
//=============================================

//======================================
const genToDate=async()=>{
    console.log('genToDate')
    genCalendarDateAndData({currentDate:new Date(),addYear:0,addMonth:0,addDate:0})
}


//=======================================
const genNextWeek=async()=>{
    console.log('genNextWeek')
    genCalendarDateAndData({currentDate:date,addYear:0,addMonth:0,addDate:7})

}
//==================================
const genPrevWeek=async()=>{
    console.log('genPrevWeek')
    genCalendarDateAndData({currentDate:date,addYear:0,addMonth:0,addDate:-7})

}
//==================================
const genNextMonth=async()=>{
    console.log('genNextMonth')
    genCalendarDateAndData({currentDate:date,addYear:0,addMonth:1,addDate:0})
   
}
//==================================
const genPrevMonth=async()=>{
    console.log('genPrevMonth')
    genCalendarDateAndData({currentDate:date,addYear:0,addMonth:-1,addDate:0})
   
}
//==================================
const genNextYear=async()=>{
    console.log("genNextYear")
    genCalendarDateAndData({currentDate:date,addYear:1,addMonth:0,addDate:0})
   
}
//==================================
const genPrevYear=async()=>{
    console.log('genprevYear')
    genCalendarDateAndData({currentDate:date,addYear:-1,addMonth:0,addDate:0})
 
}

//=======================================
//=======================================
const genFormTemplate=()=>{
    let tempObj={}
    
    FormTemplate.map(a=>{
        let tempObj2={
            formHead:a.formHead
        }
        a.template.map(b=>{
            tempObj2={...tempObj2,[b.key]:b.lb}
        })

        tempObj={...tempObj,
            [a.formName]:tempObj2
        }
    })

    return tempObj
}

//=========================================
const submitsearchdataformat=async()=>{
    console.log('submitsearchdataformat............')
    try{
        const qry={}
        const result=await axios.post('/p40dataformat/getlimit',
        {   sort:{id:1},
            pageNumber:1,
            limitRow:100,
            ...qry
        },
        genMyTk(myheader))
        //console.log(result.data)
        if(result.data){
            console.log('result.data......')
            console.log(result.data)

            let tempArray=[]

            result.data.data.map(a=>{
                if(a.dfName==="shopDataFormat"||
                a.dfName==="userDataFormat"||
                a.dfName==="shopPaymentDataFormat"
                ){
                }
                else {
                    tempArray=[...tempArray,a]
                }
            })
            
            const {data,count,lastRecordId}=result.data
            
            setCountDataFormat(count)
            setLastRecordIdDataFormat(lastRecordId)
            setDataDataFormat(tempArray)
            //setPageData2(result.data.data)
            
        }
        else {
            throw { message:"error" }
        }

    }
    catch (err){

        displayError("2975",err)
      
    }
}
//==========================================
const submitUpdateDefaultDataFormatWithFilterState=async(a)=>{
    console.log("submitUpdateDefaultDataFormatWithFilterState")
    
    try {
        const tempObj={id:1,
            defaultDataFormat:a}

        console.log('tempObj')
        console.log(tempObj)
        
        const result =await axios.post('/p40basicdata/updatedefaultdataformat',tempObj,genMyTk(myheader))
        console.log(result.data)
        window.location.reload()

    }
    catch(err){

        displayError("2993",err)
       
    }


}

//==========================================
const submitUpdateDefaultDataFormat=async()=>{
    console.log("submitUpdateDefaultDataFormat")

    try {
        
        const tempObj={id:1,
                    defaultDataFormat:defaultDataFormat}
        
        //console.log('tempObj...')
        //console.log(tempObj)

        const result =await axios.post('/p40basicdata/updatecustom',tempObj,genMyTk(myheader))

        //console.log('result../p40basicdata/updatecustom..')
        //{id: 1, defaultDataFormat: 5}
        //console.log(result.data)

        /*
        const result2=await axios.post('/p40dataformat/getcustom',{},genMyTk(myheader))
        console.log('result2../p40dataformat/getcustom..')
        //0 : {_id: '652838555deb6f00193f67cc', timestamp: '2023-10-12T18:17:56.513Z', id: 1, shopId: 'shopmaster', userId: 1, …}
        //1 : {_id: '652838555deb6f00193f67fb', timestamp: '2023-10-12T18:17:56.513Z', id: 2, shopId: 'shopmaster', userId: 1, …}
        //2 : {_id: '652838555deb6f00193f680c', timestamp: '2023-10-12T18:17:56.513Z', id: 3, shopId: 'shopmaster', userId: 1, …}
        //3 : {_id: '652838555deb6f00193f682a', timestamp: '2023-10-12T18:17:56.513Z', id: 4, shopId: 'shopmaster', userId: 1, …}
        //4 : {_id: '652923a218cc7a001af469a3', timestamp: '2023-10-12T18:17:56.513Z', id: 5, shopId: 'shopmaster', userId: 1, …}
        //5 : {_id: '6529578e0e0aed001ac8f700', timestamp: '2023-10-12T18:17:56.513Z', id: 6, shopId: 'shopmaster', userId: 1, …}
        //6 : {_id: '652968e20e0aed001ac8f9e9', timestamp: '2023-10-12T18:17:56.513Z', id: 7, shopId: 'shopmaster', userId: 1, …}
        
        console.log(result2)

        const result3=await axios.post('/p40stringarray/getcustom',{},genMyTk(myheader))
        console.log('result3../p40stringarray/getcustom..')
        //0 : {_id: '652838545deb6f00193f67cb', timestamp: '2023-10-12T18:17:56.545Z', type: Array(3), itemString1: Array(3), itemString2: Array(3), …}
        //1 : {_id: '652923a218cc7a001af469d2', timestamp: '2023-10-12T18:17:56.545Z', type: Array(3), itemString1: Array(3), itemString2: Array(3), …}
        //2 : {_id: '6529578e0e0aed001ac8f72f', timestamp: '2023-10-12T18:17:56.545Z', type: Array(3), itemString1: Array(3), itemString2: Array(3), …}
        //3 : {_id: '652968e20e0aed001ac8fa18', timestamp: '2023-10-12T18:17:56.545Z', type: Array(3), itemString1: Array(3), itemString2: Array(3), …}
        console.log(result3)

        const result4=await axios.post('/p40basicdata/getdataforcontext',{
        },genMyTk(myheader))
        console.log('result4../p40basicdata/getdataforcontext..')
        //basicData : {userLevel: Array(3), timestamp: '2023-10-12T18:17:38.731Z', _id: '652838545deb6f00193f67ad', id: 1, shopId: 'shopmaster', …}
        //dataFormat : [{…}]
        //dataFormatMaster : (3) [{…}, {…}, {…}]
        //shop : {monthlyFee: 50, expirationDate: '2053-12-15T12:00:00.000Z', costPerUser: '20'}
        //stringArray : [{…}]
        //user :  {id: 1, username: 'shopmaster_userone', userLevel: 'admin', active: true, name: '', …}
        console.log(result4)
        */
        
        window.location.reload()

    }
    catch (err) {

        displayError("3056",err)
      
    }

}
//=======================================
const submitSearchDataFormatFromTemplate=async()=>{
      console.log("submitSearchDataFormatFromTemplate")
    try {
        const result =await axios.post('/p40dataformat/getdataformatfromtemplate',{},genMyTk(myheader))
        
        console.log(result.data)
        
        setDataFormatFromTemplate(result.data)
        setShowDataFormatFromTemplate(true)

    }
    catch (err){

        displayError("3075",err)

    }
}
//======================================
const submitAddDataFormatFromTemplate=async()=>{
    console.log("submitAddDataFormatFromTemplate")
    try {

        if(selectDataFormatFromTemplate){
            const result =await axios.post('/p40dataformat/adddataformatfromtemplate',
            selectDataFormatFromTemplate,
            genMyTk(myheader))
            
            window.location.reload()
        }
        
    }
    catch (err){

        displayError("3095",err)
       
    }
}


//=======================================

const submitAddUser=async(inputState)=>{

    try {
        //console.log('submitAddUser')
        //console.log(inputState)

        const result =await axios.post('/p40user/adduser',inputState,genMyTk(myheader))
        
        window.location.reload()

    }
    catch (err){ 

        displayError("3116",err)
       
    }
}
//=========================================
const submitDeleteUser=async(editUser)=>{
    console.log('submitDeleteUser....')
    try {

        const {id}=editUser

        const result =await axios.post('/p40user/deleteuser',{id},genMyTk(myheader))
        console.log(result.data.data)
    
        
        window.location.reload()
        
    }
    catch (err){

        displayError("3136",err)
        
    }
}

const submitEditUser=async(editUser)=>{
    console.log('submitEditUser')

    try {
        const {_id,...remaining}=editUser

        const result =await axios.post('/p40user/updateuser',remaining,genMyTk(myheader))
        //console.log(result.data.data)
        
        window.location.reload()
    }
    catch (err){
        
        displayError("3154",err)
      
    }
}
//============================================
const submitLogInWithRecoveryPasswordByMaster=async(inputState)=>{
    console.log("submitLogInWithRecoveryPasswordByMaster")
    
    const {shopName,recoveryPassword}=inputState

    try{
        if(shopName&&recoveryPassword){
            const result =await axios.post('/p40shop/loginwithrecoverypasswordbymaster',
            inputState
            ,genMyTk(myheader))

            //console.log(result.data)

            if(result.data){
                const {expirationDate,shopToken,userToken,username}=result.data
                localStorage.setItem("tempexpirationDate",expirationDate)
                localStorage.setItem("tempshopauthorization",`b ${shopToken}`)
                localStorage.setItem("tempuserauthorization",`b ${userToken}`)
                localStorage.setItem("tempusername",username)
            }

        }
    }
    catch(err){
        displayError("3204",err)
    }
}



//===========================================
const submitChangeShopPassword=async(inputState)=>{
    console.log('submitChangeShopPassword')

    try {
        const {shopPassword,newShopPassword,confirmNewShopPassword}=inputState

        if(shopPassword&&newShopPassword&&confirmNewShopPassword){
            if(newShopPassword===confirmNewShopPassword){
                if(shopPassword!==newShopPassword){
                    const result =await axios.post('/p40shop/changeshoppassword',
                    inputState
                    ,genMyTk(myheader))
                    
                    //console.log(result.data.data)
                    
                    localStorage.removeItem('shopauthorization')
                    localStorage.removeItem('userauthorization')
                    localStorage.removeItem('username')
                    localStorage.removeItem('expirationDate')
                    //console.log(2)
                    
                    window.location.reload()
                }
            }
            else {
                throw {message:"shopPassword"}
            }
        }
        else {
            throw { message:"shopPassword" }
        }

    }
    catch (err){

        displayError("3195",err)
       
    }
}


const submitChangeUserPasswordByAdmin=async(editUser)=>{
    console.log('submitChangeUserPasswordByAdmin')

    try {
        const {id,newUserPassword,confirmNewUserPassword}=editUser

        if(newUserPassword&&confirmNewUserPassword){
            if(newUserPassword===confirmNewUserPassword){

                const result =await axios.post('/p40user/changeuserpasswordbyadmin',
                {id,newUserPassword,confirmNewUserPassword},
                genMyTk(myheader))
                console.log(result.data.data)       
                window.location.reload()
                
            }
            else {
                throw { message:"Invalid Password" }
            }
        }
        else {
            throw { message:"Invalid Password" }
        }

    }
    catch (err){

        displayError("3228",err)
    
    }
}


const submitGetSupportLink=()=>{
    console.log("submitGetSupportLink")

    return new Promise(async(resolve, reject) => {  

        try {

            const result =await axios.post('/p40basicdata/getsupportlink',
            {},
            genMyTk(myheader))  
            
            if(result){
                resolve(result)
            }
            else {
                throw {message:"error"}
            }
        }
        catch (err){
         
            displayError("3254",err)
        }
               
    });
    
}

//=========================================
//=========================================
//=========================================
//=========================================
return(
        <MainContext.Provider value={
            {

                myheader,
                tokenSt,setTokenSt,
                setReloadCheckToken,
 
                basicDataSt,setBasicDataSt,
 
                languageDisplay,
                languageSetting,setLanguageSetting,

                toSortUp,setToSortUp,
                toSortDown,setToSortDown,
                toAllTrue,setToAllTrue,
 
                recordPerPageForShop,setRecordPerPageForShop,
                toSortUpForShop,setToSortUpForShop,
                toSortDownForShop,setToSortDownForShop,
                toAllTrueForShop,setToAllTrueForShop,
                filterStateForShop,setFilterStateForShop,
                lastRecordIdForShop,setLastRecordIdForShop,
                currentPageForShop,setCurrentPageForShop,
                totalPageForShop,setTotalPageForShop,
                countRecordForShop,setCountRecordForShop,
                
                keyArrayOfShop,
                shopData,setShopData,
                submitSearchForSearchButtonForShop,
                
               
                recordPerPageForUser,setRecordPerPageForUser,
                toSortUpForUser,setToSortUpForUser,
                toSortDownForUser,setToSortDownForUser,
                toAllTrueForUser,setToAllTrueForUser,
                filterStateForUser,setFilterStateForUser,
                lastRecordIdForUser,setLastRecordIdForUser,
                currentPageForUser,setCurrentPageForUser,
                totalPageForUser,setTotalPageForUser,
                countRecordForUser,setCountRecordForUser,
                
                keyArrayOfUser,
                userData,setUserData,
                submitSearchForSearchButtonForUser,
 
                keyArrayOfShopPayment,
                keyArrayWithPhotoUrlOfShopPayment,
                shopPaymentData,setShopPaymentData,
                submitSearchForSearchButtonForShopPayment,
 
                recordPerPageForShopPayment,setRecordPerPageForShopPayment,
                toSortUpForShopPayment,setToSortUpForShopPayment,
                toSortDownForShopPayment,setToSortDownForShopPayment,
                toAllTrueForShopPayment,setToAllTrueForShopPayment,
                filterStateForShopPayment,setFilterStateForShopPayment,
                lastRecordIdForShopPayment,setLastRecordIdForShopPayment,
                currentPageForShopPayment,setCurrentPageForShopPayment,
                totalPageForShopPayment,setTotalPageForShopPayment,
                countRecordForShopPayment,setCountRecordForShopPayment,
                
                
                dfCommon,setDfCommon,
                dfCommonForShop,setDfCommonForShop,
                dfCommonForUser,setDfCommonForUser,
                dfCommonForShopPayment,setDfCommonForShopPayment,
 
                userLevel, 
                bankDetail,shopPayment,
                userTableTemplate,userArray,
                formTemplate:genFormTemplate(),
                pageData,setPageData,
                calendarData,setCalendarData, 
 
                currentPage,setCurrentPage,
                totalPage,setTotalPage,
                countRecord,setCountRecord,
                
                countDataFormat,setCountDataFormat,
                lastRecordIdDataFormat,setLastRecordIdDataFormat,
                dataDataFormat,setDataDataFormat, 
                newDataDataFormat,setNewDataDataFormat,
 
                deleteDataFormat,setDeleteDataFormat,
                editDataFormat,setEditDataFormat,
 
                defaultDataFormat,setDefaultDataFormat, 
                defaultDataFormatName,setDefaultDataFormatName, 

                showShopPayment,setShowShopPayment,
                
                showDataFormatFromTemplate,setShowDataFormatFromTemplate,
                dataFormatFromTemplate,setDataFormatFromTemplate,
                selectDataFormatFromTemplate,setSelectDataFormatFromTemplate,

                userList,setUserList,
 
                editData,setEditData,
                filterState,setFilterState,
                recordPerPage,setRecordPerPage,
                lastRecordId,
                maxPerPage,
                
                submitSearch,
                submitSearchForSearchButton,
                submitAdd,
                submitEdit,
                submitEditWithNewId,
                submitDeleteById,
                
 
                submitDataFormat,
                submitDataFormatForShop,
                submitDataFormatForUser,
                submitDataFormatForShopPayment, 
 
                submitCopyRecord, 
                submitUploadData,
                submitSearchInCalendar,
                submitUpdateStringArray,
                submitsearchdataformat,
                submitNewDataDataFormat,
                submitDeleteDataFormat,
                submitEditDataFormat,
                submitUpdateDefaultDataFormat,
                submitSearchUserList,
 
                submitUpdateDefaultDataFormatWithFilterState,

                submitChangeShopPassword,

                submitAddUser, 
                submitDeleteUser,
                submitEditUser,
                submitChangeUserPasswordByAdmin,
  
                submitLogInWithRecoveryPasswordByMaster,

                submitAddForShop,submitEditForShop,submitDeleteForShop,
                submitAddForUser,submitEditForUser,submitDeleteForUser,
                submitAddForShopPayment,submitEditForShopPayment,submitDeleteForShopPayment,
 
                submitEditWithNewIdForShop,submitCopyRecordForShop,
                submitEditWithNewIdForUser,submitCopyRecordForUser,
                submitEditWithNewIdForShopPayment,submitCopyRecordForShopPayment,
 
                submitGetSupportLink,

                submitUpdateShopFromShopPayment,

                currentLink,setCurrentLink,
                prevLink,setPrevLink,
                addInputState,setAddInputState,
                
                dataLabel2,setDataLabel2,
 
                dataLabelShop,setDataLabelShop,
                dataLabelShopPayment,setDataLabelShopPayment,
                dataLabelUser,setDataLabelUser,
 
                stringArray, 
                dateArrayOfItem,
 
                editShop,setEditShop,
                editShopPayment,setEditShopPayment,
                editUser,setEditUser, 
 
                editInputState,setEditInputState,
                blankAddInputState,
                settingType,setSettingType,
 
 
                addInputStateForShop,setAddInputStateForShop,
                blankAddInputStateForShop,
                //editInputStateForShop,setEditInputStateForShop,
            
                addInputStateForUser,setAddInputStateForUser,
                blankAddInputStateForUser,
                //editInputStateForUser,setEditInputStateForUser,
            
                addInputStateForShopPayment,setAddInputStateForShopPayment,
                blankAddInputStateForShopPayment,
                //editInputStateForShopPayment,setEditInputStateForShopPayment,
                
                isCsv,setIsCsv,
                isXls,setIsXls,
                widthLeft,setWidthLeft,
 
                date,setDate,
                firstLoadCalendar,setFirstLoadCalendar,
                thisWeekDay,setThisWeekDay,
                //taskInMonth,setTaskInMonth,
 
                uploadPageData,setUploadPageData,
                uploadCurrentPage,setUploadCurrentPage,
                uploadCurrentPageData,setUploadCurrentPageData,
                uploadTotalPage,setUploadTotalPage,
                uploadEditData,setUploadEditData,
                uploadEditInputState,setUploadEditInputState,
 
                modalImageWidth,setModalImageWidth,
 
                showFilterSetting,setShowFilterSetting,
                showTableSetting,setShowTableSetting,
                showAlbumSetting,setShowAlbumSetting,
                showAddSetting,setShowAddSetting, 
                showBarcodeSetting,setShowBarcodeSetting,
                showTaskCalendarSetting,setShowTaskCalendarSetting,
                //fieldName,
 
                //----------------------------------
 
                showFilterSettingForShop,setShowFilterSettingForShop,
                showTableSettingForShop,setShowTableSettingForShop,
                showFormSettingForShop,setShowFormSettingForShop,
 
                showModalAddPageForShop,setShowModalAddPageForShop,
                showModalEditPageForShop,setShowModalEditPageForShop,
                showModalConfirmForShop,setShowModalConfirmForShop,
                showAddPageSettingForShop,setShowAddPageSettingForShop,
 
                //----------------------------------
                
                showFilterSettingForUser,setShowFilterSettingForUser,
                showTableSettingForUser,setShowTableSettingForUser,
                showFormSettingForUser,setShowFormSettingForUser,
            
                showModalAddPageForUser,setShowModalAddPageForUser,
                showModalEditPageForUser,setShowModalEditPageForUser,
                showModalConfirmForUser,setShowModalConfirmForUser,
                showAddPageSettingForUser,setShowAddPageSettingForUser,
                
                //================================================
                
                showFilterSettingForShopPayment,setShowFilterSettingForShopPayment,
                showTableSettingForShopPayment,setShowTableSettingForShopPayment,
                showFormSettingForShopPayment,setShowFormSettingForShopPayment,
            
                showModalAddPageForShopPayment,setShowModalAddPageForShopPayment,
                showModalEditPageForShopPayment,setShowModalEditPageForShopPayment,
                showModalConfirmForShopPayment,setShowModalConfirmForShopPayment,
                showAddPageSettingForShopPayment,setShowAddPageSettingForShopPayment,
 
                //--------------------------------------------------
                showAddDataFormatModal,setShowAddDataFormatModal,
                showDeleteDataFormatModal,setShowDeleteDataFormatModal,
                showEditDataFormatModal,setShowEditDataFormatModal,
                showUpdateDefaultDataFormatModal,setShowUpdateDefaultDataFormatModal,
 
                submitSearchDataFormatFromTemplate,
                submitAddDataFormatFromTemplate,

                //===========================================
                stringArrayState,setStringArrayState,
                showModalStringArray,setShowModalStringArray,
 
                keyArray,
                keyArrayWithPhotoUrl,

                keyArrayWithPhotoUrlForUpload,
                
                widthRight,setWidthRight,
 
                tempUploadTableTemplate,
                setTempUploadTableTemplate,
 
                genNextWeek,
                genPrevWeek,
                genNextMonth,
                genPrevMonth,
                genNextYear,
                genPrevYear,
                genToDate,
 
                thisPage,setThisPage,


                showError,setShowError,
                messageError,setMessageError,

                showSuccess,setShowSuccess,
                messageSuccess,setMessageSuccess,

                console,

                checkChar
                
            }
        }>
            {props.children}
        </MainContext.Provider>
    )
}

export default MainContextProvider;