import React from 'react';
import {MdErrorOutline} from 'react-icons/md'
import {FaBan} from 'react-icons/fa'
import console from '../../util/consoleVerify'
//import './Modal.css'

const ModalError=({
    showError,setShowError,
    messageError,setMessageError,
    languageDisplay
})=>{
    //console.log('renderModalError')
   // //console.log(message)
return(
    <div className="Modal-background">
        <div className="Modal-box2">
            <div className="Modal-header">
                <div>
                    <MdErrorOutline style={{fontSize:"2.5rem",color:"red"}}/>
                </div>
            </div>
            <div className="Modal-header">
                <h5 style={{color:"red"}}>
                    {
                      languageDisplay
                      ?languageDisplay.error
                        ?languageDisplay.error
                        :"ERROR"
                      :"ERROR"
                    }
                </h5>
            </div>

            <div className="Modal-body">
                <div className ="flex-center-center">
                    {
                    messageError
                    ?<h5 style={{color:"red"}}>
                        {messageError}
                    </h5>:
                    null
                    }
                </div>
            </div>
            <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                            setMessageError("")
                            setShowError(false)
                            //if(setMessage){setMessage(null)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>

        </div>
    </div>
    
)}



export default ModalError
               