import React from 'react';
import {FaRegArrowAltCircleDown,FaRegArrowAltCircleUp,
    FaPlusSquare,FaMinusSquare,FaCaretDown,FaCaretUp,
    FaFileCsv,FaRegFileExcel,FaSearch,
    FaArrowAltCircleDown,FaArrowAltCircleUp,FaCircle,
    FaAngleDown
} from 'react-icons/fa';

import TypeSelect from './TypeSelect';

import { BsIntersect,BsUnion } from 'react-icons/bs';
import Calendar from './Calendar'
import {Link} from 'react-router-dom';
import {MainContext} from '../../context/MainContext'
import console from '../../util/consoleVerify'

//========================================

function FilterInput({
    goAfterSearch,

    dataLabel,
    stringArray,
    basicData,

    filterState,setFilterState,
    recordPerPage,setRecordPerPage,
    toSortUp,setToSortUp,
    toSortDown,setToSortDown,
    toAllTrue,setToAllTrue,

    maxPerPage,
    
    submitSearch,
    setIsCsv,
    setIsXls,

    keyArray,
    prevLink,

    languageDisplay,

    submitUpdateDefaultDataFormatWithFilterState

}){

//filterLineOrder,showFilterLine,filterLineColor,filterLineBreak

    const {
        currentLink
    }=React.useContext(MainContext)

    const refCalendar=React.createRef()
    const refTable=React.createRef()
    const refAlbum=React.createRef()
    const refHome=React.createRef()

    let tempArray=[]

    
    if(dataLabel&&stringArray){
        //tempArray=Object.keys(dataLabel)
        tempArray=keyArray

        tempArray.sort((a, b) => {
            return dataLabel[a].filterLineOrder - dataLabel[b].filterLineOrder;
        });
    }

    const keyObjectArray=tempArray


//==================================
const genWidthLeft=()=>{
    
    return "xc12"
}

//==================================
const renderArrow=(b)=>{

        let toShowArrowUp=false
        let toShowArrowDown=false
        
        if(b===toSortUp){
            toShowArrowUp=true
            toShowArrowDown=false
        }
        else if(b===toSortDown){
            toShowArrowUp=false
            toShowArrowDown=true
        }
   
        if(toShowArrowDown){

            return(
                <div>
                    <FaArrowAltCircleDown
                        className='sm-icon'
                        onClick={e=>{
                              setToSortUp(b)
                              setToSortDown("")
                        }}
                    />
                </div> 
            )
        }
        else if(toShowArrowUp){

            return(
                <div>
                    <FaArrowAltCircleUp
                        className='sm-icon'
                        onClick={e=>{
                            setToSortUp("")
                            setToSortDown("")
                      
                        }}
                    />
                </div>
            )
        }
        else{

            return(
                <div>
                    <FaCircle
                        className='sm-icon'
                        onClick={e=>{
                            setToSortUp()
                            setToSortDown(b)
                         
                        }}
                    />
                </div>
            )
        }
}

//==================================
    const renderBetweenNumber=(b,idxB)=>{
        
        return (
        <React.Fragment
            key={idxB}
        >
            <div 
                 className={genWidthLeft()}
                 style={{
                         //marginBottom:"0.5rem",
                         paddingTop:"0.2rem",
                         paddingBottom:"0.2rem",
                         //borderRadius:"0.3rem",
                         backgroundColor:dataLabel[b].filterLineBackgroundColor 
                 }}
            >
                <div className='flex-center-center flex-no-wrap'>
                    
                    <div className='xc1 h-100'
                         style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                    >
                        <input
                            style={{height:"1.5rem"}}
                            type="checkbox"
                            checked={filterState[b].toCheck?"checked":""}
                            onChange={e=>{
                                const temp1={...filterState[b],toCheck:e.target.checked}
                                const temp2={...filterState,[b]:temp1}
                                setFilterState(temp2)

                            }}
                        />

                     
                    </div>

                    <div className='xc3 h-100'
                         style={{display:"flex",
                                justifyContent:"flex-start",
                                alignItems:"center",
                            
                         }}
                    >
                       {
                        renderArrow(b)
                       }
                       
                        <p className='label table-p w-100 h-100'
                           style={{color:dataLabel[b].filterLineColor,
                                  }}
                        >
                            {dataLabel[b].lb}
                        </p>

                    </div>

                    <div className='xc8 h-100'>
                         <div className='xc12 flex-center-baseline'>

                            <div className="xc6" style={{paddingLeft:"0.2rem"}}>
                                <input
                                    type={"number"}
                                    value={filterState[b].min}
                                    onChange={e=>{
                                        const temp1={...filterState[b],min:e.target.value}
                                        const temp2={...filterState,[b]:temp1}
                                        setFilterState(temp2)
                                    }}
                                />
                            </div>
                          

                            <div className="xc6" style={{paddingLeft:"0.2rem"}}>
                                <input
                                    type={"number"}
                                    value={filterState[b].max}
                                    onChange={e=>{
                                        const temp1={...filterState[b],max:e.target.value}
                                        const temp2={...filterState,[b]:temp1}
                                        setFilterState(temp2)
                                    }}
                                />
                            </div>
                         </div>

                    </div>

                </div>

            </div>
            
           
            {
            dataLabel[b].filterLineBreak
            ?<div style={{visibility:"hidden"}}>
                aa
            </div>   
            :null
            }                        
           
        </React.Fragment>
        )
    }

//======================================

    const renderBetweenDate=(b,idxB)=>{
        
        return (
        <React.Fragment
            key={idxB}
        >
            <div 
                 className={genWidthLeft()}
                 style={{
                    //marginBottom:"0.5rem",
                    paddingTop:"0.2rem",
                    paddingBottom:"0.2rem",
                    //borderRadius:"0.3rem",
                    backgroundColor:dataLabel[b].filterLineBackgroundColor 
                }}
            >
                <div className='flex-center-center flex-no-wrap'>
                    
                    <div className='xc1 h-100'
                         style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                    >
                        <input
                            style={{height:"1.5rem"}}
                            type="checkbox"
                            checked={filterState[b].toCheck?"checked":""}
                            onChange={e=>{
                                const temp1={...filterState[b],toCheck:e.target.checked}
                                const temp2={...filterState,[b]:temp1}
                                setFilterState(temp2)

                            }}
                        />
                    </div>

                    <div className='xc3 h-100'
                         style={{display:"flex",
                                justifyContent:"flex-start",
                                alignItems:"center"
                         }}
                    >

                        {
                            renderArrow(b)
                        }
                        <p className='label table-p'
                           style={{color:dataLabel[b].filterLineColor}}
                        >
                            {dataLabel[b].lb}
                        </p>

                    </div>

                    <div className='xc8 h-100'>
                         <div className='xc12 flex-center-baseline'>

                            <div className="xc6" style={{paddingLeft:"0.2rem"}}>
                                <Calendar
                                    style={{height:"1.6rem",fontSize:"0.8rem"}}
                                    showDateOption={"datetime"}
                                    useTimeSetting={true}
                                    onMyClick={
                                        (e)=>{
                                            const temp1={...filterState[b],min:e}
                                            const temp2={...filterState,[b]:temp1}
                                            setFilterState(temp2)
                                        }
                                    }
                                    value={filterState[b].min}
                                    useTimeZeroForNoValue={true}
                                />
                            </div>
                          

                            <div className="xc6" style={{paddingLeft:"0.2rem"}}>
                                <Calendar
                                    style={{height:"1.6rem",fontSize:"0.8rem"}}
                                    showDateOption={"datetime"}
                                    useTimeSetting={true}
                                    onMyClick={
                                        (e)=>{
                                            const temp1={...filterState[b],max:e}
                                            const temp2={...filterState,[b]:temp1}
                                            setFilterState(temp2)
                                        }
                                    }
                                    value={filterState[b].max}
                                    useTimeZeroForNoValue={true}
                                />

                            </div>
                         </div>

                    </div>

                </div>

            </div>
                
     
            {
            dataLabel[b].filterLineBreak
            ?<div style={{visibility:"hidden"}}>
                hidden
            </div>   
            :null
            }

           
        </React.Fragment>
        )
    }

//==========================================
    const renderBoolean=(b,idxB)=>{

    return (
    <React.Fragment key={idxB}>
        <div 
             className={genWidthLeft()}
             style={{
                    //marginBottom:"0.5rem",
                    paddingTop:"0.2rem",
                    paddingBottom:"0.2rem",
                    //borderRadius:"0.3rem",
                    backgroundColor:dataLabel[b].filterLineBackgroundColor 
                }}
        >
            <div className='flex-center-center flex-no-wrap'>
                
                <div className='xc1 h-100'
                        style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                >
                    <input
                        style={{height:"1.5rem"}}
                        type="checkbox"
                        checked={filterState[b].toCheck?"checked":""}
                        onChange={e=>{
                            const temp1={...filterState[b],toCheck:e.target.checked}
                            const temp2={...filterState,[b]:temp1}
                            setFilterState(temp2)

                        }}
                    />
                </div>

                <div className='xc3 h-100'
                        style={{display:"flex",
                            justifyContent:"flex-start",
                            alignItems:"center"
                        }}
                >
                    {
                        renderArrow(b)
                    }
                    <p className='label table-p'
                       style={{color:dataLabel[b].filterLineColor}}
                    >
                        {dataLabel[b].lb}
                    </p>

                </div>

                <div className='xc8 h-100'>
                        <div className='xc12 flex-center-baseline'>

                            <input
                                type={"checkbox"}
                                checked={filterState[b].value}
                                onChange={e=>{
                                    const temp1={...filterState[b],value:e.target.checked}
                                    const temp2={...filterState,[b]:temp1}
                                    setFilterState(temp2)
                                }}
                            />

                        </div>

                </div>

            </div>

        </div>

        
        {
        dataLabel[b].filterLineBreak
        ?<div style={{visibility:"hidden"}}>
            aa
        </div>   
        :null
        }

    
    </React.Fragment>
        )
    }

//=====================================
const genFakeValue=()=>{

    let tempString=null
    //console.log(filterState.type)
    basicData.typeArray.map(g=>{
        if(g.typeId===filterState.type.value){
        //if(g.typeId===2){
            tempString=`${g.typeId}.${g.typeName}`
        }
    })

    return tempString
}
//=====================================
const renderType=(b,idxB)=>{

    return (
    <React.Fragment
        key={idxB}
    >
        <div 
             className={genWidthLeft()}
             style={{
                //marginBottom:"0.5rem",
                paddingTop:"0.2rem",
                paddingBottom:"0.2rem",
                //borderRadius:"0.3rem",
                backgroundColor:dataLabel[b].filterLineBackgroundColor 
               
            }}
        >
            <div className='flex-center-center flex-no-wrap'>
                
                <div className='xc1 h-100'
                        style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                >
                    <input
                        style={{height:"1.5rem"}}
                        type="checkbox"
                        checked={"checked"}//{filterState[b].toCheck?"checked":""}
                        onChange={e=>{
                            //const temp1={...filterState[b],toCheck:e.target.checked}
                            //const temp2={...filterState,[b]:temp1}
                            //setFilterState(temp2)

                        }}
                    />
                </div>

                <div className='xc3 h-100'
                        style={{display:"flex",
                            justifyContent:"flex-start",
                            alignItems:"center"
                        }}
                >
                    {
                        renderArrow(b)
                    }

                    <p className='label table-p'
                       style={{color:dataLabel[b].filterLineColor}}
                    >
                        {dataLabel[b].lb}
                    </p>

                </div>

                <div className='xc8 h-100'>
                    <div className='xc12 flex-center-baseline'>
                        {basicData&&filterState&&
                        <TypeSelect
                            value={genFakeValue()}//{"1.one-1111"}//{filterState.type.value}
                            typeArray={basicData.typeArray}
                            onChange={(a)=>{

                                if(basicData.defaultDataFormat===a){

                                }
                                else {
                                    submitUpdateDefaultDataFormatWithFilterState(a)
                                }   


                                const temp1={...filterState[b],value:a}
                                const temp2={...filterState,[b]:temp1}
                                setFilterState(temp2)
                                
                            }}
                        />
                        }

                    </div>

                </div>

            </div>

        </div>

       
        {
        dataLabel[b].filterLineBreak
        ?<div style={{visibility:"hidden"}}>
            aa
        </div>   
        :null
        }

    </React.Fragment>
    )
}


//=====================================
const renderString=(b,idxB)=>{

    return (
    <React.Fragment
        key={idxB}
    >

        <div key={idxB}
             className={genWidthLeft()}
             style={{
                //marginBottom:"0.5rem",
                paddingTop:"0.2rem",
                paddingBottom:"0.2rem",
                //borderRadius:"0.3rem",
                backgroundColor:dataLabel[b].filterLineBackgroundColor 
            }}
        >
            <div className='flex-center-center flex-no-wrap'>
                
                <div className='xc1 h-100'
                        style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                >
                    <input
                        style={{height:"1.5rem"}}
                        type="checkbox"
                        checked={filterState[b].toCheck?"checked":""}
                        onChange={e=>{
                            const temp1={...filterState[b],toCheck:e.target.checked}
                            const temp2={...filterState,[b]:temp1}
                            setFilterState(temp2)

                        }}
                    />
                </div>

                <div className='xc3 h-100'
                        style={{display:"flex",
                            justifyContent:"flex-start",
                            alignItems:"center"
                        }}
                >
                    {
                        renderArrow(b)
                    }
                    <p className='label table-p'
                       style={{color:dataLabel[b].filterLineColor}}
                    >
                        {dataLabel[b].lb}
                    </p>

                </div>

                <div className='xc8 h-100'>
                        <div className='xc12 flex-center-baseline'>

                            <input
                                type={"text"}
                                value={filterState[b].value}
                                onChange={e=>{
                                    const temp1={...filterState[b],value:e.target.value}
                                    const temp2={...filterState,[b]:temp1}
                                    setFilterState(temp2)
                                }}
                            />

                        </div>

                </div>

            </div>

        </div>

        {
        dataLabel[b].filterLineBreak
        ?<div style={{visibility:"hidden"}}>
            aa
        </div>   
        :null
        }

    </React.Fragment>
        )
    }
//=====================================
    const renderSelectObj=(b,idxB)=>{

        const renderOption=()=>{
            return stringArray[b].map((j,idx2)=>
            <option key={`op-${idx2}`} 
                value={j}
            >
            {j}
            </option>
            )
     
        }

        return (
        <React.Fragment
            key={idxB}
        >
            <div 
                 className={genWidthLeft()}
                 style={{
                    //marginBottom:"0.5rem",
                    paddingTop:"0.2rem",
                    paddingBottom:"0.2rem",
                    //borderRadius:"0.3rem",
                    backgroundColor:dataLabel[b].filterLineBackgroundColor 
                   
                }}
            >
                <div className='flex-center-center flex-no-wrap'>
                    
                    <div className='xc1 h-100'
                            style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                    >
                        <input
                            style={{height:"1.5rem"}}
                            type="checkbox"
                            checked={filterState[b].toCheck?"checked":""}
                            onChange={e=>{
                                const temp1={...filterState[b],toCheck:e.target.checked}
                                const temp2={...filterState,[b]:temp1}
                                setFilterState(temp2)

                            }}
                        />
                    </div>
    
                    <div className='xc3 h-100'
                            style={{display:"flex",
                                justifyContent:"flex-start",
                                alignItems:"center"
                            }}
                    >
                        {
                            renderArrow(b)
                        }

                        <p className='label table-p'
                           style={{color:dataLabel[b].filterLineColor}}
                        >
                            {dataLabel[b].lb}
                        </p>
    
                    </div>
    
                    <div className='xc8 h-100'>
                        <div className='xc12 flex-center-baseline'>

                            <select
                                type={"text"}
                                value={filterState[b].value}
                                onChange={e=>{
                                    const temp1={...filterState[b],value:e.target.value}
                                    const temp2={...filterState,[b]:temp1}
                                    setFilterState(temp2)
                                }}
                            >
                                <option>{""}</option>
                                {   stringArray[b]&&
                                    renderOption()
                                }
                            </select>

                        </div>
    
                    </div>
    
                </div>
    
            </div>

           
            {
            dataLabel[b].filterLineBreak
            ?<div style={{visibility:"hidden"}}>
                aa
            </div>   
            :null
            }

        </React.Fragment>
            )
        }
    
//===========================================
const renderFooter=()=>{
    return (
    <React.Fragment>
        <div  className={genWidthLeft()}>
            <div
                style={{paddingTop:"0.2rem",marginBottom:"0.5rem"}}
                >
                    <div className='flex-center-center flex-no-wrap'>
                        
                        <div className='xc1 h-100'
                            style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                        >
                            <input
                                style={{height:"1.5rem"}}
                                type="checkbox"
                                checked={toAllTrue?"checked":""}
                                onChange={e=>{
                                    setToAllTrue(e.target.value)
                                }}
                            />
                        </div>

                        <div className='xc3 h-100'
                            style={{display:"flex",
                                    justifyContent:"flex-start",
                                    alignItems:"center",
                                    fontSize:"1.2rem"
                            }}
                        >
                            <BsIntersect style={{marginRight:"1rem"}}/>
                            {languageDisplay
                            ?languageDisplay.allTrue 
                                ?languageDisplay.allTrue 
                                :"All True"
                            :"All True"
                            }
                        </div>
                    </div>
            </div>


            <div className='xc12'
                style={{paddingTop:"0.2rem",marginBottom:"0.5rem"}}
                >
                    <div className='flex-center-center flex-no-wrap'>
                        
                        <div className='xc1 h-100'
                            style={{display:"flex",justifyContent:"center",alignItems:"center"}}
                        >
                            <input
                                style={{height:"1.5rem"}}
                                type="checkbox"
                                checked={!toAllTrue?"checked":""}
                                onChange={e=>{
                                    setToAllTrue(!e.target.value)
                                }}
                            />
                        </div>

                        <div className='xc3 h-100'
                            style={{display:"flex",
                                    justifyContent:"flex-start",
                                    alignItems:"center",
                                    fontSize:"1.2rem"
                            }}
                        >
                            <BsUnion style={{marginRight:"1rem"}}/>
                            {languageDisplay
                            ?languageDisplay.someTrue 
                                ?languageDisplay.someTrue 
                                :"Some True"
                            :"Some True"
                            }
                        </div>
                    </div>
            </div>        


            <div className='flex-center-center'>
                <button
                    style={{width:"80%",fontSize:"1.2rem"}}
                    onClick={e=>{
                        submitSearch()

                        
                        if(goAfterSearch){
                            if(prevLink==="table"){
                                refTable.current.click()
                            }
                            else if(prevLink==="album"){
                                refAlbum.current.click()
                            }
                            else if(prevLink==="calendar"){
                                refCalendar.current.click()
                            }else {
                                refTable.current.click()
                            }
                        }
                        

                    }}
                >
                    <FaSearch style={{marginRight:"1rem"}}/>
                    {languageDisplay
                        ?languageDisplay.search
                            ?languageDisplay.search
                            :"Search"
                        :"Search"
                    }
                </button>
            </div>    
        </div>    
    </React.Fragment>

    )
}

//============================================
const renderLimitRow=()=>{
    return(
            <div className={genWidthLeft()}
                 style={{paddingTop:"1rem"}}
            >
             
                
                <div className="flex-center-center flex-no-wrap" >
                    <div className="flex-center-center lc6">
                        <h4 className="table-p">{
                        languageDisplay
                        ?languageDisplay.recordPerPage
                            ?languageDisplay.recordPerPage
                            :"Records Per Page"
                        :"Records Per Page"
                        
                        }</h4>
                    </div>
                    <div className="flex-center-center lc6">
                        <div className="flex-center-center flex-no-wrap">
                      
                            <FaMinusSquare className="md-icon mb-3"
                                style={{marginRight:"1rem"}}
                                onClick={e=>{
                                    
                                    if(recordPerPage>1){
                                        const temp1=parseInt(recordPerPage)-1
                                                    
                                        setRecordPerPage(temp1)
                                    }
                                    
                                }}
                            />
                            <div className="h-100" 
                                 style={{alignItems:"flex-start"}}
                            >
                                <input
                                    type="number"
                                    value={recordPerPage}

                                    onChange={e=>{
                                        
                                        if(e.target.value===0){
                                            return
                                        }
                    
                                        if(e.target.value==="0"){
                                            return
                                        }
                                        
                                        if(e.target.value<=-1){
                                            return
                                        }

                                        if(e.target.value>maxPerPage){
                                            return
                                        }
                                        
                                        setRecordPerPage(parseInt(e.target.value))
                                        
                                    }}

                                />
                            </div>
                            
                            
                            <FaPlusSquare className="md-icon mb-3"
                                style={{marginLeft:"1rem"}}
                                onClick={e=>{
                                    
                                    if(recordPerPage<maxPerPage){
                                        const temp1=parseInt(recordPerPage)+1

                                        setRecordPerPage(temp1)
                                    }
                                    
                                }}
                            />
                        </div>   
    
                       
                    </div>
                </div>
    
                <div className="flex-center-center w-100" 
                     
                >
                  
                    <FaFileCsv className='lg-icon'
                        style={{margin:"1rem",color:"red"}}
    
                        onClick={e=>{
                            setIsCsv(true)
                        }}
                    />      
    
                    <FaRegFileExcel className='lg-icon'
                        style={{margin:"1rem",color:"green"}}
    
                        onClick={e=>{
                            setIsXls(true)
                        }}
                    />                      
    
                </div>
            </div>
    )
}
//===========================================
//===========================================
    return (
        <div style={{width:"100%",height:"100%",
                     padding:"0.5rem",
                     paddingBottom:"3rem",
                     position:"relative",
                     overflowY:"auto"
             }}>
          
            <div className="flex-center-top"
                 style={{marginBottom:"1rem"}}
            >
                
                <FaSearch
                    style={{
                        fontSize:"2rem",
                        marginRight:"1rem"
                    }}
                />
                <h3>
                    {languageDisplay
                    ?languageDisplay.search
                        ?languageDisplay.search
                        :"Search"
                    :"Search"
                    }
                </h3>
            </div>

            
            <div className="flex-center-center jc-start"
            >
                {   dataLabel&&
                    keyObjectArray.map((b,idxB)=>{

                        const key0_5=b.substring(0,5)

                        
                        if(b==="id"&&
                        dataLabel[b].showFilterLine){
                            return renderBetweenNumber(b,idxB)
                        }
                        else if((key0_5==="itemN"||
                                 b==="monthlyFee"||
                                 b==="thisMonthDiscount"||
                                 b==="remainingBalance"||
                                 b==="userId"||
                                 b==="transferAmount"||
                                 b==="addMonth"||
                                 b==="newRemainingBalance"||
                                 b==="overdueBalance"
                                )&&
                        dataLabel[b].showFilterLine){
                            return renderBetweenNumber(b,idxB)
                        }
                        else if(b==="type"&&
                        dataLabel[b].showFilterLine){
                            return renderType(b,idxB)
                        }
                        /*
                        dataLabel[b].showFilterLine){
                         if(dataLabel[b].stringInputType==="selection"){
                             return renderSelectObj(b,idxB)
                         }
                         else {
                             return renderString(b,idxB)
                         }
                         */
                        
                        else if((key0_5==="itemS"||
                                 b==="shopName"||
                                 b==="shopPassword"||
                                 b==="code"||
                                 b==="ownerName"||
                                 b==="ownerSurname"||
                                 b==="ownerEmail"||
                                 b==="recoveryPassword"||
                                 b==="shopId"||
                                 b==="username"||
                                 b==="userPassword"||
                                 b==="code"||
                                 b==="userLevel"||
                                 b==="name"||
                                 b==="surname"||
                                 b==="shopTarget"||
                                 b==="accountName"||
                                 b==="accountNumber"||
                                 b==="fromBank"||
                                 b==="checkBy"||
                                 b==="ownerName"||
                                 b==="ownerSurname"
                            
                                )&&
                                dataLabel[b].showFilterLine){
                         if(dataLabel[b].stringInputType==="selection"){
                             return renderSelectObj(b,idxB)
                         }
                        
                         else {
                             return renderString(b,idxB)
                         }
                        }
                        else if(
                            (b==="createdAt"||b==="updatedAt")
                            &&dataLabel[b].showFilterLine){
                            return renderBetweenDate(b,idxB)
                        }
                        else if((key0_5==="itemD"||
                                 b==="timestamp"||
                                 b==="dateIn"||
                                 b==="expirationDate"||
                                 b==="tempExpirationDate"||
                                 b==="transferDate"||
                                 b==="newExpirationDate"
                                )&&
                                dataLabel[b].showFilterLine){
                            return renderBetweenDate(b,idxB)
                        }
                        else if
                                (b==="active"&&dataLabel[b].showFilterLine)
                            {
                            return renderBoolean(b,idxB)
                        }
                        else if((key0_5==="itemB"||
                                 b==="active"||
                                 b==="toDelete"||
                                 b==="isCheck"||
                                 b==="hasProblem"||
                                 b==="newSignUp"||
                                 b==="forgetShopPassword"
                                )&&
                                dataLabel[b].showFilterLine){
                            return renderBoolean(b,idxB)
                        }
                       
                    })
                }
              
            </div>

            {renderLimitRow()}

            {renderFooter()}

            <div className="d-none">
                <Link ref={refTable} to="/cloud/table"/>
                <Link ref={refAlbum} to="/cloud/album"/>
                <Link ref={refCalendar} to="/cloud/calendar"/>

                <Link ref={refHome} onClick={()=>{
                   //window.location.href = 'http://localhost:3000/home'
                }}/>    
            </div>

        </div>
    )

}

export default FilterInput