

import React from 'react'
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import console from '../../util/consoleVerify'

function DeleteDataFormatModal ({
    submitCheck,
    submitCancel,
    deleteDataFormat,
    setDeleteDataFormat,

    
    languageDisplay
}){

    return(
    <div className="Modal-background">
        <div className="Modal-box2"
        >
            <div className="Modal-header">
                <div>
                    {
                    languageDisplay
                    ?languageDisplay.deleteDataFormatModal
                        ?languageDisplay.deleteDataFormatModal
                        :"Delete Data Format"
                    :"Delete Data Format"
                            
                    }
                                    </div>
            </div>
          
            <div className="Modal-footer">
                <div>
                    <button
                        onClick={()=>{
                            submitCheck()
                        }}
                     
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button                       
                        onClick={()=>{
                            submitCancel()
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
          
        </div>
    </div>
    )

}

export default DeleteDataFormatModal