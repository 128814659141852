import axios from 'axios'

import console from './consoleVerify'
import axiosUtil from './axiosUtil'

//const {catchErrorToMessage}=axiosUtil

const pattern1=/^[\w@#&_-]*$/gi

const valBasic= (pttn,str)=>{
      const temp= new RegExp(pttn).test(str)
      return temp
}

const genMyTk=(scrt)=>{
    //console.log('genMyTk')
    const getLastNumber=(str,n)=>{
          //str=123456789
          //n=-3
          //numAddYear=789 ==> last 3 of string 
          const tempArrayLength=str.length
          const textAddYear=str.substring(tempArrayLength+n,tempArrayLength)
          const numAddYear=parseInt(textAddYear)
          return numAddYear
    }

    function makeid(n) {
          //makeid(5)
          //bNJwg ==>
          var text = "";
          var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        
          for (var i = 0; i < n; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        
          return text;
        }
    
    const rndInt=(n)=>{
          //rndInt(10)
          //==> 1,2,3,...10

          const temp=Math.floor(Math.random() * n) + 1
          return temp
    }
    
    const rndBool=()=>{
          //rndBool 
          //true or false
          if(Math.random() >= 0.5){
                return true
          }
          else {
                return false
          }
    }
    
    const addYear=(yrs)=>{
          //yrs = 5
          //2023-09-28T14:10:12.395Z
          //addYear(5)=1853763012395
          //new Date(1853763012395)==>2028-09-28T14:10:12.395Z

          const toDay=new Date()
          const thisYear=toDay.getFullYear()
          const nextYear=thisYear+yrs
          const nextDay=toDay.setFullYear(nextYear)
          return nextDay
    }

    //4798100208569
    //4798100208569
    const mixUp=(today)=>{
          //console.log(today)
          const textToday=today.toString()
          const tempTextTodayArray=Array.from(textToday);

          //console.log("tempTextTodayArray")
          //console.log(tempTextTodayArray)


          let finalText=''
          tempTextTodayArray.map(i=>{
                
                let tempMixText=''
                if(rndBool){
                     tempMixText=tempMixText+makeid(rndInt(4))
                }

                finalText=finalText+i+tempMixText
          })

          return finalText
    }

    const toKen=mixUp(addYear(getLastNumber(scrt,-3)))   
    // genMyTk=(scrt)=>{....}
    // REACT_APP_KEY = '6LcCvsQdAAAAAAqWKtgK3Tb6l3LYBjtCVrl103'
    // genMyTk(process.env.REACT_APP_KEY)
    // getLastNumber(scrt,-3)==> 103
    // tempLastNumber
    // 103
    // toDay
    // 2023-09-28T14:37:46.343Z
    // tempAddYear
    // 4946279866343
    // new Date(tempAddYear)
    // 2126-09-28T14:37:46.343Z
    
    //tempTextTodayArray
    //['4', '9', '4', '6','2', '7', '9', '8','6', '6', '3', '4','3']
    //toKen===>
    //4x9qR4iOFW6N2xf7p9K8J6W6W3Ujyd4C3gqFQ
    //4 9  4    6 2  7 9 8 6 6 3    4 3  

    return toKen
}


const checkUnauthorized=(error)=>{
    let tempResult=false
    
    /*
    const temp=catchErrorToMessage(error)

    //console.log('checkUnauthorized')
    //console.log(temp)

    if(temp.includes("Unauthorized-user")||
       temp.includes("jwt")
    ){
        localStorage.removeItem('userauthorization');
        localStorage.removeItem('username');
        //setReloadCheckToken(true)
        tempResult=true
    }
    if(temp.includes("Unauthorized-shop")){
        localStorage.removeItem('shopauthorization');
        //setReloadCheckToken(true)
        tempResult=true
    }
    */
    return tempResult
}




const axiosPost=(route,reqBody,header,shouldRender)=>{
        //console.log('axiosPost...............')
        //console.log('route')
        //console.log(route)
        //console.log('reqBody')
        //console.log(reqBody)
        //console.log('header')
        //console.log(header)
            let isVerify=true

            const tempKeyArray=Object.keys(reqBody)

            tempKeyArray.map(i=>{
                const tempType=typeof(reqBody[i])

                if(tempType=="number"||tempType=="boolean"){
                    if(reqBody[i].toString().length>process.env.REACT_APP_LIMIT_REQ_BODY_NUMBER_LENGTH){
                        isVerify=false
                    }
                }
                else if(tempType=="string"){
                    if(reqBody[i].length>process.env.REACT_APP_LIMIT_REQ_BODY_STRING_LENGTH){
                        isVerify=false
                    }
                    else{
                        if(i=="shopName"||i=="username"||i=="password"||
                        i=="ownerPassword"||i=="newPassword1"||i=="newPassword2"){

                            const temp=valBasic(pattern1,reqBody[i])
                            if(!temp){
                                isVerify=false
                            }
                            
                        }
                    }
                }
            })

            //console.log('shouldRender')
            //console.log(shouldRender)

            if(isVerify){
                let tempShouldRender=true

                if(shouldRender){
                    if(shouldRender.current===false){
                        tempShouldRender=false
                    }
                }
        

                let tempHeader=header
                if(!header){
                    tempHeader={headers: {'Content-Type': 'application/json'}}
                }

                const appToken=genMyTk(process.env.REACT_APP_KEY)
                ///console.log(appToken)
                tempHeader={headers:{...tempHeader.headers,
                            "appauthorization":appToken
                        }}

                return new Promise((resolve,reject)=>{

                    axios.post(route,reqBody,tempHeader)
                    .then(result=>{
                        //console.log('result axiosVerify')
                        //console.log(result)
                        if(tempShouldRender){
                            resolve(result)
                        }
                    })
                    .catch(error=>{
                        
                        const temp=checkUnauthorized(error)
                        if(temp){
                            window.location.reload()
                        }

                        if(tempShouldRender){
                            reject(error)
                        }
                    })

                })
            }
            else {
                return new Promise((resolve,reject)=>{
                    reject({message:"Invalid Verification"})
                })
            }
}


const axiosGet=(route,header)=>{

    
    let tempHeader=header
    if(!header){
        tempHeader={headers: {'Content-Type': 'application/json'}}
    }

    const appToken=genMyTk(process.env.REACT_APP_KEY)
    ///console.log(appToken)
    tempHeader={headers:{...tempHeader.headers,
                       "appauthorization":appToken
                }}


    return new Promise((resolve,reject)=>{

        axios.get(route,tempHeader)
        .then(result=>{
            resolve(result)
        })
        .catch(error=>{

            //const temp=catchErrorToMessage(error)
            

            const temp=checkUnauthorized(error)
            if(temp){
                window.location.reload()
            }
            //console.log('error..........')
            //console.log(temp)

            //localStorage.removeItem('userauthorization');
            //localStorage.removeItem('username');
            //setReloadCheckToken(true)
            //window.location.reload()

            reject(error)
        })

    })
}

//grep -rl "../../axiosVerify" src| xargs sed -i "s/../../axiosVerify/axios'/g"

const axiosVerify={post:axiosPost,
                   get:axiosGet
                   }

export default axiosVerify
  
/*






const getMyDate=(tk,srct)=>{

    const getLastNumber=(str,n)=>{
          const tempArrayLength=str.length
          const textAddYear=str.substring(tempArrayLength+n,tempArrayLength)
          const numAddYear=parseInt(textAddYear)
          return numAddYear
    }

    const dateValue = parseInt(tk.replace(/\D+/g, ''))
    const tkYear=new Date(dateValue).getFullYear()-getLastNumber(srct,-3)

    const clientDay=new Date(dateValue).setFullYear(tkYear)

    return new Date(clientDay)
}

const checkDateUnderScope=(sendDate,minuteScope)=>{
    function addMinutes(date, minutes) {
          return new Date(date.getTime() + minutes*60000);
    }

    const rightNow=new Date()

    if(rightNow>addMinutes(new Date(sendDate),0-minuteScope) && rightNow<addMinutes(new Date(sendDate),minuteScope)){
         return true
    }
    else{
          return false
    }
}






*/