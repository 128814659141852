import React from 'react';
import {MdErrorOutline} from 'react-icons/md'
import {FaBan,FaRegCheckCircle} from 'react-icons/fa'
import console from '../../util/consoleVerify'
//import './Modal.css'

const ModalSuccess=({
    showSuccess,setShowSuccess,
    messageSuccess,setMessageSuccess,

    languageDisplay,
    

})=>{
    //console.log('renderModalError')
    //console.log(message)
return(
    <div className="Modal-background">
        <div className="Modal-box2">
            <div className="Modal-header">
                <div>
                    <FaRegCheckCircle style={{fontSize:"2.5rem",color:"green"}}/>
                </div>
            </div>
            <div className="Modal-body">
                <div className ="flex-center-center">
                    {
                    messageSuccess?<div>{messageSuccess}</div>:null
                    }
                </div>
            </div>
            <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                            setShowSuccess(false)
                            //if(setMessage){setMessage(null)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
            </div>

        </div>
    </div>
    
)}



export default ModalSuccess
               