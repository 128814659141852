import dataTemplate from './dataTemplate'
//import uuid from 'react-uuid'
import console from '../../util/consoleVerify'

import uuid from 'react-uuid'
import download from 'js-file-download'
import * as XLSX from 'xlsx';

//import console from '../../util/consoleVerify'

const {mData}=dataTemplate

const escapeRegexSpecialChar=(str)=>{
  let tempStr=str

  if(str){
    let temp2=''
    Array.from(str).map(j=>{
        if(
          //j==="."||
          j==="*"||
          j==="+"||
          j==="?"||
          j==="{"||
          j==="}"||
          j==="["||
          j==="]"||
          j==="("||
          j===")"||
          j==="|"||
          j==="^"||
          j==="$")
        {
          temp2=temp2+"\\"+j
        }
        
        else{
          temp2=temp2+j
        }
          
    })
    
    tempStr=temp2
  }

  return tempStr
}

const genArrayCommandAndSortForFilterAxios=({
    stringArray,booleanArray,
    numberArray,dateArray,
    toSortUp,toSortDown,
    filterState})=>{

    const limitStringLength=process.env.REACT_APP_LIMIT_FILTER_STRING_LENGTH

    let arrayCommand=[]
    let isVerify=true

    console.log("genArrayForFilterAxios")
    
    let sortUp=null
    let sortDown=null
   
    stringArray.map((k,idxK)=>{
        
            if(filterState[k].toCheck){
                if(filterState[k].value){  
                    if(filterState[k].value.length>limitStringLength){
                        isVerify=false
                    }
                }
                else {
                    isVerify=false
                }

                if(isVerify){
                    const tempStrTrim=filterState[k].value.trim()
                    const tempStr2=escapeRegexSpecialChar(tempStrTrim)

                    const temp ={[k]:
                        {
                        //$regex:filterState[i.keyName].value.trim().replace(/\s/,"|"),
                        //$regex:filterState[i.keyName].value.trim(),
                        $regex:tempStr2,
                        $options:'gi' }
                    }
                    arrayCommand=[...arrayCommand,temp]  
                }
                
            }
        

    })

    numberArray.map((k,idxK)=>{

        if(filterState[k].toCheck){


            if(k==="type"){
                const tempValue=parseFloat(filterState[k].value)

                if(isVerify){
                    const temp ={[k]:
                            {$eq:tempValue}
                        }
                        arrayCommand=[...arrayCommand,temp]  
                }
            }

            else {

                const tempMin=parseFloat(filterState[k].min)
                const tempMax=parseFloat(filterState[k].max)

                if(tempMin||tempMin===0){     
                    if(tempMin>process.env.REACT_APP_LIMIT_FILTER_NUMBER_MIN){
                    }
                    else {
                        isVerify=false
                    }
                }

                if(tempMax||tempMax===0){
                    if(tempMax<process.env.REACT_APP_LIMIT_FILTER_NUMBER_MAX){
                    }
                    else{
                        isVerify=false
                    }
                }

                if(isVerify){
                const temp ={[k]:
                        {$gte:tempMin,$lte:tempMax}
                    }
                    arrayCommand=[...arrayCommand,temp]  
                }

            }                
           
        }
    })  

    booleanArray.map((k,idxK)=>{

        if(filterState[k].toCheck){
              if(filterState[k].value){  
                  if(filterState[k].value.length>limitStringLength){
                    isVerify=false
                  }
              }
              if(isVerify){
                  const temp ={[k]:
                      filterState[k].value
                    }            
                  arrayCommand=[...arrayCommand,temp]  
              }

        }
    })


    dateArray.map((k,idxK)=>{

        if(filterState[k].toCheck){
              if(!filterState[k].min){
                isVerify=false
              }

              if(!filterState[k].min){
                isVerify=false
              }

          
              if(isVerify){
                const temp ={[k]:
                    {$gte:filterState[k].min,$lte:filterState[k].max}
                  }
                  arrayCommand=[...arrayCommand,temp]  
              }

            
        }
  })  

  let tempSort={["id"]:-1}
  if(toSortUp){
      tempSort={[toSortUp]:-1}
  }
  else if(toSortDown){
      tempSort={[toSortDown]:1}
  }
  
  return {arrayCommand,sort:tempSort}

}




//const linePerPage=4

const getDataLength=()=>{
    return mData.length
}

const getLimitRow=(linePerPage)=>{
    return linePerPage
}
   
const getLimit=(page,linePerPage)=>{
    const totalLength=mData.length

    let tempArray=[]
    let tempSubArray=[]


    for(let i=0;i<totalLength;i++){
        const remainder=(i+1)%linePerPage

        if(remainder===0){
            tempSubArray=[...tempSubArray,mData[i]]
            tempArray=[...tempArray,tempSubArray]
            tempSubArray=[]
        }
        else{
            tempSubArray=[...tempSubArray,mData[i]]
        }
        
    }

    if(tempSubArray.length>0){
        tempArray=[...tempArray,tempSubArray]
    }

    return tempArray[page-1]

}


const findToltalPage=(dataLength,limitRow)=>{
    
    const temp1=dataLength%limitRow

    let temp2

    if(temp1){
        temp2=Math.floor(dataLength/limitRow)+1
    }
    else {
        temp2=dataLength/limitRow
    }

    return temp2
    
}


const deleteById=(editData)=>{
    console.log(`deleteById=${editData.id}`)
}

//==============================================
//==============================================


const genXlsx=(arrayData,tableTemplate,setIsXls)=>{

    if(!arrayData){return}
    if(!tableTemplate){return} 
  
    const keys = Object.keys(tableTemplate)
    let keys2=[]
    keys.map(i=>{
        if(tableTemplate[i].showCol){
            keys2=[...keys2,i]
        }
    })
    

    let tempFinalArray=[]
    arrayData.map((a,idxA)=>{
        //console.log('item')
        //console.log(item)
        let tempObj={}
        let myJson
        keys2.map((k,idxK)=>{
            let temp=a[k]
            
            const key0_5=k.substring(0,5)

            if(key0_5==="itemD"){
                    temp=new Date(temp).toLocaleString()
            }
            //console.log(`key : ${key}, itemKey: ${itemKey}`)

            const tempLb=tableTemplate[k].lb
            tempObj={...tempObj,[tempLb]:k }
            //myJson=JSON.stringify(tempObj)
        })

        tempFinalArray=[...tempFinalArray,tempObj]
    })

    //console.log(tempFinalArray)
    //const myJsonData=JSON.stringify(tempFinalArray)

    /*
    console.log(myJsonData)
    
    const myData=[
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"},
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"},
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"},
        {"name":"John", "city": "Seattle"},
        {"name":"Mike", "city": "Los Angeles"},
        {"name":"Zach", "city": "New York"},
    ];

    
    const downloads =(data)=>{
        console.log('downloads')
        console.log(data)
        let ws = XLSX.utils.json_to_sheet(data)
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "sheet")
        let buf = XLSX.write(wb, {bookType:'xlsx',type:'buffer'})
        let str = XLSX.write(wb, {bookType:'xlsx',type:'binary'})
  
        XLSX.writeFile(wb,`${Date.now()}.xlsx`)
    }
    */

    //downloads(myData)

    
    const downloads2 =async(tempFinalArray)=>{
        //console.log(tempFinalArray)
        //console.log('0')
        //const data=JSON.stringify(tempFinalArray)
        //console.log('1')
        //console.log(data)

        let ws = XLSX.utils.json_to_sheet(tempFinalArray)
        //console.log('2')
        let wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, "sheet")
        let buf = XLSX.write(wb, {bookType:'xlsx',type:'buffer'})
        let str = XLSX.write(wb, {bookType:'xlsx',type:'binary'})
  
        XLSX.writeFile(wb,`${Date.now()}.xlsx`)
    }
    downloads2(tempFinalArray)
  
    setIsXls(false)
 }


 

 const genCsv=(arrayData,tableTemplate,setCsv)=>{

    //console.log('genCsv')
    //console.log(filterData)
    //console.log('tableTemplateName')
    //console.log(tableTemplateName)

    if(!arrayData){return}
    if(!tableTemplate){return}
     
    //(tableTemplateName=="productStockTableTemplate")
    //console.log('tempFilterData=tempArray')
    //console.log(tempFilterData)

    const keys = Object.keys(tableTemplate)
    let keys2=[]
    keys.map(i=>{
        if(tableTemplate[i].showCol){
        keys2=[...keys2,i]
        }
    })
          
    //console.log('keys2')
    //console.log(keys2)

    const columnDelimiter = ","
    const lineDelimiter = "\n"
    
    //const keys = Object.keys(filterData.data0[0])

    let result = ""
    result += keys2.join(columnDelimiter)
    result += lineDelimiter
 
    arrayData.map((item,indexItem)=>{
        keys2.map((key,indexKey)=>{

        if(indexKey>0){
                result+=columnDelimiter
        
        }

        let itemKey=item[key]

        const key0_5=key.substring(0,5)

        if(key0_5=="itemD"){
            if(itemKey){
                itemKey=new Date(itemKey).toLocaleString()
            }
        }

        result += typeof itemKey === "string"&& 
                    itemKey.includes(columnDelimiter) 
                    ? `"${itemKey}"` 
                    : itemKey



        })

        result += lineDelimiter
    })

    //console.log(result)
    download(result,`${Date.now()}.csv`)
          //download(result,`${pageFilterForm.formHead}${filterData.pageNumber}.csv`)
    setCsv(false)
 }
 

const fakeAxiosUtil={
    getLimit,
    getDataLength,
    getLimitRow,
    findToltalPage,
    deleteById,
    genCsv,
    genXlsx,
    genArrayCommandAndSortForFilterAxios
}


export default fakeAxiosUtil