import React from 'react'
import cosmeticUtil from '../../util/cosmeticUtil'

import {MdOutlineSettingsSuggest} from 'react-icons/md';


import {MainContext} from '../../context/MainContext'
import MnavbarMaster from '../../component/mnavbar/MnavbarMaster'
import RenderBadgeMaster from './RenderBadgeMaster'
import FilterInput from './FilterInput'
import FilterSetting from './FilterSetting'
import ModalAddPage from './ModalAddPage'

import ModalEditPage from './ModalEditPage'
import ModalConfirm from './ModalConfirm'
import ModalAddPageSetting from './ModalAddPageSetting'


import Table from './Table'
import TableSetting from './TableSetting'
import console from '../../util/consoleVerify'
import ModalShopLogInWithRecoveryPassword from './ModalShopLogInWithRecoveryPassword';


const {checkWindowLandscape}=cosmeticUtil


function MasterComponent ({
 
        maxPerPage,

        dataLabelShop,setDataLabelShop,
        dataLabel2,setDataLabel2,

        recordPerPageForShop,setRecordPerPageForShop,
        toSortUpForShop,setToSortUpForShop,
        toSortDownForShop,setToSortDownForShop,
        toAllTrueForShop,setToAllTrueForShop,
        filterStateForShop,setFilterStateForShop,

        lastRecordIdForShop,setLastRecordIdForShop,
        
        currentPageForShop,setCurrentPageForShop,
        totalPageForShop,setTotalPageForShop,
        countRecordForShop,setCountRecordForShop,

        submitSearchForShop,
        submitSearchForSearchButtonForShop,

        submitLogInWithRecoveryPasswordByMaster,

        submitAddForShop,
        submitDeleteForShop,
        submitEditForShop,

        submitEditWithNewIdForShop,
        submitCopyRecordForShop,
        
        submitUpdateShopFromShopPayment,
        
        stringArray,
        setIsXls,
        setIsCsv,

        shopData,setShopData,
        editShop,setEditShop,
        
        prevLink,setPrevLink,
        currentLink,setCurrentLink,

        submitDataFormat,
        dfCommon,setDfCommon,

        showFilterSettingForShop,setShowFilterSettingForShop,
        showTableSettingForShop,setShowTableSettingForShop,
        showAddPageSettingForShop,setShowAddPageSettingForShop,

        showModalAddPageForShop,setShowModalAddPageForShop,
        showModalEditPageForShop,setShowModalEditPageForShop,
        showModalConfirmForShop,setShowModalConfirmForShop,
        
        keyArrayFilterInput,
        keyArrayFilterInputSetting,
        
        keyArrayTable,
        keyArrayTableSetting,

        keyArrayModalAdd,
        keyArrayModalAddSetting,

        keyArrayModalEdit,
        
        //keyArrayWithPhotoUrl,
        widthRight,setWidthRight,
        basicDataSt,

        blankAddInputState,
        addInputState,setAddInputState,
        editInputState,setEditInputState,
        showPhoto,

        matchEditDataField,

        languageDisplay,

        useUpdateShopAfterShopPayment

    }){

    console.log("MasterComponent")


    const [ showModalShopLogInWithRecoveryPassword,
            setShowModalShopLogInWithRecoveryPassword
          ]=React.useState(false)

    return (
        <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
           
            <div style={{
                    height:checkWindowLandscape()?"5%":"8%",width:"100%",
                }}>
                <MnavbarMaster         
                    setPrevLink={setPrevLink}          
                    setCurrentLink={setCurrentLink}
                />
            </div>

            <div style={{
                    height:checkWindowLandscape()?"5%":"8%",width:"100%",
                }}>
                <RenderBadgeMaster
                    widthRight={widthRight}
                    setWidthRight={setWidthRight}
                    
                    showTableSettingForShop={showTableSettingForShop}
                    setShowTableSettingForShop={setShowTableSettingForShop}

                    showFilterSettingForShop={showFilterSettingForShop}
                    setShowFilterSettingForShop={setShowFilterSettingForShop}

                    showModalAddPage={showModalAddPageForShop}
                    setShowModalAddPage={setShowModalAddPageForShop}

                    showModalEditPage={showModalEditPageForShop}
                    setShowModalEditPage={setShowModalEditPageForShop}

                    showModalConfirm={showModalConfirmForShop}
                    setShowModalConfirm={setShowModalConfirmForShop}
                   
                    showAddPageSettingForShop={showAddPageSettingForShop}
                    setShowAddPageSettingForShop={setShowAddPageSettingForShop}

                    showModalShopLogInWithRecoveryPassword={showModalShopLogInWithRecoveryPassword}
                    setShowModalShopLogInWithRecoveryPassword={setShowModalShopLogInWithRecoveryPassword}

                    editInputState={editShop}

                    totalPageForShop={totalPageForShop}
                    currentPageForShop={currentPageForShop}
                    setCurrentPageForShop={setCurrentPageForShop}
                    countRecordForShop={countRecordForShop}
                    


                />
            </div>


            <div style={{ display:"flex",
                      height:checkWindowLandscape()?"90%":"84%",width:"100%"}}
            >
            
                <div style={{height:"100%",
                             width:`${widthRight}%`,
                             border:""
                            }}
                >
                                        
                    {
                    showTableSettingForShop
                    ?<TableSetting
                        dataLabel={dataLabelShop}   
                        setDataLabel={setDataLabelShop}
                        submitDataFormat={submitDataFormat}
                        setShowTableSetting={setShowTableSettingForShop}
                        dfCommon={dfCommon}
                        setDfCommon={setDfCommon}
                        keyArray={keyArrayTableSetting}
                    />

                    :<FilterInput
                        goAfterSearch={false}

                        dataLabel={dataLabelShop}
                        setDataLabel={setDataLabelShop}
                        stringArray={{}}
                        prevLink={prevLink}

                        filterState={filterStateForShop}
                        setFilterState={setFilterStateForShop}

                        recordPerPage={recordPerPageForShop}
                        setRecordPerPage={setRecordPerPageForShop}

                        toSortUp={toSortUpForShop}
                        setToSortUp={setToSortUpForShop}
                        toSortDown={toSortDownForShop}
                        setToSortDown={setToSortDownForShop}
                        toAllTrue={toAllTrueForShop}
                        setToAllTrue={setToAllTrueForShop}

                        maxPerPage={maxPerPage}
                    
                        setIsCsv={setIsCsv}
                        setIsXls={setIsXls}

                        submitSearch={submitSearchForSearchButtonForShop}
                        keyArray={keyArrayFilterInput}

                        basicData={basicDataSt.basicData}
                        
                    />
                    }
                </div>

            
            
                <div
                  style={{height:"100%",
                    width:`${100-widthRight}%`,
                   }}
                >           
                     {
                     dataLabelShop
                     ?showFilterSettingForShop
                      ?<FilterSetting
                            dataLabel={dataLabelShop}
                            setDataLabel={setDataLabelShop}
                            submitDataFormat={submitDataFormat}
                            setShowFilterSetting={setShowFilterSettingForShop}
                            dfCommon={dfCommon}
                            setDfCommon={setDfCommon}
                            keyArray={keyArrayFilterInputSetting}
                        />
                      :<Table
                        dataLabel={dataLabelShop}
                        setDataLabel={setDataLabelShop}
                        pageData={shopData}
                        setPageData={setShopData}
                        editData={editShop}
                        setEditData={setEditShop}
                        keyArray={keyArrayTable}
                        matchEditDataField={matchEditDataField}
                      />
                     :null
                     }
                </div>
            
            </div>
        
        
            {
            showModalAddPageForShop&&
             <ModalAddPage
                setShow={setShowModalAddPageForShop}
                addInputState={addInputState}
                setAddInputState={setAddInputState}
                currentLink={currentLink}
                prevLink={prevLink}
                blankAddInputState={blankAddInputState}
                stringArray={stringArray}
                lastRecordId={lastRecordIdForShop}
                submitAdd={submitAddForShop}
                dfCommon={dfCommon}
                setDfCommon={setDfCommon}
                keyArray={keyArrayModalAdd}
                basicDataSt={basicDataSt}
                dataLabel2={dataLabelShop}
                setCancel={setShowModalAddPageForShop}
                showPhoto={showPhoto}
             />
            }

            {
            showModalEditPageForShop&&
             <ModalEditPage
                setShow={setShowModalEditPageForShop}
                editInputState={editInputState}
                setEditInputState={setEditInputState}
                currentLink={currentLink}
                prevLink={prevLink}
                stringArray={stringArray}
                lastRecordId={lastRecordIdForShop}
                submitEdit={submitEditForShop}
                submitEditWithNewId={submitEditWithNewIdForShop}
                submitCopyRecord={submitCopyRecordForShop}
                
          
                dfCommon={dfCommon}
                setDfCommon={setDfCommon}
                keyArray={keyArrayModalEdit}
                basicDataSt={basicDataSt}
                dataLabel2={dataLabelShop}
                setCancel={setShowModalEditPageForShop}
                showPhoto={showPhoto}

                useUpdateShopAfterShopPayment={useUpdateShopAfterShopPayment}

                submitUpdateShopFromShopPayment={submitUpdateShopFromShopPayment}
             />
            }

            { showModalAddPageForShop&&
             <MdOutlineSettingsSuggest
                className='md-icon'
                style={{
                    position:"fixed",
                    top:"1rem",right:"2rem",
                    zIndex:"200"
                }}

                onClick={()=>{
                    setShowAddPageSettingForShop(true)
                }}
             />
            }

            {showAddPageSettingForShop&&
                <ModalAddPageSetting
                    showAddPageSettingForShop={showAddPageSettingForShop}
                    setShowAddPageSettingForShop={setShowAddPageSettingForShop}
                    dfCommon={dfCommon}
                    setDfCommon={setDfCommon}
                    keyArray={keyArrayModalAddSetting}
                
                    dataLabel2={dataLabelShop}
                    setDataLabel2={setDataLabelShop}
                    submitDataFormat={submitDataFormat}
                />
            }

            {showModalConfirmForShop&&
             <ModalConfirm
                setShow={setShowModalConfirmForShop}
                submitFunction={submitDeleteForShop}
             />
            }

            {showModalShopLogInWithRecoveryPassword&&
                <ModalShopLogInWithRecoveryPassword
                    setShow={setShowModalShopLogInWithRecoveryPassword}

                    submitFunction={submitLogInWithRecoveryPasswordByMaster}
                    languageDisplay={languageDisplay}
                />
            }
        </div>


        
    )

}

export default MasterComponent