import React from 'react'

import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'
import cosmeticUtil from '../../util/cosmeticUtil'


import {MdRefresh,MdSwapHoriz,MdSettings,
    MdSearch,MdEdit,MdAddCircle,MdDelete,MdPrint,
    MdChevronLeft,MdChevronRight,
    MdLastPage,MdFirstPage,
    MdRadioButtonChecked,MdClose,
} from 'react-icons/md';

import { FaBullseye, FaRegArrowAltCircleUp} from 'react-icons/fa';
import Ticon from '../../component/ticon/Ticon'

const {checkWindowLandscape}=cosmeticUtil


const goToFirst=(currentPage,totalPage,setCurrentPage)=>{
    setCurrentPage(1)
}
const goToPrev=(currentPage,totalPage,setCurrentPage)=>{
    if(currentPage>1){
        setCurrentPage(currentPage-1)
    }
}
const goToNext=(currentPage,totalPage,setCurrentPage)=>{
    if(currentPage<totalPage){
        setCurrentPage(currentPage+1)
    }
}
const goToLast=(currentPage,totalPage,setCurrentPage)=>{
        setCurrentPage(totalPage)
}

const goToPageNumber=(currentPage,totalPage,setCurrentPage)=>{

}

const RenderBadge = ({
    totalPage,
    currentPage,
    setCurrentPage,
    editInputState,
    setEditInputState,
    setShowConfirm,
    badgeColor,
    submitSearch,
    countRecord

})=>{
   
    const refEdit=React.createRef()
    const refBarcode=React.createRef()

    
    const refRefresh=React.createRef()

    //const [tempCurrentPage,setTempCurrentPage]=React.useState(currentPage)

    //React.useEffect(()=>{
    //    if(tempCurrentPage>0&&tempCurrentPage<=totalPage){
    //        setCurrentPage(tempCurrentPage)
    //    }
    //},[tempCurrentPage])


    return (
    <div className="badge-frame-xc12"
        style={{background:badgeColor,
                width:"100%",
            }}
    > 
        
        <div className="flex-center-center flex-no-wrap xc12 jc-start"
              style={{justifyContent:"flex-start",
                      overflowX:"auto",
                      paddingRight:"5rem"
              }}>

           
            <div>
                 <MdFirstPage
                    className="sm-icon"
                    onClick={e=>{
                        goToFirst(currentPage,totalPage,setCurrentPage)
                  
                    }}
                 />
            </div>   
            


            <div>
                <MdChevronLeft
                    className="sm-icon"
                    style={{visible:"visible"
                           
                        }}
                    onClick={e=>{
                        goToPrev(currentPage,totalPage,setCurrentPage)
             
                    }}
                />
            </div>
            
                <input 
                type="number"
                style={{width:"5rem"}}
                value={currentPage
                    //pageNumber.toString()
                }
                onChange={e=>{
                    if(e.target.value>totalPage){
                        return 
                    }
                    
                    if(e.target.value===0){
                        return
                    }

                    if(e.target.value==="0"){
                        return
                    }
                    
                    if(e.target.value<=-1){
                        return
                    }
                    
                    setCurrentPage(e.target.value)
                    
                }}
              />
         
            <div style={{
                paddingTop:"0rem",
                width:"5rem",
            }}>
                    <div
                        style={{fontSize:"0.9rem"}}
                    >
                        {
                        `${totalPage}`
                        }
                    </div>
                    {<div style={{fontSize:"0.7rem"}}>
                        {countRecord
                        ?`(${countRecord})`
                        :`(-)`
                        }
                    </div>
               
                    }
            </div>
           

            <div>
                <MdChevronRight
                    className="sm-icon"
                    onClick={e=>{
                        goToNext(currentPage,totalPage,setCurrentPage)
                       
                    }}
                />
            </div>
            
            <div>
                 <MdLastPage
                    className="sm-icon"
                    style={{marginRight:"0.5rem"}}
                    onClick={e=>{
                        goToLast(currentPage,totalPage,setCurrentPage)
                        
                    }}
                 />
            </div>   
            
          
            <div className="iconbox"

                onClick={e=>{
                   submitSearch()
                }}
            >   
                <Ticon
                    iconName="MdRefresh"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>

           {editInputState&&
           <div className="iconbox"
                onClick={e=>{
                   
                    if(editInputState){
                        refEdit.current.click()
                    }
                }}
            >   
                <Ticon
                    iconName="MdEdit"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
          
            {editInputState&&
            <div className="iconbox"
                onClick={e=>{
                    setShowConfirm(true)
     
                }}
            >
                <Ticon
                    iconName="MdDelete"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
         
            {editInputState&&
            <div className="iconbox"
                onClick={e=>{
                    
                    if(editInputState){
                        refBarcode.current.click()
                    }
                  
                }}
            >
                <Ticon
                    iconName="AiOutlineFileText"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
      
            
            {/*
            editInputState&&
            <div className="iconbox"
                onClick={e=>{
                    if(editInputState){
                        refForm.current.click()
                    }                   
                }}
            >
                <Ticon
                    iconName="AiOutlineFileImage"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            */}


            <div className="d-none">
                <Link ref={refEdit} to="/cloud/edit"/>
                <Link ref={refBarcode} to="/cloud/barcode"/>

            </div>

              
        
        </div>
        
    </div>  
    )
}

export default RenderBadge

