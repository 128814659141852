import React from 'react';
import {BsShop} from 'react-icons/bs'
import {MdCloudQueue} from 'react-icons/md'


function MMCLOUDZONELOGO(props) {

const {bgColor}=props
//====================

const renderMM=()=>{

   return(
      <div
      style={{
         display:"flex",
         justifyContent:"flex-start", 
         alignItems:"center",
         marginBottom:"0.3rem",
         marginLeft:"0.5rem",
         transform:"scale(1.3)"
      }}
      >
         <div style={{
            border:"1px solid white",
            borderRadius:"50%",
            width:"3rem",height:"3rem",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            backgroundColor:"#dd3524"

         }}>
               <div
                    style={{
                     fontFamily:"Baumans",
                     color:"white",
                     fontWeight:"bold",
                     fontSize:"2.7rem",
                     letterSpacing:"3px",
                     paddingBottom:"0.6rem",
                  }}
               >
               m   
               </div>
         </div>

         <div style={{
            border:"1px solid white",
            borderRadius:"50%",
            width:"3rem",height:"3rem",
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            marginLeft:"-0.4rem",
            background:"rgb(255,210,127)",
            background:"radial-gradient(circle, rgba(255,210,127) 0%, rgba(251,156,6,1) 100%)"
         }}>
               <div
                    style={{
                     fontFamily:"Baumans",
                     color:"white",
                     fontWeight:"bold",
                     fontSize:"2.7rem",
                     letterSpacing:"3px",
                     paddingBottom:"0.6rem",
                  }}
               >
               m   
               </div>
         </div>         

      </div>


   )
}

  //=======================
return (

   <div
      style={{
         display:"flex",
         justifyContent:"center",
         alignItems:"center",
         width:"13rem",height:"13rem",

         backgroundColor:"white",
         border:"0.5rem solid #2c3e75",
         borderRadius:"50%",
         padding:"0.5rem",
         paddingBottom:"1.2rem"
      }}
   >
        <div>

                <div
                    style={{
                        width:"100%",
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"flex-end",
                    }}
                >
                    {
                    renderMM()
                    }
                    
                    <div style={{
                        transform:"scale(1)"
                    }}
                    >
                        <MdCloudQueue
                            style={{
                            color:"black",
                            opacity:"0.2",
                            fontSize:"5rem",
                            marginLeft:"0rem"
                            }}
                        />
                    </div>

                
                </div>
     
                <div 
                    style={{
                        display:"flex",
                        justifyContent:"center",
                        alignItems:"flex-start",
                        width:"100%" ,
                        fontFamily:"Baumans",
                        color:"#2c3e75",
                        fontWeight:"bold",
                        fontSize:"2rem",
                        letterSpacing:"1px",
                        padding:"auto", 
                    }}
                > 
                    <h4>
                        CLOUDZONE
                    </h4>
                    
            </div>
        </div>

   </div>

  )
}

MMCLOUDZONELOGO.defaultProps={
   bgColr:null
}


export default MMCLOUDZONELOGO;
