import React from 'react'
import Galleryone from '../../component/galleryone/Galleryone'

import {MdClose,MdArrowUpward,MdArrowDownward,
    MdSettings,MdSave,MdRadioButtonChecked,MdRadioButtonUnchecked,
    MdCheckBox,MdCheckBoxOutlineBlank,MdEdit
} from 'react-icons/md';

import console from '../../util/consoleVerify'

import './Table.css'

function Table({
    dataLabel,
    setDataLabel,
    
    pageData,
    setPageData,
    
    editData,
    setEditData,

    keyArray,

    matchEditDataField
}){

console.log('Table....')
//console.log("dataLabel")
//console.log(dataLabel)

//console.log("keyArray")
//console.log(keyArray)

console.log('pageData')
console.log(pageData)

/*
dataLabel
albumLineBackgroundColor: "transparent"
albumLineBreak:false
albumLineColor: "green"
albumLineOrder: 3
albumShowLb: true
showAlbumLine: true


calendarBackgroundColor: "transparent"
calendarColor: "black"
calendarOrder: 1
calendarShowLb: true
calendarShowLine: true


colBackgroundColor: "black"
colColor: "white"
colOrder: 3
showCol: true
showColHead: true
showSum: false

toCheck: true
showFilterLine: true
filterLineBackgroundColor: "transparent"
filterLineBreak: false
filterLineColor: "blue"
filterLineOrder: 3

formBackgroundColor: "transparent"
formColor: "red"
formFontSize: 14
formLeft: 50
formShowLb: true
formShowLine: true
formTop: 10

labelColor: "black"
labelLineFontSize: 14
labelLineJustifyContent: "left"
labelLineMargin: 10
labelOrder: 3
labelShowBarcode: true
labelShowLb: true
labelShowLine: false

lb: "active"

showLine: true
lineBackgroundColor: "transparent"
lineBreak: false
lineColor: "green"
lineOrder: 3


type: "boolean"
width: 90

active,createdAt,id,itemDate1,
itemNumber1,itemString1,photoUrl1,type,updatedAt

*/

    const lastRef=React.createRef()

    
    let tempArray=[]
    if(dataLabel){
        //tempArray=Object.keys(dataLabel)

        tempArray=keyArray

        tempArray.sort((a, b) => {
            //console.log(`${a}-${b}`)
            //console.log(dataLabel[a])
            //console.log(dataLabel[b])
          
            return dataLabel[a].colOrder - dataLabel[b].colOrder;
        });

    }
    

    const keyObjectArray=tempArray



    //==========================================
    const tableResize=({tableTemplate/*,showTable,setShowTable*/})=>{

        if(tableTemplate){
        
            let tempWidth=0;
            let tempGridCol=""
        
            let objKeys = []
            //Object.keys(tempA);
            objKeys=keyArray
            
            objKeys.sort((a, b) => {
                return tableTemplate[a].colOrder - tableTemplate[b].colOrder;
            });


            objKeys.map(i=>{

                if(tableTemplate[i].showCol){
                    tempWidth=tempWidth+parseInt(tableTemplate[i].width);
                    tempGridCol=`${tempGridCol} ${tableTemplate[i].width}px`
                }
            })  

            return {width:tempWidth,gridCol:tempGridCol}
        }
        else {
        //console.log('tableTemplate null')
            return null
        }
        
    }
    //===========================================
    const numberWithCommas=(x)=>{
        const tempX=x

        try{
          if(!isNaN(tempX)){
                //console.log(`n: ${n}`)
                //const tmx.toFixed(n)
                if(tempX>0||tempX<0){
                    const temp=tempX.toString().split(".")
                    const beforeDot=temp[0].toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        
                    if(temp.length>1){
                        return `${beforeDot}.${temp[1]}`
                    }
                    else{
                        return beforeDot
                    }
                }
                else if(tempX===0){
                    return "0"
                }
            }
        }
        catch (error){
          //console.log('error')
          //console.log(typeof x)
          //console.log(x)
          //console.log(error)
        }
      }
    //==========================
    const getColor=(d)=>{
        let tempColor="black"

        if(editData){
            if(editData[matchEditDataField]===d[matchEditDataField]){
                tempColor="red"
            }
        }
        return tempColor
    }  
      
    //===========================================
    const sortColumn=(filterData,colName,sortType)=>{

        const key0_5=colName.substring(0,5)

      
        if(! 
            (key0_5=="itemN"||
             key0_5=="itemS"||
             key0_5=="itemB"||
             key0_5=="itemD"||
             key0_5=="photo"||
             colName=="id"||
             colName=="active"||
             colName=="newSignUp"||
             colName=="forgetShopPassword"||
             colName=="type"||
             colName=="createdAt"||
             colName=="updatedAt"
            )
        ){ 
          return []
        }

        if(key0_5=="photo"){
            return filterData
        }
      
        let tempArray1=[]
        let tempArray2=[]

        filterData.map(kk=>{
            if(kk[colName]===""||
               kk[colName]===null||
               kk[colName]===undefined
            ){

                tempArray1=[...tempArray1,kk]
            }
            else{
                tempArray2=[...tempArray2,kk]
            }
        })

        let tempArray
        if(sortType=="a-b"){
            tempArray=[...tempArray2,...tempArray1]
        }
        else {
            tempArray=[...tempArray1,...tempArray2]
        }
      

        if (colName=="id"||key0_5=="itemN") {    
            if(sortType=="a-b"){
                tempArray.sort((a, b)=>(parseFloat(a[colName])>parseFloat(b[colName]))?1:-1);
                //console.log('a-b')
            }
            if(sortType=="b-a"){
                tempArray.sort((a, b)=>(parseFloat(a[colName])<parseFloat(b[colName]))?1:-1);
                //console.log('b-a')
            }
        }
    
        else if (colName=="type"||colName=="createdAt"||
            colName=="updatedAt"||key0_5=="itemD"||
            key0_5=="itemS"||key0_5=="itemB"||
            colName=="active"||colName=="newSignUp"||
            colName=="forgetShopPassword"
            ) {
            if(sortType=="a-b"){
                tempArray.sort((a, b)=>(a[colName]>b[colName])?1:-1);
            }
            if(sortType=="b-a"){
                tempArray.sort((a, b)=>(a[colName]<b[colName])?1:-1);
            }
        }
      
        let tempResult=tempArray
        
      
        return tempResult    
      }



    const sortUp=(b)=>{
        //console.log(`sortUp of ${b}`)
        const temp=sortColumn(pageData,b,"b-a")
        setPageData(temp)
    }

    const sortDown=(b)=>{
        //console.log(`sortDown of ${b}`)
        const temp=sortColumn(pageData,b,"a-b")
        setPageData(temp)
    }
    //===========================================
    const showContent=(d,j)=>{
        const key0_5=j.substring(0,5)
        if(key0_5==="itemB"||j==="active"||j==="toDelete"||
           j==="isCheck"||j==="hasProblem"||j==="newSignUp"||
           j==="forgetShopPassword"
        ){
            return (
            <div className="flex-center-center" 
                 style={{marginTop:"0.3rem"}}>
            {d[j]
            ?<MdCheckBox className="sm-icon"
                 style={{color:getColor(d)}}
            />
            :<MdCheckBoxOutlineBlank className="sm-icon"
                 style={{color:getColor(d)}}
            />
            }
            </div>
            )
        }
        else if(key0_5==="itemD"&&dataLabel[j].type==="date"){
            let tempDate=null
            if(d[j]){       
                const date0=new Date(d[j])
                if(date0 instanceof Date && !isNaN(date0.valueOf())){
                  const date1=new Date(d[j]).toISOString()
                  const date2=new Date(date1).toLocaleString('en-GB')
                  tempDate= date2.substring(0,10)
                }
            }
            return(
            <p  className="table-p"
                 style={{color:getColor(d),
                        margin:"0.1rem 0",
                 }} >
                {tempDate
                /*?`${i[j].substring(8,10)}-${i[j].substring(5,7)}-${parseInt(i[j].substring(0,4))+543}`*/
                ?`${tempDate}`
                :null
                }
            </p>
            )
        }
        else if(key0_5==="itemD"||
                j==="createdAt"||
                j==="updatedAt"||
                j==="timestamp"||
                j==="dateIn"||
                j==="expirationDate"||
                j==="tempExpirationDate"||
                j==="transferDate"||
                j==="newExpirationDate"
                
        ){
            let tempDateTime=null
            if(d[j]){
                const date1=new Date(d[j]).toISOString()
                const date3=new Date(date1).toLocaleString('en-GB')
                tempDateTime=date3
                if(dataLabel[j].type==="date"){
                    tempDateTime= date3.substring(0,10)
                }
            }
            return(
                <p className="table-p"
                    style={{
                        color:getColor(d),
                        margin:"0.1rem 0",
                    }} 
                >
                {tempDateTime
                ?`${tempDateTime}`
                :null
                }</p>
            )
        }

        else if(key0_5==="itemN"||
                j==="monthlyFee"||
                j==="thisMonthDiscount"||
                j==="remainingBalance"||
                j==="userId"||
                j==="transferAmount"||
                j==="addMonth"||
                j==="newRemainingBalance"||
                j==="overdueBalance"
        ){
            return(
                <p className="table-p"
                  style={{
                    color:getColor(d),
                    margin:"0.1rem 0",
                }}
                >
                    {
                        numberWithCommas(d[j])
                    }
                </p>
            )
        }
        else if(j==="id"||j==="type"||key0_5==="itemS"||
            j==="shopName"||j==="shopPassword"||j==="code"||
            j==="ownerName"||j==="ownerSurname"||j==="ownerEmail"||
            j==="recoveryPassword"||
            j==="shopId"||j==="username"||j==="userPassword"||
            j==="userLevel"||j==="name"||j==="surname"||j==="shopTarget"||
            j==="accountName"||j==="accountNumber"||j==="fromBank"||
            j==="checkBy"

        ){
            return(
                <p className="table-p"
                  style={{
                    color:getColor(d),
                    margin:"0.1rem 0",
                }}
                >
                    {
                        d[j]
                    }
                </p>
            )
        }
        else if(key0_5==="photo"){
            return(
                <Galleryone imgarrs={d[j]} 
                    width={"100%"}
                />
            )
        }
   

    }
    //===========================================
    let [showTable,setShowTable]=React.useState(
        null
        //tableResize({tableTemplate:genObjKeysByColOrder(tableTemplate)/*,showTable,setShowTable*/})
        //width:1200,
        //gridCol:"",
        //{
        //width: 870, 
        //gridCol: ' 40px 90px 100px 70px 110px 60px 200px 200px'
        //}
    )

    React.useEffect(()=>{
        const temp=tableResize({tableTemplate:dataLabel})
   
        setShowTable({...showTable,...temp})
    },[dataLabel])
    //==========================================
    const renderToolBarForColumn=(b)=>{
        return(
          <div className=""
          style={{
              width:"200px",
              height:"70px",
              backgroundColor:"#3b5d52",
              borderRadius:"15px",
              boxShadow:"5px 5px 10px",
              position:"absolute",
              top:`45px`,
              left:`0px`,
              zIndex:"11"
          }}    
      >  
        <input 
          style={{width:"100%"}}
          type="range" min="10" max="1000" 
          value={dataLabel[b].width}
          onChange={e=>{
  
              const tempObj={...dataLabel[b],width:e.target.value}
              setDataLabel({...dataLabel,[b]:tempObj})
            }} 
        />
        <div style={{width:"100%",display:"flex",
               alignContent:"center",
               justifyContent:"space-around"}}>
              
             <MdArrowDownward 
                className="md-icon"
                onClick={e=>{
                    
                    sortDown(b)
                                             
                }}
              />
              
              <MdArrowUpward 
                className="md-icon"
                onClick={e=>{
                    sortUp(b)
                                             
                }}
              />
              
              <MdClose 
                className="md-icon"
                onClick={e=>{
                    //console.log('....MdClose')
                    const tempObj={...dataLabel[b],showColHead:true}
                    setDataLabel({...dataLabel,[b]:tempObj})
                }}
              />
        </div>
      </div>
        )
      }
    
    //==========================================
    return (
        <div className="w-100 h-100" 
            style={{
                position:"relative",
                minHeight:"150px",
                overflow:"auto"
                }}
        >
                <button 
                        style={{
                            position:'fixed',
                            bottom:"4rem",
                            left:"0",
                            visibility:"hidden"
                        }}
                        //ref={buttonLastRef}
                        onClick={e=>{
                            if(lastRef.current){
                                lastRef.current.scrollIntoView()
                            }
                        }}
                >
                    clickToLast
                </button>

                
                {dataLabel&&showTable&&
                <div className="w-100 h-100"
                    style={{padding:"0 0 2rem 0"}}
                >
                    {/*Track*/}
                    <div style={{width:`${showTable.width}px`}}>   

                            {/*Table Head*/}
                            <div className="TableGrid-head" 
                                style={{display:"grid",
                                        gridTemplateColumns:showTable.gridCol,
                                        gridAutoRows:"minmax(1rem,auto)",
                                        position:'sticky',top:'0',
                                        //backgroundColor:"black",//colorHead,
                                        zIndex:"10"
                                    }}
                            > 
                                    { 
                                        keyObjectArray.map((b,idxB)=>{
                                            if(dataLabel[b].showCol){
                                                return dataLabel[b].showColHead
                                                ?<div key={`tableHead-${idxB}`}
                                                    style={{
                                                        width:`${dataLabel[b].width}px`,
                                                        height:"2rem",
                                                        padding:"0 0.3rem",
                                                        position:"relative",
                                                        display:'flex',
                                                        alignItems:'center',
                                                        justifyContent:'space-around',
                                                        backgroundColor:`${dataLabel[b].colBackgroundColor}`,
                                                        color:`${dataLabel[b].colColor}`
                                                    }}   
                                                    onClick={e=>{                                                       
                                                        //const tempObj={...dataLabel[b],showColHead:false}
                                                        //setDataLabel({...dataLabel,[b]:tempObj})
                                                        let tempObj={}
                                                        keyObjectArray.map(c=>{
                                                            let tempObj2
                                                            if(c===b){
                                                                tempObj2={...dataLabel[c],showColHead:false}
                                                            }
                                                            else{
                                                                tempObj2={...dataLabel[c],showColHead:true}
                                                            }
                                                            tempObj={...tempObj,[c]:tempObj2}
                                                        })
                                                        setDataLabel(tempObj)
                                                    }}
                                                >
                                                    {dataLabel[b].lb}
                                                </div>
                                                :<div
                                                    key={`tableHead-${idxB}`}
                                                    style={{
                                                        width:`${dataLabel[b].width}px`,
                                                        padding:"0 0.3rem",
                                                        position:"relative",
                                                        display:'flex',
                                                        alignItems:'center',
                                                        justifyContent:'space-around',
                                                        backgroundColor:`${dataLabel[b].colBackgroundColor}`,
                                                        color:`${dataLabel[b].colColor}`
                                                    }}   
                                               
                                                >
                                                    <MdClose
                                                        className='sm-icon'
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],showColHead:true}
                                                            setDataLabel({...dataLabel,[b]:tempObj})
                                                        }}
                                                    />
                                                    {
                                                        renderToolBarForColumn(b)
                                                    }
                                                </div>
                                            }             
                                        })                        
                                    }
                            </div>


                            {
                            pageData.map((d,idxD)=>{
                                return d&&
                                    <div key={`pageData-${idxD}`}
                                            className="TableGrid-body" 
                                            style={{
                                                display:"grid",
                                                gridTemplateColumns:showTable.gridCol,
                                                //backgroundColor:idxD%2==1?"rgba(120,120,120,0.5)":"null"                                        
                                            }}
                                    >
                                        {
                                            keyObjectArray.map((j,idxJ)=>{
                                                return dataLabel[j].showCol&&
                                                <div key={`${idxD}-${idxJ}`}
                                                    style={{
                                                        display:"flex",
                                                        textAlign:"left",
                                                        alignItems:"center",
                                                        height:"100%"
                                                        //width:`${dataLabel[j].width}px`,
                                                        //color:getEditColor(d)
                                                    }}

                                                    onClick={e=>{
                                                        //console.log('ddddd')
                                                        //setEditData(null)
                                                        //if(j!=="photoUrl1"){
                                                        setEditData(d)
                                                        //captureClientXY(e.clientX,e.clientY)
                                                        //}
                                                    }}
                                                >
                                                    {showContent(d,j)}
                                                </div>
                                            })
                                        }
                                    </div>
                                
                            })
                            }      

                    </div>

                

                </div>
                }

        </div>
    )
}

Table.defaultProps={
    matchEditDataField:"id"
}

export default Table