
import console from '../util/consoleVerify';


const genMyTk=(myheader)=>{

    const scrt=process.env.REACT_APP_KEY

    console.log('genMyTk......')
    
    const getLastNumber=(str,n)=>{
          //str=123456789
          //n=-3
          //numAddYear=789 ==> last 3 of string 
          const tempArrayLength=str.length
          const textAddYear=str.substring(tempArrayLength+n,tempArrayLength)
          const numAddYear=parseInt(textAddYear)
          return numAddYear
    }

    function makeid(n) {
          //makeid(5)
          //bNJwg ==>
          var text = "";
          var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        
          for (var i = 0; i < n; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        
          return text;
        }
    
    const rndInt=(n)=>{
          //rndInt(10)
          //==> 1,2,3,...10

          const temp=Math.floor(Math.random() * n) + 1
          return temp
    }
    
    const rndBool=()=>{
          //rndBool 
          //true or false
          if(Math.random() >= 0.5){
                return true
          }
          else {
                return false
          }
    }
    
    const addYear=(yrs)=>{
          //yrs = 5
          //2023-09-28T14:10:12.395Z
          //addYear(5)=1853763012395
          //new Date(1853763012395)==>2028-09-28T14:10:12.395Z

          const toDay=new Date()
          const thisYear=toDay.getFullYear()
          const nextYear=thisYear+yrs
          const nextDay=toDay.setFullYear(nextYear)

          console.log("nextDay")
          console.log(nextDay)

          return nextDay
    }

    //4798100208569
    //4798100208569
    const mixUp=(today)=>{
          //console.log(today)
          const textToday=today.toString()
          const tempTextTodayArray=Array.from(textToday);

          //console.log("tempTextTodayArray")
          //console.log(tempTextTodayArray)


          let finalText=''
          tempTextTodayArray.map(i=>{
                
                let tempMixText=''
                if(rndBool){
                     tempMixText=tempMixText+makeid(rndInt(4))
                }

                finalText=finalText+i+tempMixText
          })

          return finalText
    }

    const toKen=mixUp(addYear(getLastNumber(scrt,-3)))   
    // genMyTk=(scrt)=>{....}
    // REACT_APP_KEY = '6LcCvsQdAAAAAAqWKtgK3Tb6l3LYBjtCVrl103'
    // genMyTk(process.env.REACT_APP_KEY)
    // getLastNumber(scrt,-3)==> 103
    // tempLastNumber
    // 103
    // toDay
    // 2023-09-28T14:37:46.343Z
    // tempAddYear
    // 4946279866343
    // new Date(tempAddYear)
    // 2126-09-28T14:37:46.343Z
    
    //tempTextTodayArray
    //['4', '9', '4', '6','2', '7', '9', '8','6', '6', '3', '4','3']
    //toKen===>
    //4x9qR4iOFW6N2xf7p9K8J6W6W3Ujyd4C3gqFQ
    //4 9  4    6 2  7 9 8 6 6 3    4 3  

    const tempMyheader={headers: 
      {
      'Content-Type': 'application/json',
      'Shopauthorization':localStorage.getItem('shopauthorization'),
      'Userauthorization':localStorage.getItem('userauthorization'),
      'Appauthorization':toKen
      }
      }

      /*
      const tempMyheader={headers:
                              {...myheader.headers,
                                    Appauthorization:`${toKen}`
                              }
                       }
      */                  
      console.log('tempMyheader....')
      console.log(tempMyheader)

    return tempMyheader
}


const checkReqBody=()=>{
    let temp=true

    return temp
}


const appVerify={genMyTk,checkReqBody}

export default appVerify

