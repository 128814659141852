import React, { isValidElement } from 'react'

import axios from 'axios'

import cosmeticUtil from '../../util/cosmeticUtil'
import {Link} from 'react-router-dom';
import photoUtil from '../../component/galleryone_add/photoUtil'


import {FaUserCircle,FaCheck,FaBan,FaMoneyBillWave,FaKeyboard
} from 'react-icons/fa'
import {MdVisibility,MdVisibilityOff,MdAddCircle,MdVpnKey,
    MdOutlineVpnKeyOff,MdOutlineVpnKey,
    MdSettings
} from 'react-icons/md';
import {BsShop} from 'react-icons/bs'

import ModalCapCha from './ModalCapCha'

import Gallery20_add from '../../component/galleryone_add/Gallery20_add'

import {MainContext} from '../../context/MainContext'
import LangList from '../../context/LangList'

import LogOutTool from './LogOutTool'
import ModalError from './ModalError'
import ModalLineSignUp from './ModalLineSignUp';

//import console from '../../util/consoleVerify'
import appVerify from '../../util/appVerify';


import { loadCaptchaEnginge, 
         LoadCanvasTemplate, 
         LoadCanvasTemplateNoReload, 
         validateCaptcha } from 'react-simple-captcha';

         
const {genMyTk,checkReqBody}=appVerify
const {checkWindowLandscape}=cosmeticUtil

function ItemLogin(){

    const {
        myheader,  
        tokenSt,
        setTokenSt,
        basicDataSt,
        setBasicDataSt,
        shopPayment,
        bankDetail,
        userLevel,
        userArray,userTableTemplate,
        formTemplate,
        
        dataLabel,setDataLabel,
        dataLabel2,setDataLabel2,

        settingType,setSettingType,
        languageDisplay,
        
        languageSetting,setLanguageSetting,
        
        showError,setShowError,
        messageError,setMessageError,
        
    }=React.useContext(MainContext)




    const vrfChar=[
        "A","B","C","D","E","F","G","H","I","J",
        "K","L","M","N","O","P","Q","R","S","T",
        "U","V","W","X","Y","Z",
        "a","b","c","d","e","f","g","h","i","j",
        "k","l","m","n","o","p","q","r","s","t",
        "u","v","w","x","y","z",
        "1","2","3","4","5","6","7","8","9","0",
        "@","_","-","+","#","&"
    ]
    

    const checkChar=(testChar)=>{
        let tempCheck=false
        
        vrfChar.map(a=>{
            if(!tempCheck){
                if(testChar===a){
                    tempCheck=true
                }
            }
        })

        return tempCheck

    }

    let tempUsername=""

    if(localStorage.getItem("username")){
        tempUsername=localStorage.getItem("username")
    }


    const displayError=(line,err)=>{

        //console.log('err')
        //console.log(err.response.data)

        setShowError(true) 
        let tempErr="error"

        if(err.response){
            if(err.response.data){
                if(err.response.data.message){
                    tempErr=err.response.data.message
                }
            }
        }

        setMessageError(`${line}: ${languageDisplay[tempErr]}`)
    }

    //=====================
    //=====================
    /*
    const shouldRender= React.useRef(true)
    React.useEffect(()=>{
    
       setTimeout(()=>{
          if(shouldRender.current){
             loadCaptchaEnginge(4)
          }
       },1000)
    
        return () => {
            //console.log('end.................Bill................')
            shouldRender.current=false
        } 
    },[])
    */
    //=====================
    //=====================

    //const user={username:"Peter",shopId:"vrp",branchId:"1"}
    const user=basicDataSt.user
    
    //-------------------
    //------------------
    const refHome=React.createRef()

    const refShopLogInShopName=React.createRef()
    const refShopLogInPassword=React.createRef()

    const refShopLogInSubmit=React.createRef()

    const refUserLogInUsername=React.createRef()
    const refUserLogInPassword=React.createRef()
    const refUserLogInSubmit=React.createRef()

    const refLogInWithRecoveryPasswordShopName=React.createRef()
    const refLogInWithRecoveryPasswordRecoveryPassword=React.createRef()
    const refLogInWithRecoveryPasswordNewShopPassword=React.createRef()
    const refLogInWithRecoveryPasswordConfirmNewShopPassword=React.createRef()
    const refLogInWithRecoveryPasswordSubmit=React.createRef()
    const refLogInWithRecoveryPasswordCancel=React.createRef()

    const refShopLogInForShopPaymentShopName=React.createRef()
    const refShopLogInForShopPaymentShopPassword=React.createRef()
    const refShopLogInForShopPaymentSubmit=React.createRef()
    const refShopLogInForShopPaymentCancel=React.createRef()

    const refShopPayment=React.createRef()

    const refChangeUserPasswordUsername=React.createRef()
    const refChangeUserPasswordUserPassword=React.createRef()
    const refChangeUserPasswordNewUserPassword=React.createRef()
    const refChangeUserPasswordConfirmNewUserPassword=React.createRef()
    const refChangeUserPasswordSubmit=React.createRef()
    const refChangeUserPasswordCancel=React.createRef()

    const refForgetShopPasswordShopName=React.createRef()
    const refForgetShopPasswordSubmit=React.createRef()
    const refForgetShopPasswordCancel=React.createRef()


    //const [tokenSt,setTokenSt]=React.useState({haveShopToken:true,haveUserToken:true})

    const [hidePassword,setHidePassword]=React.useState(true)
    //====================================



    const [showUserChangePassword,setShowUserChangePassword]=React.useState(false)
    const [showShopSignUp,setShowShopSignUp]=React.useState(false)
    const [showShopLogIn,setShowShopLogIn]=React.useState(false)
    const [showShopChangePassword,setShowShopChangePassword]=React.useState(false)
  
    const [showForgetShopPassword,setShowForgetShopPassword]=React.useState(false)
    //const [showRequestRecoveryPassword,setShowRequestRecoveryPassword]=React.useState(false)
  
    const [showShopPayment,setShowShopPayment]=React.useState(false)
    const [showShopLogInForShopPayment,setShowShopLogInForShopPayment]=React.useState(false)
    const [showLogInWithRecoveryPassword,setShowLogInWithRecoveryPassword]=React.useState(false)

    const [Language,setLanguage]=React.useState("Thai")
    
    const [shopMonthlyFeeState,setShopMonthlyFeeState]=React.useState({
        photoUrl1:[]
    })

    const [showModalCapChaForShopLogIn,setShowModalCapChaForShopLogIn]=React.useState(false)
    const [showModalCapChaForShopSignUp,setShowModalCapChaForShopSignUp]=React.useState(false)


    const [showModalLineSignUp,setShowModalLineSignUp]=React.useState(false)
    const [signUpImage,setSignUpImage]=React.useState(null)


    React.useEffect(()=>{
        if(signUpImage){
            setShowModalLineSignUp(true)
        }
    },[signUpImage])

    const [companyBankState,setCompanyBankState]=React.useState(null)

    //====================================
    const [userChangePasswordInputState,setUserChangePasswordInputState]=React.useState({
        username:"",
        userPassword:"",
        userPasswordStar:"",
        newUserPassword:"",
        newUserPasswordStar:"",
        confirmNewUserPassword:"",
        confirmNewUserPasswordStar:""
    })

    const [userLogin,setUserLogin]=React.useState({
        username:localStorage.getItem("loginusername")||"",
        userPassword:"",
        UserPasswordStar:""
    })
    

    const [shopLogInState,setShopLogInState]=React.useState({
        shopName:localStorage.getItem("loginshopname")||"",
        shopPassword:"",
        shopPasswordStar:""
    })
      
    const [shopSignUpState,setShopSignUpState]=React.useState({
        shopName:"",
        shopPassword:"",shopPasswordStar:"",
        ownerName:"",
        ownerSurname:"",
        ownerEmail:"",
        username:"",
        userPassword:"",userPasswordStar:""
    })

    const [logInWithRecoveryPasswordState,setLogInWithRecoveryPasswordState]=React.useState({
        shopName:"",
        recoveryPassword:"",recoveryPasswordStar:"",
        newShopPassword:"",newShopPasswordStar:"",
        confirmNewShopPassword:"",confirmNewShopPasswordStar:""
    })
    
    const [shopLogInForShopPaymentState,setShopLogInForShopPaymentState]=React.useState({
        shopName:localStorage.getItem("loginshopname")||"",
        shopPassword:"",
        shopPasswordStar:""
    })

    const [shopPaymentState,setShopPaymentState]=React.useState({
        shopName:"",
        expirationDate:"",
        monthlyFee:"",
        remainingBalance:"",
        newExpirationDate:"",
        overdueBalance:"",

        transactionAmount:0,

        companyBankAccountName:"",
        companyBankAccountNumber:"",
        companyBankAccountBank:"",

        shopToken:"",
        file1:null,
        photoUrl1:[]
    })

    const [forgetShopPasswordState,setForgetShopPasswordState]=React.useState({
        shopName:""
    })

    //=====================================

    React.useEffect(()=>{

        if(companyBankState){
            if(languageSetting==="Thai"){
                const temp={
                    ...shopPaymentState,
                    companyBankAccountName:companyBankState.companyBankAccountNameThai,
                    companyBankAccountNumber:companyBankState.companyBankAccountNumberThai,
                    companyBankAccountBank:companyBankState.companyBankAccountBankThai
                }
                setShopPaymentState(temp)
            }

            else {
                const temp={
                    ...shopPaymentState,
                    companyBankAccountName:companyBankState.companyBankAccountNameEnglish,
                    companyBankAccountNumber:companyBankState.companyBankAccountNumberEnglish,
                    companyBankAccountBank:companyBankState.companyBankAccountBankEnglish
                }
                setShopPaymentState(temp)
            }
        }
    },[languageSetting])


    //====================================
    const {changeArrayFile}=photoUtil
  
    const [showImage1,setShowImage1]=React.useState(true)
    const [arrayFile1,setArrayFile1]=React.useState([])
    const [fileUrl1,setFileUrl1]=React.useState([])

    const [imageLimit,setImageLimit]=React.useState(1)

    
    React.useEffect(()=>{
        //console.log('fileUrl1')
        //console.log(fileUrl1)
    },[fileUrl1])
  
    React.useEffect(()=>{
        //console.log('useEffect ArrayFile1')
        //console.log(arrayFile1)
                
        changeArrayFile({ arrayFile:arrayFile1,
            fileUrl:fileUrl1,
            setFileUrl:setFileUrl1,
            inputState:shopPaymentState,
            setInputState:setShopPaymentState,
            keyName:"photoUrl1",
            //fileName,
            //serverFolder,
            //fileName:"file",
            //serverFolder:"/upload/customer",
            setShowImage:setShowImage1})
            
    },[arrayFile1])

//=========================

//=========================
const renderUserChangePassword=()=>{
    
    const tempArray=["username","userPassword",
        "newUserPassword",
        "confirmNewUserPassword"
    ]

    const refArray=[
        refChangeUserPasswordUsername,
        refChangeUserPasswordUserPassword,
        refChangeUserPasswordNewUserPassword,
        refChangeUserPasswordConfirmNewUserPassword,
    ]

    return (
        <div className='bd-pureWhite' 
        style={{
            padding:"3rem",
            minHeight:"40%",
            minWidth:"60%"
        }}
        >
        
            <div className='w-100 flex-center-center'
                style={{marginBottom:"1rem"}}
            >
            <h3>{
                languageDisplay
                ?languageDisplay.changeUserPasswordModal
                    ?languageDisplay.changeUserPasswordModal
                    :"Change User Password"
                :"Change User Password"
            }</h3>
            </div>


            {   
                tempArray.map((i,idxI)=>{

                    return (
                        <div key={idxI}
                            className="flex-center-center jc-start w-100"
                            style={{marginBottom:"1rem"}}
                        >
            
                            <div className="xc4">
                                {
                                    languageDisplay
                                    ?languageDisplay[i]
                                        ?languageDisplay[i]
                                        :""
                                    :""
                                }
                            </div>
                            
                            <div className="xc8"
                                style={{position:"relative"}}
                            >
                                <input
                                    style={{color:(hidePassword&&i==="userPassword")||
                                                  (hidePassword&&i==="newUserPassword")||
                                                  (hidePassword&&i==="confirmNewUserPassword")
                                        ?"transparent"
                                        :null
                                    }}

                                    type={"text"}
                                    ref={refArray[idxI]}
                                    
                                    value={
                                        userChangePasswordInputState[i]
                                    }
                                    
                                    onKeyDown={e=>{
                                      
                                        if(e.key==="Enter"){
                                            if(i==="username"){
                                                refChangeUserPasswordUserPassword.current.focus()
                                            }
                                            else if(i==="userPassword"){
                                                refChangeUserPasswordNewUserPassword.current.focus()
                                            }   
                                            else if(i==="newUserPassword"){
                                                refChangeUserPasswordConfirmNewUserPassword.current.focus()
                                            }   
                                            else if(i==="confirmNewUserPassword"){
                                                refChangeUserPasswordSubmit.current.focus()
                                            }                                          

                                        }
                                    }}

                                    onChange={e=>{

                                        if(i==="username"){
                                            setUserChangePasswordInputState({
                                                ...userChangePasswordInputState,
                                                [i]:e.target.value
                                            })
                                        }
                                        else if(i==="userPassword"||
                                                i==="newUserPassword"||
                                                i==="confirmNewUserPassword"
                                        ){
                                            const lastChar=e.target.value.slice(-1)

                                            let tempResult=checkChar(lastChar)
                                            if(e.target.value.length>15){
                                                tempResult=false
                                            }

                                            let tempStar=""
                                            for(let z=0;z<e.target.value.length;z++){
                                                tempStar=tempStar+"*"
                                            }
                                            

                                            if(tempResult||e.target.value===""){
                                                setUserChangePasswordInputState({
                                                    ...userChangePasswordInputState,
                                                    [i]:e.target.value,
                                                    [i+"Star"]:tempStar
                                                })
                                            }

                                        }


                                    }}
                                />

                                {(i==="userPassword"&&hidePassword)||
                                 (i==="newUserPassword"&&hidePassword)||
                                 (i==="confirmNewUserPassword"&&hidePassword)
                                    ?<div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {userChangePasswordInputState[i+"Star"]}
                                    </div>
                                    :null
                                }

                                {
                                i==="userPassword"||
                                i==="newUserPassword"||
                                i==="confirmNewUserPassword"
                                ?hidePassword
                                    ?<MdVisibilityOff 
                                    className="sm-icon" 
                                    style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                    onClick={e=>{
                                    setHidePassword(!hidePassword)
                                    }}
                                    />
                                    :<MdVisibility 
                                    className="sm-icon" 
                                    style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                    onClick={e=>{
                                    setHidePassword(!hidePassword)
                                    }}
                                />
                                :null
                                }
                            </div>
                        </div>
                    )
                })
            }

          

            <div style={{
                    display:"flex",
                    justifyContent:"flex-end"
                    }}
            >
                    <button
                        ref={refChangeUserPasswordSubmit}

                        onClick={async(e)=>{

                            try {

                                const result2= await axios.post('/p40user/changeuserpassword',
                                userChangePasswordInputState,
                                genMyTk(myheader))

                                setShowUserChangePassword(false)

                                localStorage.removeItem("userauthorization");
                                localStorage.removeItem("username")

                                window.location.reload()
                            }
                            catch (err){
                                displayError("IL409",err)
                            }


                        }}
                    >
                        <FaCheck/>
                    </button>
                
                    <button
                    //ref={refCancel}
                    ref={refChangeUserPasswordCancel}
                    onClick={e=>{
                        //submitCancel()
                        setUserChangePasswordInputState({
                            username:"",
                            userPassword:"",
                            userPasswordStar:"",
                            newUserPassword:"",
                            newUserPasswordStar:"",
                            confirmNewUserPassword:"",
                            confirmNewUserPasswordStar:""
                        })
                        setShowUserChangePassword(false)
                    }}
                    >
                        <FaBan/>
                    </button>  
                
            </div>
        </div>

    )
}

const renderAvatar=()=>{
    return user
    ?<div className="">
          <div className="w-100 flex-center-center">
             <FaUserCircle
                style={{fontSize:"4.5rem",margin:"auto"}}
             />
          </div>
          <div 
             style={{textAlign:"center",fontSize:"1.5rem"}}>
                {`${user.username}`}
          </div>
          <div className="divlink "
               style={{textAlign:"center"}}
             onClick={e=>{
                setShowUserChangePassword(true)
          }}>
                {
                    languageDisplay
                    ?languageDisplay.changeUserPassword
                        ?languageDisplay.changeUserPassword
                        :"Change User Password"
                    :"Change User Password"
                
                }
          </div>
        
          
    </div>
    :null
    
}

const renderUserLogIn=()=>{

    const tempArray=["username","userPassword"]
    const refArray=[refUserLogInUsername,
                    refUserLogInPassword
                   ]

    return (
        <div className='bd-pureWhite' 
        style={{
            padding:"3rem",
            minHeight:"40%",
            minWidth:"60%"
        }}
        >
        
        <div className='w-100 flex-center-center'
            style={{marginBottom:"1rem"}}
        >
            <h3>
                {
                languageDisplay
                ?languageDisplay.userLogInForm
                    ?languageDisplay.userLogInForm
                    :"User Log In"
                :"User Log In"           
                }
            </h3>
        </div>


            {
                tempArray.map((i,idxI)=>{
                    return (
                        <div key={idxI}
                            className="flex-center-center jc-start w-100"
                            style={{marginBottom:"1rem"}}
                        >
                                <div className="xc4">
                                    {
                                    languageDisplay
                                    ?languageDisplay[i]
                                        ?languageDisplay[i]
                                        :""
                                    :""
                                    }
                                </div>
                        
                                <div className="xc8"
                                    style={{position:"relative"}}
                                >
                                        <input
                                            style={{color:hidePassword&&i==="userPassword"
                                                ?"transparent"
                                                :null
                                            }}

                                            ref={refArray[idxI]}
                                            
                                            type={"text"}  

                                            onKeyDown={e=>{

                                                    if(i==="userPassword"){
                                                        if(e.key==="Enter"){
                                                                refUserLogInSubmit.current.focus()  
                                                        }
                                                    }

                                                    if(i==="username"){
                                                        if(e.key=="Enter"){
                                                                refUserLogInPassword.current.focus()
                
                                                        }
                                                    }

                                            }}
                                            
                                            value={userLogin[i]}
                                            

                                            onChange={e=>{
                                                if(i==="username"){
                                                    setUserLogin({
                                                        ...userLogin,
                                                        username:e.target.value
                                                    })
                                                }
                                                else if(i==="userPassword"){
                                                    const lastChar=e.target.value.slice(-1)
    
                                                    let tempResult=checkChar(lastChar)
                                                    if(e.target.value.length>15){
                                                        tempResult=false
                                                    }
    
                                                    let tempStar=""
                                                    for(let z=0;z<e.target.value.length;z++){
                                                        tempStar=tempStar+"*"
                                                    }
    
                                                    if(tempResult||e.target.value===""){
                                                        setUserLogin({
                                                            ...userLogin,
                                                            [i]:e.target.value,
                                                            [i+"Star"]:tempStar
                                                        })
                                                    }
    
                                                }

                                            }}
                                           
                                        />

                                    {i==="userPassword"&&hidePassword&&
                                    <div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {userLogin[i+"Star"]}
                                    </div>
                                    }    

                                        {
                                        i==="userPassword"
                                        ?hidePassword
                                            ?<MdVisibilityOff 
                                                className="sm-icon" 
                                                style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                                onClick={e=>{
                                                    setHidePassword(!hidePassword)
                                                }}
                                            />
                                            : <MdVisibility
                                                className="sm-icon" 
                                                style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                                onClick={e=>{
                                                    setHidePassword(!hidePassword)
                                                }}
                                            />
                                        :null
                                        }
                                        
                                </div>

                        </div>
                    )
                })
            }


          
        
            <div className="flex-center-center jc-end"
            >
                    <button
                        ref={refUserLogInSubmit}
                        onClick={e=>{
                            
                            axios.post('/p40user/login',{
                                username:userLogin.username,
                                userPassword:userLogin.userPassword
                            },
                            genMyTk(myheader))

                            .then(result2=>{

                                console.log('result2')
                                console.log(result2.data)

                                const userToken=`b ${result2.data.userToken}`
                                localStorage.setItem("userauthorization",userToken);
                                localStorage.setItem("username",result2.data.username)
                                localStorage.setItem("loginusername",userLogin.username)


                                const temp={
                                    ...tokenSt,
                                    reloadCheckToken:true,
                                    haveShopToken:true,
                                    haveUserToken:true
                                }
                                setTokenSt(temp)
                                setUserLogin({username:"",userPassword:""})
                                setHidePassword(true)

                                window.location.reload()
                                //window.location.href=result2.data.homeLink

                                
                            })
                            .catch(err=>{
                                //console.log("err....")
                                //console.log(err.response)
                                displayError("IL641",err)
                               
                            })


                        }}
                    >
                        <FaCheck/>
                    </button>
                
                    <button
                        onClick={e=>{
                            setUserLogin({username:"",userPassword:""})
                        }}
                    >
                        <FaBan/>
                    </button>  
                
            </div>
        </div>

    )
}


const refShopSignUpShopName=React.createRef()
const refShopSignUpShopPassword=React.createRef()
const refShopSignUpOwnerName=React.createRef()
const refShopSignUpOwnerSurname=React.createRef()
const refShopSignUpOwnerEmail=React.createRef()
const refShopSignUpUserName=React.createRef()
const refShopSignUpUserPassword=React.createRef()

const refShopSignUpSubmit=React.createRef()
const refShopSignUpCancel=React.createRef()


const renderShopSignUp=()=>{
    const tempArray=[
        "shopName",
        "shopPassword",
        "ownerName",
        "ownerSurname",
        "ownerEmail",
        "username",
        "userPassword"
    ]
    const refArray=[
        refShopSignUpShopName,
        refShopSignUpShopPassword,
        refShopSignUpOwnerName,
        refShopSignUpOwnerSurname,
        refShopSignUpOwnerEmail,
        refShopSignUpUserName,
        refShopSignUpUserPassword
    ]


    return (
        <div className='bd-pureWhite' 
            style={{
                padding:"3rem",
                minHeight:"40%",
                minWidth:"60%"
            }}
        >
            
                <div className='w-100 flex-center-center'
                    style={{marginBottom:"1rem"}}
                >
                    <h3>{
                        languageDisplay
                        ?languageDisplay.shopSignUpForm
                            ?languageDisplay.shopSignUpForm
                            :"SHOP SIGN UP"
                        :"SHOP SIGN UP"
                         //formTemplate.shopSignUpForm.formHead
                    }</h3>
                </div>
                {   
                    tempArray.map((i,idxI)=>{
                        
                        return (
                            <div key={idxI}
                                className="flex-center-center jc-start w-100"
                                style={{marginBottom:"1rem"}}
                            >
                
                                <div className="xc4">
                                    {
                                        languageDisplay
                                        ?languageDisplay[i]
                                            ?languageDisplay[i]
                                            :""
                                        :""
                                        //formTemplate.shopSignUpForm[i]
                                    
                                    }
                                </div>
                                
                                <div className="xc8"
                                    style={{position:"relative"}}
                                >
                                    <input

                                        style={{color:(hidePassword&&i==="shopPassword")||
                                                      (hidePassword&&i==="userPassword")
                                        ?"transparent"
                                        :null
                                        }}

                                        ref={refArray[idxI]}
                                        type={"text"}
            
                                        value={
                                            shopSignUpState[i]
                                        }

                                        onChange={e=>{

                                            if(i==="userPassword"||i==="shopPassword"){

                                                const lastChar=e.target.value.slice(-1)

                                                let tempResult=checkChar(lastChar)
                                                if(e.target.value.length>15){
                                                    tempResult=false
                                                }

                                                let tempStar=""
                                                for(let z=0;z<e.target.value.length;z++){
                                                    tempStar=tempStar+"*"
                                                }
                                                

                                                if(tempResult||e.target.value===""){
                                                    setShopSignUpState({
                                                        ...shopSignUpState,
                                                        [i]:e.target.value,
                                                        [i+"Star"]:tempStar
                                                    })
                                                }

                                            }
                                            else {
                                                setShopSignUpState({
                                                    ...shopSignUpState,
                                                    [i]:e.target.value
                                                })
                                            }
                                        }}
    
                                        onKeyDown={e=>{

                                            if(e.key=="Enter"){
                                                if(i=="shopName"){
                                                    refShopSignUpShopPassword.current.focus()
                                                }
                                                else if(i=="shopPassword"){
                                                    refShopSignUpOwnerName.current.focus()
                                                }
                                                else if(i=="ownerName"){
                                                    refShopSignUpOwnerSurname.current.focus()
                                                }
                                                else if(i=="ownerSurname"){
                                                    refShopSignUpOwnerEmail.current.focus()
                                                }
                                                else if(i=="ownerEmail"){
                                                    refShopSignUpUserName.current.focus()
                                                }
                                                else if(i=="username"){
                                                    refShopSignUpUserPassword.current.focus()
                                                }
                                                else if(i=="userPassword"){
                                                    refShopSignUpSubmit.current.focus()
                                                }
                                                
                                            }
                                        }}
                                    />
    
    
                                    {(i==="shopPassword"&&hidePassword)||
                                     (i==="userPassword"&&hidePassword)
                                    ?<div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {shopSignUpState[i+"Star"]}
                                    </div>
                                    :null
                                    }


                                    {           
                                    i=="shopPassword"||
                                    i=="userPassword"
                                    ?hidePassword
                                        ?<MdVisibilityOff 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                        :<MdVisibility 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                    :null
                                }

                                  
                                </div>
                            </div>
                        )
                    })
                }

                <div className='flex-center-center jc-start'>
                    <MdSettings className='md-icon'
                        onClick={e=>{
                            setShowModalLineSignUp(true)
                        }}
                    />
                    <div>
                        {
                        languageDisplay
                        ?languageDisplay.activateNewAccount
                            ?languageDisplay.activateNewAccount
                            :"ACCTIVATE NEW ACCOUNT"
                        :"ACCTIVATE NEW ACCOUNT"
                        }
                    </div>
                </div>

                <div style={{
                        display:"flex",
                        justifyContent:"flex-end"
                        }}
                >

                        <button
                            ref={refShopSignUpSubmit}
                            onClick={e=>{
                                //submitFunc(inputState,setInputState)
                                setShowModalCapChaForShopSignUp(true)
                            }}
                        >
                            <FaCheck/>
                        </button>

                        <button
                            ref={refShopSignUpCancel}
                            onClick={e=>{
                                //submitCancel()
                                //setShowUserChangePassword(false)
                                setShowShopSignUp(false)
                                window.location.reload()
                            }}
                        >
                            <FaBan/>
                        </button>  
                </div>

    
                {
                      showModalCapChaForShopSignUp
                      ?<ModalCapCha
                        setShow={setShowModalCapChaForShopSignUp}
                        inputState={shopSignUpState}
                        setInputState={setShopSignUpState}
                        submitFunction={submitShopSignUp}
                        languageDisplay={languageDisplay}

                        displayError={displayError}

                      />
                      :null
                    }
        </div>
    
        )

}


const renderForgetShopPassword=()=>{

    return (
    <div className='bd-pureWhite' 
        style={{
            padding:"3rem",
            minHeight:"40%",
            minWidth:"60%"
        }}
    >
        
            <div className='w-100 flex-center-center'
                style={{marginBottom:"1rem"}}
            >
                <h3>{
                    languageDisplay
                    ?languageDisplay.requestRecoveryPassword
                        ?languageDisplay.requestRecoveryPassword
                        :"Reqeust for new password"
                    :"Request for new password"
                }</h3>
            </div>

            <div className='w-100 flex-center-center'
                style={{marginBottom:"1rem"}}
            >
                <h6>{
                    languageDisplay
                    ?languageDisplay.detailOfRequestRecoveryPassword
                        ?languageDisplay.detailOfRequestRecoveryPassword
                        :"New password will be sent to your email"
                    :"New password will be sent to your email"
                }
                </h6>
            </div>

            <div className='w-100 flex-center-center'
                style={{marginBottom:"1rem"}}
            >
                <h6>{
                    languageDisplay
                    ?languageDisplay.detailOfRequestRecoveryPassword2
                        ?languageDisplay.detailOfRequestRecoveryPassword2
                        :"After That Log In With Recovery Password"
                    :"After That Log In With Recovery Password"
                }</h6>
            </div>

          
                
                    
                <div 
                    className="flex-center-center jc-start w-100"
                    style={{marginBottom:"1rem"}}
                >
    
                    <div className="xc4">
                        {
                        languageDisplay
                        ?languageDisplay["shopName"]
                            ?languageDisplay["shopName"]
                            :""
                        :""                               
                        }
                    </div>
                    
                    <div className="xc8"
                        style={{position:"relative"}}
                    >
                        <input
                            ref={refForgetShopPasswordShopName}
                            
                            onKeyDown={e=>{
                                if(e.key=="Enter"){
                                    refForgetShopPasswordSubmit.current.focus()
                                }
                            }}
                            type={"text"}
                                
                            onChange={e=>{
                                setForgetShopPasswordState({
                                    shopName:e.target.value
                                })
                            }}
                        />

                        
                    </div>
                </div>
        
            

          
            <div style={{
                    display:"flex",
                    justifyContent:"flex-end"
                    }}
            >
                    <button
                        ref={refForgetShopPasswordSubmit}
                        onClick={async(e)=>{
                            try {

                                const result2= await axios.post('/p40shop/forgetshoppassword',
                                forgetShopPasswordState,
                                genMyTk(myheader))

                                //console.log(result2.data)
                                //setShowUserChangePassword(false)

                                //localStorage.removeItem("userauthorization");
                                //localStorage.removeItem("username")

                                window.location.reload()
                            }
                            catch (err){
                                displayError("IL955",err)
                            }


                            //submitFunc(inputState,setInputState)
                        }}
                    >
                        <FaCheck/>
                    </button>
                
                    <button
                    //ref={refCancel}
                        ref={refForgetShopPasswordCancel}
                        onClick={e=>{
                            //submitCancel()
                            //setShowUserChangePassword(false)
                            //setShowShopSignUp(false)
                            setShowForgetShopPassword(false)
                        }}
                    >
                        <FaBan/>
                    </button>  
                
            </div>
    </div>

    )
}



const renderLogInWithRecoveryPassword=()=>{

    const tempArray=[
        "shopName",
        "recoveryPassword",

        "newShopPassword",
        "confirmNewShopPassword",

    ]
    const refArray=[
        refLogInWithRecoveryPasswordShopName,
        refLogInWithRecoveryPasswordRecoveryPassword,
        refLogInWithRecoveryPasswordNewShopPassword,
        refLogInWithRecoveryPasswordConfirmNewShopPassword
    ]


    return (
        <div className='bd-pureWhite' 
            style={{
                padding:"3rem",
                minHeight:"40%",
                minWidth:"60%"
            }}
        >
            
                <div className='w-100 flex-center-center'
                    style={{marginBottom:"1rem"}}
                >
                    <h3>{
                        languageDisplay
                        ?languageDisplay.loginWithRecoveryPassword
                            ?languageDisplay.loginWithRecoveryPassword
                            :"LOG IN WITH RECOVERY PASSWORD"
                        :"LOG IN WITH RECOVERY PASSWORD"
                    }</h3>
                </div>
    
                {   
                    tempArray.map((i,idxI)=>{
                        
                        return (
                            <div key={idxI}
                                className="flex-center-center jc-start w-100"
                                style={{marginBottom:"1rem"}}
                            >
                
                                <div className="xc4">
                                    {
                                    languageDisplay
                                    ?languageDisplay[i]
                                        ?languageDisplay[i]
                                        :""
                                    :""                               
                                    }
                                </div>
                                
                                <div className="xc8"
                                    style={{position:"relative"}}
                                >
                                    <input
                                            
                                            style={{color:(hidePassword&&i==="recoveryPassword")||
                                                          (hidePassword&&i==="newShopPassword")||
                                                          (hidePassword&&i==="confirmNewShopPassword")
                                                    ?"transparent"
                                                    :null
                                            }}

                                            ref={refArray[idxI]}
                                            type={"text"}

                                            value={
                                                logInWithRecoveryPasswordState[i]
                                            }

                                            onKeyDown={e=>{
                            

                                                if(e.key=="Enter"){
                                                    
                                                    if(i=="shopName"){
                                                        refLogInWithRecoveryPasswordRecoveryPassword.current.focus()
                                                    }
                                                    else if(i=="recoveryPassword"){
                                                        refLogInWithRecoveryPasswordNewShopPassword.current.focus()
                                                    }
                                                    else if(i=="newShopPassword"){
                                                        refLogInWithRecoveryPasswordConfirmNewShopPassword.current.focus()
                                                    }
                                                    else if(i=="confirmNewShopPassword"){
                                                        refLogInWithRecoveryPasswordSubmit.current.focus()
                                                    }
                                                    
                                                }
                                            }}

                                        onChange={e=>{

                                            if(i==="shopName"){
                                                setLogInWithRecoveryPasswordState({
                                                    ...logInWithRecoveryPasswordState,
                                                    [i]:e.target.value
                                                })
                                            }
                                            else {
                                                 
                                                const lastChar=e.target.value.slice(-1)

                                                let tempResult=checkChar(lastChar)
                                                if(e.target.value.length>15){
                                                    tempResult=false
                                                }

                                                let tempStar=""
                                                for(let z=0;z<e.target.value.length;z++){
                                                    tempStar=tempStar+"*"
                                                }
                                                

                                                if(tempResult||e.target.value===""){
                                                    setLogInWithRecoveryPasswordState({
                                                        ...logInWithRecoveryPasswordState,
                                                        [i]:e.target.value,
                                                        [i+"Star"]:tempStar
                                                    })
                                                }

                                            }

                                        }}
                                    />

                                    {(i==="recoveryPassword"&&hidePassword)||
                                     (i==="newShopPassword"&&hidePassword)||
                                     (i==="confirmNewShopPassword"&&hidePassword)
                                    ?<div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {logInWithRecoveryPasswordState[i+"Star"]}
                                    </div>
                                    :null
                                    }

                                    {
                                        i=="shopName"
                                        ?null
                                        :hidePassword
                                            ?<MdVisibilityOff 
                                            className="sm-icon" 
                                            style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                            onClick={e=>{
                                                setHidePassword(!hidePassword)
                                            }}
                                            />
                                            :<MdVisibility 
                                            className="sm-icon" 
                                            style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                            onClick={e=>{
                                                setHidePassword(!hidePassword)
                                            }}
                                        />
                                    }
                                
                                </div>
                            </div>
                        )
                    })
                }

                <div style={{
                        display:"flex",
                        justifyContent:"flex-end"
                        }}
                >
                        <button
                            ref={refLogInWithRecoveryPasswordSubmit}
                            onClick={async(e)=>{
                               try {

                                    const result2= await axios.post('/p40shop/loginwithrecoverypassword',
                                    logInWithRecoveryPasswordState,
                                    genMyTk(myheader))

                                    //console.log(result2.data)
                                    
                                    localStorage.removeItem("shopauthorization");
                                    localStorage.removeItem("expirationDate");

                                    window.location.reload()
                                }
                                catch (err){

                                    displayError("IL1129",err)

                                }





                            }}
                        >
                            <FaCheck/>
                        </button>
                    
                        <button
                            ref={refLogInWithRecoveryPasswordCancel}
                            onClick={e=>{
                                setShowLogInWithRecoveryPassword(false)
                   
                            }}
                        >
                            <FaBan/>
                        </button>  
                    
                </div>

        </div>
    )
}
//==============================
const submitShopLogInForShopPayment=async()=>{

    console.log('submitShopLogInForShopPayment......')
    try {
       const result2= await axios.post('/p40shop/shoploginforshoppayment',
            shopLogInForShopPaymentState,
            genMyTk(myheader))

        localStorage.setItem("loginshopname",shopLogInForShopPaymentState.shopName)
        localStorage.setItem("loginusername","")

        setShowShopLogInForShopPayment(false)
        setShowShopPayment(true)
        
        //console.log('result2.data....')
        //console.log(result2.data)

        setShopLogInForShopPaymentState({
            shopName:"",
            shopPassword:""
        })

        setHidePassword(true)

        const {shopName,shopToken,
               expirationDate,monthlyFee,
               remainingBalance,newExpirationDate,overdueBalance,
               
               companyBankAccountNameThai,
               companyBankAccountNumberThai,
               companyBankAccountBankThai,

               companyBankAccountNameEnglish,
               companyBankAccountNumberEnglish,
               companyBankAccountBankEnglish,   

              } = result2.data

        //console.log('result2.data shoploginforshoppyament')      
        //console.log(result2.data)
        let temp={}

        localStorage.setItem("shopauthorization",`b ${shopToken}`);

        if(languageSetting==="Thai"){
            temp={
                shopName,shopToken,
                expirationDate,monthlyFee,
                remainingBalance,newExpirationDate,overdueBalance,

                companyBankAccountName:companyBankAccountNameThai,
                companyBankAccountNumber:companyBankAccountNumberThai,                                        
                companyBankAccountBank:companyBankAccountBankThai,
            }
        }
        else {
            temp={
                shopName,shopToken,
                expirationDate,monthlyFee,
                remainingBalance,newExpirationDate,overdueBalance,

                companyBankAccountName:companyBankAccountNameEnglish,
                companyBankAccountNumber:companyBankAccountNumberEnglish,
                companyBankAccountBank:companyBankAccountBankEnglish,   
            }
        }
       
        setShopPaymentState({...shopPaymentState,...temp})
        setCompanyBankState({
            companyBankAccountNameThai,
            companyBankAccountNumberThai,
            companyBankAccountBankThai,

            companyBankAccountNameEnglish,
            companyBankAccountNumberEnglish,
            companyBankAccountBankEnglish,   
        })
    }
    catch (err){
        displayError("IL1215",err)
      
    }
}

const genDate=(date)=>{
    let tempDate=""
    
    if(date){
        tempDate=new Date(date).toLocaleString('en-GB')
    }
   
    return tempDate

}

const renderShopLogInForShopPayment=()=>{

    const tempArray=["shopName","shopPassword"]
    
    const refArray=[
        refShopLogInForShopPaymentShopName,
        refShopLogInForShopPaymentShopPassword,
    ]

    return (
    <div className='bd-pureWhite' 
        style={{
            padding:"3rem",
            minHeight:"40%",
            minWidth:"60%"
        }}
    >
        
            <div className='w-100 flex-center-center'
                style={{marginBottom:"1rem"}}
            >
                <h3>{
                    languageDisplay
                    ?languageDisplay.shopPayment
                        ?languageDisplay.shopPayment
                        :"Service Payment"
                    :"Service Payment"
                }</h3>
            </div>

            <div className='w-100 flex-center-center'
                style={{marginBottom:"1rem"}}
            >
                <h6>{
                    languageDisplay
                    ?languageDisplay.detailShopLogInForShopPayment
                        ?languageDisplay.detailShopLogInForShopPayment
                        :"PLEASE LOG IN SHOP TO ACQIURE SERVICE FEE"
                    :"PLEASE LOG IN SHOP TO ACQUIRE SERVICE FEE"
                }
                </h6>
            </div>

            
            {   
                tempArray.map((i,idxI)=>{
                    
                    return (
                        <div key={idxI}
                            className="flex-center-center jc-start w-100"
                            style={{marginBottom:"1rem"}}
                        >
            
                            <div className="xc4">
                                {
                                languageDisplay
                                ?languageDisplay[i]
                                    ?languageDisplay[i]
                                    :""
                                :""                               
                                }
                            </div>
                            
                            <div className="xc8"
                                style={{position:"relative"}}
                            >
                                <input
                                
                                    style={{color:hidePassword&&i==="shopPassword"
                                            ?"transparent"
                                            :null
                                    }}

                                    type={"text"}
                               
                                    ref={refArray[idxI]}
                                  
                                    value={
                                        shopLogInForShopPaymentState[i]
                                    }


                                    onKeyDown={e=>{

                                        if(i==="shopPassword"){
                                            if(e.key==="Enter"){
                                                refShopLogInForShopPaymentSubmit.current.focus()  
                                            }
                                        }

                                        if(i==="shopName"){
                                            if(e.key=="Enter"){
                                                refShopLogInForShopPaymentShopPassword.current.focus()
                                            }
                                        }
                                    }}

                                    onChange={e=>{
                                        if(i==="shopName"){
                                            setShopLogInForShopPaymentState({
                                                ...shopLogInForShopPaymentState,
                                                shopName:e.target.value
                                            })
                                        }

                                        else if(i==="shopPassword"){
                                            const lastChar=e.target.value.slice(-1)

                                            let tempResult=checkChar(lastChar)
                                            if(e.target.value.length>15){
                                                tempResult=false
                                            }

                                            let tempStar=""
                                            for(let z=0;z<e.target.value.length;z++){
                                                tempStar=tempStar+"*"
                                            }

                                            if(tempResult||e.target.value===""){
                                                setShopLogInForShopPaymentState({
                                                    ...shopLogInForShopPaymentState,
                                                    [i]:e.target.value,
                                                    [i+"Star"]:tempStar
                                                })
                                            }

                                        }

                                    }}

                                />
                                
                                {i==="shopPassword"&&hidePassword&&
                                    <div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {shopLogInForShopPaymentState[i+"Star"]}
                                    </div>
                                }


                                {
                                i=="shopPassword"
                                ?hidePassword
                                    ?<MdVisibilityOff 
                                    className="sm-icon" 
                                    style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                    onClick={e=>{
                                    setHidePassword(!hidePassword)
                                    }}
                                    />
                                    :<MdVisibility 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                    />
                                :null
                                }
                               
                            </div>
                        </div>
                    )
                })
            }

           
            <div style={{
                    display:"flex",
                    justifyContent:"flex-end"
                    }}
            >
                    <button
                        ref={refShopLogInForShopPaymentSubmit}
                        onClick={e=>{
                            submitShopLogInForShopPayment(shopLogInForShopPaymentState)
                        }}
                    >
                        <FaCheck/>
                    </button>
                
                    <button
                    //ref={refCancel}
                        onClick={e=>{
                            setShowShopLogInForShopPayment(false)
                        }}
                    >
                        <FaBan/>
                    </button>  
                
            </div>
    </div>

    )

}
//==================================
const genFD=(inputState)=>{
    //console.log('genFD')

    const tempArray=Object.keys(inputState)
    const fd=new FormData()
    
    tempArray.map((b,idxB)=>{
        //console.log(b)
        if(b==="file1"){
            for(let i=0;i<inputState[b].length;i++){
                const fileName=`${inputState[b][i].name}`
                //console.log()
                fd.append(`imageUrl`,inputState[b][i],fileName);  
            }
        }
        else if(b==="photoUrl1"){
            inputState[b].map((c,idxC)=>{
                //console.log(`${b}[${idxC}].......${inputState[b][idxC]}...`)
                fd.append(`${b}[${idxC}]`,inputState[b][idxC])
            })
        }
        else {
            fd.append(`${b}`,inputState[b])
        }
    })
    //console.log('end genFD')
    return fd

}
//==================================
const addFileNameInPhotoUrl=(formInputState)=>{
    let temp1=[]
    if(formInputState.file1){
        //console.log(1)
      for (let i=0;i<formInputState.file1.length;i++){
        temp1=[...temp1,formInputState.file1[i].name]
      }
    }
    if(formInputState.photoUrl1){
      //console.log(2)  
      formInputState.photoUrl1=[...formInputState.photoUrl1,...temp1]
    }
    //console.log(3)
    return formInputState
  }

//==================================
const submitAddShopPayment=async()=>{
    //console.log("submitAddShopPayment..........")
    //console.log(currentLink)
    try{ 
            //console.log(1)
            /*
            const myheader2={headers: 
                {
                'Content-Type': 'application/json',
                'Shopauthorization':`b ${shopPaymentState.shopToken}`
                }
            }
            */


            //console.log(2)
            //console.log(shopPaymentState)
            
            const {file1,transferAmount,shopName,
                photoUrl1,...remaining}=shopPaymentState
            
            //console.log(file1)
            //console.log(transferAmount)    
            
            if(file1&&transferAmount>0){

                    let checkFile1=false
                    if(file1){
                        if(file1.length>0){
                            checkFile1=true
                        }
                    }
                    //console.log(3)
                    
                    if(checkFile1){
                        //console.log(4)

                        const tempFormInputState2=addFileNameInPhotoUrl({file1,photoUrl1,transferAmount})
                        //console.log("tempFormInputState2")
                        //console.log(tempFormInputState2)
                        const fd=genFD(tempFormInputState2)
                        const result =await axios.post('/p40shoppayment/addcustomshoppayment',
                                        fd,
                                        genMyTk(myheader))
                        
                        //console.log('result.data')
                        //console.log(result.data)
                        window.location.reload()


                        /*
                        setShowShopLogInForShopPayment(false)
                        setShowShopPayment(false)

                        setShopLogInForShopPaymentState({
                            shopName:"",
                            shopPassword:""
                        })
                    
                        setShopPaymentState({
                            shopName:"",
                            expirationDate:"",
                            monthlyFee:"",
                            remainingBalance:"",
                            newExpirationDate:"",
                            overdueBalance:"",
                    
                            companyBankAccountName:"",
                            companyBankAccountNumber:"",
                            companyBankAccountBank:"",
                    
                            shopToken:"",
                            file1:null,
                            photoUrl1:[]
                        })
                        */

                    }       
            } 
    }
    catch(err){
        displayError("IL1541",err)
    
    }        
}
//===================================

const renderShopPayment=()=>{

    const tempArray=[
        "shopName",
        "expirationDate",
        "overdueBalance",
    ]

    const tempArray2=[
        "companyBankAccountBank",
        "companyBankAccountName",
        "companyBankAccountNumber",
    ]

    return(
    <div className='shoppaymentbox' 
    >
        
            <div className='w-100 flex-center-center'
                style={{marginBottom:"0rem"}}
            >
                <h3>{
                    languageDisplay
                    ?languageDisplay.shopPayment
                        ?languageDisplay.shopPayment
                        :"Service Payment"
                    :"Service Payment"
                    }
                </h3>
               
            </div>

            <div className='w-100 flex-center-center'
                style={{marginBottom:"0rem"}}
            >
                <h6>{
                    languageDisplay
                    ?languageDisplay.detailCheckBeforeShopPayment
                        ?languageDisplay.detailCheckBeforeShopPayment
                        :"PLEASE CHECK OVERDUE BALANCE BEFORE PAYMENT"
                    :"PLEASE CHECK OVERDUE BALANCE BEFORE PAYMENT"
                }
                </h6>
            </div>


            {tempArray.map((i,idxI)=>{
                return (
                    <div key={idxI}
                        className='w-100 flex-center-center jc-start'
                        style={{marginBottom:"0rem"}}
                    >
                        <h6
                            style={{
                                marginRight:"0.5rem",
                                color:"red",
                                fontSize:i==="overdueBalance"?"1.6rem":""
                                
                            }}
                        >{
                           languageDisplay
                           ?languageDisplay[i]
                               ?languageDisplay[i]
                               :""
                           :""
                        }
                        </h6>
                        <h6 style={{
                                color:"red",
                                fontSize:i==="overdueBalance"?"2rem":""
                            }}>
                            {i==="expirationDate"||i==="newExpirationDate"
                            ?`: ${genDate(shopPaymentState[i])}`
                            :shopPaymentState[i]
                            }
                        </h6>

                        <h6 style={{
                                marginLeft:"0.5rem",color:"red",
                                fontSize:i==="overdueBalance"?"1.6rem":""
                            }}>
                            {i==="monthlyFee"||i==="remainingBalance"||i==="overdueBalance"
                            ?languageDisplay
                                ?languageDisplay.bath
                                    ?languageDisplay.bath
                                    :""
                                :""
                            :null
                            }
                        </h6>

                    </div>
                )
            })}


            <div className='w-100 flex-center-center'
                style={{marginBottom:"0rem"}}
            >
                <h5>{
                    languageDisplay
                    ?languageDisplay.detailCompanyBankAccount
                        ?languageDisplay.detailCompanyBankAccount
                        :"PLEASE TRANSFER MONEY TO OUR BANK ACCOUNT AS FOLLOWING AND ATTACH TRANSACTION SLIP AS IMAGE FILE"
                    :"PLEASE TRANSFER MONEY TO OUR BANK ACCOUNT AS FOLLOWING AND ATTACH TRANSACTION SLIP AS IMAGE FILE"
                    }
                </h5>
            </div>


            {tempArray2.map((i,idxI)=>{
                return (
                    <div key={idxI}
                        className='w-100 flex-center-center jc-start'
                        style={{marginBottom:"0rem"}}
                    >
                        <h6
                            style={{marginRight:"0.5rem",color:"green"}}
                        >{
                           languageDisplay
                           ?languageDisplay[i]
                               ?languageDisplay[i]
                               :""
                           :""
                        }
                        </h6>
                        <h6 style={{marginRight:"0.5rem",color:"green"}}>
                            :
                        </h6>
                        <h6 style={{color:"green"}}>
                            {
                            shopPaymentState[i]
                            }
                        </h6>                  

                    </div>
                )
            })}


            <div
                className="flex-center-center jc-start w-100"
                style={{padding:"1rem",backgroundColor:""}}
            >
            
                    <div className="xc4">
                        <h5>
                            {
                            languageDisplay
                            ?languageDisplay.transferAmount
                                ?languageDisplay.transferAmount
                                :"TRANSFER AMOUNT (BAHT)"
                            :"TRANSFER AMOUNT (BAHT)"
                            }
                        </h5>
                    
                    </div>
                    
                    <div className="xc8"
                        style={{position:"relative"}}
                    >
                        <input
                            type="number"
                            onChange={e=>{
                                setShopPaymentState({
                                    ...shopPaymentState,
                                    ["transferAmount"]:e.target.value
                                }) 
                            }}
                        />
                    </div>
            </div>

            <div className="xc8 lc8 mc10 sc12 bgc-darkGray bd-lightGray h-50"
                 style={{height:"30%"}}
            >
                <Gallery20_add 
                    fileUrl={fileUrl1}
                    arrayFile={arrayFile1}
                    setArrayFile={setArrayFile1}
                    keyName={"photoUrl1"}

                    setShowImage={setShowImage1}
                    inputState={shopPaymentState}
                    setInputState={setShopPaymentState}

                    imageLimit={imageLimit}
                    useYoutubeLink={false}
                />
            </div>    

            <div style={{
                    display:"flex",
                    justifyContent:"flex-end"
                    }}
            >
                    <button
                        //ref={refShopPayment}
                        onClick={e=>{
                            submitAddShopPayment(shopPaymentState)
                        }}
                    >
                        <FaCheck/>
                    </button>
                
                    <button
                    //ref={refCancel}
                        onClick={e=>{
                            //setShowShopLogInForShopPayment(false)
                            const temp={
                                shopName:"",
                                expirationDate:"",
                                monthlyFee:"",
                                remainingBalance:"",
                                newExpirationDate:"",
                                overdueBalance:"",
                        
                                companyBankAccountName:"",
                                companyBankAccountNumber:"",
                                companyBankAccountBank:"",
                        
                                shopToken:"",
                                file1:null,
                                photoUrl1:[]
                            }


                            setShopPaymentState(temp)

                            const temp2={
                                shopName:"",
                                shopPassword:""
                            }

                            setShopLogInForShopPaymentState(temp2)

                            setShowShopLogInForShopPayment(false)
                            setShowShopPayment(false)
                        }}
                    >
                        <FaBan/>
                    </button>  
                
            </div>


     </div>
    )
}


const submitShopSignUp=async()=>{
    //console.log('submitShopSignUp......')

    try {
       const result2= await axios.post('/p40shop/shopsignup',{
            shopName:shopSignUpState.shopName,
            shopPassword:shopSignUpState.shopPassword,
            ownerName:shopSignUpState.ownerName,
            ownerSurname:shopSignUpState.ownerSurname,
            ownerEmail:shopSignUpState.ownerEmail,
            username:shopSignUpState.username,
            userPassword:shopSignUpState.userPassword

        },
        genMyTk(myheader))

        //console.log(result2.data)
        if(result2.data){
            if(result2.data.signup_image){
                setSignUpImage(result2.data.signup_image)
            }
        }
      
        setShowModalCapChaForShopSignUp(false)
        setShowShopSignUp(false)
        setShopSignUpState({
            shopName:"",shopPassword:"",
            ownerName:"",ownerSurname:"",ownerEmail:"",
            username:"",userPassword:""
        })

        //setShowModalCapChaForShopLogIn(false)

        //window.location.reload()
    }
    catch (err){
        displayError("IL1694",err)
    }

}



const submitShopLogIn=async()=>{
    //console.log("submit shop log in")
    try {
        axios.post('/p40shop/shoplogin',{
            shopName:shopLogInState.shopName,
            shopPassword:shopLogInState.shopPassword
        },
        genMyTk(myheader))

        .then(result2=>{          
    
            const shopToken=`b ${result2.data.shopToken}`
            localStorage.setItem("shopauthorization",shopToken);
            localStorage.setItem("expirationDate",result2.data.expirationDate)

            localStorage.setItem("loginshopname",shopLogInState.shopName)
            localStorage.setItem("loginusername","")

            const temp={
                ...tokenSt,
                reloadCheckToken:true,
                haveShopToken:true,
            }
            setTokenSt(temp)
            setShopLogInState({shopName:"",shopPassword:""})
            setHidePassword(true)

        })
        .catch(err=>{
            //setShopLogInState({shopName:"",shopPassword:""})
            displayError("IL1870",err)

        })
    }
    catch (err){
        displayError("IL1878",err)
    }

}

const renderShopLogIn=()=>{
    const tempArray=[
        "shopName",
        "shopPassword"
    ]
    const refArray=[
        refShopLogInShopName,refShopLogInPassword
    ]
    
   
    return (
        <div className='bd-pureWhite' 
        style={{
            padding:"3rem",
            minHeight:"40%",
            minWidth:"60%"
        }}
        >
        
                <div className='w-100 flex-center-center'
                    style={{marginBottom:"1rem"}}
                >
                    <h3>{
                          languageDisplay
                          ?languageDisplay.shopLogInForm
                              ?languageDisplay.shopLogInForm
                              :"SHOP LOG IN"
                          :"SHOP LOG IN"
                    }</h3>
                </div>

                {   
                    tempArray.map((i,idxI)=>{

                        return (
                            <div key={idxI}
                                className="flex-center-center jc-start w-100"
                                style={{marginBottom:"1rem"}}
                            >
                
                                <div className="xc4">
                                    {
                                       languageDisplay
                                       ?languageDisplay[i]
                                           ?languageDisplay[i]
                                           :""
                                       :""                                   
                                    }
                                </div>
                                
                                <div className="xc8"
                                    style={{position:"relative"}}
                                >
                                    <input 
                                        style={{color:hidePassword&&i==="shopPassword"
                                                    ?"transparent"
                                                    :null
                                              }}

                                        ref={refArray[idxI]}
                                      
                                        type={"text"}
                                       
                                        value={                                     
                                            shopLogInState[i]
                                        }

                                        onKeyDown={e=>{
                                        
                                            if(i==="shopName"){
                                                if(e.key=="Enter"){
                                                    refShopLogInPassword.current.focus()
                                                }
                                            }
                                            
                                            if(i==="shopPassword"){
                                                if(e.key=="Enter"){
                                                    refShopLogInSubmit.current.focus()
                                                }
                                            }
    
                                        }}
    
    
                                       onChange={e=>{
                                            if(i==="shopName"){
                                                setShopLogInState({
                                                    ...shopLogInState,
                                                    [i]:e.target.value
                                                })
                                            }
                                            else if(i==="shopPassword"){
                                               
                                                const lastChar=e.target.value.slice(-1)

                                                let tempResult=checkChar(lastChar)
                                                if(e.target.value.length>15){
                                                    tempResult=false
                                                }

                                                let tempStar=""
                                                for(let z=0;z<e.target.value.length;z++){
                                                    tempStar=tempStar+"*"
                                                }
                                                

                                                if(tempResult||e.target.value===""){
                                                    setShopLogInState({
                                                        ...shopLogInState,
                                                        [i]:e.target.value,
                                                        [i+"Star"]:tempStar
                                                    })
                                                }

                                            }

                                        }}
                                        
                                    />

                                    {i==="shopPassword"&&hidePassword&&
                                    <div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {shopLogInState[i+"Star"]}
                                    </div>
                                    }
                                    
                                    {
                                    i==="shopPassword"
                                    ?hidePassword
                                        ?<MdVisibilityOff 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                        :<MdVisibility 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                    :null
                                    }

                                </div>
                            </div>
                        )
                    })
                }

                
                <div className="bill-p"
                  style={{display:"flex",justifyContent:"flex-start",alignItems:"baseline",
                           margin:"1rem 0"}}
                  onClick={e=>{
                     setShowShopSignUp(true)
                  }}
               >
                     <MdAddCircle style={{marginLeft:"-1rem"}}/>
                     <BsShop style={{fontSize:"2rem"}}/>

                     <div style={{marginLeft:"1rem"}}>
                        {
                            languageDisplay
                            ?languageDisplay.createNewShopAccount
                                ?languageDisplay.createNewShopAccount
                                :"CREATE A NEW ACCOUNT"
                            :"CREATE A NEW ACCOUNT"
                        }
                     </div>
               </div>

               <div className="bill-p"
                  style={{display:"flex",justifyContent:"flex-start",alignItems:"baseline",
                           margin:"1rem 0"}}

                  onClick={e=>{
                     setShowForgetShopPassword(true)

                  }}
               >
                  <MdOutlineVpnKeyOff style={{marginLeft:"-1rem"}}/>
                  <BsShop   style={{fontSize:"2rem"}}/>

                  <div style={{marginLeft:"1rem"}}>
                     {
                        languageDisplay
                        ?languageDisplay.forgetShopPassword
                            ?languageDisplay.forgetShopPassword
                            :"FORGET ACCOUNT PASSWORD"
                        :"FORGET ACCOUNT PASSWORD"
                     }
                  </div>
               </div>


               <div className="bill-p"
                  style={{display:"flex",justifyContent:"flex-start",alignItems:"baseline",
                           margin:"1rem 0"}}

                  onClick={e=>{
                     setShowLogInWithRecoveryPassword(true)

                  }}
               >
                  <MdOutlineVpnKey style={{marginLeft:"-1rem"}}/>
                  <BsShop   style={{fontSize:"2rem"}}/>

                  <div style={{marginLeft:"1rem"}}>
                     {
                        languageDisplay
                        ?languageDisplay.loginWithRecoveryPassword
                            ?languageDisplay.loginWithRecoveryPassword
                            :"LOG IN WITH RECOVERY PASSWORD"
                        :"LOG IN WITH RECOVERY PASSWORD"
                     }
                  </div>
               </div>

               <div className="bill-p"
                  style={{display:"flex",justifyContent:"flex-start",alignItems:"baseline",
                           margin:"1rem 0"}}

                  onClick={e=>{
                     setShowShopLogInForShopPayment(true)
                  }}
               >
                  <FaMoneyBillWave style={{marginLeft:"-1rem"}}/>
                  <BsShop   style={{fontSize:"2rem"}}/>

                  <div style={{marginLeft:"1rem"}}>
                     {
                      languageDisplay
                      ?languageDisplay.shopPayment
                          ?languageDisplay.shopPayment
                          :"SERVICE PAYMENT"
                      :"SERVICE PAYMENT"
                     }
                  </div>
               </div>

              

                <div style={{
                        display:"flex",
                        justifyContent:"flex-end"
                        }}
                >
                        <button
                            ref={refShopLogInSubmit}
                            onClick={async(e)=>{
                                setShowModalCapChaForShopLogIn(true)
                             
                            }}
                        >
                            <FaCheck/>
                        </button>
                    
                        <button
                        //ref={refCancel}
                            onClick={e=>{
                                //submitCancel()
                                //setShowUserChangePassword(false)
                            }}
                        >
                            <FaBan/>
                        </button>  
                    
                </div>


                {
                  showModalCapChaForShopLogIn
                  ?<ModalCapCha
                    setShow={setShowModalCapChaForShopLogIn}
                    inputState={shopLogInState}
                    setInputState={setShopLogInState}
                    submitFunction={submitShopLogIn}
                    languageDisplay={languageDisplay}
                    displayError={displayError}

                  />
                  :null
                }


        </div>

    )
}
//=========================

const getSignUpImage=async()=>{

    //console.log('getSignUpImage......')
    try {
        const result2= await axios.post('/p40shop/getsignupimage',
            {},
            genMyTk(myheader))


        if(result2.data){
            if(result2.data.signUpImage){
                setSignUpImage(result2.data.signUpImage)
            }
        }
        
    
    }
    catch (err){
        displayError("IL409",err)
    }

}

//=========================

    const renderBody=()=>{

        if(tokenSt.haveShopToken&&
            tokenSt.haveUserToken&&
            showUserChangePassword
        ){
            return(
            renderUserChangePassword()
        )}
        else if(tokenSt.haveShopToken&&
                tokenSt.haveUserToken
        ){
            return(
            renderAvatar()
        )}
        else if(tokenSt.haveShopToken){
            return(
            renderUserLogIn()
        )}
        else if(showShopSignUp){
            return(
            renderShopSignUp()
        )}
        
        else if(showForgetShopPassword
            ){
            return renderForgetShopPassword()
        }
        else if(showLogInWithRecoveryPassword){
            return renderLogInWithRecoveryPassword()
        }
       
        else if(showShopLogInForShopPayment
        ){
            return(
                renderShopLogInForShopPayment()
        )}

        else if(showShopPayment){
            return(
                renderShopPayment()
            )
        }
        else {
            return renderShopLogIn()
        }

    }
  
    return (
        <div className='' 
            style={{
                border:"",height:"100vh",width:"100vw",
                overflow:"hidden",
                }}>
        
            <div style={{position:"fixed",
                width:"200vw",height:"15vh",
                top:"-2rem",left:"-5rem",
                transform: "rotate(15deg)",
                backgroundColor:"#ff9933",
            }}
            >     
            </div>       
            
            <div className='bgc-shadeGray' 
                style={{ display:"flex",
                          height:"100%",
                          width:"100%",
                          overflow:"auto",
                          marginBottom:"2rem"      
                    }}>
                
                <div className='flex-center-center' 
                    style={{height:"100%",width:`100%`,padding:"1rem"
                }}>
                    {renderBody()}
                </div>

            </div>
                   
            
            <LogOutTool
                useShopLogOut={true}
                tokenSt={tokenSt}
                setReloadCheckToken={(val)=>{
                        setTokenSt({...tokenSt,reloadCheckToken:val})
                }}

                user={user}
                useHomeIcon={true}
                formTemplate={formTemplate}
                Language={languageSetting}    
                languageDisplay={languageDisplay}         

                languageSetting={languageSetting}
                setLanguageSetting={setLanguageSetting}
            />

            {
             showModalLineSignUp&&
                <ModalLineSignUp
                    signUpImage={signUpImage}
                    languageDisplay={languageDisplay}
                    
                    getSignUpImage={getSignUpImage}

                    setShow={()=>{
                        setShowModalLineSignUp(false)
                        window.location.reload()
                    }}
                />   
            }


            <div className='d-none'
            >
                <div>
                    <Link ref={refHome} to="/cloud/home"/>
                </div>
            </div>

        </div>
       
    )

}

export default ItemLogin