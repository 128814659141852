import React from 'react';

import {FaBan,FaCheck} from 'react-icons/fa';
import Ticon from '../../component/ticon/Ticon';
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'

import '../../component/mnavbar/Mnavbar.css';

function LogOutTool(props) {

   const {//useShopLogOut,haveShopToken,haveUserToken,userName,
      useShopLogOut,
      tokenSt,
      setReloadCheckToken,
      user,
      useHomeIcon,
      myheader,
      useBillWaveIcon,
      formTemplate,
      Language,
      languageDisplay,
      
      languageSetting,
      setLanguageSetting
   }=props
  
   //console.log(formTemplate)

   const shouldRender= React.useRef(true)
   React.useEffect(()=>{
      return () => {
         //console.log('end................................')
         shouldRender.current=false
      } 
   },[])

   const {haveShopToken,haveUserToken}=tokenSt
  //const {myheader,tokenSt,setReloadCheckToken}=React.useContext(MainContext)
  //const {reloadCheckToken,haveShopToken,haveUserToken,userName}=tokenSt
  const refHome=React.createRef()
  const refLogin=React.createRef()

  const [showConfirmBeforeShopLogout,
         setShowConfirmBeforeShopLogout
        ]=React.useState(false)

   const [showConfirmBeforeUserLogout,
         setShowConfirmBeforeUserLogout
        ]=React.useState(false)

   
  const getIconStyle=()=>{
     
   if(shouldRender.current){
     const toDate=new Date()
     
     let temp1=localStorage.getItem("expirationDate")
     const expirationDate=new Date(temp1)
     
     if(!temp1){
        return null
     }

     const temp3=new Date(expirationDate.toISOString())
     temp3.setDate(temp3.getDate()-3)
     const threeDayBeforeExpirationDate=new Date(temp3)
      if(toDate>expirationDate){
         console.log("toDate>expirationDate")
         return {color:"red",fontSize:"1.5rem"}
      }
      else if(toDate>threeDayBeforeExpirationDate){
         console.log("toDate>threeDayBeforeExpirationDate")
         return {color:"yellow",fontSize:"1.5rem"}
      }
      else{
         console.log("else")
         return {color:"green",fontSize:"1.5rem"}
      }
   }
  }
//=======================

const renderUserLogOutWaringModal=()=>{
   return(
   <div className="Modal-background">
      <div className="Modal-box" 
           style={{
               margin:"5rem",
               minWidth:"60%",
               minHeight:"30%",
               zIndex:100

           }}>
          <div className="Modal-header" 
               style={{
                  fontSize:"1.2rem",
                  
               }}
          >
            {"formTemplate.confirmBeforeUserLogout.formHead"}
          </div>
          <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                 
                           localStorage.removeItem('userauthorization');
                           localStorage.removeItem('username');
                           setShowConfirmBeforeUserLogout(false)
                           refLogin.current.click()
                           //setReloadCheckToken(true)
                           //setReloadCheckToken(true)
                           window.location.reload()
                        
                        }}
                        
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        onClick={e=>{
                           setShowConfirmBeforeUserLogout(false)
                              //if(setShow){setShow(false)}
                        }}
                     >
                        <FaBan/>
                    </button>
                </div>

            </div>
      </div>
   </div>
   )
}


//=======================

const renderShopLogOutWaringModal=()=>{
   return(
   <div className="Modal-background">
      <div className="Modal-box" 
           style={{
               margin:"5rem",
               minWidth:"60%",
               minHeight:"30%"

           }}>
          <div className="Modal-header" 
               style={{
                  fontSize:"1.2rem",
                  
               }}
          >
            {
            languageDisplay
            ?languageDisplay.confirmBeforeShopLogout
               ?languageDisplay.confirmBeforeShopLogout
               :"Log Out From Account ??"
            :"Log Out From Account ??"
            }
          </div>
          <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                           localStorage.removeItem('shopauthorization');
                           localStorage.removeItem('expirationDate');
                           localStorage.removeItem('loginusername');

                           setShowConfirmBeforeShopLogout(false)
                           //setReloadCheckToken(true)
                           window.location.reload()
                        
                        }}
                        
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        onClick={e=>{
                           setShowConfirmBeforeShopLogout(false)
                              //if(setShow){setShow(false)}
                        }}
                     >
                        <FaBan/>
                    </button>
                </div>

            </div>
      </div>
   </div>
   )
}
//=======================
return (
<div className="" style={{position:"fixed",right:"1rem",top:"0.1rem"}}>


   
   <div className="" 
        style={{borderRadius:"1.2rem",padding:"0 0.5rem",height:"2rem",
                display:"flex",justifyContent:"space-between",alignItems:"center",
                flexWrap:"nowrap"}}>
        
        
     

   </div>

  
   {showConfirmBeforeUserLogout&&
   renderUserLogOutWaringModal()
   }

   {showConfirmBeforeShopLogout&&
   renderShopLogOutWaringModal()
   }
   
   
   <div className="" 
        style={{
                position:"fixed",right:"0.1rem",top:"0.5rem",
                borderRadius:"1.2rem",padding:"0 0.5rem",height:"2rem",
                display:"flex",justifyContent:"space-between",alignItems:"center",
                flexWrap:"nowrap"}}>
        
    

      {
       haveUserToken
          ?<div className="iconbox"
               onClick={e=>{
                  localStorage.removeItem('userauthorization');
                  localStorage.removeItem('username');
                  setReloadCheckToken(true)
                  window.location.reload()
               }}
          >
            <Ticon iconName="MdVpnKey" className="navIcon"
                    Language={Language}
             />
          </div>
          :haveShopToken
            ?useShopLogOut&&
            <div/>
            :null
       
       }

      {
         haveShopToken&&!haveUserToken&&useShopLogOut&&
         <div className="iconbox"
            style={{ 
               width:"100%",
               display:"flex",
               justifyContent:"center",
               paddingRight:"",
               //padding:"0.5rem"
            }} 
         onClick={e=>{
            setShowConfirmBeforeShopLogout(true)
         
         }}
         >
            <Ticon iconName="MdLogout" className="navIcon"
                    Language={Language}
            />
         </div>
       

      }

      {useHomeIcon&&
        <div className="iconbox"
         onClick={e=>refHome.current.click()}
        >
            <Ticon iconName="FaHome" 
                   className="navIcon" 
                   iconStyle={getIconStyle()}
                   Language={Language}
            />
        </div>
      }
   </div>

   <div className="flex-center-center navLink" 
        style={{
                position:"fixed",
                right:"0.3rem",top:"2.5rem",
                height:"2rem",
                width:"10rem",
               
               }}

         onClick={e=>{
            if(languageSetting==="Thai"){
               setLanguageSetting("English")
            }
            else {
               setLanguageSetting("Thai")
            }
         }}
   >
      <div
         style={{
            color:"white",
            fontSize:"1.1rem",
            fontWeight:"bold"
         }}
      >
      {"ไทย/Eng"}

      </div>
   </div>

   <div style={{display:"none"}}>
         <div>
            <Link ref={refHome} to="/cloud/home"/>
            <Link ref={refLogin} to="/cloud/login"/>
         </div>
   </div>


</div>

)}

LogOutTool.defaultProps={
   useShopLogOut:true,
   useHomeIcon:true
}
 


export default LogOutTool;
