import React from 'react'

import Table from './Table'

import RenderBadge from './RenderBadge'
import Mnavbar from '../../component/mnavbar/Mnavbar'
import fakeAxiosUtil from './fakeAxiosUtil'
import cosmeticUtil from '../../util/cosmeticUtil'
import ModalConfirm from './ModalConfirm'

import TableSetting from './TableSetting'
import {MdSettings} from 'react-icons/md';

import {MainContext} from '../../context/MainContext'
import console from '../../util/consoleVerify'
import ModalTypeSelect from './ModalTypeSelect'

const {checkWindowLandscape}=cosmeticUtil


//const {dataFormat}=dataTemplate


function ItemTable(){

    //const [tableTemplate,setTableTemplate] = React.useState(dataFormat)
    
    const {
        userArray,userTableTemplate,
        pageData,setPageData,
        currentPage,setCurrentPage,
        totalPage,setTotalPage,
        editInputState,setEditInputState,
        dataLabel,setDataLabel,
        dataLabel2,setDataLabel2,
        
        submitSearch,
        submitDeleteById,
        submitDataFormat,

        showTableSetting,
        setShowTableSetting,
        dfCommon,setDfCommon,
        countRecord,

        keyArray,keyArrayWithPhotoUrl,
        widthRight,setWidthRight,

        languageDisplay,
        
        defaultDataFormat,
        defaultDataFormatName,

        submitUpdateDefaultDataFormatWithFilterState,
        basicDataSt

    }=React.useContext(MainContext)

    
    const setEditInputStateWithNewId=(editData)=>{
        setEditInputState({...editData,newId:editData.id})
    }
    

    const [showConfirm,setShowConfirm]=React.useState(false)
    const [showModalTypeSelect,setShowModalTypeSelect]=React.useState(false)


    return (
       

    <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <Mnavbar/>
        </div>
     
        <div style={{height:checkWindowLandscape()?"5%":"8%",
                    width:"100%",
                    position:"relative"
                    }}
        >

            {
                RenderBadge({
                    totalPage:totalPage,
                    currentPage:currentPage,
                    setCurrentPage:setCurrentPage,
                    editInputState:editInputState,
                    setEditInputState:setEditInputState,
                    setShowConfirm:setShowConfirm,
                    badgeColor:"#F5DF4D",
                    submitSearch:submitSearch,
                    countRecord:countRecord,

                    showSetting:showTableSetting,
                    setShowSetting:setShowTableSetting,
                    defaultDataFormat:defaultDataFormat,
                    defaultDataFormatName:defaultDataFormatName
  
                })
            }

            {
                <div 
                    style={{
                        display:"flex",
                        alignItems:"baseline",
                        flexWrap:"nowrap",

                        position:"absolute",
                        zIndex:10,
                        top:"0rem",
                        right:"0"
                    }}
                >
                        
                        <h5 className='hovertext'
                            onClick={e=>{
                                setShowModalTypeSelect(true)
                            }}
                        >
                            {defaultDataFormat}
                        </h5>

                        <h5 className='hovertext showinbigscreen'
                            onClick={e=>{
                                setShowModalTypeSelect(true)
                            }}
                        >
                            {`.${defaultDataFormatName}`}
                        </h5>


                        <MdSettings className='md-icon'
                                style={{
                                    marginLeft:"1rem"
                                }}
                                onClick={e=>{
                                    setShowTableSetting(!showTableSetting)
                                }}
                        />
                </div>
                }
               

          
        </div>

        <div style={{ display:"flex",
                      height:checkWindowLandscape()?"90%":"84%",
                      width:"100%"
                      }}
                    >
            
            <div className='flex-center-center' 
                style={{height:"100%",
                        width:showTableSetting?`${widthRight}%`:"100%",
                        background:dfCommon.tableBackgroundColor
                        ?dfCommon.tableBackgroundColor
                        :"transparent"
                        }}
            >
                {dataLabel2&&
                <Table
                    dataLabel={dataLabel2}
                    setDataLabel={setDataLabel2}
                    pageData={pageData}
                    setPageData={setPageData}
                    editData={editInputState}
                    setEditData={setEditInputState}
                    keyArray={keyArrayWithPhotoUrl}
                />
                }
            </div>

            {showTableSetting&&
            <div
                className="bgc-shadeGray"
                style={{
                    height:"100%",
                    width:`${100-widthRight}%`,
                    //overflowY:"scroll"
                }}
            >
                <TableSetting
                    dataLabel={dataLabel2}   
                    setDataLabel={setDataLabel2}
                    submitDataFormat={submitDataFormat}
                    setShowTableSetting={setShowTableSetting}
                    dfCommon={dfCommon}
                    setDfCommon={setDfCommon}
                    keyArray={keyArrayWithPhotoUrl}
                    languageDisplay={languageDisplay}
                />
            </div>
            }
         

        </div>
     
        {showTableSetting&&
        <div
            style={{
                position:"fixed",
                bottom:"0",
                width:"100vw"
            }}
        >
            <input
                type="range" min="10" max="100" 
                value={widthRight}
                onChange={e=>{
                    setWidthRight(e.target.value)
                }}
            />
        </div>
        }

        {showConfirm&&editInputState
        ?<ModalConfirm
               setShow={setShowConfirm}
               submitFunction={submitDeleteById}
           
               
               editData={editInputState}
               languageDisplay={languageDisplay}

        />
        :null
        }   

        {
         showModalTypeSelect
         ?<ModalTypeSelect
            setShow={setShowModalTypeSelect}
            submitFunction={submitUpdateDefaultDataFormatWithFilterState}
            basicData={basicDataSt.basicData}
         />
         :null
        }

    </div>

    )

}

export default ItemTable


