const LangList={
    //a
    album:   {English:"ALBUM",Thai:"อัลบัม"},
    albumSetting: {English:"ALBUM SETTING",Thai:"ตั้งค่าอัลบั้ม"},
    account: {English:"ACCOUNT",Thai:"ปัญชี"},
    add:     {English:"ADD",Thai:"เพิ่ม"},
    inActive:{English:"INACTIVE ACCOUNT",Thai:"ยังไม่เปิดใช้งานบัญชี"},
    active:{English:"ACTIVE ACCOUNT",Thai:"เปิดใช้งาน"},

    accountName:{English:"",Thai:""},
    accountNumber:{English:"",Thai:""},
    addMonth:{English:"",Thai:""},
    allTrue:{English:"ALL CONDITIONS ARE TRUE",Thai:"ตรงตามเงื่อนไขทุกข้อ"},
    addUserModal:{English:"ADD USER",Thai:"เพิ่มผู้ใช้"},
    areYouRobot:{English:"ARE YOUR A BOT ??",Thai:"คุณเป็นบอทหรือเปล่า ??"},
    addDataFormatModal:{English:"ADD DATA FORMAT",Thai:"เพิ่มรูปแบบข้อมูล"},
    addContactToActivateSignUp:{
        English:"PLEASE ADD FRIEND, SUBMIT NEW ACCOUNT NAME TO ACCTIVATE THE ACCOUNT",
        Thai:"โปรดเพิ่มเพื่อนและแจ้งชื่อบัญชีใหม่เพื่อเริ่มใช้งานบัญชี"
    },
    activateNewAccount:{
        English:"ACCTIVATE NEW ACCOUNT",
        Thai:"เปิดใช้งานบัญชีใหม่"
    },
    
    //b
    barcodeSetting:{English:"Paper Setting",Thai:"ตั้งค่าหน้ากระดาษ"},
    barcodeHeight:{English:"Barcode Height",Thai:"ความสูงบาร์โค้ด"},
    barcodePaddingTop:{English:"Barcode Padding Top",Thai:"ช่องว่างบนของบาร์โค้ด"},
    barcodePaddingBottom:{English:"Barcode Padding Bottom",Thai:"ช่องว่างล่างของบาร์โค้ด"},
    barcodePaddingLeft:{English:"Barcode Padding Left",Thai:"ช่องว่างซ้ายของบาร์โค้ด"},
    barcodePaddingRight:{English:"Barcode Padding Right",Thai:"ช่องว่างขวาของบาร์โค้ด"},

    
    barcodeWidth:{English:"Barcode Width",Thai:"ความกว้างบาร์โค้ด"},
    bath:{English:"Bath",Thai:"บาท"},


    //c
    calendar:{English:"CALENDAR",Thai:"ปฎิทิน"},
    calendarSetting:{English:"CALENDAR SETTING",Thai:"ตั้งค่าปฎิทิน"},
    code:{English:"",Thai:""},
    checkBy:{English:"",Thai:""},
    changeShopPassword:{English:"Change Account Password",Thai:"เปลี่ยนรหัสบัญชี"},
    changeUserPasswordModal:{English:"CHANGE USER PASSWORD",Thai:"เปลี่ยนรหัสผู้ใช้"},
    confirmNewUserPassword:{English:"CONFIRM NEW USER PASSWORD",Thai:"ยืนยันรหัสผู้ใช้ใหม่อีกครั้ง"},
    confirmNewShopPassword:{English:"CONFIRM NEW ACCOUNT PASSWORD",Thai:"ยืนยันรหัสบัญชีใหม่"},
    
    createNewShopAccount:{English:"CREATE A NEW ACCOUNT",Thai:"สร้างบัญชีใหม่"},
    changeUserPassword:{English:"CHANGE USER PASSWORD",Thai:"เปลี่ยนรหัสผู้ใช้"},
    confirmBeforeShopLogout:{English:"LOG OUT FROM ACCOUNT ??",Thai:"ต้องการออกจากบัญชี ??"},
    
    companyBankAccountName:{
        English:"BANK ACCOUNT NAME",
        Thai:"ชื่อบัญชีธนาคาร"
    },
    companyBankAccountNumber:{
        English:"BANK ACCOUNT NUMBER",
        Thai:"เลขบัญชีธนาคาร"
    },
    companyBankAccountBank:{
        English:"BANK NAME",
        Thai:"ชื่อธนาคาร"
    }, 
    checkServiceFee:{English:"CHECK SERVICE FEE",Thai:"เช็คค่าบริการ"},
    changeAccount:{English:"CHANGE ACCOUNT",Thai:"เปลี่ยนบัญชี"},
    
    //d
    del:     {English:"DELETE",Thai:"ลบ"},
    deleteRecord:{English:"DELETE RECORD",Thai:"ลบรายการ"},
    dataFormatSetting:{English:"DATA DISPLAY SETTING",Thai:"ตั้งค่าการแสดงข้อมูล"},
    dateIn:{English:"",Thai:""},
    detailOfRequestRecoveryPassword:{
        English:"RECOVERY PASSWORD WILL BE SENT TO YOUR EMAIL",
        Thai:"รหัสบัญชีชั่วคราวจะถูกส่งไปยังอีเมลของท่าน"
    },
    detailOfRequestRecoveryPassword2:{
        English:"AFTER THAT LOG IN WITH RECOVERY PASSWORD",
        Thai:"หลังจากนั้นให้ล็อกอินด้วยรหัสบัญชีชั่วคราว"
    },
    detailShopLogInForShopPayment:{
        English:"PLEASE LOG INTO ACCOUNT TO ACQUIRE SERVICE FEE",
        Thai:"กรุณาล็อกอินบัญชีเพื่อสอบถามยอดค่าบริการ"
    },
    detailCheckBeforeShopPayment:{
        English:"PLEASE CHECK OVERDUE BALANCE BEFORE PAYMENT",
        Thai:"กรุณาตรวจสอบยอดค้างชำระก่อนชำระเงิน"
    },
    detailCompanyBankAccount:{
        English:"PLEASE TRANSFER MONEY TO OUR BANK ACCOUNT AS FOLLOWING AND ATTACH TRANSACTION SLIP AS IMAGE FILE",
        Thai:"กรุณาโอนเงินของท่านเข้าบัญธนาคารของเราด้านล่างและแนบสลิปโอนเงินเป็นไฟล์รูป"
    },
    dataFormatSetting:{English:"DATA FORMAT SETTING",Thai:"ตั้งค่ารูปแบบข้อมูล"},
    deleteDataFormatModal:{English:"DELETE DATA FORMAT MODAL",Thai:"ลบรูปแบบข้อมูล"},
    deleteUser:{English:"DELETE USER",Thai:"ลบผู้ใช้"},

    //e
    edit:    {English:"EDIt",Thai:"แก้ไข"},
    editId:  {English:"EDIT ID",Thai:"แก้ไขไอดี"},
    errorOccured:{English:"ERROR OCCURED",Thai:"เกิดข้อผิดพลาด"},
    expirationDate:{English:"EXPIRATION DATE",Thai:"วันหมดอายุ"},
    editUserModal:{English:"EDIT USER",Thai:"แก้ไขผู้ใช้"},
    editDataFormatModal:{English:"EDIT DATA FORMAT NAME",Thai:"แก้ไขชื่อรูปแบบข้อมูล"},
    error:{English:"ERROR FOUND",Thai:"พบข้อผิดพลาด"},
    existingShopPassword:{English:"EXISTING ACCOUNT PASSWORD",Thai:"รหัสบัญชีปัจจุบัน"},

    //f
    formSetting:{English:"FORM SETTING",Thai:"ตั้งค่าฟอร์ม"},
    fromBank:{English:"",Thai:""},
    forgetShopPassword:{English:"FORGET ACCOUNT PASSWORD",Thai:"ลืมรหัสบัญชี"},

    //g
    gridRowGap:{English:"Row Gap",Thai:"ช่องว่างระหว่างแถว"},
    gridColumnGap:{English:"Column Gap",Thai:"ช่องว่างระหว่างคอลัมน์"},

    //h
    home:    {English:"HOME",Thai:"หน้าหลัก"},
    hasProblem:{English:"",Thai:""},
    heightP: {English:"Height of Paper",Thai:"ความสูงกระดาษ"},

    //i
    id:      {English:"ID",Thai:"ไอดี"},
    isCheck:{English:"",Thai:""},
    idOfNewRecord: {English:"ID OF NEW RECORD",Thai:"ไอดีของรายการใหม่"},
    inactiveShop:{English:"INACTIVE ACCOUNT",Thai:"ยังไม่เปิดใช้งานบัญชี"},
    inactiveUser:{English:"INACTIVE USER",Thai:"ยังไม่เปิดใช้งานผู้ใช้"},
    //j

    //k

    //l
    login:   {English:"LOGIN",Thai:"เข้าใช้"},
    loginWithRecoveryPassword:{
            English:"LOGIN WITH RECOVERY PASSWORD",
            Thai:"ล็อกอินด้วยรหัสบัญชีชั่วคราว"
    },
    language:{English:"LANGUAGE",Thai:"ภาษา"},
    logInShop:{English:"LOGIN ACCOUNT",Thai:"เข้าบัญชี"},

    //m
    monthlyFee:{English:"MONTHLY FEE",Thai:"ค่าบริการรายเดือน"},
    marginTopP:{English:"Top Margin",Thai:"ระยะขอบบน"},
    marginBottomP:{English:"Bottom Margin",Thai:"ระยะขอบล่าง"},
    marginRightP:{English:"Right Margin",Thai:"ระยะขอบขวา"},
    marginLeftP:{English:"Left Margin",Thai:"ระยะขอบซ้าย"},

    monthlyPayment:{English:"Monthly Payment",Thai:"ค่าบริการรายเดือน"},

    //n
    newId:   {English:"NEW ID",Thai:"ไอดีใหม่"},
    name:{English:"NAME",Thai:"ชื่อ"},
    newExpirationDate:{English:"NEW EXPIRATION DATE",Thai:"วันหมดอายุใหม่"},
    newUserPassword:{English:"NEW USER PASSWORD",Thai:"รหัสผู้ใช้ใหม่"},
    newShopPassword:{English:"NEW ACCOUNT PASSWORD",Thai:"รหัสบัญชีใหม่"},

    //o
    ownerName:{English:"Owner Name",Thai:"ชื่อจริง"},
    ownerSurname:{English:"Owner Surname",Thai:"นามสกุล"},
    ownerEmail:{English:"Email",Thai:"อีเมล"},
    overdueBalance:{English:"OVERDUE BALANCE",Thai:"ยอดที่ต้องชำระ"},
    overduePayment:{English:"OVERDUE PAYMENT",Thai:"โปรดชำระค่าบริการ"},
    //p
    print:   {English:"PRINT",Thai:"พิมพ์"},
    paper:   {English:"PAPER",Thai:"กระดาษ"},
    photoUrl1:{English:"",Thai:""},
    paddingTopP:{English:"Top Padding",Thai:"ช่องว่างบน"},
    paddingBottomP:{English:"Bottom Padding",Thai:"ช่องว่างล่าง"},
    paddingLeftP:{English:"Left Padding",Thai:"ช่องว่างซ้าย"},
    paddingRightP:{English:"Right Padding",Thai:"ช่องว่างขวา"},

    paddingOfEachLabel:{English:"Padding of Each",Thai:"ช่องว่างแต่ละอัน"},
    payment:{English:"Billing",Thai:"ชำระค่าบริการ"},
    //q
    qty: {English:"Quantity",Thai:"จำนวน"},

    //r
    reload:  {English:"REELOAD",Thai:"รีโหลด"},
    recordPerPage:{English:"RECORD PER PAGE",Thai:"จำนวนรายการต่อหน้า"},
    recoveryPassword:{English:"RECOVERY PASSWORD",Thai:"รหัสบัญชีชั่วคราว"},
    remainingBalance:{English:"REMAINING BALANCE",Thai:"เงินคงค้างบัญชี"},
    requestRecoveryPassword:{English:"REQUEST FOR RECOVERY PASSWORD",Thai:"ร้องขอรหัสบัญชีชั่วคราว"},
    refresh:{English:"REFRESH",Thai:"รีเฟรช"},

    //s
    setting: {English:"SETTING",Thai:"ตั้งค่า"},
    support: {English:"SUPPORT",Thai:"ช่วยเหลือ"},
    search:  {English:"SEARCH",Thai:"ค้นหา"},
    saveAsNewRecord:{English:"SAVE AS NEW RECORD",Thai:"บันทึกเป็นรายการใหม่"},
    searchSetting:{English:"SEARCH SETTING",Thai:"ตั้งค่าการค้นหา"},
    shopName:{English:"ACCOUNT NAME",Thai:"ชื่อบัญชี"},
    
    surname:{English:"SURNAME",Thai:"นามสกุล"},
    shopPassword:{English:"ACCOUNT PASSWORD",Thai:"รหัสบัญชี"},
    shopId:{English:"ACCOUNT NAME",Thai:"รหัสบัญชี"},
    shopLogIn:{English:"SIGN UP",Thai:"เข้าใชับัญชี"},
    shopTarget:{English:"",Thai:""},
    someTrue:{English:"SOME CONDITIONS ARE TRUE",Thai:"ตรงตามเงื่อนไขบางข้อ"},
    shopPayment:{English:"SERVICE PAYMENT",Thai:"ชำระค่าบริการ"},
    showBorder:{English:"Show Border",Thai:"แสดงขอบ"},
    shopLogInForm:{English:"ACCOUNT LOG IN",Thai:"ล็อคอินเข้าบัญชี"},
    shopSignUpForm:{English:"ACCOUNT SIGN UP",Thai:"สมัครบัญชีใหม่"},
    shoplogout:{English:"ACCOUNT_LOGOUT",Thai:"ออกจากบัญชี"},
    selectDataFormatFromTemplate:{English:"SELECT DATA FORMAT FROM TEMPLATE",Thai:"เลือกรูปแบบข้อมูลจากแบบ"},
    shoppaymentToShop:{English:"SHOPPAYMENT TO SHOP",Thai:"อัพข้อมูลชำระเงินไปยังข้อมูลร้าน"},

    //t
    table:   {English:"TABLE",Thai:"ตาราง"},
    tableSetting:{English:"TABLE SETTING",Thai:"ตั้งค่าตาราง"},
    timestamp:{English:"",Thai:""},
    transferDate:{English:"",Thai:""},
    transferAmount:{English:"TRANSFER AMOUNT (BAHT)",Thai:"จำนวนเงินโอน (บาท)"},
    tempExpirationDate:{English:"",Thai:""},
    toDelete:{English:"TO BE DELETED",Thai:"ต้องการลบ"},

    //u
    upload:  {English:"UPLOAD",Thai:"นำเข้าข้อมูล"},
    userId:{English:"USER ID",Thai:"ไอดีผู้ใช้"},
    userPassword:{English:"USER PASSWORD",Thai:"รหัสผู้ใช้"},
    userPassword2:{English:"CONFIRM USER PASSWORD",Thai:"ยืนยันรหัสผู้ใช้อีกครั้ง"},
    userLevel:{English:"USER LEVEL",Thai:"ระดับผู้ใช้"},
    username:{English:"USER NAME",Thai:"ชื่อผู้ใช้"},
    userList:{English:"User List",Thai:"รายชื่อผู้ใช้"},
    userListDisplay:{English:"DISPLAY USER LIST",Thai:"แสดงรายชื่อผู้ใช้"},

    useMm:{English:"ีunit as milimiter",Thai:"หน่วยเป็นมิลลิเมตร"},

    userLogInForm:{English:"USER LOG IN",Thai:"ล็อคอินผู้ใช้"},
    userlogout:{English:"USER_LOGOUT",Thai:"ออกจากผู้ใช้"},
    updateDefaultDataFormat:{
        English:"Update Default Data Format",
        Thai:"ตั้งค่ารูปแบบข้อมูล"
    },

    unauthorizedUser:{English:"UNAUTHRIZED USER",Thai:"ไม่มีสิทธิ์ผู้ใช้"},
    unauthorizedShop:{English:"UNAUTHORIZED ACCOUNT",Thai:"ไม่มีสิทธิ์บัญชี"},
    //v

    //w
    widthP:{English:"Width of Paper",Thai:"ความกว้างกระดาษ"},
    //x
    xPerLine:{English:"Number Per Line",Thai:"จำนวนต่อบรรทัด"},

    //y
    youtubeLink: {English:"YOUTUBE LINK",Thai:"ยูทูบลิงค์"},

    //z

}

export default LangList