import React from 'react'
import AddPage from "./AddPage"
import Mnavbar from '../../component/mnavbar/Mnavbar'
import cosmeticUtil from '../../util/cosmeticUtil'
import dataTemplate from './dataTemplate'
import {MainContext} from '../../context/MainContext'
import {MdSettings} from 'react-icons/md';
import AddPageSetting from './AddPageSetting'
import console from '../../util/consoleVerify'

const {checkWindowLandscape}=cosmeticUtil

const {dataFormat,selectArray}=dataTemplate


function ItemAdd(){

    const {
       addInputState,
       setAddInputState,
       dataLabel,

       dataLabel2,
       setDataLabel2,
       currentLink,prevLink,
       blankAddInputState,
       stringArray,
       lastRecordId,
       submitAdd,
       showAddSetting,
       setShowAddSetting,

       submitDataFormat,
       dfCommon,
       setDfCommon,
       keyArray,
       keyArrayWithPhotoUrl,
       widthRight,setWidthRight,

       stringArrayState,setStringArrayState,
       showModalStringArray,setShowModalStringArray,
       submitUpdateStringArray,
       basicDataSt,

       languageDisplay,

       submitUpdateDefaultDataFormatWithFilterState

    }=React.useContext(MainContext)

    return (

    <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden",
                }}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <Mnavbar/>
        </div>


        <div style={{ display:"flex",
                      height:checkWindowLandscape()?"95%":"82%",width:"100%",
                      position:"relative"
                      }}>
            
            <MdSettings className="lg-icon"
                style={{position:"absolute",right:"2rem",
                        zIndex:"30"
            }}
                onClick={e=>{
                    setShowAddSetting(!showAddSetting)
                }}
            />

            <div style={{height:"100%",
                        width:showAddSetting?`${widthRight}%`:`100%`,
                        position:"relative"
            }}>
                {basicDataSt.basicData&&
                <AddPage
                    dataLabel={dataLabel2}
                    addInputState={addInputState}
                    setAddInputState={setAddInputState}
                    currentLink={currentLink}
                    prevLink={prevLink}
                    blankAddInputState={blankAddInputState}
                    stringArray={stringArray}
                    lastRecordId={lastRecordId}
                    submitAdd={submitAdd}
                    dfCommon={dfCommon}
                    setDfCommon={setDfCommon}
                    keyArray={keyArray}
                    basicData={basicDataSt.basicData}
                    languageDisplay={languageDisplay}

                    submitUpdateDefaultDataFormatWithFilterState={submitUpdateDefaultDataFormatWithFilterState}
                />
                }
            </div>

            {showAddSetting&&
                <div className="bgc-shadeGray"
                    style={{
                        height:"100%",
                        width:showAddSetting?`${100-widthRight}%`:"100%",
                        //padding:"1rem"
                }}
                >
                    <AddPageSetting
                        dataLabel={dataLabel2}
                        setDataLabel={setDataLabel2}
                        submitDataFormat={submitDataFormat}
                        setShowAddSetting={setShowAddSetting}
                        dfCommon={dfCommon}
                        setDfCommon={setDfCommon}
                        keyArray={keyArray}
                        stringArray={stringArray}

                        stringArrayState={stringArrayState}
                        setStringArrayState={setStringArrayState}
                        showModalStringArray={showModalStringArray}
                        setShowModalStringArray={setShowModalStringArray}
                        submitUpdateStringArray={submitUpdateStringArray}
                        languageDisplay={languageDisplay}
                    />

                </div>
            }

            {showAddSetting&&
                <div
                    style={{
                        position:"fixed",
                        bottom:"0",
                        width:"100vw"
                    }}
                >
                    <input
                        type="range" min="1" max="100" 
                        value={widthRight}
                        onChange={e=>{
                            setWidthRight(e.target.value)
                        }}
                    />
                </div>
                }


        </div>


    </div>
    
    )

}

export default ItemAdd