
const checkWindowLandscape=()=>{
    const w=window.innerWidth;
    const h=window.innerHeight;
    let temp=false
    if(w>h){
        temp=true
    }
    return temp
}

const cosmeticUtil = {checkWindowLandscape}

export default cosmeticUtil







