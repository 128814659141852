import React from 'react';
import Galleryone from '../../component/galleryone/Galleryone'

import './TaskCalendar.css';
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'

import {FaPlusCircle,FaMinusCircle, FaLessThanEqual, FaBullseye,
    FaChevronCircleRight,FaChevronCircleLeft,FaWindowClose,

    FaAnglesLeft,FaAnglesRight,
    FaChevronLeft,FaChevronRight,
    FaFirstPage,FaLastPage,
    FaArrowAltCircleLeft,FaArrowAltCircleRight,
    FaRegArrowAltCircleLeft,FaRegArrowAltCircleRight,
    FaArrowLeft,FaArrowRight,

} from 'react-icons/fa';

import { GiBrokenTablet, GiConsoleController } from 'react-icons/gi';
import { MdSearch,MdCheckBoxOutlineBlank,MdCheckBox,
    MdKeyboardDoubleArrowLeft,MdKeyboardDoubleArrowRight,
    MdChevronLeft,MdChevronRight,
    MdLastPage,MdFirstPage,MdRefresh
} from 'react-icons/md';

import Ticon from '../../component/ticon/Ticon'

import './TaskCalendar.css'
//==================
function TaskCalendar(props) {

const {
        calendarData,
        date,setDate,
        thaiMonths,
        weekdays,
        dataLabel,dateArrayOfItem,
        dfCommon,
        filterState,
        thisWeekDay,
        setThisWeekDay,
        checkWindowLandscape,
        headColor,
        keyArrayWithPhotoUrl,
        submitSearchInCalendar,

        genNextWeek,
        genPrevWeek,
        genNextMonth,
        genPrevMonth,
        genNextYear,
        genPrevYear,
        genToDate,

        firstLoadCalendar,
        setFirstLoadCalendar,
        keyArray,
        editData,setEditData,
        
        setShowConfirm,

    

    }=props

const timeOption=true
//const value=new Date()
//console.log(new Date(value))
//console.log(new Date(value).toISOString())

console.log('TaskCalendar............')

const {calendarOrder,calendarBackgroundColor,
       calendarColor,toShowInCalendar
    }=dataLabel

const shouldRender= React.useRef(true)


const refEdit=React.createRef()
const refBarcode=React.createRef()


React.useEffect(()=>{
    if(shouldRender){
        return () => {
            //console.log('end.......................................')
            shouldRender.current=false
            
        } 
    }
},[])
//===========================
//==========================
const genBoxShadow=(v)=>{
    let temp="1px 3px 3px 1px #444"
    if(editData&&v){
        if(editData._id==v._id){
            temp="3px 3px 3px 3px #405d27"
        }
    }    
    return temp
}


const genBackgroundcolor=(v)=>{
    let temp=calendarBackgroundColor
    if(editData&&v){
        if(editData._id==v._id){
            temp="#d5f4e6"
        }
    }    
    return temp
}


const genDate=(i,b)=>{
    let temp=""
    if(i[b]){
        if(new Date(i[b])){
            const date1=new Date(i[b]).toISOString()
            const date2=new Date(date1).toLocaleString('en-GB')

            temp=date2
        }
    }
    return temp
}

const genNumber=(i,b)=>{
    let temp=""
    if(i[b]){
        const temp2=parseFloat(i[b])
        if(temp2){
            temp=temp2
        }
    }
    return temp
}

const genBoolean=(i,b)=>{
    let temp=""

    if(i[b]){
        if(i[b]===true){
            return <MdCheckBox
                        style={{fontSize:"1.5rem"}}
                    />
        }
        else {
            return <MdCheckBoxOutlineBlank
                        style={{fontSize:"1.5rem"}}
                    />
        }
    }
    else {
        return null
    }
}

const genString=(i,b)=>{
    let temp=""
    if(i[b]===undefined||i[b]===null){

    }
    else {
        temp=i[b]
    }
    return temp
}
//===========================
//===========================
if(firstLoadCalendar){
    submitSearchInCalendar()
    setFirstLoadCalendar(false)

}


const genThaiMonth=(date)=>{
    //console.log(date)
    const thaiMonth=thaiMonths[date.getMonth()]
    const thaiYear=date.getFullYear()//+543

    const temp=`${thaiMonth}-${thaiYear}`
    return temp
}

//==================================
//==================================
let tempArray=keyArray

tempArray.sort((a, b) => {
    return dataLabel[a].calendarOrder - dataLabel[b].calendarOrder;
});

const keyObjectArray=tempArray


//==================================
//==================================
return (
    <>
        <div className=""
            style={{
                height:checkWindowLandscape()?"5%":"8%",
                display:"flex",
                width:"100%",
                borderRadius:"0",
                paddingRight:"10px",
                backgroundColor:headColor,
                justifyContent:"flex-start",
                overflowX:"auto"
            }}
        >
            <div  
                      className="arrow"
                      style={{
                            //width:"10%"
                        }}
                      onClick={e=>{
                            genPrevYear()
                            
                      }}
                 >
                    <i>&lt;&lt;&lt;</i>
            </div>
            
            
            
            <div  
                      className="arrow"
                      style={{
                            //width:"10%"
                      }}
                      onClick={e=>{
                            genPrevMonth()
                      
                      }}
                 >
                    <i>&lt;&lt;</i>
                   
            </div>

            <div className="arrow"
                    style={{
                        //width:"10%"
                    }}    
                      onClick={e=>{
                        genPrevWeek()
                       
                      }}
                 >
                    <i>&lt;</i>
                   
            </div>

            <div 
                 style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    width:"",
                    margin:"auto 0.1rem",
                }}
            >
                  
                    <div className="monthyear"
                    >{
                        genThaiMonth(date)
                        }
                    </div>
            </div>            

            <div className="arrow"
                    style={{
                        //width:"10%"
                    }}
                    onClick={e=>{
                        genToDate()
                    }}
            >
                    <MdRefresh style={{fontSize:"2rem"}}/>
            </div>


            <div className="arrow"
                    style={{
                        //width:"10%"
                    }}
                    onClick={e=>{
                        genNextWeek()
                   
                    }}
            >
                    <i>&gt;</i>
            </div>

            <div className="arrow"
                    style={{
                        //width:"10%"
                    }}
                    onClick={e=>{
                        genNextMonth()

                    }}
            >
                <i>&gt;&gt;</i>

              
            </div>
            
            <div className="arrow"
                    style={{
                        //width:"10%"
                    }}
                    onClick={e=>{
                        genNextYear()
                        
                    }}
            >
               
                <i>&gt;&gt;&gt;</i>
            </div>

            
           {editData&&
           <div className="iconbox"
                onClick={e=>{
                    refEdit.current.click()
                }}
            >   
                <Ticon
                    iconName="MdEdit"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
          
            {editData&&
            <div className="iconbox"
                onClick={e=>{
                    setShowConfirm(true)
                }}
            >
                <Ticon
                    iconName="MdDelete"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
         
            {editData&&
            <div className="iconbox"
                onClick={e=>{
                   refBarcode.current.click()
                }}
            >
                <Ticon
                    iconName="AiOutlineFileText"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }        



            <div
                 style={{width:"10rem",visibility:"hidden"}}
            >
                aaaa
            </div>

        </div>




        <div className=""
             style={{
                height:checkWindowLandscape()?"95%":"84%",
                width:"100%",
                overflowX:"auto"
            }}
        >

            <div className="tcwidth"
                 style={{
                    display:"flex",
                    height:"100%",
                    paddingBottom:"0.3rem",
                    paddingTop:"0.3rem"
                }}
            >
            {
              weekdays.map((a,idxA)=>{
                return (
                    
                    <div className='tc17 weekday bgc-pureWhite'
                        key={`weekdays-${idxA}`}
                        style={{
                            boxShadow:"2px 2px 2px 2px #555",
                            height:"120%",
                            borderRadius:"10px",
                            margin:"0.3rem",
                            backgroundColor:"#eee",
                            overflowY:"auto"
                            //:idxA===1||idxA===3||idxA===5
                            //?"#e0ffff"
                            //:"#E6E6FA"
                        }}
                    >
                        <div className='flex-center-center w-100'
                             style={{
                                width:"100%",
                                height:"2rem",
                                borderRadius:"0.5rem 0.5rem 0 0",
                                backgroundColor:
                                    idxA===0||idxA===6
                                    ?dfCommon.weekEndColor
                                    :dfCommon.weekDayColor,
                                opacity:"1"                               
                            }}
                        >
                            <div>
                            {a}
                            </div>
                            <div style={{marginLeft:"1rem"}}>
                                {   
                                    thisWeekDay[idxA]
                                    ?thisWeekDay[idxA].toLocaleString('en-GB').substring(0,10)
                                    :"-"
                                }
                            </div>
                           
                        </div>

                        {
                        calendarData[idxA]&&
                        calendarData[idxA].map((i,idxI)=>{
                            return (
                            <div key={`i-${idxI}`}
                                 style={{
                                    fontSize:"1rem",
                                    //backgroundColor:calendarBackgroundColor,
                                    backgroundColor:genBackgroundcolor(i),//"white",
                                    color:calendarBackgroundColor,
                                    //border:"1px solid red",
                                    margin:"0.5rem 0rem",
                                    borderRadius:"0.1rem",
                                    boxShadow:genBoxShadow(i)
                                            //i.id===editData.id
                                            //?"2px 2px 2px 2px red"
                                            //:"2px 2px 2px 2px #444"
                                 }}

                               
                            >

                                    {dfCommon.calendarShowPhoto&&
                                    <div 
                                        style={{
                                           
                                        }}
                                    >
                                        <Galleryone imgarrs={i.photoUrl1} 
                                            width={"100%"}
                                        
                                        />

                                    </div>
                                    }
                                    <div
                                        onClick={e=>{
                                            setEditData(i)
                                        }}
                                    >
                                        {
                                            keyObjectArray.map((b,idxB)=>{

                                                const key0_5=b.substring(0,5)

                                                if(b==="id"&&dataLabel.id.calendarShowLine){
                                                    return (
                                                        <div key={idxB}
                                                            style={{
                                                                padding:"0 0.5rem",
                                                                backgroundColor:dataLabel[b].calendarBackgroundColor,
                                                                color:`${dataLabel.id.calendarColor}`
                                                            }}
                                                        >
                                                            <div>
                                                                {dataLabel[b].calendarShowLb
                                                                ?`${dataLabel.id.lb}: ${i.id}`
                                                                :`${i.id}`
                                                                }
                                                            </div>
                                                        
                                                        </div>
                                                    )
                                                }
                                                
                                                else if(b==="type"&&dataLabel.type.calendarShowLine){
                                                    return(
                                                        <div key={idxB}
                                                            style={{
                                                                backgroundColor:`${dataLabel.type.calendarBackgroundColor}`
                                                            }}    
                                                        
                                                        >
                                                            <div
                                                                style={{
                                                                    padding:"0 0.5rem",
                                                                    color:`${dataLabel.type.calendarColor}`
                                                                }}
                                                            >
                                                                {dataLabel[b].calendarShowLb
                                                                ?`${dataLabel[b].lb}: ${genString(i,b)}`
                                                                :`${genString(i,b)}`
                                                                }
                                                            </div>
                                                        
                                                        </div>
                                                    )
                                                }
            
                                                else if(
                                                    (key0_5==="itemD"&&dataLabel[b].calendarShowLine)||
                                                    (b==="createdAt"&&dataLabel[b].calendarShowLine)||
                                                    (b==="updatedAt"&&dataLabel[b].calendarShowLine)
                                                ){
                                                    return (
                                                        <div key={idxB}
                                                            style={{
                                                                lineHeight:"1.5rem",
                                                                color:dataLabel[b].calendarColor,
                                                                padding:"0 0.5rem",
                                                                backgroundColor:dataLabel[b].calendarBackgroundColor,
                                                            }}
                                                        >
                                                            <div>
                                                                {dataLabel[b].calendarShowLb
                                                                ?`${dataLabel[b].lb}: ${genDate(i,b)}`
                                                                :`${genDate(i,b)}`
                                                                }
                                                            </div>
            
                                                        
                                                        </div>
                                                    )
                                                }
            
                                                else if(key0_5==="itemS"&&dataLabel[b].calendarShowLine){
                                                    return(
                                                        <div key={idxB}
                                                            style={{
                                                                lineHeight:"1.5rem",
                                                                color:dataLabel[b].calendarColor,
                                                                padding:"0 0.5rem",
                                                                backgroundColor:dataLabel[b].calendarBackgroundColor,
                                                            }}
                                                        >
                                                            <div>
                                                                {dataLabel[b].calendarShowLb
                                                                ?`${dataLabel[b].lb}: ${genString(i,b)}`
                                                                :`${genString(i,b)}`
                                                                }
                                                            </div>
                                                        
                                                        </div>
                                                    )
                                                }
            
                                                else if(key0_5==="itemN"&&dataLabel[b].calendarShowLine){
                                                    return (
                                                    <div key={idxB}
                                                        style={{
                                                            lineHeight:"1.5rem",
                                                            color:dataLabel[b].calendarColor,
                                                            padding:"0 0.5rem",
                                                            backgroundColor:dataLabel[b].calendarBackgroundColor,
                                                        }}
                                                    >
                                                        <div>
                                                            {dataLabel[b].calendarShowLb
                                                            ?`${dataLabel[b].lb}: ${genNumber(i,b)}`
                                                            :`${genNumber(i,b)}`
                                                            }
                                                        </div>
                                                    
                                                    </div>
                                                    )
                                                }
            
                                                else if(
                                                    (key0_5==="itemB"&&dataLabel[b].calendarShowLine)||
                                                    (b==="active"&&dataLabel[b].calendarShowLine)
                                                ){
                                                    return(
                                                        <div key={idxB}
                                                            style={{
                                                                lineHeight:"1.5rem",
                                                                color:dataLabel[b].calendarColor,
                                                                padding:"0 0.5rem",
                                                                backgroundColor:dataLabel[b].calendarBackgroundColor,
                                                            }}
                                                        >
                                                            <div style={{display:"flex"}}>
                                                                <div
                                                                    style={{marginRight:"1rem"}}
                                                                >
                                                                    {dataLabel[b].calendarShowLb
                                                                    ?`${dataLabel[b].lb}: `
                                                                    :null
                                                                    }
                                                                </div>
                                                                {
                                                                    genBoolean(i,b)
                                                                }
                                                            </div>
                                                        
                                                        </div>
                                                    )
                                                }








                                            })
                                        }
                                    </div>
                            </div>
                            )
                        })
                        }
                    </div>

                    
                )

              })  
            }
            </div>


            <div className="d-none">
                <Link ref={refEdit} to="/cloud/edit"/>
                <Link ref={refBarcode} to="/cloud/barcode"/>

            </div>

        </div>

    </>
  );
}


TaskCalendar.defaultProps={
    onMyClick:()=>{},
    findTaskId:()=>{},
    showDateOption:"date",
    timeOption:null,

    value:new Date().toISOString(),
    weekdays:["Su/อา","Mo/จ","Tu/อ","Wd/พ","Th/พฤ","Fr/ศ","Sa/ส"],
    thaiMonths:[
        "01","02","03","04","05","06",
        "07","08","09","10","11","12"
    ],
    useTimeSetting:false
}

export default TaskCalendar;



