import React from 'react'
import axios from 'axios';

import {MdAddCircle,MdEdit} from 'react-icons/md';
import {FaBan,FaCheck} from 'react-icons/fa';

import photoUtil from '../../component/galleryone_add/photoUtil'

import ChangeShopPasswordModal from './ChangeShopPasswordModal'
import UserListModal from './UserListModal';

import GallerySinglePhoto_add from '../../component/galleryone_add/GallerySinglePhoto_add'
import console from '../../util/consoleVerify'
import appVerify from '../../util/appVerify';

const {genMyTk,checkReqBody}=appVerify


function Account({
    userList,
    submitSearchUserList,

    submitAddUser,

    submitDeleteUser,
    submitEditUser,
    submitChangeUserPasswordByAdmin,

    submitChangeShopPassword,


    basicData,
    formTemplate,
    userArray,
    userTableTemplate,
    userLevel,
    bankDetail,
    shopPayment,
    user,
    showError,setShowError,
    messageError,setMessageError,
    
    myheader,
    languageDisplay,
    languageSetting,

    checkChar
}){

    const [showChangeShopPassword,setShowChangeShopPassword]=React.useState(false)
    const [showUserList,setShowUserList]=React.useState(false)
    const [showShopPaymentModal,setShowShopPaymentModal]=React.useState(false)

    //const [shopState,setShopState]=React.useState({shopName:"shopa",password:"shopa"})
    //const [userState,setUserState]=React.useState({username:"userone",password:"userone"})

    const [showCheckServiceFee,setShowCheckServiceFee]=React.useState(false)
    const [showShopPayment,setShowShopPayment]=React.useState(false)


    const [shopPaymentState,setShopPaymentState]=React.useState({
        shopName:"",
        expirationDate:"",
        monthlyFee:"",
        remainingBalance:"",
        newExpirationDate:"",
        overdueBalance:"",

        companyBankAccountName:"",
        companyBankAccountNumber:"",
        companyBankAccountBank:"",

        transactionAmount:0,
        shopToken:"",
        file1:null,
        photoUrl1:[]
    })

    //===========================
    //===========================
    const {changeArrayFile}=photoUtil
  
    const [showImage1,setShowImage1]=React.useState(true)
    const [arrayFile1,setArrayFile1]=React.useState([])
    const [fileUrl1,setFileUrl1]=React.useState([])

    const [imageLimit,setImageLimit]=React.useState(1)

    
    React.useEffect(()=>{
        //console.log('fileUrl1')
        //console.log(fileUrl1)
    },[fileUrl1])
  
    React.useEffect(()=>{
        //console.log('useEffect ArrayFile1')
        //console.log(arrayFile1)
               
        changeArrayFile({ arrayFile:arrayFile1,
            fileUrl:fileUrl1,
            setFileUrl:setFileUrl1,
            inputState:shopPaymentState,
            setInputState:setShopPaymentState,
            keyName:"photoUrl1",
            //fileName,
            //serverFolder,
            //fileName:"file",
            //serverFolder:"/upload/customer",
            setShowImage:setShowImage1})
          
    },[arrayFile1])


    //===================================
    //============================

    const submitCheckServiceFee=async()=>{
        console.log("submitCheckServiceFee")
        try {
            const result=await axios.post('/p40shoppayment/checkservicefee',
                    {},
                    genMyTk(myheader))
            console.log(result.data)

            const {
                shopName,remainingBalance,overdueBalance,
                newExpirationDate,expirationDate,
                monthlyFee,
                companyBankAccountBankEnglish,
                companyBankAccountBankThai,
                companyBankAccountNameEnglish,
                companyBankAccountNameThai,
                companyBankAccountNumberEnglish,
                companyBankAccountNumberThai
            }=result.data

            let temp={
                shopName,remainingBalance,overdueBalance,
                newExpirationDate,expirationDate,
                monthlyFee,
                companyBankAccountBank:"",
                companyBankAccountName:"",
                companyBankAccountNumber:"",

                transactionAmount:0,
                file1:null,
                photoUrl1:[]
            }


            if(languageSetting==="Thai"){
                temp={
                    ...temp,
                    companyBankAccountBank:companyBankAccountBankThai,
                    companyBankAccountName:companyBankAccountNameThai,
                    companyBankAccountNumber:companyBankAccountNumberThai
                }
            }
            else if(languageSetting==="English"){
                temp={
                    ...temp,
                    companyBankAccountBank:companyBankAccountBankEnglish,
                    companyBankAccountName:companyBankAccountNameEnglish,
                    companyBankAccountNumber:companyBankAccountNumberEnglish
                }
            }

            setShopPaymentState(temp)
            setShowCheckServiceFee(false)
            setShowShopPayment(true)
        }
        catch (err){
            setShowError(true)
            setMessageError("Ac270")
        }

    }

    //============================

 
  
//============================
//============================

const genDate=(date)=>{
    let tempDate=""
    
    if(date){
        tempDate=new Date(date).toLocaleString('en-GB')
    }
   
    return tempDate

}

//===========================
const genFD=(inputState)=>{
    //console.log('genFD')

    const tempArray=Object.keys(inputState)
    const fd=new FormData()
    
    tempArray.map((b,idxB)=>{
        //console.log(b)
        if(b==="file1"){
            for(let i=0;i<inputState[b].length;i++){
                const fileName=`${inputState[b][i].name}`
                //console.log()
                fd.append(`imageUrl`,inputState[b][i],fileName);  
            }
        }
        else if(b==="photoUrl1"){
            inputState[b].map((c,idxC)=>{
                //console.log(`${b}[${idxC}].......${inputState[b][idxC]}...`)
                fd.append(`${b}[${idxC}]`,inputState[b][idxC])
            })
        }
        else {
            fd.append(`${b}`,inputState[b])
        }
    })
    //console.log('end genFD')
    return fd

}

//===========================
const addFileNameInPhotoUrl=(formInputState)=>{
    let temp1=[]
    if(formInputState.file1){
        //console.log(1)
      for (let i=0;i<formInputState.file1.length;i++){
        temp1=[...temp1,formInputState.file1[i].name]
      }
    }
    if(formInputState.photoUrl1){
      //console.log(2)  
      formInputState.photoUrl1=[...formInputState.photoUrl1,...temp1]
    }
    //console.log(3)
    return formInputState
  }


//===========================
const submitAddShopPaymentFromUser=async()=>{

    console.log("submitAddShopPaymentFromUser")

    try {
        const {file1,transferAmount,shopName,
            photoUrl1,...remaining}=shopPaymentState
        
        console.log(file1)
        console.log(transferAmount)    

        if(file1&&transferAmount>0){

            let checkFile1=false
            if(file1){
                if(file1.length>0){
                    checkFile1=true
                }
            }
            //console.log(3)
            if(checkFile1){
                const tempFormInputState2=addFileNameInPhotoUrl({file1,photoUrl1,transferAmount})
                //console.log("tempFormInputState2")
                //console.log(tempFormInputState2)
                const fd=genFD(tempFormInputState2)
                const result =await axios.post('/p40shoppayment/addshoppaymentfromuser',
                                fd,
                                genMyTk(myheader))
                
                //console.log('result.data')
                //console.log(result.data)
                
                setShowCheckServiceFee(false)
                setShowShopPayment(false)
            
                setShopPaymentState({
                    shopName:"",
                    expirationDate:"",
                    monthlyFee:"",
                    remainingBalance:"",
                    newExpirationDate:"",
                    overdueBalance:"",
            
                    companyBankAccountName:"",
                    companyBankAccountNumber:"",
                    companyBankAccountBank:"",
            
                    shopToken:"",
                    file1:null,
                    photoUrl1:[]
                })

            
            }
        }

    }
    catch (err){
        setShowError(true)
        setMessageError("Ac467")
    }

}
//===========================
//===========================



    //============================
    //============================
    return (
    
        <div style={{
                padding:"1rem",
                height:"100%",
                overflow:"auto"
            }}>
         
          
            <div className='flex-center-center jc-start'>
                <div>
                    {
                      languageDisplay
                      ?languageDisplay.changeShopPassword
                        ?languageDisplay.changeShopPassword
                        :"Change Shop Password"
                      :"Change Shop Password" 
                    }
                    
                </div>
                <div>
                    <MdEdit
                        className='md-icon'
                        onClick={e=>{
                            setShowChangeShopPassword(true)
                        }}
                    />
                </div>
            </div>
            <div className='flex-center-center jc-start'>
                <div>
                    {
                      languageDisplay
                      ?languageDisplay.userList
                        ?languageDisplay.userList
                        :"User List"
                      :"User List"   
                    }
                </div>
                <div>
                    <MdEdit
                        className='md-icon'
                        onClick={e=>{
                            setShowUserList(true)
                        }}
                    />
                </div>
            </div>
         

            {
            showChangeShopPassword&&
                <ChangeShopPasswordModal
                    submitCheck={submitChangeShopPassword}
                    submitCancel={setShowChangeShopPassword}
                    languageDisplay={languageDisplay}

                    checkChar={checkChar}
                />
            }

          
            {showUserList&&
                <UserListModal
                    submitSearchUserList={submitSearchUserList}
                    submitDeleteUser={submitDeleteUser}
                    submitEditUser={submitEditUser}
                    userList={userList}
                    submitCheck={()=>{}}
                    submitCancel={setShowUserList}
                    submitAddUser={submitAddUser}
                    submitChangeUserPasswordByAdmin={submitChangeUserPasswordByAdmin}
                    basicData={basicData}
                    languageDisplay={languageDisplay}
                    checkChar={checkChar}
                />
            }

          
           

        </div>
    )
}

export default Account