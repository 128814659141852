


import React from 'react'

import {MdVisibility,MdVisibilityOff,
    MdEdit,MdAddCircle,MdDelete,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineCheckBox,
    MdRefresh,
    MdCheckBox,
    MdCheckBoxOutlineBlank
} from 'react-icons/md';
import {FaBan,FaCheck} from 'react-icons/fa'
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'


function ChangeUserPasswordModal({
    editUser,
    submitCheck,
    submitCancel,
    languageDisplay,
    checkChar
}){

    const refNewUserPassword=React.createRef()
    const refConfirmNewUserPassword=React.createRef()
    const refCheck=React.createRef()
    const refBan=React.createRef()

    const tempArray=[
        {   
            key:"newUserPassword",
            lb: languageDisplay
                ?languageDisplay.newUserPassword
                    ?languageDisplay.newUserPassword
                    :"NEW USER PASSWORD"
                :"NEW USER PASSWORD"
            ,
            type:"password",
            ref:refNewUserPassword
        },
        {
            key:"confirmNewUserPassword",
            lb: languageDisplay
                ?languageDisplay.confirmNewUserPassword
                    ?languageDisplay.confirmNewUserPassword
                    :"CONFIRM NEW USER PASSWORD"
                :"CONFIRM NEW USER PASSWORD"
            ,
            type:"password",
            ref:refConfirmNewUserPassword
        },
    ]
        
    const [hidePassword,setHidePassword]=React.useState(true)

    const [inputState,setInputState]=React.useState({...editUser,
        newUserPassword:"",
        newUserPasswordStar:"",
        confirmNewUserPassword:"",
        confirmNewUserPasswordStar:""
    })


    return(
    <div className="Modal-background">

        <div className="Modal-box2"
             style={{width:"90%"}}
        >
            <div className="Modal-header">
                <h5>
                    {
                        languageDisplay
                        ?languageDisplay.changeUserPasswordModal
                            ?languageDisplay.changeUserPasswordModal
                            :"CHANGE USER PASSWORD MODAL"
                        :"CHANGE USER PASSWORD MODAL"
                    }
                </h5>
            </div>
        
            <div className='flex-center-center'>
                <div>
                {`${editUser.id}.${editUser.username}`}
                </div>
            </div>

            <div className='Modal-box'>
                {
                    tempArray.map((v,idxV)=>{
                        return (
                            <div className='w-100'
                                 style={{display:"flex",
                                        justifyContent:"flex-start",
                                        margin:"1rem 0"
                                }}
                            >
                                <div className='w-20'>
                                    {v.lb}
                                </div>

                                <div className='w-80'
                                  style={{position:"relative"}}
                                >
                                    <input
                                        className='w-100'

                                        style={{color:hidePassword
                                                ?"transparent"
                                                :null
                                        }}

                                        ref={v.ref}
                                        type={"text"}

                                        value={
                                            inputState[v.key]
                                        }

                                        onKeyDown={e=>{
                                            
                                            if(e.key==="Enter"){
                                                if(idxV===tempArray.length-1){
                                                    refCheck.current.focus()
                                                }
                                                else {
                                                    tempArray[idxV+1].ref.current.focus()
                                                }
                                            }
                                        }}


                                        onChange={e=>{

                                            const lastChar=e.target.value.slice(-1)

                                            let tempResult=checkChar(lastChar)
                                            if(e.target.value.length>15){
                                                tempResult=false
                                            }

                                            let tempStar=""
                                            for(let z=0;z<e.target.value.length;z++){
                                                tempStar=tempStar+"*"
                                            }
                                            

                                            if(tempResult||e.target.value===""){
                                                setInputState({
                                                    ...inputState,
                                                    [v.key]:e.target.value,
                                                    [v.key+"Star"]:tempStar
                                                })
                                            }

                                        }}    
                                        
                                    />

                                    {hidePassword&&
                                    <div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {inputState[v.key+"Star"]}
                                    </div>
                                    }

                                    {
                                        hidePassword
                                        ?<MdVisibilityOff 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                        :<MdVisibility 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                    }

                                </div>
                            </div>
                        )
                    })
                }
            </div>
           

            <div className="Modal-footer"
                style={{marginTop:"2rem"}}
            >
                   

                   <div>
                        <button                       
                            ref={refCheck}
                            onClick={()=>{
                                submitCheck(inputState)
                            }}
                        >
                            <FaCheck/>
                        </button>
                    </div>

                    <div>
                        <button                     
                            ref={refBan}  
                            onClick={()=>{
                                submitCancel(false)
                            }}
                        >
                            <FaBan/>
                        </button>
                    </div>
            </div>

        </div>
    </div>
    )

}

export default ChangeUserPasswordModal

