import React from 'react'
import { MdSettings,MdCheckBox,MdCheckBoxOutlineBlank, MdOutlineCheckBoxOutlineBlank,
        MdOutlineSubtitles,MdOutlineIndeterminateCheckBox,
        MdEdit
} from 'react-icons/md';
import {FaArrowAltCircleDown,FaArrowAltCircleUp,FaCircle,
       FaList,FaAngleDown,FaCheck,FaBan,FaRegCopy,
       FaPlusSquare,FaMinusSquare,
       FaCaretSquareDown,FaCaretSquareUp,
} from 'react-icons/fa';
import ColorPick from './ColorPick';
import console from '../../util/consoleVerify'

function AddPageSetting({
    dataLabel,setDataLabel,
    submitDataFormat,
    setShowAddSetting,
    keyArray,
    stringArray,
    stringArrayState,setStringArrayState,
    showModalStringArray,setShowModalStringArray,
    submitUpdateStringArray,
    languageDisplay,
    dfCommon,setDfCommon
}){

    let tempArray=[]
    if(dataLabel){
        //tempArray=Object.keys(dataLabel)
        tempArray=keyArray

        tempArray.sort((a, b) => {
            return dataLabel[a].lineOrder - dataLabel[b].lineOrder;
        });
    }
    
    const keyObjectArray=tempArray

    //console.log('dataLabel....................')
    //console.log(dataLabel)

    const [showColorPlate,setShowColorPlate]=React.useState(false)
    const [showColorPlateForBackground,setShowColorPlateForBackground]=React.useState(false)

    const [showColorPlateForAll,setShowColorPlateForAll]=React.useState(false)
    const [showColorPlateForBackgroundForAll,setShowColorPlateForBackgroundForAll]=React.useState(false)
    const [showColorPlateForBackgroundForPage,setShowColorPlateForBackgroundForPage]=React.useState(false)


    const [editFilterLine,setEditFilterLine]=React.useState(null)
    const [editKey,setEditKey]=React.useState(null)
    const [editKeyInputState,setEditKeyInputState]=React.useState(null)

    const renderStringInputType=(b,idxB)=>{

        const key0_5=b.substring(0,5)
        let tempShow=false

        if(key0_5==="itemS"){
            tempShow=true
        }
        //if(b==="type"){tempShow=true}

        if(dataLabel[b].stringInputType==="selection"){
            return(
                <div style={{display:"flex",visibility:tempShow?"visible":"hidden"}}>
                    <FaList className='md-icon'
                         onClick={e=>{
                            //setStringArrayState()
                            //console.log('setStringArrayState')
                            //console.log(b)
                            //console.log(stringArray[b])
                            setStringArrayState({
                                id:stringArray.id,
                                key:b,
                                value:stringArray[b],
                                lb:dataLabel[b].lb
                                }
                            )
                            setShowModalStringArray(true)
                         }}
                       
                    />
                    <FaAngleDown className='md-icon'
                        onClick={e=>{
                            const tempObj={...dataLabel[b],
                                stringInputType:"textarea"
                                }
                
                            setDataLabel({...dataLabel,
                                        [b]:tempObj
                                    })
                        }}
                    />
                </div>
            )
        }
        
        else if(dataLabel[b].stringInputType==="textarea"){
            return(
                <div style={{display:"flex"}}>
                    <MdOutlineSubtitles 
                        style={{fontSize:"1.4rem"}}
                    />
                    <FaAngleDown className='md-icon'
                         onClick={e=>{
                            const tempObj={...dataLabel[b],
                                stringInputType:"text"
                                }
                
                            setDataLabel({...dataLabel,
                                        [b]:tempObj
                                    })
                        }}
                    />
                </div>
            )
        }
        
        else {
            return(
                <div style={{display:"flex",visibility:tempShow?"visible":"hidden"}}>
                    <MdOutlineIndeterminateCheckBox
                         style={{fontSize:"1.4rem"}}                       
                    />
                    <FaAngleDown className='md-icon'
                        onClick={e=>{
                            const tempObj={...dataLabel[b],
                                stringInputType:"selection"
                                }
                
                            setDataLabel({...dataLabel,
                                        [b]:tempObj
                                    })
                        }}
                    
                    />
                </div>
            )
        }

    }
//===========================================
   
                
const renderFooter=()=>{
    return(
    <div className='' 
        style={{display:"flex",
                
                position:"fixed",
                bottom:"2.5rem",
                right:"2rem",
                zIndex:"100"
                }}
    >
            
            <div style={{marginLeft:"8rem"}}>
                <button
               
                    onClick={e=>{
                        
                        submitDataFormat()
                        setShowAddSetting(false)
                    }}
                >
                    <FaCheck/>
                </button>
            </div>
                
            <div>
                <button
                  
                    onClick={e=>{
                        setShowAddSetting(false)
                    }}
                >
                    <FaBan/>
                </button>
            </div>


    </div>
    )
}
//===========================================
const moveUp=(idxA,tempArray)=>{
    let tempArray2=[]
    tempArray.map((c,idxC)=>{
        if(idxC===idxA-1){
            tempArray2=[...tempArray2,tempArray[idxA]]
        }
        else if (idxC===idxA){
            tempArray2=[...tempArray2,tempArray[idxA-1]]
        }
        else {
            tempArray2=[...tempArray2,tempArray[idxC]]
        }
    })
    return tempArray2
}

const moveDown=(idxA,tempArray)=>{
    let tempArray2=[]
    tempArray.map((c,idxC)=>{
        if(idxC===idxA){
            tempArray2=[...tempArray2,tempArray[idxA+1]]
        }
        else if (idxC===idxA+1){
            tempArray2=[...tempArray2,tempArray[idxA]]
        }
        else {
            tempArray2=[...tempArray2,tempArray[idxC]]
        }
    })
    return tempArray2
}

const addOne=(idxA,tempArray)=>{
    let tempArray2=[]
    tempArray.map((c,idxC)=>{
        if(idxC===idxA){
            tempArray2=[...tempArray2,tempArray[idxA],""]
        }
     
        else {
            tempArray2=[...tempArray2,tempArray[idxC]]
        }
    })
    return tempArray2
}

const deleteOne=(idxA,tempArray)=>{
    let tempArray2=[]
    tempArray.map((c,idxC)=>{
        if(idxC===idxA){

        }
     
        else {
            tempArray2=[...tempArray2,tempArray[idxC]]
        }
    })
    return tempArray2
}

//===========================================
const renderTool=(a,idxA)=>{

    const tempLength=stringArrayState.value.length
   
    return(
        <>
            <FaPlusSquare
                className='md-icon'
                style={{margin:"0 1rem"}}
                onClick={(e)=>{
                    
                    setStringArrayState(
                        {
                            ...stringArrayState,
                            value: addOne(idxA,stringArrayState.value)
                        }
                    )
                    
                }}
            />
       
            <FaMinusSquare
                className='md-icon'
                style={{
                    margin:"0 1rem",
                    visibility:
                        tempLength>1
                        ?"visible"
                        :"hidden"
                }}
                onClick={(e)=>{
                    
                    setStringArrayState(
                        {
                            ...stringArrayState,
                            value: deleteOne(idxA,stringArrayState.value)
                        }
                    )
                }}
            />
            
            <FaCaretSquareUp
                className='md-icon'
                style={{
                    margin:"0 1rem",
                    visibility:
                        tempLength>1&&idxA>0
                        ?"visible"
                        :"hidden"
                }}
                onClick={()=>{
                    
                    setStringArrayState(
                        {
                            ...stringArrayState,
                            value: moveUp(idxA,stringArrayState.value)
                        }
                    )
                    
                }}
            />
            <FaCaretSquareDown
                className='md-icon'
                style={{
                    margin:"0 1rem",
                    visibility:
                        tempLength>1&&idxA<tempLength-1
                        ?"visible"
                        :"hidden",
                }}
                onClick={()=>{
                    
                    setStringArrayState(
                        {
                            ...stringArrayState,
                            value: moveDown(idxA,stringArrayState.value)
                        }
                    )
                    
                }}

            />
        </>
    )
}
//===========================================
    return (
        <div style={{width:"100%",height:"100%",
                     position:"relative",
                     overflowY:"auto",
                     paddingBottom:"8rem"
                }}
        >

                <div className="flex-center-top"
                    style={{marginBottom:"1rem"}}
                >
                    
                    <h3>
                        {languageDisplay
                        ?languageDisplay.formSetting
                            ?languageDisplay.formSetting
                            :"Form Setting"
                        :"Form Setting"
                        }
                    </h3>
                </div>

                {
                    renderFooter()
                }


                

                <div className='w-100'
                        style={{visibility:"hidden"}}
                >
                        newLine
                </div>



                <div className='w-100'
                        style={{display:"flex"}}
                >
                    
                    <div className='w-75'
                        style={{display:"flex",justifyContent:"flex-start"}}
                    >
                        <MdCheckBox className='sm-icon'
                                    style={{
                                        color:"brown",
                                        fontSize:"2.5rem"
                                    }}
                                    onClick={e=>{
                                        let tempDataLabel={...dataLabel}
                                        
                                        keyObjectArray.map((g,idxG)=>{
                                            
                                            const tempObj={...tempDataLabel[g],
                                                        showLine:true
                                                        }
                                            tempDataLabel={...tempDataLabel,[g]:tempObj}
                                        })

                                        setDataLabel(tempDataLabel)
                                    }}           
                        />

                        <MdCheckBoxOutlineBlank 
                            className='sm-icon'
                            style={{
                                color:"brown",
                                fontSize:"2.5rem"
                            }}
                            onClick={e=>{
                                let tempDataLabel={...dataLabel}
                                        
                                        keyObjectArray.map((g,idxG)=>{
                                            
                                            const tempObj={...tempDataLabel[g],
                                                        showLine:false
                                                        }
                                            tempDataLabel={...tempDataLabel,[g]:tempObj}
                                        })

                                        setDataLabel(tempDataLabel)
                            }}    
                        />

                        <div
                            style={{
                                width:"2rem",
                                height:"2rem",
                                borderRadius:"0.25rem",
                                backgroundColor:
                                    dfCommon.formPageBackgroundColor
                                    ?dfCommon.formPageBackgroundColor
                                    :"transparent",
                                border:"1px solid white",
                                margin:"auto 0",
                                marginLeft:"0.8rem"
                            }}

                            onClick={e=>{
                                setShowColorPlateForBackgroundForPage(true)
                            }}
                        >   
                        </div>
                        <div
                            style={{margin:"auto 0",marginLeft:"0.5rem"}}
                        >
                            {
                            dfCommon.formPageBackgroundColor
                            ?dfCommon.formPageBackgroundColor
                            :"transparent"
                            }
                        </div>

                    </div>
                    
                    <div className='w-25'
                     style={{display:"flex",
                             justifyContent:"flex-start",
                             alignItems:"center"
                            }}
                    >

                        <div
                            style={{
                                width:"2rem",
                                height:"1.2rem",
                                borderRadius:"0.25rem",
                                backgroundColor:"transparent",
                                border:"1px solid white"
                            }}

                            onClick={e=>{
                                setShowColorPlateForAll(true)
                            }}
                        >   
                        </div>

                        <div
                            style={{
                                width:"2rem",
                                height:"1.2rem",
                                borderRadius:"0.25rem",
                                backgroundColor:"transparent",
                                border:"1px solid white",
                                marginLeft:"1rem"
                            }}

                            onClick={e=>{
                                setShowColorPlateForBackgroundForAll(true)
                            }}
                        >   
                        </div>
                

                    </div>


                </div>


            
                             
                <div className="flex-center-center jc-start"
                >
                    {   dataLabel&&
                         keyObjectArray.map((b,idxB)=>{
                            
                            const key0_5=b.substring(0,5)

                            let key_short=` (${b.substring(0,2)})`
                            if(key0_5==="itemS"||key0_5==="itemN"||key0_5==="itemB"||key0_5==="itemD"){
                                key_short=` (${b.substring(4,5)}${b.slice(-1)})`
                            }


                            const {lb,showLine,lineOrder,
                                   lineColor,lineBackgroundColor,
                                   lineBreak
                                }=dataLabel[b]
                            
                            return (
                            <div className='flex-center-center jc-start w-100'
                                        key={`ab-${idxB}`}
                                        style={{
                                        height:"2.6rem",
                                        backgroundColor:idxB%2==1?"rgba(120,120,120,0.5)":"null"
                                        }}
                                >

                                            <div style={{width:"10%"}}
                                            >{
                                                showLine
                                                ?<MdCheckBox className='sm-icon'
                                                        style={{color:"brown"}}
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                        showLine:false
                                                                        }
                                                        
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}
                                                
                                                />
                                                :<MdCheckBoxOutlineBlank className='sm-icon'
                                                        style={{color:"brown"}}
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                        showLine:true
                                                                        }
                                                        
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}
                                                
                                                />
                                            }
                                            
                                            </div>


                                            <div style={{width:"25%"}}
                                            >
                                                {editKey===b
                                                ?<input
                                                    value={editKeyInputState}
                                                    onChange={e=>{
                                                        setEditKeyInputState(e.target.value)
                                                    }}
                                                />
                                                :<div className='w-100'
                                                    style={{
                                                        display:"flex",
                                                        alignItems:"center"
                                                    }}          

                                                    onClick={e=>{

                                                        if(editKey){
                                                            const tempObj={[editKey]:{...dataLabel[editKey],lb:editKeyInputState}}
                                                            setDataLabel({...dataLabel,...tempObj})
                                                        }
                                                        
                                                        setEditKey(b)
                                                        setEditKeyInputState(dataLabel[b].lb)
                                                    }}
                                                >
                                                    <MdEdit className='sm-icon'/>
                                                    
                                                    <div>
                                                    {lb+key_short}
                                                    </div>
                                                </div>
                                                }
                                                
                                            </div>




                                            <div className='flex-center-center'
                                                    style={{width:"20%"}}
                                            >
                                                {
                                                    //<div>{dataLabel[b].colOrder}</div>
                                                }
                                                <FaArrowAltCircleDown className='sm-icon'
                                                    style={{visibility:idxB===(keyObjectArray.length-1)?"hidden":"visible"}}
                                                    onClick={e=>{
                                                        let tempObj={}

                                                        keyObjectArray.map((g,idxG)=>{
                                                            //console.log(dataLabel[g].filterLineOrder)
                                                            if(idxG===idxB){
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],lineOrder:(idxG+1)+1}
                                                                    }
                                                            }
                                                            else if(idxG===idxB+1){
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],lineOrder:(idxG+1)-1}
                                                                    }
                                                            }
                                                            else {
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],lineOrder:(idxG+1)}
                                                                    }
                                                            }
                                                            
                                                        })

                                                        let tempObj2=dataLabel
                                                        Object.keys(tempObj).map(a=>{
                                                            tempObj2={...tempObj2,[a]:tempObj[a]}
                                                        })
                
                                                        setDataLabel(tempObj2)

                                                    }}
                                                />
                                                <FaArrowAltCircleUp className='sm-icon'
                                                    style={{visibility:idxB===0?"hidden":"visible"}}
                                                    onClick={e=>{
                                                        let tempObj={}

                                                        keyObjectArray.map((g,idxG)=>{
                                                            //console.log(dataLabel[g].filterLineOrder)
                                                            if(idxG===idxB-1){
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],lineOrder:(idxG+1)+1}
                                                                    }
                                                            }
                                                            else if(idxG===idxB){
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],lineOrder:(idxG+1)-1}
                                                                    }
                                                            }
                                                            else {
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],lineOrder:(idxG+1)}
                                                                    }
                                                            }
                                                        })

                                                        let tempObj2=dataLabel
                                                        Object.keys(tempObj).map(a=>{
                                                            tempObj2={...tempObj2,[a]:tempObj[a]}
                                                        })
                
                                                        setDataLabel(tempObj2)

                                                    }}
                                                />
                                                
                                            </div>

                                            <div style={{width:"10%"}}
                                            >{
                                                lineBreak
                                                ?<MdCheckBox className='sm-icon'
                                                    onClick={e=>{
                                                        const tempObj={...dataLabel[b],
                                                                    lineBreak:false
                                                                    }
                                                        
                                                        setDataLabel({...dataLabel,
                                                                        [b]:tempObj
                                                                    })
                                                    }}
                                                />
                                                :<MdCheckBoxOutlineBlank className='sm-icon'
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                        lineBreak:true
                                                                        }
                                                            
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}
                                                />
                                            }</div>


                                            <div style={{width:"10%"}}
                                            >{
                                                renderStringInputType(b,idxB)
                                            }
                                            </div>


                                            <div className='flex-center-center jc-start' 
                                                style={{width:"25%"}}
                                            >
                                                <div
                                                    style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                                        backgroundColor:dataLabel[b].lineColor,
                                                        border:"1px solid white"
                                                    }}

                                                    onClick={e=>{
                                                        setShowColorPlate(true)
                                                        setEditFilterLine({[b]:dataLabel[b]})
                                                    }}
                                                >   
                                                </div>

                                                <div
                                                    style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                                        backgroundColor:dataLabel[b].lineBackgroundColor,
                                                        border:"1px solid white",
                                                        marginLeft:"1rem"
                                                    }}

                                                    onClick={e=>{
                                                        setShowColorPlateForBackground(true)
                                                        setEditFilterLine({[b]:dataLabel[b]})
                                                    }}
                                                >   
                                                </div>
                                            </div>
                                </div>
                            
                            )



                        })
                    }

                </div>

            
                
                {showColorPlate&&
                        <ColorPick
                            setShow={setShowColorPlate}
                            settingColorFunction={(x)=>{
                                const tempKey=Object.keys(editFilterLine)[0]
                                const tempObj={...editFilterLine[tempKey],lineColor:x}
                                setDataLabel({...dataLabel,[tempKey]:tempObj})
                                setEditFilterLine(null)
                            }}
                        />
                }
                {showColorPlateForBackground&&
                        <ColorPick
                            setShow={setShowColorPlateForBackground}
                            settingColorFunction={(x)=>{
                                const tempKey=Object.keys(editFilterLine)[0]
                                const tempObj={...editFilterLine[tempKey],lineBackgroundColor:x}
                                setDataLabel({...dataLabel,[tempKey]:tempObj})
                                setEditFilterLine(null)
                            }}
                        />
                }


                {showColorPlateForAll&&
                        <ColorPick
                            setShow={setShowColorPlateForAll}
                            settingColorFunction={(x)=>{

                                const tempKey=Object.keys(dataLabel)
                                
                                let tempObj2={}

                                tempKey.map(a=>{
                                    tempObj2={...tempObj2,
                                        [a]:{...dataLabel[a],lineColor:x}
                                    }
                                })

                                setDataLabel(tempObj2)
                                setEditFilterLine(null)
                    
                            }}
                        />
                }

                {showColorPlateForBackgroundForAll&&
                        <ColorPick
                            setShow={setShowColorPlateForBackgroundForAll}
                            settingColorFunction={(x)=>{
            
                                const tempKey=Object.keys(dataLabel)
                                
                                let tempObj2={}

                                tempKey.map(a=>{
                                    tempObj2={...tempObj2,
                                        [a]:{...dataLabel[a],lineBackgroundColor:x}
                                    }
                                })

                                setDataLabel(tempObj2)
                                setEditFilterLine(null)
                
                            }}
                        />
                }

                {showColorPlateForBackgroundForPage&&
                        <ColorPick
                            setShow={setShowColorPlateForBackgroundForPage}
                            settingColorFunction={(x)=>{
                                console.log(x)
                                setDfCommon({...dfCommon,formPageBackgroundColor:x})
                            }}
                        />
                }



                {
                 showModalStringArray&&stringArrayState&&
                 <div className="Modal-background">
                    
                    <div className="Modal-box2">
                    
                        <div className="Modal-body"
                            style={{display:"block"}}
                        >
            
                            <div className='flex-center-center w-100'
                                style={{fontSize:"1.2rem"}}
                            >
                                {`${stringArrayState.lb}`}
                            </div>

                            <div className="w-100"
                                style={{display:"block"}}    
                            > 
                                {
                                   stringArrayState.value.map((a,idxA)=>{
                                    return (
                                    <div key={`stringArray${idxA}`}
                                         className=''
                                         style={{
                                            height:"2.4rem",
                                            margin:"0rem 0rem",
                                            paddingRight:"0.9rem",
                                            display:"flex",
                                            justifyContent:"space-between",
                                            alignItems:"center",
                                            backgroundColor:idxA%2?"transparent":"rgba(100,100,100,0.8)"
                                        }}
                                    
                                    >
                                        {
                                            renderTool(a,idxA)
                                        }

                                        
                                        <input
                                            type="text"
                                            value={a}
                                            style={{
                                                marginLeft:"1rem"
                                            }}
                                            onChange={e=>{
                                                //console.log(e.target.value)
                                                //console.log(stringArrayState)
                                                
                                                let tempArray=[]
                                                stringArrayState.value.map((k,idxK)=>{
                                                    if(idxK===idxA){
                                                        tempArray=[...tempArray,e.target.value]
                                                    }
                                                    else {
                                                        tempArray=[...tempArray,k]
                                                    }
                                                })
                                                //console.log(tempArray)
                                                
                                                setStringArrayState({
                                                    ...stringArrayState,
                                                    value:tempArray
                                                })
                                                
                                            }}
                                        />

                                        
                                    </div>
                                    )
                                   })  
                                }
                            </div>
                        </div>




                        <div className="Modal-footer">

                                <div>
                                    <button
                                        onClick={e=>{
                                            //setShowError(true)
                                            //submitFunction()
                                            //if(setShow){setShow(false)}
                                            //setShow(true)
                                            submitUpdateStringArray()
                                        }}
                                    >
                                        <FaCheck
                                            style={{fontSize:"1.2rem"}}
                                        />
                                    </button>
                                </div>
                                <div>
                                    <button
                                        onClick={e=>{
                                            //if(setShow){setShow(false)}
                                            setShowModalStringArray(false)
                                        }}
                                    >
                                        <FaBan/>
                                    </button>
                                </div>

                        </div>

                    </div>
                </div>
                }

        </div>
    )
}

export default AddPageSetting