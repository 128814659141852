import React from 'react'
import Ticon from '../ticon/Ticon'
import {Link} from 'react-router-dom';
import {MainContext} from '../../context/MainContext'
import LogOutToolForUser from '../../mpage/item/LogOutToolForUser';
import console from '../../util/consoleVerify'


import './Mnavbar.css'

function MnavbarMaster({

}){

    const {
        formTemplate,
        basicDataSt,
        tokenSt,
        setTokenSt,

        thisPage,setThisPage,
        setPrevLink,setCurrentLink
    }=React.useContext(MainContext)

    //const haveShopToken=true
    //const haveUserToken=true
    //const user={username:"Peter",shopId:"a",branchId:"1"}
    //const pageData=formTemplate
    //const [tokenSt,setTokenSt]=React.useState({haveShopToken,haveUserToken})

    const pageData=formTemplate

    const shouldRender= React.useRef(true)
    React.useEffect(()=>{
        return () => {
            //console.log('end.................................')
            shouldRender.current=false
        } 
    },[])

    const refHome=React.createRef()
    const refShopPayment=React.createRef()
    const refShop=React.createRef()
    const refUser=React.createRef()
    const refUpload=React.createRef()


    const getIconStyle=()=>{

        
        if(shouldRender.current){
            const toDate=new Date()
            
            let temp1=localStorage.getItem("expirationDate")
            const expirationDate=new Date(temp1)
            
            if(!temp1){
                return null
            }
    
            const temp3=new Date(expirationDate.toISOString())
            temp3.setDate(temp3.getDate()-3)
            const threeDayBeforeExpirationDate=new Date(temp3)
                   
            const a=toDate.getTime()-expirationDate.getTime()

            if(toDate>expirationDate){
                return {color:"red"}
            }
            else if(toDate>threeDayBeforeExpirationDate){
                return {color:"yellow"}
            }
            else{
                return {color:"#74b979"}
            }
        }
        
     }



    return (
        <div className="navFrame flex-center-center jc-space-between"
             style={{background:"#5A7247"}}
        >

            <div id="1" className="h-100" 
                style={{
                        position:"absolute",top:"0",left:"0",
                        width:"100%",display:"flex",alignItems:"center",
                        justifyContent:"start",
                        overflowX:"auto",
                        overflowY:"hidden",
                       
                        }}>

                <div className="navLink" 
                    onClick={e=>{
                        //setMainContextFilterData()
                        refHome.current.click()
                    }}> 
                    
                        <div className="navIconBox"
                            style={{position:"relative"}}
                        >
                            <Ticon
                                iconName="FaHome"
                                className="navIcon"
                                iconStyle={getIconStyle()}
                            />
                        </div>                    
                </div>

                <div className="navLink" 
                     onClick={e=>{
                         //setMainContextFilterData()
                         //setCurrentLink("search")
                         setPrevLink("shop")
                         setCurrentLink("shop")
                         setThisPage("shop")
                         refShop.current.click()
                     }}> 
                    <div className="navIconBox"
                         
                         style={{
                            color:thisPage==="shop"?"#FFA500":"white"
                         }}
                    >
                        Shop
                    </div>
                  
                </div>    

                <div className="navLink" 
                     onClick={e=>{
                         //setMainContextFilterData()
                         //setCurrentLink("add")
                         setPrevLink("user")
                         setCurrentLink("user")
                         setThisPage("user")
                         refUser.current.click()
                     }}> 
                    <div className="navIconBox"
                         style={{
                            color:thisPage==="user"?"#FFA500":"white"
                         }}
                    >
                       User
                    </div>
                  
                </div>

                <div className="navLink" 
                     onClick={e=>{
                        //setMainContextFilterData()
                        //setCurrentLink("table")
                        //setPrevLink("table")
                        setPrevLink("shoppayment")
                         setCurrentLink("shoppayment")
                        setThisPage("shoppayment")
                        refShopPayment.current.click()
                }}> 
                    <div className="navIconBox"
                          style={{
                            color:thisPage==="shoppayment"?"#FFA500":"white"
                         }}
                    >
                       ShopPayment
                    </div>
                 
                </div>

                <div className="navLink" 
                     onClick={e=>{
                        //setMainContextFilterData()
                        //setCurrentLink("table")
                        //setPrevLink("table")
                        setPrevLink("upload")
                         setCurrentLink("upload")
                        setThisPage("upload")
                        refUpload.current.click()
                }}> 
                    <div className="navIconBox"
                          style={{
                            color:thisPage==="upload"?"#FFA500":"white"
                         }}
                    >
                       Upload
                    </div>
                 
                </div>     

            </div>

            {
            
            <LogOutToolForUser
               useShopLogOut={true}
               tokenSt={tokenSt}
               setReloadCheckToken={(val)=>{
                    if(val==="haveShopToken"){
                        setTokenSt({...tokenSt,haveShopToken:false})
                    }
                    else if(val==="haveUserToken"){
                        setTokenSt({...tokenSt,haveUserToken:false})
                    }
               }}
               user={basicDataSt.user}
               useHomeIcon={false}
               pageData={null}
               Language={"English"}             
            />
        
            }


            <div className="d-none">
                <Link ref={refHome} to="/cloud/home"/>
                <Link ref={refShop} to="/cloud/shop_mm23pos"/>
                <Link ref={refShopPayment} to="/cloud/shoppayment_mm23pos"/>
                <Link ref={refUser} to="/cloud/user_mm23pos"/>
                <Link ref={refUpload} to="/cloud/upload_mm23pos"/>

               
            </div>



        </div>
    )
}

export default MnavbarMaster
