import React from 'react'
import Mnavbar from '../../component/mnavbar/Mnavbar'
import cosmeticUtil from '../../util/cosmeticUtil'
import Album from './Album'
import RenderBadge from './RenderBadge'
import fakeAxiosUtil from './fakeAxiosUtil'
import ModalConfirm from './ModalConfirm'
import {MainContext} from '../../context/MainContext'
import {MdSettings} from 'react-icons/md';
import AlbumSetting from './AlbumSetting'
import console from '../../util/consoleVerify'
import ModalTypeSelect from './ModalTypeSelect'

const {getLimit,getLimitRow,getDataLength,findToltalPage,
       deleteById
      }=fakeAxiosUtil
const {checkWindowLandscape}=cosmeticUtil

//const {mData,dataFormat}=dataTemplate



function ItemAlbum(){

    const {
        defaultDataFormat,defaultDataFormatName,

        pageData,setPageData,
        currentPage,setCurrentPage,
        totalPage,setTotalPage,
        editInputState,setEditInputState,
        dataLabel,setDataLabel,
        dataLabel2,setDataLabel2,
        submitSearch,
        showAlbumSetting,
        setShowAlbumSetting,
        submitDeleteById,

        submitDataFormat,
        dfCommon,setDfCommon,
        countRecord,
        keyArray,
        keyArrayWithPhotoUrl,

        widthRight,
        setWidthRight,

        languageDisplay,

        basicDataSt,
        submitUpdateDefaultDataFormatWithFilterState,


    }=React.useContext(MainContext)

    const setEditInputStateWithNewId=(editData)=>{
        setEditInputState({...editData,newId:editData.id})
    }

    const [showConfirm,setShowConfirm]=React.useState(false)
    const [showModalTypeSelect,setShowModalTypeSelect]=React.useState(false)

    console.log("Item Album")
  
    return (


    <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <Mnavbar/>
        </div>

        <div style={{ height:checkWindowLandscape()?"5%":"8%",width:"100%",
                      position:"relative"
                    }}
        >
                {
                    RenderBadge({ 
                        totalPage:totalPage,
                        currentPage:currentPage,
                        setCurrentPage:setCurrentPage,
                        editInputState:editInputState,
                        setEditInputState:setEditInputState,
                        setShowConfirm:setShowConfirm,
                        submitSearch:submitSearch,
                        countRecord:countRecord,
                        badgeColor:"#A0DAA9",
                        
                        showSetting:showAlbumSetting,
                        setShowSetting:setShowAlbumSetting,
                        defaultDataFormat:defaultDataFormat,
                        defaultDataFormatName:defaultDataFormatName

                    })
                }


                {
                <div 
                    style={{
                        display:"flex",
                        alignItems:"baseline",
                        flexWrap:"nowrap",
                        position:"absolute",
                        zIndex:10,
                        top:"0rem",
                        right:"0"
                    }}
                >
                        <h5 className='hovertext'
                            onClick={()=>{
                                setShowModalTypeSelect(true)
                            }}
                        >
                            {defaultDataFormat}
                        </h5>

                        <h5 className='hovertext showinbigscreen'
                            onClick={()=>{
                                setShowModalTypeSelect(true)
                            }}
                        >
                            {`.${defaultDataFormatName}`}
                        </h5>
                        <MdSettings className='md-icon'
                                 style={{
                                    marginLeft:"1rem"
                                }}
                                onClick={e=>{
                                    setShowAlbumSetting(!showAlbumSetting)
                                }}
                        />
                </div>
                }
               
        </div>

        <div style={{ display:"flex",
                      height:checkWindowLandscape()?"90%":"84%",width:"100%",
                    }}
    
                      >
            
                <div style={{height:"100%",
                            width:showAlbumSetting?`${widthRight}%`:"100%",

                            }}>
                    
                    {dataLabel2&&
                    <Album
                        pageData={pageData}
                        dataLabel={dataLabel2}
                        editData={editInputState}
                        setEditData={setEditInputStateWithNewId}
                        dfCommon={dfCommon}
                        keyArray={keyArray}
                    />
                    }
                </div>

                {showAlbumSetting&&
                    <div
                        className="bgc-shadeGray"
                        style={{
                            height:"100%",
                            width:`${100-widthRight}%`,
                        }}
                    >
                            <AlbumSetting
                                dataLabel={dataLabel2}   
                                setDataLabel={setDataLabel2}
                                submitDataFormat={submitDataFormat}
                                setShowAlbumSetting={setShowAlbumSetting}
                                dfCommon={dfCommon}
                                setDfCommon={setDfCommon}
                                keyArray={keyArray}
                                languageDisplay={languageDisplay}
                            />
                    </div>
                }
             
                {showAlbumSetting&&
                <div
                    style={{
                        position:"fixed",
                        bottom:"0",
                        width:"100vw"
                    }}
                >
                    <input
                        type="range" min="1" max="100" 
                        value={widthRight}
                        onChange={e=>{
                            setWidthRight(e.target.value)
                        }}
                    />
                </div>
                }


        </div>

        {showConfirm&&editInputState
        ?<ModalConfirm
               setShow={setShowConfirm}
               submitFunction={submitDeleteById}
 
               editData={editInputState}
               languageDisplay={languageDisplay}

        />
        :null
        }

        {showModalTypeSelect
        ?<ModalTypeSelect
                setShow={setShowModalTypeSelect}
                submitFunction={submitUpdateDefaultDataFormatWithFilterState}
                basicData={basicDataSt.basicData}
            />
        :null
        }
  

    </div>
    )

}

export default ItemAlbum