import React from 'react'
import cosmeticUtil from '../../util/cosmeticUtil'

import {MainContext} from '../../context/MainContext'

import MasterComponent from './MasterComponent'
import console from '../../util/consoleVerify'


const {checkWindowLandscape}=cosmeticUtil


function ItemShop (){

    const {
 
        maxPerPage,

        dataLabelShop,setDataLabelShop,
        dataLabel2,setDataLabel2,

        recordPerPageForShop,setRecordPerPageForShop,
        toSortUpForShop,setToSortUpForShop,
        toSortDownForShop,setToSortDownForShop,
        toAllTrueForShop,setToAllTrueForShop,
        filterStateForShop,setFilterStateForShop,
        lastRecordIdForShop,setLastRecordIdForShop,
        
        currentPageForShop,setCurrentPageForShop,
        totalPageForShop,setTotalPageForShop,
        countRecordForShop,setCountRecordForShop,
        
        keyArrayOfShop,
        
        stringArray,
        setIsXls,
        setIsCsv,


        shopData,setShopData,
        editShop,setEditShop,
        
        prevLink,

        submitDataFormat,
        dfCommon,setDfCommon,

        showFilterSettingForShop,setShowFilterSettingForShop,
        showTableSettingForShop,setShowTableSettingForShop,
        showAddPageSettingForShop,setShowAddPageSettingForShop,

        showModalAddPageForShop,setShowModalAddPageForShop,
        showModalEditPageForShop,setShowModalEditPageForShop,
        showModalConfirmForShop,setShowModalConfirmForShop,
        
        widthRight,setWidthRight,
        basicDataSt,

        addInputStateForShop,setAddInputStateForShop,
        blankAddInputStateForShop,
        editInputStateForShop,setEditInputStateForShop,
 
        submitLogInWithRecoveryPasswordByMaster,

        submitSearchForShop,
        submitSearchForSearchButtonForShop,
        submitAddForShop,
        submitEditForShop,
        submitDeleteForShop,

        submitEditWithNewIdForShop,
        submitCopyRecordForShop,
        submitDataFormatForShop,


        setPrevLink,
        setCurrentLink,

        dfCommonForShop,
        setDfCommonForShop,
        
        languageDisplay,
    
    }=React.useContext(MainContext)

    return <MasterComponent
            
            maxPerPage={maxPerPage}

            dataLabelShop={dataLabelShop}
            setDataLabelShop={setDataLabelShop}

            recordPerPageForShop={recordPerPageForShop}
            setRecordPerPageForShop={setRecordPerPageForShop}
            toSortUpForShop={toSortUpForShop}
            setToSortUpForShop={setToSortUpForShop}
            toSortDownForShop={toSortDownForShop}
            setToSortDownForShop={setToSortDownForShop}
            toAllTrueForShop={toAllTrueForShop}
            setToAllTrueForShop={setToAllTrueForShop}
            filterStateForShop={filterStateForShop}
            setFilterStateForShop={setFilterStateForShop}
            lastRecordIdForShop={lastRecordIdForShop}
            setLastRecordIdForShop={setLastRecordIdForShop}
            
            currentPageForShop={currentPageForShop}
            setCurrentPageForShop={setCurrentPageForShop}
            totalPageForShop={totalPageForShop}
            setTotalPageForShop={setTotalPageForShop}
            countRecordForShop={countRecordForShop}
            setCountRecordForShop={setCountRecordForShop}


            submitSearchForShop={submitSearchForShop}
            submitSearchForSearchButtonForShop={submitSearchForSearchButtonForShop}
            submitLogInWithRecoveryPasswordByMaster={submitLogInWithRecoveryPasswordByMaster}

            stringArray={stringArray}
            setIsXls={setIsXls}
            setIsCsv={setIsCsv}


            shopData={shopData}
            setShopData={setShopData}
            editShop={editShop}
            setEditShop={setEditShop}
            
            submitDataFormat={submitDataFormatForShop}
            dfCommon={dfCommonForShop}
            setDfCommon={setDfCommonForShop}

            showFilterSettingForShop={showFilterSettingForShop}
            setShowFilterSettingForShop={setShowFilterSettingForShop}
            showTableSettingForShop={showTableSettingForShop}
            setShowTableSettingForShop={setShowTableSettingForShop}
            showAddPageSettingForShop={showAddPageSettingForShop}
            setShowAddPageSettingForShop={setShowAddPageSettingForShop}

            showModalAddPageForShop={showModalAddPageForShop}
            setShowModalAddPageForShop={setShowModalAddPageForShop}
            showModalEditPageForShop={showModalEditPageForShop}
            setShowModalEditPageForShop={setShowModalEditPageForShop}
            showModalConfirmForShop={showModalConfirmForShop}
            setShowModalConfirmForShop={setShowModalConfirmForShop}
            
        
            keyArrayFilterInput={keyArrayOfShop}
            keyArrayFilterInputSetting={keyArrayOfShop}
            
            keyArrayTable={keyArrayOfShop}
            keyArrayTableSetting={keyArrayOfShop}

            keyArrayModalAdd={keyArrayOfShop}
            keyArrayModalAddSetting={keyArrayOfShop}

            keyArrayModalEdit={keyArrayOfShop}
        

            //keyArrayWithPhotoUrl,
            widthRight={widthRight}
            setWidthRight={setWidthRight}
            basicDataSt={basicDataSt}

            blankAddInputState={blankAddInputStateForShop}
            
            addInputState={addInputStateForShop}
            setAddInputState={setAddInputStateForShop}

            editInputState={editShop}
            setEditInputState={setEditShop}
            
            currentLink={"Peter"}
            prevLink={"Peter"}
    
            showPhoto={false}

            submitAddForShop={submitAddForShop}
            submitDeleteForShop={submitDeleteForShop}
            submitEditForShop={submitEditForShop}

            submitEditWithNewIdForShop={submitEditWithNewIdForShop}
            submitCopyRecordForShop={submitCopyRecordForShop}
            

            setPrevLink={setPrevLink}
            setCurrentLink={setCurrentLink}

            matchEditDataField={"shopName"}

            languageDisplay={languageDisplay}

            useUpdateShopAfterShopPayment={false}

            submitUpdateShopFromShopPayment={null}

    />

}

export default ItemShop