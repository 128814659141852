
import React from 'react'
import console from '../../util/consoleVerify'

function TypeSelect ({
    typeArray,
    value,
    onChange,

}){

    console.log('TypeSelect')
  
    const genTempArray=()=>{
        let tempArray=[]
        typeArray.map(a=>{
            tempArray=[...tempArray,`${a.typeId}.${a.typeName}`]

        })

        return tempArray
    }

    return (
        <select
            type={"text"}
            value={value}
            onChange={e=>{
                //console.log(e.target.value)
                const tempArray=e.target.value.split(".")
                onChange(parseInt(tempArray[0]))
            }}

            onKeyDown={()=>{

            }}

        >
            <option>...</option>
            {
              genTempArray().map((c,idxC)=>{
                return (
                    <option key={`op-${idxC}`}
                            value={c}
                    >
                        {c}
                    </option>
                )
              })  
            }

        </select>
    )
}

export default TypeSelect