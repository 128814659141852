import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';

import React from 'react'
import console from '../../util/consoleVerify'


function AddDataFormatModal ({
    submitCheck,
    submitCancel,
    newDataDataFormat,
    setNewDataDataFormat,
    languageDisplay
    
}){


    return (
    <div className="Modal-background">
        <div className="Modal-box2"
        >
            <div className="Modal-header">
                <div>
                    {
                    languageDisplay
                    ?languageDisplay.addDataFormatModal
                        ?languageDisplay.addDataFormatModal
                        :"Add Data Format"
                    :"Add Data Format"
                    
                    }
                </div>
            </div>
          
            <div className='flex-center-center'
            
            >
                <div style={{width:"20%",padding:"0 2px"}}>
                    <input className='w-100'
                        type="number"
                        value={newDataDataFormat.id}
                        onChange={e=>{
                            setNewDataDataFormat({
                                ...newDataDataFormat,
                                id:parseFloat(e.target.value)
                            })
                        }}
                    />
                </div>

                <div style={{width:"80%",padding:"0 2px"}}>
                    <input className='w-100'
                        type="text"
                        value={newDataDataFormat.dfName}
                        onChange={e=>{
                            setNewDataDataFormat({
                                ...newDataDataFormat,
                                dfName:e.target.value
                            })
                        }}
                    />
                </div>

            </div>

            <div className="Modal-footer">
                <div>
                    <button
                        onClick={()=>{
                            submitCheck()
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button                       
                        onClick={()=>{
                            submitCancel()
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
          
        </div>
    </div>
    )
}

export default AddDataFormatModal