import React from 'react'

import FilterInput from './FilterInput'
import FilterSetting from './FilterSetting'

import Mnavbar from '../../component/mnavbar/Mnavbar'
import cosmeticUtil from '../../util/cosmeticUtil'
import { MdSettings} from 'react-icons/md';
import {MainContext} from '../../context/MainContext'
import console from '../../util/consoleVerify'


const {checkWindowLandscape}=cosmeticUtil

function ItemSearch(){

    const {
        filterState,setFilterState,
        recordPerPage,setRecordPerPage,
        toSortUp,setToSortUp,
        toSortDown,setToSortDown,
        toAllTrue,setToAllTrue,
        
        maxPerPage,
        submitSearch,submitSearchForSearchButton,
        dataLabel,setDataLabel,
        dataLabel2,setDataLabel2,
        stringArray,
        setIsXls,
        setIsCsv,
        
        showFilterSetting,
        setShowFilterSetting,
        currentLink,prevLink,

        submitDataFormat,

        dfCommon,setDfCommon,

        keyArray,keyArrayWithPhotoUrl,

        widthRight,setWidthRight,

        basicDataSt,

        languageDisplay,

        submitUpdateDefaultDataFormatWithFilterState
        
    }=React.useContext(MainContext)

    return (
        <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>

            <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
                <Mnavbar/>
            </div>

            <div className='bgc-shadeGray' 
                style={{ display:"flex",
                          height:checkWindowLandscape()?"95%":"82%",width:"100%"}}>
                    
                <div style={{
                        height:"100%",
                        width:showFilterSetting?`${widthRight}%`:"100%",
                        background:dfCommon.filterInputBackgroundColor
                        ?dfCommon.filterInputBackgroundColor
                        :"transparent"
                        //paddingTop:"1rem",
                        //paddingBottom:"1rem"
                        //position:"relative",
                        
                }}>
                    
                    <FilterInput
                         goAfterSearch={true}
                         dataLabel={dataLabel2}
                         setDataLabel={setDataLabel2}
                         stringArray={stringArray}
                         prevLink={prevLink}

                         filterState={filterState}
                         setFilterState={setFilterState}
                         recordPerPage={recordPerPage}
                         setRecordPerPage={setRecordPerPage}

                         toSortUp={toSortUp}
                         setToSortUp={setToSortUp}
                         toSortDown={toSortDown}
                         setToSortDown={setToSortDown}
                         toAllTrue={toAllTrue}
                         setToAllTrue={setToAllTrue}

                         maxPerPage={maxPerPage}
                       
                         setIsCsv={setIsCsv}
                         setIsXls={setIsXls}

                         submitSearch={submitSearchForSearchButton}
                         keyArray={keyArray}

                         basicData={basicDataSt.basicData}

                         languageDisplay={languageDisplay}

                         submitUpdateDefaultDataFormatWithFilterState={submitUpdateDefaultDataFormatWithFilterState}
                    />
                </div>

                {showFilterSetting&&
                <div
                    style={{
                        height:"100%",
                        width:showFilterSetting?`${100-widthRight}%`:"100%",
                        //padding:"1rem"
                        }}
                >
                    <FilterSetting
                        dataLabel={dataLabel2}
                        setDataLabel={setDataLabel2}
                        submitDataFormat={submitDataFormat}
                        setShowFilterSetting={setShowFilterSetting}
                        dfCommon={dfCommon}
                        setDfCommon={setDfCommon}
                        keyArray={keyArray}
                        languageDisplay={languageDisplay}
                    />

                </div>
                }
                

                {showFilterSetting&&
                <div
                    style={{
                        position:"fixed",
                        bottom:"0",
                        width:"100vw"
                    }}
                >
                    <input
                        type="range" min="1" max="100" 
                        value={widthRight}
                        onChange={e=>{
                            setWidthRight(e.target.value)
                        }}
                    />
                </div>
                }

                <MdSettings className="lg-icon"
                    style={{position:"absolute",right:"2rem"}}
                    onClick={e=>{
                        setShowFilterSetting(!showFilterSetting)
                    }}
                />
            </div>

        </div>
       
    )

}

export default ItemSearch