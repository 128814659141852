import React from 'react'
import { MdSettings,MdCheckBox,MdCheckBoxOutlineBlank,MdMonitor, 
    MdOutlineCheckBoxOutlineBlank,
    MdEdit
} from 'react-icons/md';
import {FaArrowAltCircleDown,FaArrowAltCircleUp,
    FaMinusCircle,FaPlusCircle,FaAngleDown,
    FaCheck,FaBan,FaImages
} from 'react-icons/fa';
import ColorPick from './ColorPick';
import console from '../../util/consoleVerify'

function AlbumSetting({
    dataLabel,setDataLabel,
    submitDataFormat,
    setShowAlbumSetting,
    dfCommon,setDfCommon,

    keyArray,
    languageDisplay
}){
    let tempArray=[]
    if(dataLabel){
        //tempArray=Object.keys(dataLabel)
        tempArray=keyArray
        tempArray.sort((a, b) => {
            return dataLabel[a].albumLineOrder - dataLabel[b].albumLineOrder;
        });
    }

    const keyObjectArray=tempArray

    const [showColorPlate,setShowColorPlate]=React.useState(false)
    const [showColorPlateForBackground,setShowColorPlateForBackground]=React.useState(false)
    const [editFilterLine,setEditFilterLine]=React.useState(null)
    const [editKey,setEditKey]=React.useState(null)
    const [editKeyInputState,setEditKeyInputState]=React.useState(null)

    const [showColorPlateForAll,setShowColorPlateForAll]=React.useState(false)
    const [showColorPlateForBackgroundForAll,setShowColorPlateForBackgroundForAll]=React.useState(false)
    const [showColorPlateForBackgroundForPage,setShowColorPlateForBackgroundForPage]=React.useState(false)


    const [showOption,setShowOption]=React.useState({
        "sc":false,
        "lc":false,
        "mc":false,
        "sc":false
    })

    //================================
                
    const renderFooter=()=>{
        return(
        <div className='' 
            style={{display:"flex",
                    
                    position:"fixed",
                    bottom:"2.5rem",
                    right:"2rem",
                    zIndex:"100"
                    }}
        >
                
                <div style={{marginLeft:"8rem"}}>
                    <button
                   
                        onClick={e=>{
                            
                            submitDataFormat()
                            //setShowAlbumSetting(false)
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                    
                <div>
                    <button
                      
                        onClick={e=>{
                            setShowAlbumSetting(false)
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
    
    
        </div>
        )
    }




    //================================
    return(
        <div style={{width:"100%",height:"100%",
                    position:"relative",
                    overflowY:"auto",
                    paddingBottom:"8rem"
            
        }}>
                <div className="flex-center-top"
                    style={{marginBottom:"1rem"}}
                >
                    <MdSettings
                        style={{
                            fontSize:"2rem",
                            marginRight:"1rem"
                        }}
                    />
                    <h3>
                        {languageDisplay
                        ?languageDisplay.albumSetting
                            ?languageDisplay.albumSetting
                            :"Album Setting"
                        :"Album Setting"
                        }
                    </h3>
                </div>
          


                {
                    renderFooter()
                }

                {dfCommon&&
                <div  className='flex-center-center jc-start w-100'
                      style={{height:"2.0rem"}}
                >
                    {
                      ["albumsc","albummc","albumlc","albumxc"].map((h,idxH)=>{
                        return (
                            <div key={`monitor-${idxH}`}
                                 className='w-25'
                                 style={{
                                    padding:"0 0.5rem",
                                    display:"flex",
                                    alignItems:"center",
                                    height:"100%",
                                    position:"relative",                                    
                                }}
                            >
                                    <div
                                        style={{width:"33%"}}
                                    >
                                        <MdMonitor
                                            style={{marginRight:"0.5rem",
                                                    fontSize:`${1+idxH*0.5}rem`,
                                                    color:"#191970"
                                            }}
                                        />
                                    </div>

                                    <div
                                        style={{height:"67%",
                                            backgroundColor:"white",
                                            borderRadius:"4px",
                                            width:"80%",
                                            fontSize:"1.1rem"
                                        }}
                                    >
                                        {dfCommon[h]}
                                    </div>         
                                    {/*
                                    album1  100%
                                    album2  50%
                                    album3  33.33%
                                    album4  25%
                                    album5  20%
                                    album6  16.66%
                                    album8  12.5%
                                    album10 10%
                                    album12 8.33
                                    */}
                                    <FaAngleDown className='sm-icon'
                                        style={{position:"absolute",
                                                right:"1"
                                        }}

                                        onClick={e=>{
                                                //1,2,3,4,6,12
                                                
                                                //console.log(dfCommon[h])
                                                
                                                let temp=1
                                                if(dfCommon[h]===1){
                                                    temp=2
                                                }
                                                else if(dfCommon[h]===2){
                                                    temp=3
                                                }
                                                else if(dfCommon[h]===3){
                                                    temp=4
                                                }
                                                else if(dfCommon[h]===4){
                                                    temp=5
                                                }
                                                else if(dfCommon[h]===5){
                                                    temp=6
                                                }
                                                else if(dfCommon[h]===6){
                                                    temp=8
                                                }
                                                else if(dfCommon[h]===8){
                                                    temp=10
                                                }
                                                else if(dfCommon[h]===10){
                                                    temp=12
                                                }
                                                else if(dfCommon[h]===12){
                                                    temp=1
                                                }

                                                //const tempObj={...dataLabel.dfCommon,[h]:temp}
                                                //setDataLabel({...dataLabel,dfCommon:tempObj})
                                                //temp=1---12
                                                //temp=2---6
                                                //temp=3---
                                                //
                                                
                                                setDfCommon({...dfCommon,[h]:temp})

                                        }}
                                    />
                                  

                        </div>
                        )
                      })   
                    }

                </div>
                }


                {dfCommon&&
                    <div className='flex-center-center'
                         style={{marginTop:"1rem"}}
                    >
                       

                        <input 
                                style={{width:"90%"}}
                                type="range" min="10" max="90" 
                                value={dfCommon.albumPhotoHeight}
                                onChange={e=>{
                                    setDfCommon({...dfCommon,["albumPhotoHeight"]:e.target.value})
                                }}
                        />

                    </div>
                }
                {dfCommon&&
                    <div className='flex-center-center'
                         style={{marginTop:"1rem"}}
                    >
                       

                        <input 
                                style={{width:"90%"}}
                                type="range" min="10" max="90" 
                                value={dfCommon.albumHeight}
                                onChange={e=>{
                                    setDfCommon({...dfCommon,["albumHeight"]:e.target.value})
                                }}
                        />

                    </div>
                }
                
                <div className='w-100'
                    style={{visibility:"hidden"}}
                >
                    newLine
                </div>

                <div className='w-100'
                        style={{display:"flex"}}
                >
                    <div className='w-75'
                        style={{display:"flex",justifyContent:"flex-start"}}
                    >

                            <MdCheckBox className='sm-icon'
                                        style={{
                                            color:"brown",
                                            fontSize:"2.5rem"
                                        }}
                                        onClick={e=>{
                                            let tempDataLabel={...dataLabel}
                                            
                                            keyObjectArray.map((g,idxG)=>{
                                                
                                                const tempObj={...tempDataLabel[g],
                                                                showAlbumLine:true
                                                            }
                                                tempDataLabel={...tempDataLabel,[g]:tempObj}
                                            })

                                            setDataLabel(tempDataLabel)
                                        }}           
                            />

                            <MdCheckBoxOutlineBlank 
                                className='sm-icon'
                                style={{
                                    color:"brown",
                                    fontSize:"2.5rem"
                                }}
                                onClick={e=>{
                                    let tempDataLabel={...dataLabel}
                                            
                                            keyObjectArray.map((g,idxG)=>{
                                                
                                                const tempObj={...tempDataLabel[g],
                                                                showAlbumLine:false
                                                            }
                                                tempDataLabel={...tempDataLabel,[g]:tempObj}
                                            })

                                            setDataLabel(tempDataLabel)
                                }}    
                            />

                            <div
                                style={{
                                    width:"2rem",
                                    height:"2rem",
                                    borderRadius:"0.25rem",
                                    backgroundColor:
                                        dfCommon.albumBackgroundColor
                                        ?dfCommon.albumBackgroundColor
                                        :"transparent",
                                    border:"1px solid white",
                                    margin:"auto 0",
                                    marginLeft:"0.8rem"
                                }}

                                onClick={e=>{
                                    
                                    setShowColorPlateForBackgroundForPage(true)
                                }}
                            >   
                            </div>

                            <div
                                style={{margin:"auto 0",marginLeft:"0.5rem"}}
                            >
                                {
                                    dfCommon.albumBackgroundColor
                                    ?dfCommon.albumBackgroundColor
                                    :"transparent"  
                                }
                            </div>

                    </div>

                    
                    <div className='w-25'
                     style={{display:"flex",
                             justifyContent:"flex-start",
                             alignItems:"center"
                            }}
                    >

                        <div
                            style={{
                                width:"2rem",
                                height:"1.2rem",
                                borderRadius:"0.25rem",
                                backgroundColor:"transparent",
                                border:"1px solid white"
                            }}

                            onClick={e=>{
                                setShowColorPlateForAll(true)
                            }}
                        >   
                        </div>

                        <div
                            style={{
                                width:"2rem",
                                height:"1.2rem",
                                borderRadius:"0.25rem",
                                backgroundColor:"transparent",
                                border:"1px solid white",
                                marginLeft:"1rem"
                            }}

                            onClick={e=>{
                                setShowColorPlateForBackgroundForAll(true)
                            }}
                        >   
                        </div>
                

                    </div>




                </div>



                
                <div className="flex-center-center jc-start"
                >
                {   
                    dataLabel&&
                    keyObjectArray.map((b,idxB)=>{

                        const key0_5=b.substring(0,5)

                        let key_short=` (${b.substring(0,2)})`
                        if(key0_5==="itemS"||key0_5==="itemN"||key0_5==="itemB"||key0_5==="itemD"){
                            key_short=` (${b.substring(4,5)}${b.slice(-1)})`
                        }

                        const {lb,showAlbumLine,
                                albumLineColor,
                                albumLineOrder,
                                albumLineBreak,
                                albumLineBackgroundColor,
                                albumShowLb
                            }=dataLabel[b]

                        return (
                        <div  className='flex-center-center jc-start w-100'
                                  key={`ab-${idxB}`}
                                  style={{
                                        height:"2.3rem",
                                        backgroundColor:idxB%2==1?"rgba(120,120,120,0.5)":"null"
                                  }}
                            >
                                    <div style={{width:"10%"}}
                                    >
                                        {
                                            showAlbumLine
                                            ?<MdCheckBox className='sm-icon'
                                                    style={{color:"brown"}}
                                                    onClick={e=>{
                                                        const tempObj={...dataLabel[b],
                                                                    showAlbumLine:false
                                                                    }
                                                    
                                                        setDataLabel({...dataLabel,
                                                                        [b]:tempObj
                                                                    })
                                                    }}
                                            
                                            />
                                            :<MdCheckBoxOutlineBlank className='sm-icon'
                                                    style={{color:"brown"}}
                                                    onClick={e=>{
                                                        const tempObj={...dataLabel[b],
                                                                    showAlbumLine:true
                                                                    }
                                                    
                                                        setDataLabel({...dataLabel,
                                                                        [b]:tempObj
                                                                    })
                                                    }}
                                            
                                            />
                                        }
                                    
                                    </div>


                                    <div style={{width:"30%"}}
                                    >
                                        {editKey===b
                                        ?<input
                                            value={editKeyInputState}
                                            onChange={e=>{
                                                setEditKeyInputState(e.target.value)
                                            }}
                                        />
                                        :<div className='w-100'
                                            style={{
                                                display:"flex",
                                                alignItems:"center"
                                            }}

                                            onClick={e=>{

                                                if(editKey){
                                                    const tempObj={[editKey]:{...dataLabel[editKey],lb:editKeyInputState}}
                                                    setDataLabel({...dataLabel,...tempObj})
                                                }
                                                
                                                setEditKey(b)
                                                setEditKeyInputState(dataLabel[b].lb)
                                            }}
                                        >
                                            <MdEdit className='sm-icon'/>
                                            <div className='w-90'>
                                                {lb+key_short}
                                            </div>
                                        </div>
                                        }
                                        
                                    </div>


                                    <div style={{width:"10%"}}
                                    >{
                                        albumShowLb
                                        ?<MdCheckBox className='sm-icon'
                                            onClick={e=>{
                                                const tempObj={...dataLabel[b],
                                                            albumShowLb:false
                                                            }
                                                
                                                setDataLabel({...dataLabel,
                                                                [b]:tempObj
                                                            })
                                            }}
                                        />
                                        :<MdCheckBoxOutlineBlank className='sm-icon'
                                                onClick={e=>{
                                                    const tempObj={...dataLabel[b],
                                                                albumShowLb:true
                                                                }
                                                    
                                                    setDataLabel({...dataLabel,
                                                                    [b]:tempObj
                                                                })
                                                }}
                                        />
                                    }</div>
                                            
                                  
                                    


                                    <div className='flex-center-center'
                                        style={{width:"15%"}}
                                    >
                                        {
                                            //<div>{dataLabel[b].colOrder}</div>
                                        }
                                        <FaArrowAltCircleDown className='sm-icon'
                                            style={{visibility:idxB===(keyObjectArray.length-1)?"hidden":"visible"}}
                                            onClick={e=>{
                                                let tempObj={}

                                                keyObjectArray.map((g,idxG)=>{
                                                    //console.log(dataLabel[g].filterLineOrder)
                                                    if(idxG===idxB){
                                                            tempObj={...tempObj,
                                                                [g]:{...dataLabel[g],albumLineOrder:(idxG+1)+1}
                                                            }
                                                    }
                                                    else if(idxG===idxB+1){
                                                            tempObj={...tempObj,
                                                                [g]:{...dataLabel[g],albumLineOrder:(idxG+1)-1}
                                                            }
                                                    }
                                                    else {
                                                            tempObj={...tempObj,
                                                                [g]:{...dataLabel[g],albumLineOrder:(idxG+1)}
                                                            }
                                                    }
                                                    
                                                })

                                                let tempObj2=dataLabel
                                                Object.keys(tempObj).map(a=>{
                                                    tempObj2={...tempObj2,[a]:tempObj[a]}
                                                })
        
                                                setDataLabel(tempObj2)

                                            }}
                                        />
                                        <FaArrowAltCircleUp className='sm-icon'
                                            style={{visibility:idxB===0?"hidden":"visible"}}
                                            onClick={e=>{
                                                let tempObj={}

                                                keyObjectArray.map((g,idxG)=>{
                                                    //console.log(dataLabel[g].filterLineOrder)
                                                    if(idxG===idxB-1){
                                                            tempObj={...tempObj,
                                                                [g]:{...dataLabel[g],albumLineOrder:(idxG+1)+1}
                                                            }
                                                    }
                                                    else if(idxG===idxB){
                                                            tempObj={...tempObj,
                                                                [g]:{...dataLabel[g],albumLineOrder:(idxG+1)-1}
                                                            }
                                                    }
                                                    else {
                                                            tempObj={...tempObj,
                                                                [g]:{...dataLabel[g],albumLineOrder:(idxG+1)}
                                                            }
                                                    }
                                                })

                                                let tempObj2=dataLabel
                                                Object.keys(tempObj).map(a=>{
                                                    tempObj2={...tempObj2,[a]:tempObj[a]}
                                                })
        
                                                setDataLabel(tempObj2)

                                            }}
                                        />
                                        
                                    </div>


                                    <div style={{width:"10%"}}
                                    >{
                                        albumLineBreak
                                        ?<MdCheckBox className='sm-icon'
                                            onClick={e=>{
                                                const tempObj={...dataLabel[b],
                                                            albumLineBreak:false
                                                            }
                                                
                                                setDataLabel({...dataLabel,
                                                                [b]:tempObj
                                                            })
                                            }}
                                        />
                                        :<MdCheckBoxOutlineBlank className='sm-icon'
                                                onClick={e=>{
                                                    const tempObj={...dataLabel[b],
                                                                albumLineBreak:true
                                                                }
                                                    
                                                    setDataLabel({...dataLabel,
                                                                    [b]:tempObj
                                                                })
                                                }}
                                        />
                                    }</div>
                                            


                                    <div className='flex-center-center jc-start' 
                                        style={{width:"20%"}}
                                    >
                        
                                        <div
                                            style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                                backgroundColor:dataLabel[b].albumLineColor,
                                                border:"1px solid white"
                                            }}

                                            onClick={e=>{
                                                setShowColorPlate(true)
                                                setEditFilterLine({[b]:dataLabel[b]})
                                            }}
                                        >   
                                        </div>

                                        <div
                                            style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                                backgroundColor:dataLabel[b].albumLineBackgroundColor,
                                                border:"1px solid white",
                                                marginLeft:"1rem"
                                            }}

                                            onClick={e=>{
                                                setShowColorPlateForBackground(true)
                                                setEditFilterLine({[b]:dataLabel[b]})
                                            }}
                                        >   
                                        </div>
                                    </div>







                                
                            </div>
                        )
                    })
                }
                </div>


                 
                {showColorPlate&&
                        <ColorPick
                            setShow={setShowColorPlate}
                            settingColorFunction={(x)=>{
                                const tempKey=Object.keys(editFilterLine)[0]
                                const tempObj={...editFilterLine[tempKey],albumLineColor:x}
                                setDataLabel({...dataLabel,[tempKey]:tempObj})
                                setEditFilterLine(null)
                            }}
                        />
                }

                {showColorPlateForBackground&&
                        <ColorPick
                            setShow={setShowColorPlateForBackground}
                            settingColorFunction={(x)=>{
                                const tempKey=Object.keys(editFilterLine)[0]
                                const tempObj={...editFilterLine[tempKey],albumLineBackgroundColor:x}
                                setDataLabel({...dataLabel,[tempKey]:tempObj})
                                setEditFilterLine(null)
                            }}
                        />
                }

                
                {showColorPlateForAll&&
                        <ColorPick
                            setShow={setShowColorPlateForAll}
                            settingColorFunction={(x)=>{

                                const tempKey=Object.keys(dataLabel)
                                
                                let tempObj2={}

                                tempKey.map(a=>{
                                    tempObj2={...tempObj2,
                                        [a]:{...dataLabel[a],albumLineColor:x}
                                    }
                                })

                                setDataLabel(tempObj2)
                                setEditFilterLine(null)
                    
                            }}
                        />
                }

                {showColorPlateForBackgroundForAll&&
                        <ColorPick
                            setShow={setShowColorPlateForBackgroundForAll}
                            settingColorFunction={(x)=>{
            
                                const tempKey=Object.keys(dataLabel)
                                
                                let tempObj2={}

                                tempKey.map(a=>{
                                    tempObj2={...tempObj2,
                                        [a]:{...dataLabel[a],albumLineBackgroundColor:x}
                                    }
                                })

                                setDataLabel(tempObj2)
                                setEditFilterLine(null)
                
                            }}
                        />
                }
                
                {showColorPlateForBackgroundForPage&&
                        <ColorPick
                            setShow={setShowColorPlateForBackgroundForPage}
                            settingColorFunction={(x)=>{
                                console.log(x)
                                setDfCommon({...dfCommon,albumBackgroundColor:x})
                            }}
                        />
                }


        
        </div>
    )


}

export default AlbumSetting
