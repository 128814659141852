import React from 'react'
import { MdSettings,MdCheckBox,MdCheckBoxOutlineBlank, 
    MdOutlineCheckBoxOutlineBlank} from 'react-icons/md';
import {FaArrowAltCircleDown,FaArrowAltCircleUp,
    FaAlignLeft,FaAlignRight,FaAlignCenter,FaAngleDown,
    FaBan,FaCheck,
   FaMinusCircle,FaPlusCircle} from 'react-icons/fa';
import ColorPick from './ColorPick';
import console from '../../util/consoleVerify'

function BarcodeSetting({
    dataLabel,setDataLabel,submitDataFormat,
    setShowBarcodeSetting,
    dfCommon,setDfCommon,

    keyArray,

    languageDisplay
}){

    let tempArray=[]
    if(dataLabel){
        //tempArray=Object.keys(dataLabel)
        tempArray=keyArray
        tempArray.sort((a, b) => {
            return dataLabel[a].labelOrder - dataLabel[b].labelOrder;
        });
    }

    const keyObjectArray=tempArray

    const [showColorPlate,setShowColorPlate]=React.useState(false)
    const [showColorPlateForBackground,setShowColorPlateForBackground]=React.useState(false)
    const [editFilterLine,setEditFilterLine]=React.useState(null)
    const [editKey,setEditKey]=React.useState(null)
    const [editKeyInputState,setEditKeyInputState]=React.useState(null)
    
    //=========================

    const renderCheckbox=(a,idxA)=>{
        return (
            <div key={`label-${idxA}`}
                    className='flex-center-center'
                    style={{
                        padding:"0 0.5rem",
                        display:"flex",
                        alignItems:"center",
                        height:"100%",
                        width:"50%",
                    }}
            >
                <div className='w-70'>
                    {languageDisplay
                        ?languageDisplay[a]
                            ?languageDisplay[a]
                            :a
                        :a
                    }
                </div>
                <div className='w-30'>
                    <input
                        type="checkbox"
                        checked={dfCommon[a]}

                        onChange={e=>{
                            
                            setDfCommon({...dfCommon,[a]:e.currentTarget.checked})
                            
                        }}

                    />
                </div>
            </div>
        )
    }

    const renderNumber=(a,idxA)=>{
        return (
            <div key={`label-${idxA}`}
                    className='flex-center-center'
                    style={{
                        padding:"0 0.5rem",
                        display:"flex",
                        alignItems:"center",
                        height:"100%",
                        width:"50%",
                    }}
            >
                <div className='w-70'>
                    {languageDisplay
                        ?languageDisplay[a]
                            ?languageDisplay[a]
                            :a
                        :a
                    }
                </div>
                <div className='w-30'>
                    <input
                        type="number"
                        value={dfCommon[a]}
                        onChange={e=>{

                            setDfCommon({...dfCommon,[a]:e.target.value})
                            
                        }}
                    />
                </div>
            </div>
        )
    }
    //=========================
    const renderAlignIcon=(b)=>{
        if(dataLabel[b].labelLineJustifyContent==="left"){
            return (
            <div className='flex-center-center'>
                 <FaAlignLeft 
                    style={{fontSize:"1rem"}}
                 />
                <FaAngleDown
                    className='sm-icon'
                    onClick={e=>{
                        const tempObj={...dataLabel[b],labelLineJustifyContent:"center"}
                        setDataLabel({...dataLabel,[b]:tempObj})
                    }}
                />
            </div>
            )
        }
        else if(dataLabel[b].labelLineJustifyContent==="center"){
            return (
            <div className='flex-center-center'>
                <FaAlignCenter
                   style={{fontSize:"1rem"}}
                />
               <FaAngleDown
                    className='sm-icon'
                    onClick={e=>{
                        const tempObj={...dataLabel[b],labelLineJustifyContent:"right"}
                        setDataLabel({...dataLabel,[b]:tempObj})
                    }}
               />
            </div> 
            )
        }
        else if(dataLabel[b].labelLineJustifyContent==="right"){
            return (
            <div className='flex-center-center'>
                 <FaAlignRight 
                    style={{fontSize:"1rem"}}
                 />
                <FaAngleDown
                    className='sm-icon'
                    onClick={e=>{
                        const tempObj={...dataLabel[b],labelLineJustifyContent:"left"}
                        setDataLabel({...dataLabel,[b]:tempObj})
                    }}
                />
            </div>
            )
        }
        else {
            return null
        }

    }

    //=========================


                    
    const renderFooter=()=>{
        return(
        <div className='' 
            style={{display:"flex",
                    
                    position:"fixed",
                    bottom:"2.5rem",
                    right:"2rem",
                    zIndex:"100"
                    }}
        >
                
                <div style={{marginLeft:"8rem"}}>
                    <button
                
                        onClick={e=>{
                            submitDataFormat()
                            setShowBarcodeSetting(false)
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                    
                <div>
                    <button
                    
                        onClick={e=>{
                            setShowBarcodeSetting(false)
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>


        </div>
        )
    }

    //=========================
    return(
        <div style={{width:"100%",height:"100%",
                    position:"relative",
                    overflowY:"auto",
        }}>

                <div className="flex-center-top"
                    style={{marginBottom:"1rem"}}
                >
                   
                    <h3>
                        {
                        languageDisplay
                        ?languageDisplay.barcodeSetting
                            ?languageDisplay.barcodeSetting
                            :"Paper Setting"
                        :"Paper Setting"
                        }
                    </h3>
                </div>

                
                {
                  renderFooter()
                }

                {dfCommon&&
                <div  className='flex-center-center jc-start w-100'
                      style={{
                            height:"2.0rem",
                        }}
                >
                   
                        {
                            [
                            "useMm","heightP","widthP",
                            "paddingTopP","paddingBottomP",
                            "paddingLeftP","paddingRightP",
                            "marginTopP","marginBottomP",
                            "marginLeftP","marginRightP",
                            "xPerLine","gridRowGap","gridColumnGap",
                            "qty","showBorder","paddingOfEachLabel",
                            "barcodeHeight","barcodeWidth",
                            "barcodePaddingTop","barcodePaddingBottom",
                            "barcodePaddingLeft","barcodePaddingRight"
                            ].map((a,idxA)=>{
                                
                                if(a==="useMm"||a==="showBorder"){
                                    return renderCheckbox(a,idxA)
                                }
                                else {
                                    return renderNumber(a,idxA)
                                }

                            })
                        }

                        <div className='w-100'
                             style={{visibility:"hidden"}}
                        >
                            newLine
                        </div>

                        <div className='w-100'
                             style={{display:"flex"}}
                        >
                            <MdCheckBox className='sm-icon'
                                        style={{
                                            color:"brown",
                                            fontSize:"2.5rem"
                                        }}
                                        onClick={e=>{
                                            let tempDataLabel={...dataLabel}
                                            
                                            keyObjectArray.map((g,idxG)=>{
                                                
                                                const tempObj={...tempDataLabel[g],
                                                                labelShowLine:true
                                                            }
                                                tempDataLabel={...tempDataLabel,[g]:tempObj}
                                            })

                                            setDataLabel(tempDataLabel)
                                        }}           
                            />

                            <MdCheckBoxOutlineBlank 
                                className='sm-icon'
                                style={{
                                    color:"brown",
                                    fontSize:"2.5rem"
                                }}
                                onClick={e=>{
                                    let tempDataLabel={...dataLabel}
                                            
                                            keyObjectArray.map((g,idxG)=>{
                                                
                                                const tempObj={...tempDataLabel[g],
                                                                labelShowLine:false
                                                            }
                                                tempDataLabel={...tempDataLabel,[g]:tempObj}
                                            })

                                            setDataLabel(tempDataLabel)
                                }}    
                            />
                        </div>

                        {
                            dataLabel&&
                            keyObjectArray.map((b,idxB)=>{
        
                                const key0_5=b.substring(0,5)

                                let key_short=` (${b.substring(0,2)})`
                                if(key0_5==="itemS"||key0_5==="itemN"||key0_5==="itemB"||key0_5==="itemD"){
                                    key_short=` (${b.substring(4,5)}${b.slice(-1)})`
                                }

                                const { lb,
                                        labelShowLine,
                                        labelShowLb,
                                        labelLineJustifyContent,
                                        labelLineFontSize,
                                        labelLineMargin,
                                        labelColor,
                                        labelOrder,
                                        
                                        labelShowBarcode,
                                        labelLineHeight,
                                        labelScaleB,
                                        labelMarginTopB,
                                        labelHeightB,
                                        labelFontSizeC,
                                        labelMarginTopC,
                                        
                                    }=dataLabel[b]
                            
                                
                                return (
                                    <div    className='flex-center-center jc-start w-100'
                                            key={`ab-${idxB}`}
                                            style={{
                                                height:"2.3rem",
                                                backgroundColor:idxB%2==1?"rgba(120,120,120,0.5)":"null"
                                            }}
                                    >
                                            <div style={{width:"7%"}}>
                                                {
                                                    labelShowLine
                                                    ?<MdCheckBox className='sm-icon'
                                                        style={{color:"brown"}}
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                        labelShowLine:false
                                                                        }
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}           
                                                    />
                                                    :<MdCheckBoxOutlineBlank className='sm-icon'
                                                        style={{color:"brown"}}
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                        labelShowLine:true
                                                                        }
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}
                                                
                                                    />
                                                }
                                            </div>


                                            <div style={{width:"20%"}}
                                            >
                                                {editKey===b
                                                ?<input
                                                    value={editKeyInputState}
                                                    onChange={e=>{
                                                        setEditKeyInputState(e.target.value)
                                                    }}
                                                />
                                                :<div className='w-100'
                                                    onClick={e=>{

                                                        if(editKey){
                                                            const tempObj={[editKey]:{...dataLabel[editKey],lb:editKeyInputState}}
                                                            setDataLabel({...dataLabel,...tempObj})
                                                        }
                                                        
                                                        setEditKey(b)
                                                        setEditKeyInputState(dataLabel[b].lb)
                                                    }}
                                                >
                                                    {lb+key_short}
                                                </div>
                                                }
                                                
                                            </div>


                                            <div style={{width:"7%"}}>
                                                {
                                                    labelShowLb
                                                    ?<MdCheckBox className='sm-icon'
                                                        style={{color:"black"}}
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                        labelShowLb:false
                                                                        }
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}           
                                                    />
                                                    :<MdCheckBoxOutlineBlank className='sm-icon'
                                                        style={{color:"black"}}
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                        labelShowLb:true
                                                                        }
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}
                                                
                                                    />
                                                }
                                            </div>


                                            <div className='flex-center-center'
                                                style={{width:"15%"}}
                                            >
                                                
                                                <FaArrowAltCircleDown className='sm-icon'
                                                    style={{visibility:idxB===(keyObjectArray.length-1)?"hidden":"visible"}}
                                                    onClick={e=>{
                                                        let tempObj={}

                                                        keyObjectArray.map((g,idxG)=>{
                                                            //console.log(dataLabel[g].filterLineOrder)
                                                            if(idxG===idxB){
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],labelOrder:(idxG+1)+1}
                                                                    }
                                                            }
                                                            else if(idxG===idxB+1){
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],labelOrder:(idxG+1)-1}
                                                                    }
                                                            }
                                                            else {
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],labelOrder:(idxG+1)}
                                                                    }
                                                            }
                                                            
                                                        })

                                                        let tempObj2=dataLabel
                                                        Object.keys(tempObj).map(a=>{
                                                            tempObj2={...tempObj2,[a]:tempObj[a]}
                                                        })
                
                                                        setDataLabel(tempObj2)

                                                    }}
                                                />
                                                <FaArrowAltCircleUp className='sm-icon'
                                                    style={{visibility:idxB===0?"hidden":"visible"}}
                                                    onClick={e=>{
                                                        let tempObj={}

                                                        keyObjectArray.map((g,idxG)=>{
                                                            //console.log(dataLabel[g].filterLineOrder)
                                                            if(idxG===idxB-1){
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],labelOrder:(idxG+1)+1}
                                                                    }
                                                            }
                                                            else if(idxG===idxB){
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],labelOrder:(idxG+1)-1}
                                                                    }
                                                            }
                                                            else {
                                                                    tempObj={...tempObj,
                                                                        [g]:{...dataLabel[g],labelOrder:(idxG+1)}
                                                                    }
                                                            }
                                                        })

                                                        let tempObj2=dataLabel
                                                        Object.keys(tempObj).map(a=>{
                                                            tempObj2={...tempObj2,[a]:tempObj[a]}
                                                        })
                
                                                        setDataLabel(tempObj2)

                                                    }}
                                                />
                                                
                                            </div>


                                            
                                            <div style={{width:"7%"}}>
                                                {
                                                    labelShowBarcode
                                                    ?<MdCheckBox className='sm-icon'
                                                        style={{color:"darkblue"}}
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                        labelShowBarcode:false
                                                                        }
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}           
                                                    />
                                                    :<MdCheckBoxOutlineBlank className='sm-icon'
                                                        style={{color:"darkblue"}}
                                                        onClick={e=>{
                                                            const tempObj={...dataLabel[b],
                                                                labelShowBarcode:true
                                                                        }
                                                            setDataLabel({...dataLabel,
                                                                            [b]:tempObj
                                                                        })
                                                        }}
                                                
                                                    />
                                                }
                                            </div>
                                            




                                            <div  style={{width:"10%"}} 
                                            >
                                                {
                                                    renderAlignIcon(b)
                                                }
                                            </div>


                                            <div style={{width:"10%"}}
                                            >
                                               <input
                                                    type="number"
                                                    value={dataLabel[b].labelLineFontSize}
                                                    onChange={e=>{
                                                        const tempObj={...dataLabel[b],
                                                            labelLineFontSize:e.target.value
                                                        }

                                                        setDataLabel({...dataLabel,
                                                            [b]:tempObj
                                                        })
                                                    }}
                                                />
                                            </div>

                                            <div style={{width:"10%"}}
                                            >
                                               <input
                                                    type="number"
                                                    value={dataLabel[b].labelLineMargin}
                                                    onChange={e=>{
                                                        const tempObj={...dataLabel[b],
                                                            labelLineMargin:e.target.value
                                                        }

                                                        setDataLabel({...dataLabel,
                                                            [b]:tempObj
                                                        })
                                                    }}
                                                />
                                            </div>


                                            <div style={{width:"7%"}}
                                            >
                                                <div
                                                    style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                                        backgroundColor:dataLabel[b].labelColor,
                                                        border:"1px solid white",
                                                        marginLeft:"0.5rem"
                                                    }}

                                                    onClick={e=>{
                                                        setShowColorPlate(true)
                                                        setEditFilterLine({[b]:dataLabel[b]})
                                                    }}
                                                >   
                                                </div>
                                            </div>



                                    </div>
                                
                                )

                            })
                        }


                    <div style={{
                        paddingBottom:"8rem",
                        visibility:"hidden"
                        }}>
                        aaaa
                    </div>

                </div>
                }

                
                {
                showColorPlate&&
                        <ColorPick
                            setShow={setShowColorPlate}
                            settingColorFunction={(x)=>{
                                const tempKey=Object.keys(editFilterLine)[0]
                                const tempObj={...editFilterLine[tempKey],labelColor:x}
                                setDataLabel({...dataLabel,[tempKey]:tempObj})
                                setEditFilterLine(null)
                            }}
                        />
                }


        </div>
    )

}

export default BarcodeSetting