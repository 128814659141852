import React from 'react'

import {FaBan,FaCheck,FaFolder,FaRegCopy} from 'react-icons/fa';

import {MdAddCircle,MdEdit} from 'react-icons/md';
import Calendar from './Calendar'
import {Link} from 'react-router-dom';
import ModalEditNewId from './ModalEditNewId';
import ModalCopyRecord from './ModalCopyRecord';
import ModalUpdateShopFromShopPayment from './ModalUpdateShopFromShopPayment';

import photoUtil from '../../component/galleryone_add/photoUtil'
import Gallery20_add from '../../component/galleryone_add/Gallery20_add'
import TypeSelect from './TypeSelect';

import console from '../../util/consoleVerify'


function EditPageForMaster({
    dataLabel,
    editInputState,
    setEditInputState,
    currentLink,prevLink,
    submitEditWithNewId,
    submitCopyRecord,
    submitEdit,
    stringArray,
    dfCommon,
    setDfCommon,
    lastRecordId,
    keyArray,
    basicData,
    setCancel,
    showPhoto,
    languageDisplay,

    useUpdateShopAfterShopPayment,

    submitUpdateShopFromShopPayment

}){

    const refTable=React.createRef()
    const refAlbum=React.createRef()
    const refCheck=React.createRef()
    const refCalendar=React.createRef()
    const refHome=React.createRef()

    const {changeArrayFile}=photoUtil

    const [showEditId,setShowEditId]=React.useState(false)

    const [showModalEditNewId,setShowModalEditNewId]=React.useState(false)
    const [showModalCopyRecord,setShowModalCopyRecord]=React.useState(false)

    const [showModalUpdateShopFromShopPayment,
           setShowModalUpdateShopFromShopPayment
        ]=React.useState(false)


    //const [newId,setNewId]=React.useState(editInputState.id)
    const [newId,setNewId]=React.useState(null)

    const [showImage1,setShowImage1]=React.useState(true)
    const [arrayFile1,setArrayFile1]=React.useState([])
    const [fileUrl1,setFileUrl1]=React.useState([])

    const [showHalf,setShowHalf]=React.useState(false)
    /*
    React.useEffect(()=>{
        console.log('arrayFile1...')
        console.log(arrayFile1)
    },[arrayFile1])

    React.useEffect(()=>{
        console.log('fileUrl1...')
        console.log(fileUrl1)
    },[fileUrl1])
    */

    const keyName=["photoUrl1"]

    React.useEffect(()=>{
        
          //console.log('useEffect ArrayFile1')
      
          
          if(keyName){
              if(keyName[0]=="photoUrl1"){
                  changeArrayFile({ arrayFile:arrayFile1,
                      fileUrl:fileUrl1,
                      setFileUrl:setFileUrl1,
                      inputState:editInputState,
                      setInputState:setEditInputState,
                      keyName:keyName[0],
                    
                      setShowImage:setShowImage1})
              }
          }
          
        
        },[arrayFile1])


    let imageLimit=20//process.env.REACT_APP_IMAGE_LIMIT
    const shopArray=process.env.REACT_APP_VIP_SHOP.split("_@vip@_")
    
   

    let refInput=[]

    let keyObjectArray=[]

    let tempArray=[]
    if(dataLabel){
        //let tempArray=Object.keys(dataLabel)

        tempArray=keyArray
        tempArray.sort((a, b) => {
            return dataLabel[a].lineOrder - dataLabel[b].lineOrder;
        });

        const tempArray2=tempArray

        let tempArray3=[]

        let tempArrayRef=[]

        tempArray2.map(a=>{
            if(dataLabel[a].showLine){
                tempArray3=[...tempArray3,a]
                
                tempArrayRef=[...tempArrayRef,React.createRef()]
            }
        })

        keyObjectArray=tempArray3

        refInput=tempArrayRef
    }

    const submitAddEditId=()=>{
        console.log("submitAddEditId..........")
    }

    //=================================

    const renderFooter=()=>{
        return(
        <div className='' 
            style={{display:"flex",
                    
                     position:"fixed",
                     bottom:"2.5rem",
                     right:"2rem",
                     zIndex:"100"
                    }}
        >
            
                <div style={{
                    
                    }}>
                    <button
                        onClick={e=>{
                           setShowModalCopyRecord(true)
                        }}
                    >
                        <FaRegCopy/>
                    </button>
                </div>
            
                {
                useUpdateShopAfterShopPayment
                ?<button
                    onClick={e=>{
                        setShowModalUpdateShopFromShopPayment(true)
                    }}
                >Update Shop</button>
                :null
                }
            
         
                <div style={{marginLeft:"8rem"}}>
                    <button
                        ref={refCheck}
                       
                        onClick={e=>{
                                                        
                            if(prevLink==="table"){
                                refTable.current.click()
                                submitEdit()
                            }
                            else if(prevLink==="album"){
                                refAlbum.current.click()
                                submitEdit()
                            }
                            else if(prevLink==="calendar"){
                                refCalendar.current.click()
                                submitEdit()
                            }
                         
                            else {
                                //refHome.current.click()
                                submitEdit()
                            }

                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                       
                <div>
                    <button
                        //ref={refCancelForm}
                        onKeyDown={e=>{
                            //if(e.key=="ArrowLeft"){
                            //    refCancelForm.current.focus()
                            //}
                        }}
                        onClick={e=>{
                            if(setCancel){
                                setCancel(false)
                            }
                            else {

                                if(prevLink==="table"){
                                    refTable.current.click()
                                }
                                else if(prevLink==="album"){
                                    refAlbum.current.click()
                                }
                                else if(prevLink==="calendar"){
                                    refCalendar.current.click()
                                }
                            }
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
    
    
        </div>
        )
    }
//======================================
const renderId=(b,idxB)=>{
    return(
        <React.Fragment
            key={idxB}
        >
            <div 
                className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex",
                        backgroundColor:dataLabel[b].lineBackgroundColor
                    }}    
            >    
                <div className="xc4"
                    style={{display:"flex",alignItems:"baseline"}}
                >

                    <p  className="label table-p"
                        style={{color:dataLabel[b].lineColor}}
                        >{dataLabel[b].lb}</p>

                    <MdEdit
                        className='sm-icon'
                        style={{
                            color:dataLabel[b].lineColor,
                            marginLeft:"1rem"
                        }}
                        onClick={e=>{
                            setShowModalEditNewId(true)
                        }}
                    />
                
                </div>
                <div className="xc8">
                    <input
                        ref={refInput[idxB]}
                        onKeyDown={e=>{
                            if(e.key==="Enter"||e.key==="ArrowRight"){
                                if(idxB==refInput.length-1){
                                    refCheck.current.focus()
                                }
                                else {
                                    refInput[idxB+1].current.focus()
                                }
                            }
                        }}
                        
                        style={{backgroundColor:"rgba(230,230,230,1)"}}

                        type="number"
                        value={editInputState[b]}
                        
                        onChange={e=>{
                            setEditInputState({
                                ...editInputState,
                                [b]:e.target.value
                            })
                        }}
                        disabled
                    />
                </div>
            </div>
            {
            dataLabel[b].lineBreak
            ?<div 
                className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex"}}    
            />    
            :null
            }
        
        </React.Fragment>
    )
}

//======================================
const renderSelect=(b,idxB)=>{
    return (
    <React.Fragment
        key={`renderSelect-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
        style={{display:"flex",
                backgroundColor:dataLabel[b].lineBackgroundColor
        }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}</p>
            </div>
            <div className="xc8">
                <select
                        ref={refInput[idxB]}

                        onKeyDown={e=>{
                            if(e.key==="Enter"||e.key==="ArrowRight"){
                                if(idxB==refInput.length-1){
                                    refCheck.current.focus()
                                }
                                else {
                                    refInput[idxB+1].current.focus()
                                }
                            }
                        }}

                        type="select"
                        value={editInputState[b]}
                        onChange={e=>{
                            setEditInputState({
                                ...editInputState,
                                [b]:e.target.value
                            })
                        }}
                >
                    <option>...</option>
                    { stringArray&&
                      stringArray[b].map((s,idxS)=>(
                        <option
                            key={idxS}
                            //selected={editInputState[b]==s?"selected":""}
                            value={s}

                        >{s}</option>
                      ))   
                    }
                </select>
            </div>
        </div>

        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }

    </React.Fragment>
    )
}
//======================================
const renderString=(b,idxB)=>{
    return(
    <React.Fragment
    key={`renderString-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex",
                    backgroundColor:dataLabel[b].lineBackgroundColor
            }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}</p>
            </div>
            <div className="xc8">
                <input
                    ref={refInput[idxB]}

                    onKeyDown={e=>{
                        if(e.key==="Enter"||e.key==="ArrowRight"){
                            if(idxB==refInput.length-1){
                                refCheck.current.focus()
                            }
                            else {
                                refInput[idxB+1].current.focus()
                            }
                        }
                    }}

                    type="text"
                    value={editInputState[b]}
                    onChange={e=>{
                        setEditInputState({
                            ...editInputState,
                            [b]:e.target.value
                        })
                    }}
                />
            </div>
        </div>

        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }
    </React.Fragment>
    )
}
//======================================
const renderTextArea=(b,idxB)=>{
    return(
    <React.Fragment
    key={`renderTextArea-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex",
                 backgroundColor:dataLabel[b].lineBackgroundColor
            }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}</p>
            </div>
            <div className="xc8">
                <textarea
                     ref={refInput[idxB]}

                     onKeyDown={e=>{
 
                         if(e.key==="ArrowRight"){
                             if(idxB==refInput.length-1){
                                 refCheck.current.focus()
                             }
                             else {
                                 refInput[idxB+1].current.focus()
                             }
                         }
                     }}

                    rows={"5"}
                    value={editInputState[b]}
                    onChange={e=>{
                        setEditInputState({
                            ...editInputState,
                            [b]:e.target.value
                        })
                    }}
                />
            </div>
        </div>
        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }
    </React.Fragment>
    )
}
//=========================
const renderBoolean=(b,idxB)=>{
    return(
        <React.Fragment
            key={idxB}
        >
            <div 
                className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex",
                    backgroundColor:dataLabel[b].lineBackgroundColor
                    }}    
            >    
                <div className="xc4">
                    <p  className="label table-p"
                        style={{color:dataLabel[b].lineColor}}
                        >{dataLabel[b].lb}</p>
                </div>
                <div className="xc8">
                    <input
                         ref={refInput[idxB]}
                         onKeyDown={e=>{
                             if(e.key==="Enter"||e.key==="ArrowRight"){
                                 if(idxB==refInput.length-1){
                                     refCheck.current.focus()
                                 }
                                 else {
                                     refInput[idxB+1].current.focus()
                                 }
                             }
                         }}

                        type="checkbox"
                        checked={editInputState[b]}
                        onChange={e=>{
                            setEditInputState({
                                ...editInputState,
                                [b]:e.target.checked
                            })
                        }}
                    />
                </div>
            </div>

            {
            dataLabel[b].lineBreak
            ?<div 
                className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex"}}    
            />    
            :null
            }
        </React.Fragment>
        )
}
//==========================
const renderNumber=(b,idxB)=>{
    return(
        <React.Fragment
            key={idxB}
        >
            <div 
                className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex",
                    backgroundColor:dataLabel[b].lineBackgroundColor
                }}    
            >    
                <div className="xc4">
                    <p  className="label table-p"
                        style={{color:dataLabel[b].lineColor}}
                        >{dataLabel[b].lb}</p>
                </div>
                <div className="xc8">
                    <input
                        ref={refInput[idxB]}
                        onKeyDown={e=>{
                            if(e.key==="Enter"||e.key==="ArrowRight"){
                                if(idxB==refInput.length-1){
                                    refCheck.current.focus()
                                }
                                else {
                                    refInput[idxB+1].current.focus()
                                }
                            }
                        }}
                        
                        type="number"
                        value={editInputState[b]}
                        onChange={e=>{
                            setEditInputState({
                                ...editInputState,
                                [b]:e.target.value
                            })
                        }}
                    />
                </div>
            </div>
            {
            dataLabel[b].lineBreak
            ?<div 
                className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex"}}    
            />    
            :null
            }
        </React.Fragment>
    )
}
//==========================
const renderDate=(b,idxB)=>{
    return(
        <React.Fragment
            key={idxB}
        >
            <div 
                className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex",
                        backgroundColor:dataLabel[b].lineBackgroundColor
                    }}    
            >    
                <div className="xc4">
                    <p  className="label table-p"
                        style={{color:dataLabel[b].lineColor}}
                        >{dataLabel[b].lb}</p>
                </div>
                <div className="xc8"
                     style={{display:"flex"}}
                >

                    <input 
                        style={{width:"5px",backgroundColor:"gray"}}
                        ref={refInput[idxB]}
                        onKeyDown={e=>{
                            if(e.key==="Enter"||e.key==="ArrowRight"){
                                if(idxB==refInput.length-1){
                                    refCheck.current.focus()
                                }
                                else {
                                    refInput[idxB+1].current.focus()
                                }
                            }
                        }}
                    />
                
                    <Calendar
                        style={{height:"1.6rem",fontSize:"0.8rem"}}
                        showDateOption={"datetime"}
                        useTimeSetting={true}
                        onMyClick={
                            (e)=>{
                             
                                if(b==="createdAt"||b==="updatedAt"){
                                }
                                else {
                                    setEditInputState({
                                        ...editInputState,
                                        [b]:e
                                    })
                                }
                                
                            }
                        }
                        value={editInputState[b]}
                    />
                </div>
            </div>
            {
            dataLabel[b].lineBreak
            ?<div 
                className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
                style={{display:"flex"}}    
            />    
            :null
            }
        </React.Fragment>
    )
}
//==========================
const genFakeValue=(value)=>{

    let tempString=null
    //console.log(filterState.type)
    basicData.typeArray.map(g=>{
        if(g.typeId===value){
        //if(g.typeId===2){
            tempString=`${g.typeId}.${g.typeName}`
        }
    })

    return tempString
}

//==========================
const renderTypeSelect=(b,idxB)=>{
    return(
    <React.Fragment
    key={`renderString-${idxB}`}
    >
        <div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex",
                    backgroundColor:dataLabel[b].lineBackgroundColor
            }}    
        >    
            <div className="xc4">
                <p  className="label table-p"
                    style={{color:dataLabel[b].lineColor}}
                    >{dataLabel[b].lb}
                </p>
            </div>
            <div className="xc8"
                 style={{display:"flex"}}
            >
                
                <input 
                  style={{width:"5px",backgroundColor:"gray"}}
                  ref={refInput[idxB]}
                  onKeyDown={e=>{
                      if(e.key==="Enter"||e.key==="ArrowRight"){
                          if(idxB==refInput.length-1){
                              refCheck.current.focus()
                          }
                          else {
                              refInput[idxB+1].current.focus()
                          }
                      }
                  }}
                />
                <TypeSelect
                    value={genFakeValue(editInputState[b])}//{"1.one-1111"}//{filterState.type.value}
                    typeArray={basicData.typeArray}
                    onChange={(a)=>{

                            
                            setEditInputState({
                                ...editInputState,
                                [b]:a
                            })
                            
                       
                    }}
                />




            </div>
        </div>

        {
        dataLabel[b].lineBreak
        ?<div className="xc6 lc6 mc6 sc12 p-1 bd-blacks"
            style={{display:"flex"}}    
        />    
        :null
        }
    </React.Fragment>
    )
}




//==========================
const renderBody=()=>{
    return (
        <div className="flex-center-start jc-start">
            {
                keyObjectArray.map((b,idxB)=>{
                
                    const key0_5=b.substring(0,5)

                    if(b=="id"&&dataLabel[b].showLine){
                        return renderId(b,idxB)
                    }

                    else if(b=="type"&&dataLabel[b].showLine){
                        return renderTypeSelect(b,idxB)
                    }

                    else if(
                            (key0_5==="itemS"||
                            b==="shopId"||
                            b==="username"||
                            b==="userPassword"||
                            b==="code"||
                            b==="userLevel"||
                            b==="name"||
                            b==="surname"||
                            b==="shopTarget"||
                            b==="accountName"||
                            b==="accountNumber"||
                            b==="fromBank"||
                            b==="checkBy"||
                            b==="ownerName"||
                            b==="ownerSurname"||
                            b==="ownerEmail"||
                            b==="recoveryPassword"||
                            b==="shopPassword"||
                            b==="shopName"
                            
                            )&&dataLabel[b].showLine){
                        if(dataLabel[b].stringInputType==="textarea"){
                            return renderTextArea(b,idxB)           
                        }
                        else if(dataLabel[b].stringInputType==="selection"){
                            return renderSelect(b,idxB)
                        }
                        else{
                            return renderString(b,idxB)
                        }
                     
                    }


                    else if(
                        (key0_5==="itemN"||
                         b==="userId"||
                         b==="transferAmount"||
                         b==="addMonth"||
                         b==="newRemainingBalance"||
                         b==="monthlyFee"||
                         b==="thisMonthDiscount"||
                         b==="remainingBalance"||
                         b==="overdueBalance"
                        )&&dataLabel[b].showLine){
                        return renderNumber(b,idxB)
                    }
                    else if(
                        (key0_5==="itemD"||
                        b==="createdAt"||
                        b==="updatedAt"||
                        b==="timestamp"||
                        b==="dateIn"||
                        b==="transferDate"||
                        b==="newExpirationDate"||
                        b==="expirationDate"||
                        b==="tempExpirationDate"
                        
                       )&&dataLabel[b].showLine)
                    {
                        return renderDate(b,idxB)
                    }
                    else if(
                        (  key0_5==="itemB"||
                            b==="active"||
                            b==="newSignUp"||
                            b==="isCheck"||
                            b==="hasProblem"||
                            b==="toDelete"||
                            b==="forgetShopPassword"
                        )&&dataLabel[b].showLine)
                    {
                        return renderBoolean(b,idxB)
                    }
                })
            }
        </div>
    )
}


//==========================
//==========================

    return (

    <div className="h-100 w-100 Modal-box"
            style={{
                padding:"0",
                margin:"0",
                borderRadius:"0",
                background:dfCommon.formPageBackgroundColor
                ?dfCommon.formPageBackgroundColor
                :"transparent"
            }}
        
    >
        <div 
            //className="Modal-box bgc-trans" 
             style={{
                paddingBottom:"7rem",
                width:"100%",
                background:"transparent"
            }}>
            <div className="Modal-header bgc-trans">
                <div className='flex-center-center'>
                    <MdEdit className='lg-icon'
                         style={{
                            fontSize:"2.5rem",
                            marginRight:"1rem"
                        }}

                        onClick={e=>{
                            setShowHalf(!showHalf)
                        }}
                    />

                    <h3>
                        {
                        languageDisplay
                        ?languageDisplay.edit
                            ?languageDisplay.edit
                            :"Edit"
                        :"Edit"
                        }
                    </h3>
                </div>
                {
                  renderFooter()  
                }
            </div>
            <div className="Modal-body bgc-trans" 
                 style={{alignItems:"flex-start"}}
            >
                    <div className="ModalInsideBody bgc-trans"
                         style={{
                            width:showHalf?"50%":"100%"
                         }}
                    >
                        { editInputState&&
                        renderBody()  
                        }
                    </div>
            

                    <div
                        style={{
                            width:showHalf?"50%":"100%"
                        }}
                    >
                        <div className={
                            showHalf
                            ?"xc12 bd-lightGray h-100"
                            :"xc6 lc8 mc10 sc12 bgc-darkGray bd-lightGray h-100"
                        }
                        >
                            {
                            showImage1&&showPhoto
                            ?<Gallery20_add 
                                fileUrl={fileUrl1}
                                arrayFile={arrayFile1}
                                setArrayFile={setArrayFile1}
                                keyName={keyName[0]}

                                setShowImage={setShowImage1}
                                inputState={editInputState}
                                setInputState={setEditInputState}

                                imageLimit={imageLimit}
                            />
                            :null
                            }   
                        </div>    
                    </div>

            </div>

           
            {
            showModalEditNewId
            ?<ModalEditNewId
                languageDisplay={languageDisplay}
                setShow={setShowModalEditNewId}
                lb={dataLabel.id.lb}
                editInputState={editInputState}
                setEditInputState={setEditInputState}
                lastRecordId={lastRecordId}
                submitFunction={()=>{
                     
                                
                    if(prevLink==="table"){
                        refTable.current.click()
                        submitEditWithNewId()
                    }
                    else if(prevLink==="album"){
                        refAlbum.current.click()
                        submitEditWithNewId()
                    }
                    else if(prevLink==="calendar"){
                        refCalendar.current.click()
                        submitEditWithNewId()
                    }
                    else {
                        refHome.current.click()
                        submitEditWithNewId()
                    }


                }}
            />
            :null
            }


               
            {
            showModalCopyRecord
            ?<ModalCopyRecord
                languageDisplay={languageDisplay}
                setShow={setShowModalCopyRecord}
                lb={dataLabel.id.lb}
                editInputState={editInputState}
                setEditInputState={setEditInputState}
                lastRecordId={lastRecordId}
                submitFunction={()=>{
                   
                    if(prevLink==="table"){
                        refTable.current.click()
                        submitCopyRecord()
                    }
                    else if(prevLink==="album"){
                        refAlbum.current.click()
                        submitCopyRecord()
                    }
                    else if(prevLink==="calendar"){
                        refCalendar.current.click()
                        submitCopyRecord()
                    }
                    else {
                        refHome.current.click()
                        submitCopyRecord()
                    }

                }}
            />
            :null
            }

            {
            showModalUpdateShopFromShopPayment
            ?<ModalUpdateShopFromShopPayment
                setShow={setShowModalUpdateShopFromShopPayment}
                submitFunction={submitUpdateShopFromShopPayment}
                editData={editInputState}
                languageDisplay={languageDisplay}
        
            />
            :null
            }

            <div className="d-none">
                <Link ref={refTable} to="/cloud/table"/>
                <Link ref={refAlbum} to="/cloud/album"/>              
                <Link ref={refCalendar} to="/cloud/calendar"/>
                <Link ref={refHome} to="/cloud/home"/>
            </div>
        </div>
        
    </div>
    )

}

EditPageForMaster.defaultProps={
    showPhoto:true,
    useUpdateShopAfterShopPayment:false
}

export default EditPageForMaster