const log=(data)=>{
   //console.log(`consoleVerify....`)
   //console.log(data)
}

const consoleVerify={log:log}


export default consoleVerify

//4MX9cckM4O6E4ER0io0RT2wkjN7Rjed4ss5DX6cn6jPz
//4ohxV9bRaT4sXFR6jSMW4lOSc0Wljl0Z2tekJ7ca4Wg5w6J9k
//4yqk9LVve4Py6MnKj4tmwC0w0k2ihDj8skMp2g0n5tTX2x
//4nYTP9uOX4NWo6MTT4gZ0F0hP2rjoA8dt2Aa0Ff5lzQC5hVr