
import React from 'react'
import console from '../../util/consoleVerify'
import MMCLOUDZONELOGO from './MMCLOUDZONELOGO'

function ItemLogo ({
 
}){

    console.log('ItemLogo')
  

    return (
      <div className='flex-center-center'
        style={{
           
            height:"100vh",
            width:"100vw",
        }}
      >
        <div className='flex-center-center'
            style={{
                height:"100%",
                width:"100%",
                transform:"scale(2)",
                background:"green"
            }}
        >
            <MMCLOUDZONELOGO/>
        </div>
      </div>
    )
}

export default ItemLogo