import React from 'react'
import Mnavbar from '../../component/mnavbar/Mnavbar'
import {FaBan,FaCheck,FaFolder} from 'react-icons/fa';
import {MdRefresh} from 'react-icons/md'
import Account from './Account'

import fakeAxiosUtil from './fakeAxiosUtil'
import cosmeticUtil from '../../util/cosmeticUtil'
import {MainContext} from '../../context/MainContext'
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'


import './Home.css'

//const {dataLabel,setDataLabel}=React.useContext(MainContext)

const {checkWindowLandscape}=cosmeticUtil

function ItemAccount(){

    const {
        //pageData,
        shopPayment,
        bankDetail,
        userLevel,
        userArray,userTableTemplate,
        formTemplate,
        dataLabel,setDataLabel,
        settingType,setSettingType,

        basicDataSt,

        userList,
        submitSearchUserList,
        submitAddUser,
        submitDeleteUser,
        submitEditUser,
        submitChangeUserPasswordByAdmin,
        submitChangeShopPassword,
        
        languageDisplay,
        myheader,
        languageSetting,

        showError,setShowError,
        messageError,setMessageError,

        checkChar

    }=React.useContext(MainContext)

    const refHome=React.createRef()








return (
    <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <Mnavbar/>
        </div>
        <div
            className='bgc-shadeGray' 
            style={{
                height:checkWindowLandscape()?"95%":"92%",width:"100%"
            }}
        >
          {basicDataSt&&  
          <Account
            basicData={basicDataSt.basicData}
            userList={userList}
            submitSearchUserList={submitSearchUserList}
            submitAddUser={submitAddUser}

            submitEditUser={submitEditUser}
            submitDeleteUser={submitDeleteUser}
            submitChangeUserPasswordByAdmin={submitChangeUserPasswordByAdmin}
            user={basicDataSt.user}
            languageDisplay={languageDisplay}
            submitChangeShopPassword={submitChangeShopPassword}
            languageSetting={languageSetting}
            myheader={myheader}

            showError={showError}
            setShowError={setShowError}
            messageError={messageError}
            setMessageError={setMessageError}

            checkChar={checkChar}
            
          />
          }
        </div>
        
    </div>
)
}

export default ItemAccount


