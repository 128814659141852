import React from 'react'
import { MdSettings,MdCheckBox,MdCheckBoxOutlineBlank, 
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineIndeterminateCheckBox,
    MdOutlineSubtitles,
    MdEdit
    
} from 'react-icons/md';
import {FaArrowAltCircleDown,FaArrowAltCircleUp,FaCircle,
    FaList,FaBars,FaAngleDown,
    FaCheck,FaBan
    } from 'react-icons/fa';
import ColorPick from './ColorPick';
import console from '../../util/consoleVerify'


function FilterSetting({
    dataLabel,
    setDataLabel,
    submitDataFormat,
    setShowFilterSetting,
    dfCommon,
    setDfCommon,
    keyArray,
    languageDisplay
}){

    let tempArray=[]
    if(dataLabel){
        //tempArray=Object.keys(dataLabel)

        tempArray=keyArray

        tempArray.sort((a, b) => {
            return dataLabel[a].filterLineOrder - dataLabel[b].filterLineOrder;
        });
    }
    
    const keyObjectArray=tempArray

    //console.log('dataLabel....................')
    //console.log(dataLabel)

    const [showColorPlate,setShowColorPlate]=React.useState(false)
    const [showColorPlateForBackground,setShowColorPlateForBackground]=React.useState(false)
    const [editFilterLine,setEditFilterLine]=React.useState(null)
    const [editKey,setEditKey]=React.useState(null)
    const [editKeyInputState,setEditKeyInputState]=React.useState(null)

    const [showColorPlateForAll,setShowColorPlateForAll]=React.useState(false)
    const [showColorPlateForBackgroundForAll,setShowColorPlateForBackgroundForAll]=React.useState(false)
    const [showColorPlateForBackgroundForPage,setShowColorPlateForBackgroundForPage]=React.useState(false)


    const renderStringInputType=(b,idxB)=>{

        const key0_5=b.substring(0,5)
        let tempShow=false

        if(key0_5==="itemS"){
            tempShow=true
        }
        //if(b==="type"){tempShow=true}

        if(dataLabel[b].stringInputType==="selection"){
            return(
                <div style={{display:"flex",visibility:tempShow?"visible":"hidden"}}>
                    <FaList
                         style={{fontSize:"1.2rem"}}
                       
                    />
                    <FaAngleDown className='sm-icon'
                        onClick={e=>{
                            const tempObj={...dataLabel[b],
                                stringInputType:"text"
                                }
                
                            setDataLabel({...dataLabel,
                                        [b]:tempObj
                                    })
                        }}
                    />
                </div>
            )
        }
     
        else {
            return(
                <div style={{display:"flex",visibility:tempShow?"visible":"hidden"}}>
                    <MdOutlineIndeterminateCheckBox
                         style={{fontSize:"1.2rem"}}                       
                    />
                    <FaAngleDown className='sm-icon'
                        onClick={e=>{
                            const tempObj={...dataLabel[b],
                                stringInputType:"selection"
                                }
                
                            setDataLabel({...dataLabel,
                                        [b]:tempObj
                                    })
                        }}
                    
                    />
                </div>
            )
        }

    }
    //=================================
                 
    const renderFooter=()=>{
        return(
        <div className='' 
            style={{display:"flex",
                    
                    position:"fixed",
                    bottom:"2.5rem",
                    right:"2rem",
                    zIndex:"100"
                    }}
        >
                
                <div style={{marginLeft:"8rem"}}>
                    <button
                   
                        onClick={e=>{
                            
                            submitDataFormat()
                            setShowFilterSetting(false)
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                    
                <div>
                    <button
                      
                        onClick={e=>{
                            setShowFilterSetting(false)
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>
    
    
        </div>
        )
    }


    //=================================

    return(
        <div style={{width:"100%",height:"100%",
                     position:"relative",
                     overflowY:"auto",
                     paddingBottom:"8rem"
             }}>
          
            <div className="flex-center-top"
                 style={{marginBottom:"1rem"}}
            >
                
                <MdSettings
                    style={{
                        fontSize:"2rem",
                        marginRight:"1rem"
                    }}
                />
                <h3>
                    {languageDisplay
                    ?languageDisplay.searchSetting
                        ?languageDisplay.searchSetting
                        :"Search Setting"
                    :"Search Setting"
                    }
                </h3>
            </div>


   

            <div className='w-100'
                    style={{visibility:"hidden"}}
            >
                    newLine
            </div>

            <div className='w-100'
                    style={{display:"flex"}}
            >
                <div className='w-80'
                     style={{display:"flex",justifyContent:"flex-start"}}
                >
                    <MdCheckBox className='sm-icon'
                                style={{
                                    color:"brown",
                                    fontSize:"2.5rem"
                                }}
                                onClick={e=>{
                                    let tempDataLabel={...dataLabel}
                                    
                                    keyObjectArray.map((g,idxG)=>{
                                        
                                        const tempObj={...tempDataLabel[g],
                                                    showFilterLine:true
                                                    }
                                        tempDataLabel={...tempDataLabel,[g]:tempObj}
                                    })

                                    setDataLabel(tempDataLabel)
                                }}           
                    />

                    <MdCheckBoxOutlineBlank 
                        className='sm-icon'
                        style={{
                            color:"brown",
                            fontSize:"2.5rem"
                        }}
                        onClick={e=>{
                            let tempDataLabel={...dataLabel}
                                    
                                    keyObjectArray.map((g,idxG)=>{
                                        
                                        const tempObj={...tempDataLabel[g],
                                                    showFilterLine:false
                                                    }
                                        tempDataLabel={...tempDataLabel,[g]:tempObj}
                                    })

                                    setDataLabel(tempDataLabel)
                        }}    
                    />

                    <div
                        style={{
                            width:"2rem",
                            height:"2rem",
                            borderRadius:"0.25rem",
                            backgroundColor:
                                dfCommon.filterInputBackgroundColor
                                ?dfCommon.filterInputBackgroundColor
                                :"transparent",
                            border:"1px solid white",
                            margin:"auto 0",
                            marginLeft:"0.8rem"
                        }}

                        onClick={e=>{
                            setShowColorPlateForBackgroundForPage(true)
                        }}
                    >   
                    </div>
                  
                    <div
                        style={{margin:"auto 0",marginLeft:"0.5rem"}}
                    >
                        {
                              dfCommon.filterInputBackgroundColor
                              ?dfCommon.filterInputBackgroundColor
                              :"transparent"
                        }
                    </div>
                </div>
                
                <div className='w-20'
                     style={{display:"flex",
                             justifyContent:"flex-start",
                             alignItems:"center",
                            }}
                >

                    <div
                        style={{
                            width:"2rem",
                            height:"1.2rem",
                            borderRadius:"0.25rem",
                            backgroundColor:"transparent",
                            border:"1px solid white"
                        }}

                        onClick={e=>{
                            setShowColorPlateForAll(true)
                        }}
                    >   
                    </div>

                    <div
                        style={{
                            width:"2rem",
                            height:"1.2rem",
                            borderRadius:"0.25rem",
                            backgroundColor:"transparent",
                            border:"1px solid white",
                            marginLeft:"1rem"
                        }}

                        onClick={e=>{
                            setShowColorPlateForBackgroundForAll(true)
                        }}
                    >   
                    </div>
               

                </div>

            </div>





            {
              renderFooter()
            }
            <div className="flex-center-center jc-start"
            >
                {   dataLabel&&
                    keyObjectArray.map((b,idxB)=>{

                        const key0_5=b.substring(0,5)

                        let key_short=` (${b.substring(0,2)})`
                        if(key0_5==="itemS"||key0_5==="itemN"||key0_5==="itemB"||key0_5==="itemD"){
                            key_short=` (${b.substring(4,5)}${b.slice(-1)})`
                        }


                        const {lb,filterLineOrder,filterLineColor,
                             filterLineBreak,showFilterLine
                            }=dataLabel[b]

                        return (
                        <div className='flex-center-center jc-start w-100'
                             key={`ab-${idxB}`}
                             style={{
                                height:"2.3rem",
                                backgroundColor:idxB%2==1?"rgba(120,120,120,0.5)":"null"
                                
                             }}
                        >
                            <div style={{width:"10%"}}
                            >{
                                showFilterLine
                                ?<MdCheckBox className='sm-icon'
                                        style={{color:"brown"}}
                                        onClick={e=>{
                                            const tempObj={...dataLabel[b],
                                                        showFilterLine:false
                                                        }
                                        
                                            setDataLabel({...dataLabel,
                                                            [b]:tempObj
                                                        })
                                        }}
                                
                                 />
                                :<MdCheckBoxOutlineBlank className='sm-icon'
                                        style={{color:"brown"}}
                                        onClick={e=>{
                                            const tempObj={...dataLabel[b],
                                                        showFilterLine:true
                                                        }
                                        
                                            setDataLabel({...dataLabel,
                                                            [b]:tempObj
                                                        })
                                        }}
                                
                                />
                            }</div>

                            
                            <div style={{width:"30%"}}
                            >
                                {editKey===b
                                ?<input
                                    value={editKeyInputState}
                                    onChange={e=>{
                                        setEditKeyInputState(e.target.value)
                                    }}
                                />
                                :<div className='w-100'
                                      style={{
                                            display:"flex",
                                            alignItems:"center"
                                      }}
                                     onClick={e=>{

                                        if(editKey){
                                            const tempObj={[editKey]:{...dataLabel[editKey],lb:editKeyInputState}}
                                            setDataLabel({...dataLabel,...tempObj})
                                        }
                                        
                                        setEditKey(b)
                                        setEditKeyInputState(dataLabel[b].lb)
                                     }}
                                >
                                    <MdEdit className='sm-icon'/>
                                    
                                    <div className='w-90'>
                                    {lb+key_short}
                                    </div>
                                </div>
                                }
                                
                            </div>
                            
                            <div className='flex-center-center'
                                    style={{width:"20%"}}
                            >
                                {
                                    //<div>{dataLabel[b].filterLineOrder}</div>
                                }
                                <FaArrowAltCircleDown className='sm-icon'
                                    style={{visibility:idxB===(keyObjectArray.length-1)?"hidden":"visible"}}
                                    onClick={e=>{
                                        let tempObj={}

                                        keyObjectArray.map((g,idxG)=>{
                                            //console.log(dataLabel[g].filterLineOrder)
                                            if(idxG===idxB){
                                                    tempObj={...tempObj,
                                                        [g]:{...dataLabel[g],filterLineOrder:(idxG+1)+1}
                                                    }
                                            }
                                            else if(idxG===idxB+1){
                                                    tempObj={...tempObj,
                                                           [g]:{...dataLabel[g],filterLineOrder:(idxG+1)-1}
                                                    }
                                            }
                                            else {
                                                    tempObj={...tempObj,
                                                        [g]:{...dataLabel[g],filterLineOrder:(idxG+1)}
                                                    }
                                            }
                                            
                                        })


                                        let tempObj2=dataLabel
                                        Object.keys(tempObj).map(a=>{
                                            tempObj2={...tempObj2,[a]:tempObj[a]}
                                        })

                                        setDataLabel(tempObj2)
                                    }}
                                />
                                <FaArrowAltCircleUp className='sm-icon'
                                    style={{visibility:idxB===0?"hidden":"visible"}}
                                    onClick={e=>{
                                        let tempObj={}

                                        keyObjectArray.map((g,idxG)=>{
                                            //console.log(dataLabel[g].filterLineOrder)
                                            if(idxG===idxB-1){
                                                    tempObj={...tempObj,
                                                        [g]:{...dataLabel[g],filterLineOrder:(idxG+1)+1}
                                                    }
                                            }
                                            else if(idxG===idxB){
                                                    tempObj={...tempObj,
                                                        [g]:{...dataLabel[g],filterLineOrder:(idxG+1)-1}
                                                    }
                                            }
                                            else {
                                                    tempObj={...tempObj,
                                                        [g]:{...dataLabel[g],filterLineOrder:(idxG+1)}
                                                    }
                                            }
                                        })

                                        let tempObj2=dataLabel
                                        Object.keys(tempObj).map(a=>{
                                            tempObj2={...tempObj2,[a]:tempObj[a]}
                                        })

                                        setDataLabel(tempObj2)
                                    }}
                                />
                                
                            </div>
                            
                            <div style={{width:"10%"}}
                            >{
                                filterLineBreak
                                ?<MdCheckBox className='sm-icon'
                                        onClick={e=>{
                                            const tempObj={...dataLabel[b],
                                                        filterLineBreak:false
                                                        }
                                            
                                            setDataLabel({...dataLabel,
                                                            [b]:tempObj
                                                        })
                                        }}
                                />
                                :<MdCheckBoxOutlineBlank className='sm-icon'
                                        onClick={e=>{
                                            const tempObj={...dataLabel[b],
                                                        filterLineBreak:true
                                                        }
                                            
                                            setDataLabel({...dataLabel,
                                                            [b]:tempObj
                                                        })
                                        }}
                                />
                            }
                            </div>

                            <div style={{width:"10%"}}
                            >{
                                renderStringInputType(b,idxB)
                            }
                            </div>
                            
                            <div className='flex-center-center jc-start' 
                                style={{width:"20%"}}
                            >
                
                                <div
                                    style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                           backgroundColor:dataLabel[b].filterLineColor,
                                           border:"1px solid white"
                                    }}

                                    onClick={e=>{
                                        setShowColorPlate(true)
                                        setEditFilterLine({[b]:dataLabel[b]})
                                    }}
                                >   
                                </div>

                                <div
                                    style={{width:"2rem",height:"1.2rem",borderRadius:"0.25rem",
                                           backgroundColor:dataLabel[b].filterLineBackgroundColor,
                                           border:"1px solid white",
                                           marginLeft:"1rem"
                                    }}

                                    onClick={e=>{
                                        setShowColorPlateForBackground(true)
                                        setEditFilterLine({[b]:dataLabel[b]})
                                    }}
                                >   
                                </div>
                            </div>

                        </div>
                        
                        )
                       
                    })
                }
              
            </div>
            {showColorPlate&&
                    <ColorPick
                        setShow={setShowColorPlate}
                        settingColorFunction={(x)=>{
                            const tempKey=Object.keys(editFilterLine)[0]
                            const tempObj={...editFilterLine[tempKey],filterLineColor:x}
                            setDataLabel({...dataLabel,[tempKey]:tempObj})
                            setEditFilterLine(null)
                        }}
                    />
            }
            {showColorPlateForBackground&&
                    <ColorPick
                        setShow={setShowColorPlateForBackground}
                        settingColorFunction={(x)=>{
                            const tempKey=Object.keys(editFilterLine)[0]
                            const tempObj={...editFilterLine[tempKey],filterLineBackgroundColor:x}
                            setDataLabel({...dataLabel,[tempKey]:tempObj})
                            setEditFilterLine(null)
                        }}
                    />
            }

           
            {showColorPlateForAll&&
                    <ColorPick
                        setShow={setShowColorPlateForAll}
                        settingColorFunction={(x)=>{

                            const tempKey=Object.keys(dataLabel)
                            
                            let tempObj2={}

                            tempKey.map(a=>{
                                tempObj2={...tempObj2,
                                    [a]:{...dataLabel[a],filterLineColor:x}
                                }
                            })

                            setDataLabel(tempObj2)
                            setEditFilterLine(null)
                 
                        }}
                    />
            }

            {showColorPlateForBackgroundForAll&&
                    <ColorPick
                        setShow={setShowColorPlateForBackgroundForAll}
                        settingColorFunction={(x)=>{
          
                            const tempKey=Object.keys(dataLabel)
                            
                            let tempObj2={}

                            tempKey.map(a=>{
                                tempObj2={...tempObj2,
                                    [a]:{...dataLabel[a],filterLineBackgroundColor:x}
                                }
                            })

                            setDataLabel(tempObj2)
                            setEditFilterLine(null)
            
                        }}
                    />
            }

            {showColorPlateForBackgroundForPage&&
                    <ColorPick
                        setShow={setShowColorPlateForBackgroundForPage}
                        settingColorFunction={(x)=>{
                            console.log(x)
                            setDfCommon({...dfCommon,filterInputBackgroundColor:x})
                        }}
                    />
            }


            

        </div>
    )
}

export default FilterSetting