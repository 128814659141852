

import React from 'react'

import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import console from '../../util/consoleVerify'

function UpdateDefaultDataFormatModal({
    submitCheck,
    submitCancel,
    defaultDataFormat,
    dataDataFormat,
    languageDisplay

}){

    const genDfName=()=>{
        let temp=""
        dataDataFormat.map(a=>{
            if(a.id===defaultDataFormat){
                temp=a.dfName
            }
        })
        return temp
    }

    return (
    <div className="Modal-background">
        <div className="Modal-box2"
        >
            <div className="Modal-header">
                <div>
                    {
                    languageDisplay
                    ?languageDisplay.updateDefaultDataFormat
                        ?languageDisplay.updateDefaultDataFormat
                        :"Update Default Data Format"
                    :"Update Default Data Format"
                    }
                  
                </div>
            </div>
          
            <div className='flex-center-center'
            
            >
                <div style={{width:"20%",padding:"0 2px"}}>
                    <input className='w-100'
                        type="number"
                        value={defaultDataFormat}
                        disabled="disabled"

                    />
                </div>

                <div style={{width:"80%",padding:"0 2px"}}>
                    <input className='w-100'
                        type="text"
                        value={genDfName()}
                        disabled="disabled"
                    />
                </div>

            </div>

            <div className="Modal-footer">
                <div>
                    <button
                        onClick={()=>{
                            submitCheck()
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button                       
                        onClick={()=>{
                            submitCancel()
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
          
        </div>
    </div>
    )

}

export default UpdateDefaultDataFormatModal