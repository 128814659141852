import React from 'react';
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import {MdDelete,MdVisibility,MdVisibilityOff} from 'react-icons/md';
import console from '../../util/consoleVerify'

function ModalShopLogInWithRecoveryPassword({
    setShow,
    submitFunction,
    languageDisplay

    }){

    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 

    const refLogInWithRecoveryPasswordShopName=React.createRef()
    const refLogInWithRecoveryPasswordRecoveryPassword=React.createRef()
    const refUserName=React.createRef()
    const refUserPassword=React.createRef()


    const [inputState,setInputState]=React.useState({
        shopName:"",
        recoveryPassword:"",
        username:"",
        userPassword:""
    })

    const [hidePassword,setHidePassword]=React.useState(false)


//===============================
const renderLogInWithRecoveryPassword=()=>{

    const tempArray=[
        "shopName",
        "recoveryPassword",

        "username",
        "userPassword"

    ]
    const refArray=[
        refLogInWithRecoveryPasswordShopName,
        refLogInWithRecoveryPasswordRecoveryPassword,
        refUserName,
        refUserPassword
    ]


    return (
        <div className='bd-pureWhite' 
            style={{
                padding:"3rem",
                minHeight:"40%",
                minWidth:"60%"
            }}
        >
            
                <div className='w-100 flex-center-center'
                    style={{marginBottom:"1rem"}}
                >
                    <h3>{
                        languageDisplay
                        ?languageDisplay.loginWithRecoveryPassword
                            ?languageDisplay.loginWithRecoveryPassword
                            :"LOG IN WITH RECOVERY PASSWORD"
                        :"LOG IN WITH RECOVERY PASSWORD"
                    }</h3>
                </div>
    
                {   
                    tempArray.map((i,idxI)=>{
                        
                        return (
                            <div key={idxI}
                                className="flex-center-center jc-start w-100"
                                style={{marginBottom:"1rem"}}
                            >
                
                                <div className="xc4">
                                    {
                                    languageDisplay
                                    ?languageDisplay[i]
                                        ?languageDisplay[i]
                                        :""
                                    :""                               
                                    }
                                </div>
                                
                                <div className="xc8"
                                    style={{position:"relative"}}
                                >
                                    <input
                                            
                                            ref={refArray[idxI]}
                                            type={i=="shopName"
                                                ?"text"
                                                :hidePassword?"password":"text"
                                            }

                                            onKeyDown={e=>{
                                                if(e.key=="Enter"){
                                                    
                                                    if(i=="shopName"){
                                                        refLogInWithRecoveryPasswordRecoveryPassword.current.focus()
                                                    }
                                                    else if(i=="recoveryPassword"){
                                                        refUserName.current.focus()
                                                    }
                                                    else if(i=="username"){
                                                        refUserPassword.current.focus()
                                                    }
                                                    else if(i=="userPassword"){
                                                        refModalConfirm1.current.focus()
                                                    }
                                                    
                                                }
                                            }}

                                        onChange={e=>{
                                            
                                            setInputState({
                                                ...inputState,
                                                [i]:e.target.value
                                            })

                                        }}
                                    />

                                    {
                                        i=="shopName"
                                        ?null
                                        :hidePassword
                                            ?<MdVisibilityOff 
                                            className="sm-icon" 
                                            style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                            onClick={e=>{
                                                setHidePassword(!hidePassword)
                                            }}
                                            />
                                            :<MdVisibility 
                                            className="sm-icon" 
                                            style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                            onClick={e=>{
                                                setHidePassword(!hidePassword)
                                            }}
                                        />
                                    }
                                
                                </div>
                            </div>
                        )
                    })
                }

              

        </div>
    )
}


//===============================
    return(

    <div className="Modal-background">
        <div className="Modal-box2"
        >
        {
        renderLogInWithRecoveryPassword()
        }
            
            
            <div className="Modal-footer">
                <div>
                    <button
                        ref={refModalConfirm1}
                        onClick={e=>{
                            setShow(false)
                            submitFunction(inputState)
                        }}
                        onKeyDown={e=>{
                            if(e.key=="ArrowRight"){
                                refModalConfirm2.current.focus()
                            }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        ref={refModalConfirm2}
                        onKeyDown={e=>{
                            if(e.key=="ArrowLeft"){
                                refModalConfirm1.current.focus()
                            }
                        }}
                        onClick={e=>{
                            if(setShow){
                                setShow(false)
                            }
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
           
        </div>
    </div>
    )
}

ModalShopLogInWithRecoveryPassword.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}


export default ModalShopLogInWithRecoveryPassword
