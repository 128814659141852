import React from 'react'
import EditPage from "./EditPage"
import Mnavbar from '../../component/mnavbar/Mnavbar'
import cosmeticUtil from '../../util/cosmeticUtil'
import dataTemplate from './dataTemplate'
import {FaHome,FaImages,FaTable,FaRegCalendarAlt} from 'react-icons/fa'


import {MainContext} from '../../context/MainContext'
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'


import './Home.css'

const {checkWindowLandscape}=cosmeticUtil

const {dataFormat,selectArray}=dataTemplate

function ItemEdit(){

    const {
       editInputState,
       setEditInputState,
       dataLabel,
       dataLabel2,
       setDataLabel2,
       currentLink,prevLink,
       submitEdit,
       submitEditWithNewId,
       submitCopyRecord,
       stringArray,
       lastRecordId,
       dfCommon,setDfCommon,

       keyArray,keyArrayWithPhotoUrl,
       basicDataSt,

       languageDisplay,
       

    }=React.useContext(MainContext)


    const refHome=React.createRef()


    return editInputState

    ?<div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <Mnavbar/>
        </div>


        <div style={{ display:"flex",
                      height:checkWindowLandscape()?"95%":"82%",width:"100%"}}>
            
            <div style={{height:"100%",width:`100%`
            }}>
                {basicDataSt.basicData&&
                <EditPage
                    dataLabel={dataLabel2}
                    editInputState={editInputState}
                    setEditInputState={setEditInputState}
                    currentLink={currentLink}
                    prevLink={prevLink}
                    submitEdit={submitEdit}
                    submitEditWithNewId={submitEditWithNewId}
                    submitCopyRecord={submitCopyRecord}
                    stringArray={stringArray}
                    dfCommon={dfCommon}
                    setDfCommon={setDfCommon}
                    lastRecordId={lastRecordId}
                    keyArray={keyArray}
                    basicData={basicDataSt.basicData}
                    languageDisplay={languageDisplay}
                />
                }
            </div>

        </div>


    </div>
    :<div className='home-bg-screen'
        style={{width:"100vw",height:"100vh"}}>
            <div className='flex-center-center'
                style={{height:"100%",width:"100%"}}
            >

                <button style={{
                            width:"10rem",
                            height:"10rem",
                            padding:"0"
                        }}
                    onClick={e=>{
                            refHome.current.click()
                            }}
                >
                    <FaHome
                        style={{fontSize:"4rem"}}
                    />
                    <div>
                        {
                            languageDisplay
                            ?languageDisplay.home
                                ?languageDisplay.home
                                :"Home"
                            :"Home"

                        }
                    </div>
                </button>

                
            </div>

        <div className="d-none">
 
                <Link ref={refHome} to="/cloud/home"/>  
        </div>     
    </div>
    
    

}

export default ItemEdit
