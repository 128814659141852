const uploadTableTemplate={

    id: {lb:"ID",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:1},
    type: {lb:"type",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:2},
    active: {lb:"active",type:"boolean",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:3},

    createdAt: {lb:"createdAt",type:"datetime",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:4},
    updatedAt: {lb:"updatedAt",type:"datetime",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:5},

    itemString1:{lb:"itemString1",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:6},
    itemString2:{lb:"itemString2",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:7},
    itemString3:{lb:"itemString3",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:8},
    itemString4:{lb:"itemString4",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:9},
    itemString5:{lb:"itemString5",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:10},

    itemString6:{lb:"itemString6",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:11},
    itemString7:{lb:"itemString7",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:12},
    itemString8:{lb:"itemString8",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:13},
    itemString9:{lb:"itemString9",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:14},
    itemString10:{lb:"itemString10",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:15},

    itemString11:{lb:"itemString11",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:16},
    itemString12:{lb:"itemString12",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:17},
    itemString13:{lb:"itemString13",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:18},
    itemString14:{lb:"itemString14",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:19},
    itemString15:{lb:"itemString15",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:20},

    itemString16:{lb:"itemString16",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:21},
    itemString17:{lb:"itemString17",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:22},
    itemString18:{lb:"itemString18",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:23},
    itemString19:{lb:"itemString19",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:24},
    itemString20:{lb:"itemString20",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:25},

    itemNumber1: {lb:"itemNumber1",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:26},
    itemNumber2: {lb:"itemNumber2",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:27},
    itemNumber3: {lb:"itemNumber3",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:28},
    itemNumber4: {lb:"itemNumber4",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:29},
    itemNumber5: {lb:"itemNumber5",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:30},

    itemNumber6: {lb:"itemNumber6",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:31},
    itemNumber7: {lb:"itemNumber7",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:32},
    itemNumber8: {lb:"itemNumber8",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:33},
    itemNumber9: {lb:"itemNumber9",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:34},
    itemNumber10: {lb:"itemNumber10",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:35},

    itemDate1: {lb:"itemDate1",type:"datetime",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:36},
    itemDate2: {lb:"itemDate2",type:"datetime",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:37},
    itemDate3: {lb:"itemDate3",type:"datetime",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:38},
    itemDate4: {lb:"itemDate4",type:"datetime",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:39},
    itemDate5: {lb:"itemDate5",type:"datetime",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:40},

    itemBoolean1: {lb:"itemBoolean1",type:"boolean",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:41},
    itemBoolean2: {lb:"itemBoolean2",type:"boolean",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:42},
    itemBoolean3: {lb:"itemBoolean3",type:"boolean",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:43},
    itemBoolean4: {lb:"itemBoolean4",type:"boolean",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:44},
    itemBoolean5: {lb:"itemBoolean5",type:"boolean",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:45},

    shopId:{lb:"shopId",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:46},
    userId: {lb:"userId",type:"number",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:47},
    photoUrl1:{lb:"photoUrl1",type:"string",width:140,showCol:true,showColHead:true,colColor:"white",colBackgroundColor:"black",colOrder:48},

}

export default uploadTableTemplate
