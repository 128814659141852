import React from 'react'
import {MdAddCircle,MdEdit,MdSettingsApplications,
} from 'react-icons/md';
import {FaBan,FaCheck,FaFolder,FaMinusSquare,FaPlusSquare,
    FaPlusCircle,
    FaArrowAltCircleDown,FaArrowAltCircleUp,
    FaCaretSquareDown,FaCaretSquareUp,
} from 'react-icons/fa';
import { MdSettings,MdCheckBox,MdCheckBoxOutlineBlank, 
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineIndeterminateCheckBox,
    MdOutlineSubtitles,
    MdEditSquare
} from 'react-icons/md';

import AddDataFormatModal from './AddDataFormatModal';
import DeleteDataFormatModal from './DeleteDataFormatModal';
import EditDataFormatModal from './EditDataFormatModal'
import UpdateDefaultDataFormatModal from './UpdateDefaultDataFormatModal'

import DataFormatFromTemplateModal from './DataFormatFromTemplateModal'

import console from '../../util/consoleVerify'


function SettingPage({
    basicDataSt,setBasicDataSt,

    countDataFormat,setCountDataFormat,
    lastRecordIdDataFormat,setLastRecordIdDataFormat,
    dataDataFormat,setDataDataFormat, 

    showAddDataFormatModal,
    setShowAddDataFormatModal,
    
    showDeleteDataFormatModal,
    setShowDeleteDataFormatModal,
    
    showEditDataFormatModal,
    setShowEditDataFormatModal,

    showUpdateDefaultDataFormatModal,
    setShowUpdateDefaultDataFormatModal,

    showDataFormatFromTemplate,
    setShowDataFormatFromTemplate,
    
    dataFormatFromTemplate,
    setDataFormatFromTemplate,

    selectDataFormatFromTemplate,
    setSelectDataFormatFromTemplate,


    newDataDataFormat,
    setNewDataDataFormat,
    submitNewDataDataFormat,
    submitDeleteDataFormat,
    submitEditDataFormat,
    submitUpdateDefaultDataFormat,

    submitSearchDataFormatFromTemplate,
    submitAddDataFormatFromTemplate,

    deleteDataFormat,
    setDeleteDataFormat,

    editDataFormat,
    setEditDataFormat,

    defaultDataFormat,
    setDefaultDataFormat,

    languageDisplay
}){
    //=================================
    //console.log('basicDataSt...........')
    //console.log(basicDataSt)
    //=================================
    const renderTool=(a,idxA)=>{

        const tempLength=dataDataFormat.length
       
        return(
            <div className='flex-center-end'
                 style={{width:"40%"}}
            >

                
                {basicDataSt.basicData.defaultDataFormat===a.id
                ?<MdCheckBox className='md-icon'
                    
                />
                :<MdCheckBoxOutlineBlank className='md-icon'
                    onClick={e=>{
                        setDefaultDataFormat(a.id)

                        setShowUpdateDefaultDataFormatModal(true)
                        //const tempObj={...basicDataSt.basicData,
                        //    defaultDataFormat:a.id
                        //}
                        //setBasicDataSt({...basicDataSt,basicData:tempObj})
                    }}
                />
                }
                <FaPlusSquare

                    className='md-icon'
                    onClick={(e)=>{
                        //console.log('lastRecordIdDataFormat')
                        //console.log(lastRecordIdDataFormat)
                        //setNewDataDataFormat(a)
                        const {_id,dfName,...remaining}=a
                        const newDfName=`${dfName}-copy`

                        const tempObj={
                            ...remaining,
                            dfName:newDfName,
                            id:parseFloat(lastRecordIdDataFormat)+1,
                        }

                        
                        setNewDataDataFormat(tempObj)
                        setShowAddDataFormatModal(true)
                    }}
                />
           
                <FaMinusSquare
                    className='md-icon'
                    style={{
                        visibility:
                            idxA===0||a.id===defaultDataFormat
                            ?"hidden"
                            :"visible"
                    }}
                    onClick={(e)=>{
                        setDeleteDataFormat(a)
                        setShowDeleteDataFormatModal(true)
                      
                    }}
                />
                
                <MdEditSquare
                    className='md-icon'
                    style={{
                        visibility:
                        idxA===0||a.id===defaultDataFormat
                        ?"hidden"
                        :"visible"
                    }}
                    onClick={(e)=>{
                        //setDeleteDataFormat(a)
                        //setShowDeleteDataFormatModal(true)
                        setEditDataFormat(a)
                        setShowEditDataFormatModal(true)
                    }}
                />
            
           
            </div>
        )
    }


    //=================================
    const renderBody=()=>{
        return(
            <div className="flex-center-center jc-start">
                <div className='w-100 flex-center-center jc-start'
                     style={{marginBottom:"1rem"}}
                >

                    <div className='w-40'
                         style={{
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center"
                         }}
                    >
                        <FaPlusCircle className='lg-icon'
                            onClick={e=>{
                                submitSearchDataFormatFromTemplate()

                            }}
                        />
                    </div>
                    <div className='w-60'
                         style={{visibility:"hidden"}}
                    >
                        hiddenthistext
                    </div>
                    
                    
                    
                </div>
                {
                    dataDataFormat.map((a,idxA)=>{
                        return(
                            <div key={`aa-${idxA}`}
                                 className="flex-center-start jc-start"
                                style={{ 
                                    width:"100%",
                                    height:"2.3rem",
                                    backgroundColor:
                                        idxA%2==1
                                        ?"rgba(120,120,120,0.5)"
                                        :"null"
                                
                                }}

                            >   
                                {
                                 renderTool(a,idxA)   
                                }
                               
                                <div className=''
                                     style={{
                                        fontSize:"1.2rem",
                                        width:"60%"
                                    }}
                                >
                                    {`${a.id}.${a.dfName}`}
                                </div>
                                                                
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    //=================================
    //=================================
    return (
    <div className="h-100 w-100 Modal-box"
         style={{
            position:"relative",
            overflowY:"auto",
            borderRadius:"0",
            paddingBottom:"8rem"
            
        }}
    >

            <div className="flex-center-top"
                 style={{marginBottom:"1rem"}}
            >
                
                <MdSettings
                    style={{
                        fontSize:"2rem",
                        marginRight:"1rem"
                    }}
                />
                <h3>
                    {
                        languageDisplay
                        ?languageDisplay.dataFormatSetting
                            ?languageDisplay.dataFormatSetting
                            :"Data Format Setting"
                        :"Data Format Setting"
                    }
                </h3>
            </div>

            {
              renderBody()
            }

            {
              showAddDataFormatModal&&
              <AddDataFormatModal
                    submitCheck={()=>{
                        submitNewDataDataFormat()
                    }}
                    submitCancel={()=>{
                        setShowAddDataFormatModal(false)
                    }}
                    newDataDataFormat={newDataDataFormat}
                    setNewDataDataFormat={setNewDataDataFormat}
                    languageDisplay={languageDisplay}
              />
            }
            {
              showDeleteDataFormatModal&&
              <DeleteDataFormatModal
                    submitCheck={submitDeleteDataFormat}
                    submitCancel={()=>{
                        setShowDeleteDataFormatModal(false)
                    }}
                    newDataDataFormat={newDataDataFormat}
                    setNewDataDataFormat={setNewDataDataFormat}
                    languageDisplay={languageDisplay}

              />
            }

            {
              showEditDataFormatModal&&
              <EditDataFormatModal
                    submitCheck={submitEditDataFormat}
                    submitCancel={()=>{
                        setShowEditDataFormatModal(false)
                    }}
                    editDataFormat={editDataFormat}
                    setEditDataFormat={setEditDataFormat}
                    languageDisplay={languageDisplay}

              />

            }

            {
              showUpdateDefaultDataFormatModal&&  
              <UpdateDefaultDataFormatModal
                    defaultDataFormat={defaultDataFormat}
                    dataDataFormat={dataDataFormat}

                    submitCheck={submitUpdateDefaultDataFormat}
                    submitCancel={()=>{
                        setShowUpdateDefaultDataFormatModal(false)
                        setDefaultDataFormat(basicDataSt.basicData.defaultDataFormat)
                    }}
                    languageDisplay={languageDisplay} 
              />
            }

            {
              showDataFormatFromTemplate&&
               <DataFormatFromTemplateModal
                    setShow={setShowDataFormatFromTemplate}
                    submitFunction={submitAddDataFormatFromTemplate}

                    languageDisplay={languageDisplay}
                    dataFormatFromTemplate={dataFormatFromTemplate}
                    
                    selectDataFormatFromTemplate={selectDataFormatFromTemplate}
                    setSelectDataFormatFromTemplate={setSelectDataFormatFromTemplate}
               />  
            }

    </div>
    )
}

export default SettingPage