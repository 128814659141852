import React from 'react'

import {Link} from 'react-router-dom';

import cosmeticUtil from '../../util/cosmeticUtil'

import {MdRefresh,MdSwapHoriz,MdSettings,
    MdSearch,MdEdit,MdAddCircle,MdDelete,MdPrint,
    MdChevronLeft,MdChevronRight,
    MdLastPage,MdFirstPage,
    MdRadioButtonChecked,MdClose,
} from 'react-icons/md';

import {MdSave
} from 'react-icons/md';

import { FaBullseye, FaRegArrowAltCircleUp} from 'react-icons/fa';
import Ticon from '../../component/ticon/Ticon'
import { TrainRounded } from '@material-ui/icons';
import console from '../../util/consoleVerify'

const {checkWindowLandscape}=cosmeticUtil




const RenderBadge2 = ({
    totalPage,
    currentPage,
    setCurrentPage,
    editInputState,
    setEditInputState,
    setShowConfirm,
    badgeColor

})=>{
   
    const refEdit=React.createRef()
    const refBarcode=React.createRef()
    const refForm=React.createRef()


    const goToFirst=()=>{
        setCurrentPage(1)
    }
    const goToPrev=()=>{
        if(currentPage>1){
            setCurrentPage(currentPage-1)
        }
    }
    const goToNext=()=>{
        if(currentPage<totalPage){
            setCurrentPage(currentPage+1)
        }
    }
    const goToLast=()=>{
            setCurrentPage(totalPage)
    }


    //const [tempCurrentPage,setTempCurrentPage]=React.useState(currentPage)

    //React.useEffect(()=>{
    //    if(tempCurrentPage>0&&tempCurrentPage<=totalPage){
    //        setCurrentPage(tempCurrentPage)
    //    }
    //},[tempCurrentPage])


    return (
    <div className="badge-frame-xc12"
        style={{background:badgeColor,

                width:"100%"
            }}
    > 
        
        <div className="flex-center-center flex-no-wrap xc12 jc-start"
              style={{justifyContent:"flex-start"}}>

           
            <div>
                 <MdFirstPage
                    className="sm-icon"
                    onClick={e=>{
                        goToFirst()
                  
                    }}
                 />
            </div>   
            


            <div>
                <MdChevronLeft
                    className="sm-icon"
                    style={{visible:"visible"
                           
                        }}
                    onClick={e=>{
                        goToPrev()
             
                    }}
                />
            </div>
            
                <input 
                type="number"
                style={{width:"70px"}}
                value={currentPage
                }
                onChange={e=>{
                    const tempInt=parseInt(e.target.value)
                    if(tempInt>totalPage){
                        return 
                    }
                    
                    if(tempInt===0){
                        return
                    }

                    if(e.target.value==="0"){
                        return
                    }
                    
                    if(tempInt<=-1){
                        return
                    }
                    
                    setCurrentPage(tempInt)
                }}
              />
         
            <div style={{paddingTop:"0rem"}}>
                <div>{totalPage}
                </div>
            </div>
           

            <div>
                <MdChevronRight
                    className="sm-icon"
                    onClick={e=>{
                        goToNext()
                       
                    }}
                />
            </div>
            
            <div>
                 <MdLastPage
                    className="sm-icon"
                    onClick={e=>{
                        goToLast()
                        
                    }}
                 />
            </div>   
            
          
           {true&&
           <div className="iconbox"
                onClick={e=>{
                    setShowConfirm(true)
                  
                }}
            >   
                <Ticon
                    iconName="MdSave"
                    className="sm-icon"
                    textStyle={{color:"black"}}
                />
            </div>
            }
          
    


            <div className="d-none">
                <Link ref={refEdit} to="/cloud/edit"/>
                <Link ref={refBarcode} to="/cloud/barcode"/>
            </div>

              
        
        </div>
        
    </div>  
    )
}

export default RenderBadge2

