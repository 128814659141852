import React from 'react'
import Mnavbar from '../../component/mnavbar/Mnavbar'
import cosmeticUtil from '../../util/cosmeticUtil'

import fakeAxiosUtil from './fakeAxiosUtil'

import ModalConfirm from './ModalConfirm'
import {MainContext} from '../../context/MainContext'

import TaskCalendar from './TaskCalendar'
import {MdSettings} from 'react-icons/md';
import TaskCalendarSetting from './TaskCalendarSetting'
import console from '../../util/consoleVerify'
import ModalTypeSelect from './ModalTypeSelect'


const {getLimit,getLimitRow,getDataLength,findToltalPage,
       deleteById
      }=fakeAxiosUtil
const {checkWindowLandscape}=cosmeticUtil



function ItemCalendar(){

    const {
        calendarData,setCalendarData,
        dataLabel2,setDataLabel2,
        dfCommon,setDfCommon,
        currentPage,setCurrentPage,
        totalPage,setTotalPage,
        editInputState,setEditInputState,
        dataLabel,setDataLabel,
        date,setDate,
        firstLoadCalendar,setFirstLoadCalendar,
        thisWeekDay,setThisWeekDay,
        taskInMonth,setTaskInMonth,
        showTaskCalendarSetting,setShowTaskCalendarSetting,
        widthRight,setWidthRight,
        filterState,setFilterState,
        dateArrayOfItem,
        submitDataFormat,
        submitSearchInCalendar,
        submitDeleteById,
        keyArray,keyArrayWithPhotoUrl,

        genNextWeek,
        genPrevWeek,
        genNextMonth,
        genPrevMonth,
        genNextYear,
        genPrevYear,
        genToDate,

        languageDisplay,
        
        defaultDataFormat,
        defaultDataFormatName,

        basicDataSt,
        submitUpdateDefaultDataFormatWithFilterState

    }=React.useContext(MainContext)

    //console.log('itemCalendar')
   
    const [showConfirm,setShowConfirm]=React.useState(false)
    const [showModalTypeSelect,setShowModalTypeSelect]=React.useState(false)


    return (
    <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <Mnavbar/>
        </div>

       

        <div style={{ display:"flex",
                      height:checkWindowLandscape()?"95%":"92%",width:"100%",
                      position:"relative"
                    }}
        >
            
            <div className=''
            
                style={{
                        height:"100%",
                        width:showTaskCalendarSetting?`${widthRight}%`:`100%`,
                        background:dfCommon.taskCalendarBackgroundColor
                        ?dfCommon.taskCalendarBackgroundColor
                        :"transparent"
                        }}
            >
               {dataLabel2&&filterState&&dfCommon&&date&&
                <TaskCalendar
                        date={date}
                        setDate={setDate}
                        filterState={filterState}
                        dataLabel={dataLabel2}
                        thisWeekDay={thisWeekDay}
                        setThisWeekDay={setThisWeekDay}
                        checkWindowLandscape={checkWindowLandscape}
                        headColor={"#FFA07A"}
                        dfCommon={dfCommon}
                        submitSearchInCalendar={submitSearchInCalendar}
                        calendarData={calendarData}
                        dateArrayOfItem={dateArrayOfItem}
                        genNextWeek={genNextWeek}
                        genPrevWeek={genPrevWeek}
                        genNextMonth={genNextMonth}
                        genPrevMonth={genPrevMonth}
                        genNextYear={genNextYear}
                        genPrevYear={genPrevYear}
                        genToDate={genToDate}
                        firstLoadCalendar={firstLoadCalendar}
                        setFirstLoadCalendar={setFirstLoadCalendar}
                        keyArray={keyArray}
                        editData={editInputState}
                        setEditData={setEditInputState}

                        setShowConfirm={setShowConfirm}



                       
                />
                }
           
            </div>

            {showTaskCalendarSetting&&dataLabel2&&
                <div className="bgc-shadeGray"
                    style={{
                        height:"100%",
                        width:showTaskCalendarSetting?`${100-widthRight}%`:"100%",
                        //padding:"1rem"
                }}
                >   
                    
                    <TaskCalendarSetting
                        dateArrayOfItem={dateArrayOfItem}
                        dataLabel={dataLabel2}
                        setDataLabel={setDataLabel2}
                        submitDataFormat={submitDataFormat}
                        filterState={setFilterState}
                        showTaskCalendarSetting={showTaskCalendarSetting}
                        setShowTaskCalendarSetting={setShowTaskCalendarSetting}
                        keyArrayWithPhotoUrl={keyArrayWithPhotoUrl}

                        keyArray={keyArray}
                        dfCommon={dfCommon}
                        setDfCommon={setDfCommon}
                        languageDisplay={languageDisplay}
                        //keyArray={keyArray}
                    />
                
                </div>
            }    


                {
                <div 
                    style={{
                        display:"flex",
                        alignItems:"baseline",
                        position:"absolute",
                        flexWrap:"nowrap",

                        zIndex:10,
                        top:"0rem",
                        right:"0"
                    }}
                >
                        
                        <h5 className='hovertext'
                            onClick={e=>{
                                setShowModalTypeSelect(true)
                            }}
                        >
                            {defaultDataFormat}
                        </h5>

                        <h5 className='hovertext showinbigscreen'
                            
                            onClick={e=>{
                                setShowModalTypeSelect(true)
                            }}
                        >
                            {`.${defaultDataFormatName}`}
                        </h5>


                        <MdSettings className='md-icon'
                                 style={{
                                    marginLeft:"1rem"
                                }}
                                onClick={e=>{
                                    setShowTaskCalendarSetting(!showTaskCalendarSetting)
                                }}
                        />
                </div>
                }
        </div>

        {showTaskCalendarSetting&&
        <div
            style={{
                position:"fixed",
                bottom:"0",
                width:"100vw"
            }}
        >
            <input
                type="range" min="10" max="100" 
                value={widthRight}
                onChange={e=>{
                    setWidthRight(e.target.value)
                }}
            />
        </div>
        }


        {showConfirm&&editInputState
        ?<ModalConfirm
               setShow={setShowConfirm}
               submitFunction={submitDeleteById}
               

               editData={editInputState}
               languageDisplay={languageDisplay}
        />
        :null
        }


        {showModalTypeSelect
            ?<ModalTypeSelect
                setShow={setShowModalTypeSelect}
                submitFunction={submitUpdateDefaultDataFormatWithFilterState}
                basicData={basicDataSt.basicData}
            />
            :null
        }

    </div>



    )
}

export default ItemCalendar


/*
<TaskCalendar
    pageData={pageData}
    date={date}
    setDate={setDate}
    taskInMonth={taskInMonth}
    setTaskInMonth={setTaskInMonth}
/>


*/