


import React from 'react'

import {MdVisibility,MdVisibilityOff,
    MdEdit,MdAddCircle,MdDelete,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineCheckBox,
    MdRefresh,
    MdCheckBox,
    MdCheckBoxOutlineBlank
} from 'react-icons/md';
import {FaBan,FaCheck} from 'react-icons/fa'
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'


function EditUserModal({
    editUser,
    submitCheck,
    submitCancel,
    basicData,
    languageDisplay
}){

    const refId=React.createRef()
    const refUsername=React.createRef()
    const refActive=React.createRef()
    const refUserLevel=React.createRef()
    const refName=React.createRef()
    const refSurname=React.createRef()
    const refCheck=React.createRef()
    const refBan=React.createRef()


    const tempArray=[
        {   key:"id",
            lb:languageDisplay
                ?languageDisplay.id
                  ?languageDisplay.id
                  :"ID"
                :"ID",
            type:"number",
            ref:refId
        },
        {   key:"username",
            lb:languageDisplay
                ?languageDisplay.username
                    ?languageDisplay.username
                    :"USER NAME"
                :"USER NAME",
            type:"text",
            ref:refUsername
        },
        {
            key:"active",
            lb:languageDisplay
                ?languageDisplay.active
                  ?languageDisplay.active
                  :"ACTIVE"
                :"ACTIVE",
            type:"checkbox",
            ref:refActive
        },
        {
            key:"userLevel",
            lb:languageDisplay
                ?languageDisplay.userLevel
                  ?languageDisplay.userLevel
                  :"USER LEVEL"
                :"USER LEVEL",
            type:"text",
            ref:refUserLevel
        },
        {
            key:"name",
            lb:languageDisplay
                ?languageDisplay.name
                  ?languageDisplay.name
                  :"NAME"
                :"NAME",
            type:"text",
            ref:refName
        },
        {
            key:"surname",
            lb:languageDisplay
                ?languageDisplay.surname
                  ?languageDisplay.surname
                  :"SURNAME"
                :"SURNAME",
            type:"text",
            ref:refSurname
        }

    ]
        
    
    const [inputState,setInputState]=React.useState(editUser)

 

    const renderSelect=(v,idxV)=>{
        return (
            <select
                type="select"
                ref={v.ref}
                value={inputState["userLevel"]}

                onKeyDown={e=>{
                    if(e.key==="Enter"){
                        if(idxV===tempArray.length-1){
                            refCheck.current.focus()
                        }
                        else {
                            tempArray[idxV+1].ref.current.focus()
                        }
                    }
                }}


                onChange={e=>{
                    setInputState({...inputState,
                        userLevel:e.target.value
                    })
                }}
            >
                {
                 basicData.userLevel.map((ul,idxUl)=>{
                    return (
                        <option
                            key={`ul-${idxUl}`}
                            value={ul}
                        >{ul}</option>
                    )
                 })   
                }
            </select>
        )
    }

    return(
    <div className="Modal-background">

        <div className="Modal-box2"
             style={{width:"90%"}}
        >
            <div className="Modal-header">
                <h5>
                    {
                    languageDisplay
                    ?languageDisplay.editUserModal
                        ?languageDisplay.editUserModal
                        :"EDIT USER"
                    :"EDIT USER"
                    }
                </h5>
            </div>

            <div className='Modal-box'>
                {
                    tempArray.map((v,idxV)=>{
                        return (
                            <div key={`idxV-${idxV}`}
                                 className='w-100'
                                 style={{display:"flex",
                                        justifyContent:"flex-start",
                                        margin:"1rem 0"
                                }}
                            >
                                <div className='w-20'>
                                    {v.lb}
                                </div>

                                {v.key==="active"
                                ?<div className='w-80'
                                      style={{display:"flex",
                                              justifyContent:"center",
                                              alignItems:"center"
                                            }}
                                >
                                    <input
                                        className='w-100'
                                        ref={v.ref}

                                        type={v.type}
                                        
                                        checked={inputState[v.key]}
                                        
                                        onKeyDown={e=>{
                                            if(e.key==="Enter"){
                                                if(idxV===tempArray.length-1){
                                                    refCheck.current.focus()
                                                }
                                                else {
                                                    tempArray[idxV+1].ref.current.focus()
                                                }
                                            }
                                        }}

                                        onChange={e=>{
                                            if(idxV!==1){
                                                setInputState({
                                                    ...inputState,
                                                    [v.key]:e.target.checked
                                                })
                                                }
                                        }}
                                    />
                                 
                              
                                </div>
                                :<div className='w-80'>
                                    {v.key==="userLevel"
                                    ?renderSelect(v,idxV)
                                    :<input
                                        className='w-100'
                                        ref={v.ref}
                                        type={v.type}
                                        
                                        value={inputState[v.key]}
                                        disabled={v.key==="id"?true:false}

                                        onKeyDown={e=>{
                                            if(e.key==="Enter"){
                                                if(idxV===tempArray.length-1){
                                                    refCheck.current.focus()
                                                }
                                                else {
                                                    tempArray[idxV+1].ref.current.focus()
                                                }
                                            }
                                        }}

                                        onChange={e=>{
                                            setInputState({
                                                ...inputState,
                                                [v.key]:e.target.value
                                            })

                                        }}
                                    />
                                    }
                                    
                                </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
           

            <div className="Modal-footer"
                style={{marginTop:"2rem"}}
            >
                   

                   <div>
                        <button                       
                            ref={refCheck}
                            onClick={()=>{
                                submitCheck(inputState)  
                            }}
                        >
                            <FaCheck/>
                        </button>
                    </div>

                    <div>
                        <button
                            ref={refBan}                       
                            onClick={()=>{
                                submitCancel(false)
                            }}
                        >
                            <FaBan/>
                        </button>
                    </div>
            </div>

        </div>
    </div>
    )

}

export default EditUserModal

