import React from 'react';
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import {MdCheckBoxOutlineBlank,MdCheckBox} from 'react-icons/md';
//import console from '../../util/consoleVerify'

function DataFormatFromTemplateModal({
    setShow,
    submitFunction,

    dataFormatFromTemplate,
    setDataFormatFromTemplate,

    selectDataFormatFromTemplate,
    setSelectDataFormatFromTemplate,

    languageDisplay

    }){


    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 


    console.log(dataFormatFromTemplate)
//===============================
//===============================
    return(

    <div className="Modal-background">
        <div className="Modal-box2"
        >
            <div className="Modal-header"
                 style={{display:"block"}}
            >
                <div className='flex-center-center'
                     style={{marginBottom:"1rem"}}
                >
                    <h4>
                    {
                    languageDisplay
                    ?languageDisplay.selectDataFormatFromTemplate
                        ?languageDisplay.selectDataFormatFromTemplate
                        :"SELECT DATA FORMAT FROM TEMPLATE"
                    :"SELECT DATA FORMAT FROM TEMPLATE"
                    }
                    </h4>
                </div>
                <div className='w-100'>
                    {dataFormatFromTemplate&&
                     dataFormatFromTemplate.map((a,idxA)=>{

                        let checkId=false
                        if(selectDataFormatFromTemplate){
                            if(selectDataFormatFromTemplate.id===a.id){
                                checkId=true
                            }
                        }

                        return (
                            <div className='w-100 flex-center-center' 
                                 key={idxA}
                                 
                            >
                                <div className='flex-center-center'
                                        style={{
                                            width:"20%",
                                            justifyContent:"flex-end"
                                        }}
                                >
                                    {checkId
                                    ?<MdCheckBox
                                        className='md-icon'
                                        
                                        onClick={e=>{
                                            setSelectDataFormatFromTemplate(null)
                                        }}
                                    />
                                    :<MdCheckBoxOutlineBlank 
                                        className='md-icon'
                                        
                                        onClick={e=>{
                                            setSelectDataFormatFromTemplate(a)
                                        }}
                                    />
                                    }
                                
                                </div>


                                 <div className=''
                                     style={{
                                        fontSize:"1.2rem",
                                        width:"70%"
                                    }}
                                 >
                                {`${a.id}.${a.dfName}`}
                                </div>
                            </div>
                        )
                     })

                    }
                </div>
            </div>
          
            <div className="Modal-footer">
                <div>
                    <button
                        ref={refModalConfirm1}
                        onClick={e=>{
                            //setShow(false)
                            submitFunction()
                        }}
                        onKeyDown={e=>{
                            if(e.key=="ArrowRight"){
                                //refModalConfirm2.current.focus()
                            }
                        }}
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        ref={refModalConfirm2}
                        onKeyDown={e=>{
                            if(e.key=="ArrowLeft"){
                                //refModalConfirm1.current.focus()
                            }
                        }}
                        onClick={e=>{
                            if(setShow){setShow(false)}
                        }}
                    >
                        <FaBan/>
                    </button>
                </div>

            </div>
            {/*
              showModalError
              ?renderModalError({show:showModalError,setShow:setShowModalError})
              :null
            */}
        </div>
    </div>
    )
}

DataFormatFromTemplateModal.defaultProps={
   
}


export default DataFormatFromTemplateModal
