import React from 'react'
import axios from 'axios';

import {MdAddCircle,MdEdit} from 'react-icons/md';
import {FaBan,FaCheck} from 'react-icons/fa';

import photoUtil from '../../component/galleryone_add/photoUtil'

import ChangeShopPasswordModal from './ChangeShopPasswordModal'
import UserListModal from './UserListModal';

import GallerySinglePhoto_add from '../../component/galleryone_add/GallerySinglePhoto_add'
import console from '../../util/consoleVerify'
import appVerify from '../../util/appVerify';

const {genMyTk,checkReqBody}=appVerify


function Payment({
    userList,
    submitSearchUserList,

    submitAddUser,

    submitDeleteUser,
    submitEditUser,
    submitChangeUserPasswordByAdmin,

    submitChangeShopPassword,


    basicData,
    formTemplate,
    userArray,
    userTableTemplate,
    userLevel,
    bankDetail,
    shopPayment,
    user,
    showError,setShowError,
    messageError,setMessageError,
    
    myheader,
    languageDisplay,
    languageSetting
}){

    const [showChangeShopPassword,setShowChangeShopPassword]=React.useState(false)
    const [showUserList,setShowUserList]=React.useState(false)
    const [showShopPaymentModal,setShowShopPaymentModal]=React.useState(false)

    //const [shopState,setShopState]=React.useState({shopName:"shopa",password:"shopa"})
    //const [userState,setUserState]=React.useState({username:"userone",password:"userone"})

    const [showCheckServiceFee,setShowCheckServiceFee]=React.useState(false)
    const [showShopPayment,setShowShopPayment]=React.useState(false)


    const [shopPaymentState,setShopPaymentState]=React.useState({
        shopName:"",
        expirationDate:"",
        monthlyFee:"",
        remainingBalance:"",
        newExpirationDate:"",
        overdueBalance:"",

        companyBankAccountName:"",
        companyBankAccountNumber:"",
        companyBankAccountBank:"",

        transactionAmount:0,
        shopToken:"",
        file1:null,
        photoUrl1:[]
    })

    //===========================
    //===========================
    const {changeArrayFile}=photoUtil
  
    const [showImage1,setShowImage1]=React.useState(true)
    const [arrayFile1,setArrayFile1]=React.useState([])
    const [fileUrl1,setFileUrl1]=React.useState([])

    const [imageLimit,setImageLimit]=React.useState(1)

    
    React.useEffect(()=>{
        //console.log('fileUrl1')
        //console.log(fileUrl1)
    },[fileUrl1])
  
    React.useEffect(()=>{
        console.log('useEffect ArrayFile1')
        console.log(arrayFile1)
               
        changeArrayFile({ arrayFile:arrayFile1,
            fileUrl:fileUrl1,
            setFileUrl:setFileUrl1,
            inputState:shopPaymentState,
            setInputState:setShopPaymentState,
            keyName:"photoUrl1",
            //fileName,
            //serverFolder,
            //fileName:"file",
            //serverFolder:"/upload/customer",
            setShowImage:setShowImage1})
          
    },[arrayFile1])


    //===================================
    const displayError=(line,err)=>{

        //console.log('err')
        //console.log(err.response.data)

        setShowError(true) 
        let tempErr="error"

        if(err.response){
            if(err.response.data){
                if(err.response.data.message){
                    tempErr=err.response.data.message
                }
            }
        }

        setMessageError(`${line}: ${languageDisplay[tempErr]}`)
    }

    //===================================

    const submitCheckServiceFee=async()=>{
        console.log("submitCheckServiceFee")
        try {
            const result=await axios.post('/p40shoppayment/checkservicefee',
                    {},
                    genMyTk(myheader))
            console.log(result.data)

            const {
                shopName,remainingBalance,overdueBalance,
                newExpirationDate,expirationDate,
                monthlyFee,
                companyBankAccountBankEnglish,
                companyBankAccountBankThai,
                companyBankAccountNameEnglish,
                companyBankAccountNameThai,
                companyBankAccountNumberEnglish,
                companyBankAccountNumberThai
            }=result.data

            let temp={
                shopName,remainingBalance,overdueBalance,
                newExpirationDate,expirationDate,
                monthlyFee,
                companyBankAccountBank:"",
                companyBankAccountName:"",
                companyBankAccountNumber:"",

                transactionAmount:0,
                file1:null,
                photoUrl1:[]
            }


            if(languageSetting==="Thai"){
                temp={
                    ...temp,
                    companyBankAccountBank:companyBankAccountBankThai,
                    companyBankAccountName:companyBankAccountNameThai,
                    companyBankAccountNumber:companyBankAccountNumberThai
                }
            }
            else if(languageSetting==="English"){
                temp={
                    ...temp,
                    companyBankAccountBank:companyBankAccountBankEnglish,
                    companyBankAccountName:companyBankAccountNameEnglish,
                    companyBankAccountNumber:companyBankAccountNumberEnglish
                }
            }

            setShopPaymentState(temp)
            setShowCheckServiceFee(false)
            setShowShopPayment(true)
        }
        catch (err){

            displayError("PM192",err)

        }

    }

    //============================
    const renderCheckServiceFee=()=>{
        return (
        <div className="Modal-background">
            <div className="Modal-box" 
                style={{paddingBottom:"1rem",
                        width:"80%",
                        minWidth:"70%",
                        minHeight:"100px",
                        //height:"50%",
                        minHeight:"30%",
                        overflow:"auto"
                    }}
            >

                <div className="flex-center-center">
                    <div className='w-100'
                    >

                        <div className='flex-center-center'>
                            <h5>
                                {
                                languageDisplay
                                ?languageDisplay.checkServiceFee
                                    ?languageDisplay.checkServiceFee
                                    :"CHECK SERVICE FEE"
                                :"CHECK SERVICE FEE"
                                }
                            </h5>
                        </div>


                        <div className='w-100 flex-center-center jc-end'
                            style={{marginTop:"1rem"}}
                        >
                                <div>
                                    <button

                                        onClick={e=>{
                                            submitCheckServiceFee()                                         
                                        }}
                                    >
                                        <FaCheck/>
                                    </button>
                                </div>

                                <div>
                                    <button
                                        
                                        onClick={e=>{
                                            setShowCheckServiceFee(false)
                                        }}
                                    >
                                        <FaBan/>
                                    </button>
                                </div>

                        </div>


                    </div>
        
                </div>
            </div>
        </div>
           
        )
    }

//============================
//============================

const genDate=(date)=>{
    let tempDate=""
    
    if(date){
        tempDate=new Date(date).toLocaleString('en-GB')
    }
   
    return tempDate

}

//===========================
const genFD=(inputState)=>{
    //console.log('genFD')

    const tempArray=Object.keys(inputState)
    const fd=new FormData()
    
    tempArray.map((b,idxB)=>{
        //console.log(b)
        if(b==="file1"){
            for(let i=0;i<inputState[b].length;i++){
                const fileName=`${inputState[b][i].name}`
                //console.log()
                fd.append(`imageUrl`,inputState[b][i],fileName);  
            }
        }
        else if(b==="photoUrl1"){
            inputState[b].map((c,idxC)=>{
                //console.log(`${b}[${idxC}].......${inputState[b][idxC]}...`)
                fd.append(`${b}[${idxC}]`,inputState[b][idxC])
            })
        }
        else {
            fd.append(`${b}`,inputState[b])
        }
    })
    //console.log('end genFD')
    return fd

}

//===========================
const addFileNameInPhotoUrl=(formInputState)=>{
    let temp1=[]
    if(formInputState.file1){
        //console.log(1)
      for (let i=0;i<formInputState.file1.length;i++){
        temp1=[...temp1,formInputState.file1[i].name]
      }
    }
    if(formInputState.photoUrl1){
      //console.log(2)  
      formInputState.photoUrl1=[...formInputState.photoUrl1,...temp1]
    }
    //console.log(3)
    return formInputState
  }


//===========================
const submitAddShopPaymentFromUser=async()=>{

    console.log("submitAddShopPaymentFromUser")

    try {
        const {file1,transferAmount,shopName,
            photoUrl1,...remaining}=shopPaymentState
        
        console.log(file1)
        console.log(transferAmount)    

        if(file1&&transferAmount>0){

            let checkFile1=false
            if(file1){
                if(file1.length>0){
                    checkFile1=true
                }
            }
            //console.log(3)
            if(checkFile1){
                const tempFormInputState2=addFileNameInPhotoUrl({file1,photoUrl1,transferAmount})
                //console.log("tempFormInputState2")
                //console.log(tempFormInputState2)
                const fd=genFD(tempFormInputState2)
                const result =await axios.post('/p40shoppayment/addshoppaymentfromuser',
                                fd,
                                genMyTk(myheader))
                
                //console.log('result.data')
                //console.log(result.data)
                
                setShowCheckServiceFee(false)
                setShowShopPayment(false)
            
                setShopPaymentState({
                    shopName:"",
                    expirationDate:"",
                    monthlyFee:"",
                    remainingBalance:"",
                    newExpirationDate:"",
                    overdueBalance:"",
            
                    companyBankAccountName:"",
                    companyBankAccountNumber:"",
                    companyBankAccountBank:"",
            
                    shopToken:"",
                    file1:null,
                    photoUrl1:[]
                })

            
            }
        }

    }
    catch (err){

        displayError("PM389",err)
      
    }

}
//===========================
//===========================
const renderShopPayment=()=>{

    const tempArray=[
        "shopName",
        "expirationDate",
        "overdueBalance",
    ]

    const tempArray2=[
        "companyBankAccountBank",
        "companyBankAccountName",
        "companyBankAccountNumber",
    ]

    return(
    <div className='shoppaymentbox' 
        
    >
        
            <div className='w-100 flex-center-center'
                style={{marginBottom:"0rem"}}
            >
                <h3>{
                    languageDisplay
                    ?languageDisplay.shopPayment
                        ?languageDisplay.shopPayment
                        :"Service Payment"
                    :"Service Payment"
                    }
                </h3>
               
            </div>

            <div className='w-100 flex-center-center'
                style={{marginBottom:"0rem"}}
            >
                <h6>{
                    languageDisplay
                    ?languageDisplay.detailCheckBeforeShopPayment
                        ?languageDisplay.detailCheckBeforeShopPayment
                        :"PLEASE CHECK OVERDUE BALANCE BEFORE PAYMENT"
                    :"PLEASE CHECK OVERDUE BALANCE BEFORE PAYMENT"
                }
                </h6>
            </div>


            {tempArray.map((i,idxI)=>{
                return (
                    <div key={idxI}
                        className='w-100 flex-center-center jc-start'
                        style={{marginBottom:"0rem"}}
                    >
                        <h6
                            style={{
                                marginRight:"0.5rem",
                                color:"red",
                                fontSize:i==="overdueBalance"?"1.6rem":""
                                
                            }}
                        >{
                           languageDisplay
                           ?languageDisplay[i]
                               ?languageDisplay[i]
                               :""
                           :""
                        }
                        </h6>
                        <h6 style={{
                                color:"red",
                                fontSize:i==="overdueBalance"?"2rem":""
                            }}>
                            {i==="expirationDate"||i==="newExpirationDate"
                            ?`: ${genDate(shopPaymentState[i])}`
                            :shopPaymentState[i]
                            }
                        </h6>

                        <h6 style={{
                                marginLeft:"0.5rem",color:"red",
                                fontSize:i==="overdueBalance"?"1.6rem":""
                            }}>
                            {i==="monthlyFee"||i==="remainingBalance"||i==="overdueBalance"
                            ?languageDisplay
                                ?languageDisplay.bath
                                    ?languageDisplay.bath
                                    :""
                                :""
                            :null
                            }
                        </h6>

                    </div>
                )
            })}


            <div className='w-100 flex-center-center'
                style={{marginBottom:"0rem"}}
            >
                <h5>{
                    languageDisplay
                    ?languageDisplay.detailCompanyBankAccount
                        ?languageDisplay.detailCompanyBankAccount
                        :"PLEASE TRANSFER MONEY TO OUR BANK ACCOUNT AS FOLLOWING AND ATTACH TRANSACTION SLIP AS IMAGE FILE"
                    :"PLEASE TRANSFER MONEY TO OUR BANK ACCOUNT AS FOLLOWING AND ATTACH TRANSACTION SLIP AS IMAGE FILE"
                    }
                </h5>
            </div>


            {tempArray2.map((i,idxI)=>{
                return (
                    <div key={idxI}
                        className='w-100 flex-center-center jc-start'
                        style={{marginBottom:"0rem"}}
                    >
                        <h6
                            style={{marginRight:"0.5rem",color:"green"}}
                        >{
                           languageDisplay
                           ?languageDisplay[i]
                               ?languageDisplay[i]
                               :""
                           :""
                        }
                        </h6>
                        <h6 style={{marginRight:"0.5rem",color:"green"}}>
                            :
                        </h6>
                        <h6 style={{color:"green"}}>
                            {
                            shopPaymentState[i]
                            }
                        </h6>

                  

                    </div>
                )
            })}


            <div
                className="flex-center-center jc-start w-100"
                style={{padding:"1rem",backgroundColor:""}}
            >
            
                    <div className="xc4">
                        <h5>
                            {
                            languageDisplay
                            ?languageDisplay.transferAmount
                                ?languageDisplay.transferAmount
                                :"TRANSFER AMOUNT (BAHT)"
                            :"TRANSFER AMOUNT (BAHT)"
                            }
                        </h5>
                    
                    </div>
                    
                    <div className="xc8"
                        style={{position:"relative"}}
                    >
                        <input
                            type="number"
                            onChange={e=>{
                                setShopPaymentState({
                                    ...shopPaymentState,
                                    ["transferAmount"]:e.target.value
                                }) 
                            }}
                        />
                    </div>
            </div>

            <div className="xc8 lc8 mc10 sc12 bgc-darkGray bd-lightGray h-50"
                 style={{height:"30%"}}
            >
                {
                <GallerySinglePhoto_add 
                    fileUrl={fileUrl1}
                    arrayFile={arrayFile1}
                    setArrayFile={setArrayFile1}
                    keyName={"photoUrl1"}
                    setShowImage={setShowImage1}
                    
                    inputState={shopPaymentState}
                    setInputState={setShopPaymentState}

                />
                }
            </div>    

            <div style={{
                    display:"flex",
                    justifyContent:"flex-end"
                    }}
            >
                    <button
                        //ref={refShopPayment}
                        onClick={e=>{
                            submitAddShopPaymentFromUser()
                        }}
                    >
                        <FaCheck/>
                    </button>
                
                    <button
                    //ref={refCancel}
                        onClick={e=>{
                            //setShowShopLogInForShopPayment(false)
                            const temp={
                                shopName:"",
                                expirationDate:"",
                                monthlyFee:"",
                                remainingBalance:"",
                                newExpirationDate:"",
                                overdueBalance:"",
                        
                                companyBankAccountName:"",
                                companyBankAccountNumber:"",
                                companyBankAccountBank:"",
                        
                                shopToken:"",
                                file1:null,
                                photoUrl1:[]
                            }


                            setShopPaymentState(temp)
                            setShowShopPayment(false)
                            setShowCheckServiceFee(false)
                            
                        }}
                    >
                        <FaBan/>
                    </button>  
                
            </div>

     </div>
    )
}



    //============================
    //============================
    return (
    
        <div style={{
                padding:"1rem",
                height:"100%",
                overflow:"auto"
            }}>
         
          
          
         
            <div className='flex-center-center jc-start'>
                <div>
                    {
                      languageDisplay
                      ?languageDisplay.shopPayment
                        ?languageDisplay.shopPayment
                        :"Service Payment"
                      :"Service Payment"   
                    }
                </div>
                <div>
                    <MdEdit
                        className='md-icon'

                        onClick={e=>{
                            setShowCheckServiceFee(true)
                        }}
                    />
                </div>
            </div>

          
         
            {showCheckServiceFee&&
                renderCheckServiceFee()
            }

            {showShopPayment&&
                renderShopPayment()
            }
           

        </div>
    )
}

export default Payment