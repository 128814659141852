

import React from 'react';
//import console from '../../util/consoleVerify'
//import {MdClose} from 'react-icons/md';
import {
    MdChevronLeft,MdChevronRight,
    MdLastPage,MdFirstPage,
    MdClose,
} from 'react-icons/md';

const renderModalImage=({
    show,setShow,
    imgSrc,modalImageWidth,
    setModalImageWidth,
    isYoutube,setIsYoutube,
    imageSrcArray,
    imageSrcIdx,setImageSrcIdx
    })=>{


const imageSrcArrayLength=imageSrcArray.length

const renderSingleImage=(u,idxU)=>{
    
    let checkPdf=false

    if(typeof u.src == "string"){


        let tempSplitArrayForPdf=u.src.split(".")
        if(tempSplitArrayForPdf.length==2){
            if( tempSplitArrayForPdf[1]==="pdf"||
                tempSplitArrayForPdf[1]==="PDF"
            ){
                checkPdf=true
            }
        }
    }


    if(idxU==imageSrcIdx){


        if(u.isYoutube){
            return (<div
                style={{width:`${modalImageWidth}%`,
                        height:`${modalImageWidth}%`,
                    }}
             >  
                <embed 
                    style={{width:`100%`,height:`100%`}}
                    //src={imgSrc}
                    src={u.src}
                />
            </div>)
        }


        else if(checkPdf) {
            return(
                <embed className="" 

                    type="application/pdf"
                    src={u.src}
                
                    style={{
                        width:`${modalImageWidth}%`,
                        height:`${modalImageWidth}%`
                    }} 
            />
            )
        }
        else {
            return(
                <img className="" 

                    src={u.src}
                    
                    style={{
                        width:`${modalImageWidth}%`,
                        objectFit:"cover",
                    }} 
            />
            )
        }
    }

   
    
}


return(
    show
    ?<div className="Modal-background">
        <div style={{width:"95%",height:"92%",
                     border:"solid black 1px",
                     position:"relative",
                     overflow:"scroll"}}>

            <div style={{position:"absolute9999",backgroundColor:"white"}}>
                    <MdClose className='lg-icon'
                        style={{
                            fontSize:"2.5rem",
                            marginRight:"2rem"
                        }}
                        onClick={e=>{
                            setShow(false)
                            setIsYoutube(false)
                        }} 
                    />

                    <MdFirstPage className='lg-icon'
                        onClick={e=>{
                            if(imageSrcIdx>0){
                                setImageSrcIdx(0)
                            }
                        }} 
                    />
                    
                    <MdChevronLeft className='lg-icon'
                        onClick={e=>{
                            if(imageSrcIdx>0){
                                setImageSrcIdx(imageSrcIdx-1)
                            }
                        }}
                    />
                    
                    <MdChevronRight className='lg-icon'
                        onClick={e=>{
                            if(imageSrcIdx<imageSrcArrayLength-1){
                                setImageSrcIdx(imageSrcIdx+1)
                            }
                        }}
                    />
                    
                    <MdLastPage className='lg-icon'
                        onClick={e=>{
                            if(imageSrcIdx<imageSrcArrayLength-1){
                                setImageSrcIdx(imageSrcArrayLength-1)
                            }
                        }}
                    />

                    
            </div>

            {
                imageSrcArray.map((u,idxU)=>{
                    return (
                        renderSingleImage(u,idxU)
                    )
                }) 
            }

            {
            /*
            //isYoutube
            imageSrcArray[imageSrcIdx].isYoutube
            ?<div
                style={{width:`${modalImageWidth}%`,
                        height:`${modalImageWidth}%`,
                    }}
             >  
                <embed 
                    style={{width:`100%`,height:`100%`}}
                    //src={imgSrc}
                    src={imageSrcArray[imageSrcIdx].src}
                />
            </div>
            : <img className="" 
                    //src={imgSrc} 
                    src={imageSrcArray[imageSrcIdx].src}
                    
                    style={{
                        width:`${modalImageWidth}%`,
                        objectFit:"cover",
                    }} 
                />
            */
           
            }


            <div  style={{position:"fixed",top:"5px",width:"90vw"}}>
                <input type="range" min="10" max="200"   
                    value={modalImageWidth}
                    onChange={e=>{setModalImageWidth(e.target.value)}} 
                />
            </div>       
        </div>
     
    </div>
    :null
)}

export default renderModalImage




  /*
           
*/