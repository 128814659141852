


import React from 'react'

import {MdVisibility,MdVisibilityOff,
    MdEdit,MdAddCircle,MdDelete,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineCheckBox,
    MdRefresh,
    MdCheckBox,
    MdCheckBoxOutlineBlank
} from 'react-icons/md';
import {FaBan,FaCheck} from 'react-icons/fa'
import console from '../../util/consoleVerify'


function AddUserModal({
    submitCheck,
    submitCancel,
    basicData,
    languageDisplay,
    checkChar
}){

    const refId=React.createRef()
    const refUserName=React.createRef()
    const refUserPassword=React.createRef()
    const refUserPassword2=React.createRef()
    const refUserLevel=React.createRef()
    const refName=React.createRef()
    const refSurname=React.createRef()
    const refCheck=React.createRef()
    const refBan=React.createRef()

    const tempArray=[
        {   key:"id",
            lb:languageDisplay
                ?languageDisplay.id
                    ?languageDisplay.id
                    :"ID"
                :"ID",
            type:"number",
            ref:refId
        },
        {   key:"username",
            lb: languageDisplay
                ?languageDisplay.username
                    ?languageDisplay.username
                    :"USER NAME"
                :"USER NAME",
            type:"text",
            ref:refUserName
        },
        {   key:"userPassword",
            lb: languageDisplay
                ?languageDisplay.userPassword
                    ?languageDisplay.userPassword
                    :"USER PASSWORD"
                :"USER PASSWORD",
            type:"text",
            ref:refUserPassword
        },
        
        {   key:"userPassword2",
            lb: languageDisplay
                ?languageDisplay.userPassword2
                    ?languageDisplay.userPassword2
                    :"CONFIRM USER PASSWORD"
                :"CONFIRM USER PASSWORD",
            type:"text",
            ref:refUserPassword2
        },
        {
            key:"userLevel",
            lb: languageDisplay
                ?languageDisplay.userLevel
                    ?languageDisplay.userLevel
                    :"USER LEVEL"
                :"USER LEVEL",
            type:"text",
            ref:refUserLevel
        },
        {
            key:"name",
            lb: languageDisplay
                ?languageDisplay.name
                    ?languageDisplay.name
                    :"NAME"
                :"NAME",
            type:"text",
            ref:refName
        },
        {
            key:"surname",
            lb: languageDisplay
                ?languageDisplay.surname
                    ?languageDisplay.surname
                    :"SURNAME"
                :"SURNAME",
            type:"text",
            ref:refSurname
        }

    ]
        
    const [hidePassword,setHidePassword]=React.useState(true)
    
    const [inputState,setInputState]=React.useState({
        id:"",username:"",
        userPassword:"",userPassword2:"",
        userPasswordStar:"",userPassword2Star:"",
        userLevel:"staff",name:"",surname:"",toDelete:"false"
    })


    const renderSelect=(v,idxV)=>{
        return (
            <select
                type="select"
                ref={v.ref}
                value={inputState["userLevel"]}

                onKeyDown={e=>{
                    if(e.key==="Enter"){
                        if(idxV==tempArray.length-1){
                            refCheck.current.focus()
                        }
                        else {
                            tempArray[idxV+1].ref.current.focus()
                        }
                    }
                }}

                onChange={e=>{
                    setInputState({...inputState,
                        userLevel:e.target.value
                    })
                }}
            >
                {
                 basicData.userLevel.map((ul,idxUl)=>{
                    return (
                        <option
                            key={`ul-${idxUl}`}
                            value={ul}
                        >{ul}</option>
                    )
                 })   
                }
            </select>
        )
    }


    return(
    <div className="Modal-background">

        <div className="Modal-box2"
             style={{width:"90%"}}
        >
            <div className="Modal-header">
                <h5>
                    {
                      languageDisplay
                      ?languageDisplay.addUserModal
                        ?languageDisplay.addUserModal
                        :"ADD USER"
                      :"ADD USER"
                    }
                </h5>
            </div>

            <div className='Modal-box'>
                {
                    tempArray.map((v,idxV)=>{
                        return (
                            <div key={`idxV-${idxV}`}
                                 className='w-100'
                                 style={{display:"flex",
                                        justifyContent:"flex-start",
                                        margin:"1rem 0"
                                }}
                            >
                                <div className='w-20'>
                                    {
                                        languageDisplay
                                        ?languageDisplay[v.key]
                                            ?languageDisplay[v.key]
                                            :""
                                        :""
                                    }
                                </div>

                                {v.key==="userPassword"||v.key==="userPassword2"
                                     
                                ?<div className='w-80'
                                    style={{position:"relative"}}
                                >
                                    <input
                                        className='w-100'
                                        style={{color:hidePassword
                                                ?"transparent"
                                                :null
                                        }}

                                        ref={v.ref}
                                        type={"text"}
                                        value={
                                            inputState[v.key]
                                        }

                                        onKeyDown={e=>{
                                           
                                            if(e.key==="Enter"){
                                                if(idxV==tempArray.length-1){
                                                    refCheck.current.focus()
                                                }
                                                else {
                                                    tempArray[idxV+1].ref.current.focus()
                                                }
                                            }
                                        }}
                                       
                                        onChange={e=>{
                                                                                          
                                                const lastChar=e.target.value.slice(-1)

                                                let tempResult=checkChar(lastChar)
                                                if(e.target.value.length>15){
                                                    tempResult=false
                                                }

                                                let tempStar=""
                                                for(let z=0;z<e.target.value.length;z++){
                                                    tempStar=tempStar+"*"
                                                }
                                                

                                                if(tempResult||e.target.value===""){
                                                    setInputState({
                                                        ...inputState,
                                                        [v.key]:e.target.value,
                                                        [v.key+"Star"]:tempStar
                                                    })
                                                }

                                        }}

                                    />

                                    {hidePassword&&
                                    <div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {inputState[v.key+"Star"]}
                                    </div>
                                    }



                                    {
                                        hidePassword
                                        ?<MdVisibilityOff 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                        :<MdVisibility 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                    }
                                </div>
                                :<div className='w-80'>
                                    {v.key==="userLevel"
                                    ?renderSelect(v,idxV)
                                    :<input
                                        className='w-100'
                                        ref={v.ref}
                                        type={v.type}
                                        value={inputState[v.key]}

                                        onKeyDown={e=>{
                                            if(e.key==="Enter"){
                                                if(idxV==tempArray.length-1){
                                                    refCheck.current.focus()
                                                }
                                                else {
                                                    tempArray[idxV+1].ref.current.focus()
                                                }
                                            }
                                        }}

                                        onChange={e=>{
                                        setInputState({
                                            ...inputState,
                                            [v.key]:e.target.value
                                        }) 

                                        }}
                                    />
                                    }
                                </div>
                                }
                            </div>
                        )
                    })
                }
            </div>
           

            <div className="Modal-footer"
                style={{marginTop:"2rem"}}
            >
                   

                   <div>
                        <button           
                            ref={refCheck}

                            onClick={()=>{
                                submitCheck(inputState)  
                            }}
                        >
                            <FaCheck/>
                        </button>
                    </div>

                    <div>
                        <button                       
                            ref={refBan}
                            onClick={()=>{
                                submitCancel(false)
                            }}
                        >
                            <FaBan/>
                        </button>
                    </div>
            </div>

        </div>
    </div>
    )

}

export default AddUserModal

