import React from 'react'

import {MdVisibility,MdVisibilityOff,
    MdEdit,MdAddCircle,MdDelete,
    MdOutlineCheckBoxOutlineBlank,
    MdOutlineCheckBox,
    MdRefresh,
    MdCheckBox,
    MdCheckBoxOutlineBlank
} from 'react-icons/md';
import {FaBan,FaCheck} from 'react-icons/fa'
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'


function ChangeShopPasswordModal({
    submitCheck,
    submitCancel,
    languageDisplay,
    checkChar

}){
    const refShopPassword=React.createRef()
    const refNewShopPassword=React.createRef()
    const refConfirmNewShopPassword=React.createRef()
    const refCheck=React.createRef()
    const refBan=React.createRef()


    const tempArray=[

        {   
            key:"shopPassword",
            lb: languageDisplay
                ?languageDisplay.existingShopPassword
                    ?languageDisplay.existingShopPassword
                    :"EXISTING SHOP PASSWORD"
                :"EXISTING SHOP PASSWORD"
            ,
            type:"password",
            ref:refShopPassword
        },
        {
            key:"newShopPassword",
            lb: languageDisplay
                ?languageDisplay.newShopPassword
                    ?languageDisplay.newShopPassword
                    :"NEW SHOP PASSWORD"
                :"NEW SHOP PASSWORD"
            ,
            type:"password",
            ref:refNewShopPassword
        },
        {
            key:"confirmNewShopPassword",
            lb: languageDisplay
                ?languageDisplay.confirmNewShopPassword
                    ?languageDisplay.confirmNewShopPassword
                    :"CONFIRM NEW SHOP PASSWORD"
                :"CONFIRM NEW SHOP PASSWORD"
            ,
            type:"password",
            ref:refConfirmNewShopPassword
        },
    ]

    const [hidePassword,setHidePassword]=React.useState(true)

    const [inputState,setInputState]=React.useState({
        shopPassword:"",
        shopPasswordStar:"",
        newShopPassword:"",
        newShopPasswordStar:"",
        confirmNewShopPassword:"",
        confirmNewShopPasswordStar:""
    })



    return (
    <div className="Modal-background">

        <div className="Modal-box2"
             style={{width:"90%"}}
        >
            <div className="Modal-header">
                <h5>
                    {
                        languageDisplay
                        ?languageDisplay.changeShopPassword
                            ?languageDisplay.changeShopPassword
                            :"CHANGE SHOP PASSWORD"
                        :"CHANGE SHOP PASSWORD"
                    }
                </h5>
            </div>
        
          
            <div className='Modal-box'>
                {
                    tempArray.map((v,idxV)=>{
                        return (
                            <div className='w-100'
                                 key={idxV}
                                 style={{display:"flex",
                                        justifyContent:"flex-start",
                                        margin:"1rem 0"
                                }}
                            >
                                <div className='w-20'>
                                    {v.lb}
                                </div>

                                <div className='w-80'
                                  style={{position:"relative"}}
                                >
                                    <input
                                        className='w-100'

                                        style={{color:(hidePassword&&v.key==="shopPassword")||
                                                      (hidePassword&&v.key==="newShopPassword")||
                                                      (hidePassword&&v.key==="confirmNewShopPassword")
                                                    ?"transparent"
                                                    :null
                                              }}


                                        type={"text"}
                                        ref={v.ref}
                                        value={
                                            inputState[v.key]
                                        }

                                        onKeyDown={e=>{



                                            if(e.key==="Enter"){
                                                
                                                if(idxV===tempArray.length-1){
                                                    refCheck.current.focus()
                                                }
                                                else {
                                                    tempArray[idxV+1].ref.current.focus()
                                                }
                                                
                                             
                                            }
                                        }}


                                        onChange={e=>{
                              
                                                const lastChar=e.target.value.slice(-1)

                                                let tempResult=checkChar(lastChar)
                                                if(e.target.value.length>15){
                                                    tempResult=false
                                                }

                                                let tempStar=""
                                                for(let z=0;z<e.target.value.length;z++){
                                                    tempStar=tempStar+"*"
                                                }
                                                

                                                if(tempResult||e.target.value===""){
                                                    setInputState({
                                                        ...inputState,
                                                        [v.key]:e.target.value,
                                                        [v.key+"Star"]:tempStar
                                                    })
                                                }

                                        }}
                                      
                                    />


                                    {(v.key==="shopPassword"&&hidePassword)||
                                    (v.key==="newShopPassword"&&hidePassword)||
                                    (v.key==="confirmNewShopPassword"&&hidePassword)
                                    ?<div
                                         style={{position:'absolute',top:"0.2rem",left:'0.3rem'}}    
                                    >   {inputState[v.key+"Star"]}
                                    </div>
                                    :null
                                    }

                                    {
                                        hidePassword
                                        ?<MdVisibilityOff 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                        :<MdVisibility 
                                        className="sm-icon" 
                                        style={{position:'absolute',top:"0.2rem",right:'0.3rem'}}
                                        onClick={e=>{
                                            setHidePassword(!hidePassword)
                                        }}
                                        />
                                    }

                                </div>
                            </div>
                        )
                    })
                }
            </div>
           

            <div className="Modal-footer"
                style={{marginTop:"2rem"}}
            >
                   

                   <div>
                        <button                       
                            ref={refCheck}
                            onClick={()=>{
                                submitCheck(inputState)
                            }}
                        >
                            <FaCheck/>
                        </button>
                    </div>

                    <div>
                        <button           
                            ref={refBan}            
                            onClick={()=>{
                                submitCancel(false)
                            }}
                        >
                            <FaBan/>
                        </button>
                    </div>
            </div>

        </div>
    </div>
    )
}


export default ChangeShopPasswordModal
