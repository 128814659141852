import React from 'react';

import {FaBan,FaCheck} from 'react-icons/fa';
import {Link} from 'react-router-dom';
import console from '../../util/consoleVerify'

import '../../component/mnavbar/Mnavbar.css';

function LogOutToolForUser(props) {

   const {//useShopLogOut,haveShopToken,haveUserToken,userName,
      useShopLogOut,
      tokenSt,
      setReloadCheckToken,
      user,
      useHomeIcon,
      myheader,
      useBillWaveIcon,
      formTemplate,
      Language,
      
      languageDisplay,
      console
      
   }=props
  
   //console.log(formTemplate)

   const shouldRender= React.useRef(true)
   React.useEffect(()=>{
      return () => {
         //console.log('end................................')
         shouldRender.current=false
      } 
   },[])

   const {haveShopToken,haveUserToken}=tokenSt
  //const {myheader,tokenSt,setReloadCheckToken}=React.useContext(MainContext)
  //const {reloadCheckToken,haveShopToken,haveUserToken,userName}=tokenSt
  const refHome=React.createRef()
  const refLogin=React.createRef()


   const [showConfirmBeforeUserLogout,
         setShowConfirmBeforeUserLogout
        ]=React.useState(false)

   
//=======================

const renderUserLogOutWaringModal=()=>{
   return(
   <div className="Modal-background">
      <div className="Modal-box" 
           style={{
               margin:"5rem",
               minWidth:"60%",
               minHeight:"30%",
               zIndex:"1000"

           }}>
          <div className="Modal-header" 
               style={{
                  fontSize:"1.2rem",
                  
               }}
          >
            {"formTemplate.confirmBeforeUserLogout.formHead"}
          </div>
          <div className="Modal-footer">
                <div>
                    <button
                        onClick={e=>{
                 
                           localStorage.removeItem('userauthorization');
                           localStorage.removeItem('username');
                           setShowConfirmBeforeUserLogout(false)
                           refLogin.current.click()
                           //setReloadCheckToken(true)
                           //setReloadCheckToken(true)
                           window.location.reload()
                        
                        }}
                        
                    >
                        <FaCheck/>
                    </button>
                </div>
                <div>
                    <button
                        onClick={e=>{
                           setShowConfirmBeforeUserLogout(false)
                              //if(setShow){setShow(false)}
                        }}
                     >
                        <FaBan/>
                    </button>
                </div>

            </div>
      </div>
   </div>
   )
}


//=======================

//=======================
return (
<div className="sc-hide" style={{position:"fixed",right:"0.1rem",top:"0.1rem"}}>


   
   <div
        style={{borderRadius:"1.2rem",padding:"0 0.5rem",height:"2rem",
                display:"flex",justifyContent:"space-between",alignItems:"center",
                flexWrap:"nowrap"}}>
        
       {user //haveUserToken
       ?<div className="flex-center-center h-100"
             style={{
                  color:"white",
                  margin:"0 0.4rem"
               }}
        >
          {`${user.username}@${user.shopId}`}
         </div>
       :null
       }

        {/*
         haveUserToken
          ?<div className="iconbox"
               onClick={e=>{
                  console.log("user logout...")

                  refLogin.current.click()
               }}
          >
            <Ticon iconName="MdVpnKey" className="navIcon"
                    Language={Language}
             />
          </div>
          
          :null
         */}

   </div>

  
   <div style={{display:"none"}}>
         <div>
            <Link ref={refHome} to="/cloud/home"/>
            <Link ref={refLogin} to="/cloud/login"/>
         </div>
   </div>


</div>

)}

LogOutToolForUser.defaultProps={
   useShopLogOut:true,
   useHomeIcon:true
}
 


export default LogOutToolForUser;
