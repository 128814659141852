import React from 'react';
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import {MdDelete} from 'react-icons/md';

import AddPage from './AddPage'
import console from '../../util/consoleVerify'


function ModalAddPage({
    setShow,
    submitFunction,
    showError,
    setShowError,
    editData,

    addInputState,
    setAddInputState,
    currentLink,prevLink,
    blankAddInputState,
    stringArray,
    lastRecordId,
    submitAdd,
    dfCommon,setDfCommon,
    keyArray,
    basicDataSt,
    dataLabel2,
    setCancel,
    showPhoto
    }){


    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 

    const [showModalError,setShowModalError]=React.useState(false)
    //const {id,parentId,allDeleteId,routeAuth,detail}=editData
//===============================
//===============================
    return(

    <div style={{
        position:"fixed",top:"0",left:"0",
        height:"100vh",width:"100vw",
        zIndex:"200",
        background:"radial-gradient(circle, rgba(220,220,220,1) 0%, rgba(124,124,128,1) 100%)"

    }}
    
    >
       

                <AddPage
                    dataLabel={dataLabel2}
                    addInputState={addInputState}
                    setAddInputState={setAddInputState}
                    currentLink={currentLink}
                    prevLink={prevLink}
                    blankAddInputState={blankAddInputState}
                    stringArray={stringArray}
                    lastRecordId={lastRecordId}
                    submitAdd={submitAdd}
                    dfCommon={dfCommon}
                    setDfCommon={setDfCommon}
                    keyArray={keyArray}
                    basicData={basicDataSt.basicData}
                    setCancel={setCancel}
                    showPhoto={showPhoto}
                />
  

        
    </div>
    )
}

ModalAddPage.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}


export default ModalAddPage
