import React from 'react';
import {FaCheck,FaBan,FaQuestion,FaDatabase,FaLongArrowAltRight} from 'react-icons/fa';
import {MdDelete} from 'react-icons/md';

import EditPageForMaster from './EditPageForMaster'
import console from '../../util/consoleVerify'


function ModalEditPage({
    setShow,
    submitFunction,
    showError,
    setShowError,
    editData,

    editInputState,
    setEditInputState,
    currentLink,prevLink,
    blankAddInputState,
    stringArray,
    lastRecordId,
    submitEdit,
    submitEditWithNewId,
    submitCopyRecord,

    dfCommon,setDfCommon,
    keyArray,
    basicDataSt,
    dataLabel2,
    setCancel,
    showPhoto,
    useUpdateShopAfterShopPayment,

    submitUpdateShopFromShopPayment
    }){


    const refModalConfirm1 = React.createRef()
    const refModalConfirm2 = React.createRef() 

    const [showModalError,setShowModalError]=React.useState(false)
    //const {id,parentId,allDeleteId,routeAuth,detail}=editData
//===============================
//===============================
    return(

    <div style={{
        position:"fixed",top:"0",left:"0",
        height:"100vh",width:"100vw",
        zIndex:"200",
        background:"radial-gradient(circle, rgba(220,220,220,1) 0%, rgba(124,124,128,1) 100%)"

    }}
    
    >
       

                <EditPageForMaster
                    dataLabel={dataLabel2}
                    editInputState={editInputState}
                    setEditInputState={setEditInputState}
                    currentLink={currentLink}
                    prevLink={prevLink}
                    submitEdit={submitEdit}
                    submitEditWithNewId={submitEditWithNewId}
                    submitCopyRecord={submitCopyRecord}
                    stringArray={stringArray}
                    dfCommon={dfCommon}
                    setDfCommon={setDfCommon}
                    lastRecordId={lastRecordId}
                    keyArray={keyArray}
                    basicData={basicDataSt.basicData}
                    setCancel={setCancel}
                    showPhoto={showPhoto}

                    useUpdateShopAfterShopPayment={useUpdateShopAfterShopPayment}

                    submitUpdateShopFromShopPayment={submitUpdateShopFromShopPayment}
                />
  

        
    </div>
    )
}

ModalEditPage.defaultProps={
    setShow:()=>{},
    submitFunction:()=>{}
}

export default ModalEditPage
