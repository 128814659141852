import React from "react"

import Table from './Table'
import RenderBadge2 from './RenderBadge2'
import MnavbarMaster from '../../component/mnavbar/MnavbarMaster'
import fakeAxiosUtil from './fakeAxiosUtil'
import cosmeticUtil from '../../util/cosmeticUtil'
import ModalConfirm from './ModalConfirm'
import ModalUpload from './ModalUpload'


import * as XLSX from 'xlsx';

import {MainContext} from '../../context/MainContext'
import console from '../../util/consoleVerify'


const {checkWindowLandscape}=cosmeticUtil

//const {dataFormat}=dataTemplate

function ItemUpload(){

    //const [tableTemplate,setTableTemplate] = React.useState(dataFormat)
    
    const {
        dataLabel2,setDataLabel2,

        setPrevLink,
        setCurrentLink,
        //recordPerPage,
        uploadPageData,setUploadPageData,
        uploadCurrentPage,setUploadCurrentPage,
        uploadCurrentPageData,setUploadCurrentPageData,
        uploadTotalPage,setUploadTotalPage,
        uploadEditInputState,setUploadEditInputState,
        keyArrayWithPhotoUrlForUpload,
        tempUploadTableTemplate,
        setTempUploadTableTemplate,
        
        submitUploadData,

        languageDisplay,
        
    }=React.useContext(MainContext)

    const [showConfirm,setShowConfirm]=React.useState(false)
    const [showError,setShowError]=React.useState(false)

    const recordPerPage=100

    //const localDate="2023-05-01T00:00"
    //isoDate =        2023-04-30T17:00:00.000Z

    //local Date = 2023-05-01
    //isoDate      2023-05-01T00:00:00.000Z
    //en-GB        01/05/2023, 07:00:00

    //const localDate="2023-05-01"
    //const localDate="2023-05-01T00:00:00.000Z"
    //console.log(localDate)
    //console.log(new Date(localDate).toISOString())
    //console.log(new Date(localDate).toLocaleString('en-GB'))

    let tempKeyArray=[]

    keyArrayWithPhotoUrlForUpload.map(a=>{
        if(a==="createdAt"||a==="updatedAt"){

        }
        else {
            tempKeyArray=[...tempKeyArray,a]
        }
    })

    //console.log('tempKeyArray....')
    //console.log(tempKeyArray)

    const saveTableTemplateFunc=(tableTemplate)=>{
    //console.log(tableTemplate)
    }

    const setShowConfirmAndError=(val)=>{
        setShowConfirm(val)
        setShowError(val)
    }

    const onChange = (e) => {

        const [file] = e.target.files;
        const reader = new FileReader();
    
        reader.onload = (evt) => {
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
          //console.log(data);

          let tempArray = data.split("\n")
          tempArray.pop()

          //const tempLength=tempArray.length
          //console.log(tempLength)
          let tempData=[]
          let colHead=[]

          let checkKeyOfId=false
          
          tempArray.map((a,idxA)=>{
                //console.log(a)

                let checkValueOfId=false

                if(idxA===0){
                    colHead=a.split(",")
                    //console.log(colHead)
                    colHead.map(d=>{
                        if(d==="id"){
                            checkKeyOfId=true
                        }
                    })
                }

                else if(idxA>0){

                    let tempObj={}

                    //let tempObj={_id:a.id}
                    const tempArray2=a.split(",")

                    tempArray2.map((c,idxC)=>{
    
                        tempObj={...tempObj,[colHead[idxC]]:c}

                    })

                    const tempId=parseFloat(tempObj["id"])

                    if(tempId>0){
                        tempData=[...tempData,tempObj]
                    }
    
                }
            
          })

          //console.log('tempData........')
          //console.log(tempData)

          let tempData2=[]

          if(tempData.length>0){
        
                tempData.map((da,idxDa)=>{

                        let tempObj={}

                        let checkId=false

                                tempKeyArray.map((b,idxB)=>{

                                   
                                        const key0_5=b.substring(0,5)


                                        let tempValue=null

                                        if(key0_5==="itemD"){
                                   
                                            const temp3=new Date(da[b])
                               
                                            if(temp3){
                                                if(temp3=="Invalid Date"){
                                                    tempValue=null
                                                }
                                                else {
                                                    tempValue=temp3.toISOString()
                                                }
                                            }

                                        }
                                        else if(b==="id"){
                                            const tempId=parseFloat(da[b])

                                            if(tempId>0){
                                                checkId=true
                                                tempValue=tempId
                                            }
                                        }
                                        else if(key0_5==="itemN"){
                                            const tempNumber=parseFloat(da[b])
                                            if(tempNumber>0||tempNumber<0||tempNumber===0){
                                                tempValue=tempNumber
                                            }

                                        }
                                        else if(key0_5==="itemB"||b==="active"){
                                            
                                            if(da[b]===true||da[b]==="true"||da[b]==="TRUE"||da[b]==="True"){
                                                tempValue=true
                                            }
                                            else if(da[b]===false||da[b]==="false"||da[b]==="FALSE"||da[b]==="False"){
                                                tempValue=null
                                            }
                                            else {
                                                tempValue=false
                                            }
                                        }
                                        else if(b==="type"||b==="userId"){
                                            const tempNumber=parseInt(da[b])
                                            if(tempNumber>0||tempNumber<0||tempNumber===0){
                                                tempValue=tempNumber
                                            }

                                        }
                                        else if(key0_5==="itemS"||b==="shopId"){
                                            tempValue=da[b]
                                        }

                                        else if(b==="photoUrl1"){
                                            console.log("photoUrl1")
                                            console.log(da[b])


                                            if(da[b]){

                                                const tempA=da[b].split("&_&")
                                                                                                
                                                tempValue=tempA
                                            }
                                        }

                                        tempObj={...tempObj,[b]:tempValue}
                                                                      
                                })

                        if(checkId){
                            tempData2=[...tempData2,tempObj]
                        }
                        
                })


                console.log('pass.......................')
                console.log(tempData2)

          }


        
          const tempLength=tempData2.length
          const tempTotalPage=Math.ceil(tempLength/recordPerPage)
       
          let tempArray2=[]
          let tempSubArray2=[]

          for (let x=0;x<tempLength;x++){
            for(let y=1;y<=recordPerPage;y++){
                const idx=recordPerPage*x+y-1
                tempSubArray2=[...tempSubArray2,tempData2[idx]]
            }
            tempArray2=[...tempArray2,tempSubArray2]
            tempSubArray2=[]
          }

          setUploadPageData(tempArray2)
          setUploadCurrentPage(1)          
          setUploadTotalPage(tempTotalPage)


        };
        
        reader.readAsBinaryString(file);
      };


    return (       

    <div style={{border:"",height:"100vh",width:"100vw",overflow:"hidden"}}>
        <div style={{height:checkWindowLandscape()?"5%":"8%",width:"100%"}}>
            <MnavbarMaster
                 setPrevLink={setPrevLink}          
                 setCurrentLink={setCurrentLink}
            />
        </div>

        {
        uploadCurrentPageData&&
        <div style={{height:checkWindowLandscape()?"5%":"8%",
                     width:"100%"}}>
            {
                RenderBadge2({
                    totalPage:uploadTotalPage,
                    currentPage:uploadCurrentPage,
                    setCurrentPage:setUploadCurrentPage,
                    editInputState:uploadEditInputState,
                    setEditInputState:setUploadEditInputState,
                    setShowConfirm:setShowConfirm,
                    badgeColor:"#E0B589"
                    
                })
            }
        </div>
        }

        {
        uploadCurrentPageData&&tempUploadTableTemplate
        ?<div style={{ display:"flex",
                      height:checkWindowLandscape()?"90%":"84%",width:"100%"}}>
            
            <div 
                style={{height:"100%",width:`100%`
            }}>
              
                <Table
                    dataLabel={tempUploadTableTemplate}
                    setDataLabel={setTempUploadTableTemplate}
                    pageData={uploadCurrentPageData}
                    setPageData={()=>{}}
                    editData={uploadEditInputState}
                    setEditData={()=>{}}
                    keyArray={keyArrayWithPhotoUrlForUpload}
                />
               
            </div>
        </div>
        :<div   className="flex-center-center"
                style={{
                    height:"90%",
                    width:"100%"
                }}
        >
            <input 
                style={{
                    width:"85%",
                    border:"1px solid white"
                }}
                type="file" onChange={onChange}
            />
        </div>
        
        }
         
        {showConfirm&&uploadCurrentPageData
        ?<ModalUpload
               setShow={setShowConfirm}
               submitFunction={submitUploadData}
  
               editData={uploadCurrentPageData}
               languageDisplay={languageDisplay}

        />
        :null
        }   


    </div>

    )

}


export default ItemUpload